import { Pose } from '@ng-cloud/badger-core/models/pose';

export class Costmap {
  width: number;
  height: number;
  resolution: number;
  data: number[];
  pose: Pose;

  deserialize(json: any): this {
    this.width = json.width;
    this.height = json.height;
    this.resolution = json.resolution;
    this.data = json.data;
    this.pose = json.pose && new Pose().deserialize(json.pose);
    return this;
  }
}
