import { AbstractApiModel } from './abstract-api-model';
import { HasOrganization, Organization } from '@ng-cloud/badger-core';

export class VpnConfig extends AbstractApiModel<VpnConfig> implements HasOrganization {
  vpnFile: string;
  status: string;
  organizationId: number;

  organization: Organization;

  deserialize(json: any): this {
    this.vpnFile = json.vpn_file;
    this.status = json.status;
    this.organizationId = json.organization_id;
    return super.deserialize(json);
  }
}
