<div>
    <h3>Primary:</h3>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let primary of colors">
            <div class="p{{primary}} swatch">
                {{primary}} Contrast
            </div>
        </div>
    </div>
</div>
<div>
    <h3>Accent:</h3>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let accent of colors">
            <div class="a{{accent}} swatch">
                {{accent}} Contrast
            </div>
        </div>
    </div>
</div>
<div>
    <h3>Warn:</h3>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let warn of colors">
            <div class="w{{warn}} swatch">
                {{warn}} Contrast
            </div>
        </div>
    </div>
</div>
<div>
    <h3>Foreground:</h3>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let foreground of foregrounds">
            <div class="foreground-{{foreground}} swatch">
            </div>
            <div>
                <span>{{foreground}}</span>
            </div>
        </div>
    </div>
</div>

<div>
    <h3>Background:</h3>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let background of backgrounds">
            <div class="background-{{background}} swatch">
            </div>
            <div>
                <span>{{background}}</span>
            </div>
        </div>
    </div>
</div>
