import { AnalyticGroup, AnalyticsType, ApiModel, IPagination } from '@ng-cloud/badger-core';
import { Observable, Subject } from 'rxjs';
import { TimeGroup } from '../../analytics-common/services/top-filter.service';

export interface ApiParams {
  start_time: string | null;
  end_time: string | null;
  group: TimeGroup;
  store_ids: string;
  category_ids: string;
  sale_status_ids: number;
  gtin_ids: string;
  department_ids: string;
  fmcg_org_ids?: string;
  retail_org_ids?: number;
}

export interface IWidgetData<T> {
  title: string,
  widgetConfig: Subject<IPagination>,
  resetPage$: Subject<void>,
  chartData: { total: number, data: T[] },
  csvDownloadMethod: (params: ApiParams, orgType: string, analyticsType: AnalyticsType, analyticsGroup: AnalyticGroup, analyticsSubGroup: AnalyticGroup) => Observable<ApiModel>,
  infoHTML: string,
  qaTag?: string,
  analyticsType: AnalyticsType,
  analyticsGroup?: AnalyticGroup,
  analyticsSubGroup?: AnalyticGroup,
  csvLoading?: boolean,
  dataLoading?: boolean
}

export interface IWidgetDataByDates<T> extends IWidgetData<T> {
  valueName: string;
  valueType?: string;  // $,%
  tooltipCountLabel: string;
  tooltipNameLabel: string;
  additionalTooltipLabel?: string;
}

export interface IWidgetColumnByDateData<T> extends IWidgetData<T> {
  valueType: string;  // $,%
  tooltipNameLabel: string;
}

export enum ChartType {
  BY_CATEGORY = 1,
  DATA_BY_DATE = 2,
  TREEMAP = 3,
  POS_BAR_BY_DATE = 4
}

export enum OrgType {
  FMCG = 'fmcg',
  RETAIL = 'retail'
}

export enum EChartDataType {
  OOS_BY_CATEGORIES = 'OOS_BY_CATEGORIES',
  OSA_BY_CATEGORIES = 'OSA_BY_CATEGORIES',
  PM_BY_CATEGORIES = 'PM_BY_CATEGORIES',
  OSA_BY_DATE_BY_CATEGORIES = 'OSA_BY_DATE_BY_CATEGORIES',
  OSA_BY_DATE_BY_STORES = 'OSA_BY_DATE_BY_STORES',
  OSA_BY_DATE_BY_PRODUCTS = 'OSA_BY_DATE_BY_PRODUCTS',
  OSA_BY_DATE_BY_DEPARTMENTS = 'OSA_BY_DATE_BY_DEPARTMENTS',
  OSA_BY_DATE_BY_GOODS = 'OSA_BY_DATE_BY_GOODS',
  PM_BY_DATE_BY_CATEGORIES = 'PM_BY_DATE_BY_CATEGORIES',
  PM_BY_DATE_BY_STORES = 'PM_BY_DATE_BY_STORES',
  PM_BY_DATE_BY_PRODUCTS = 'PM_BY_DATE_BY_PRODUCTS',
  PM_BY_DATE_BY_DEPARTMENTS = 'PM_BY_DATE_BY_DEPARTMENTS',
  PM_BY_DATE_BY_GOODS = 'PM_BY_DATE_BY_GOODS',
  OOS_BY_DATE_BY_CATEGORIES = 'OOS_BY_DATE_BY_CATEGORIES',
  OOS_BY_DATE_BY_STORES = 'OOS_BY_DATE_BY_STORES',
  OOS_BY_DATE_BY_PRODUCTS = 'OOS_BY_DATE_BY_PRODUCTS',
  OOS_BY_DATE_BY_DEPARTMENTS = 'OOS_BY_DATE_BY_DEPARTMENTS',
  OOS_BY_DATE_BY_GOODS = 'OOS_BY_DATE_BY_GOODS',
  PM_BY_CATEGORIES_BY_PRODUCT = 'PM_BY_CATEGORIES_BY_PRODUCT',
  OOS_BY_CATEGORIES_BY_PRODUCT = 'OOS_BY_CATEGORIES_BY_PRODUCT',
  OSA_BY_CATEGORIES_BY_PRODUCT = 'OSA_BY_CATEGORIES_BY_PRODUCT',
  POS_UNITS_SOLD = 'POS_UNITS_SOLD',
  POS_TRANSACTIONS = 'POS_TRANSACTIONS',
  POS_REVENUE = 'POS_REVENUE',
  POS_UNITS_SOLD_BY_DATE_BY_PRODUCTS = 'POS_UNITS_SOLD_BY_DATE_BY_PRODUCTS',
  POS_TRANSACTIONS_BY_DATE_BY_PRODUCTS = 'POS_TRANSACTIONS_BY_DATE_BY_PRODUCTS',
  POS_REVENUE_BY_DATE_BY_PRODUCTS = 'POS_REVENUE_BY_DATE_BY_PRODUCTS',
  POS_UNITS_SOLD_BY_DATE_BY_STORES = 'POS_UNITS_SOLD_BY_DATE_BY_STORES',
  POS_TRANSACTIONS_BY_DATE_BY_STORES = 'POS_TRANSACTIONS_BY_DATE_BY_STORES',
  POS_REVENUE_BY_DATE_BY_STORES = 'POS_REVENUE_BY_DATE_BY_STORES',
}

export const PAGE_WIDTH_COLS = 30;

export enum CsvReportState {
  IN_PROGRESS = 1,
  READY = 2,
  SEEN = 3,
  DOWNLOADED = 4
}

export interface DashboardConfigItem {
  name: string;
  state: boolean;
}

export interface DashboardConfigData
{
  general: {
    auto_apply_filters: boolean;
    interpolated_data: boolean;
    top_items_num: number;
  }
  dashboard: [{
    name: string;
    content: DashboardConfigItem[];
    }
  ]
}

export class EditDashboardData {
  title: string;
  name: string;
  items: string[];
}

export const fmcgChartTypes:string[] = [
  "OSA Rate by Category",
  "Bottom OSA Rates by Stores",
  "Bottom OSA Products",
  "Bottom OSA Rates by Categories",
  "OSA Rate by Category then by Product",

  "OOS Rate by Category",
  "Top OOS Rates by Stores",
  "Top OOS Products",
  "Top OOS Rates by Categories",
  "OOS Rate by Category then by Product",

  "PM Rate by Category",
  "Top PM Rates by Stores",
  "Top PM Products",
  "Top PM Rates by Categories",
  "PM Rate by Category then by Product",

  "POS Units Sold",
  "POS Transactions",
  "POS Revenue",
  'Top POS Units Sold by Products',
  'Top POS Transactions by Products',
  'Top POS Revenue by Products',
  'Top POS Units Sold by Stores',
  'Top POS Transactions by Stores',
  'Top POS Revenue by Stores',
];

export const retailChartTypes:string[] = [
 "Bottom OSA Rates by Stores",
 "Bottom OSA Products",
 "Bottom OSA Rates by Categories",
 "Bottom OSA Rates by Departments",
 "Bottom OSA Rates by Consumer Goods",
 "OSA Rate by Category",

  "Top OOS Rates by Stores",
  "Top OOS Products",
  "Top OOS Rates by Categories",
  "Top OOS Rates by Departments",
  "Top OOS Rates by Consumer Goods",
  "OOS Rate by Category",

  "Top PM Rates by Stores",
  "Top PM Products",
  "Top PM Rates by Categories",
  "Top PM Rates by Departments",
  "Top PM Rates by Consumer Goods",
  "PM Rate by Category",
];
