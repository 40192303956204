import { AbstractApiModel } from './abstract-api-model';

export class FocusResult extends AbstractApiModel<FocusResult> {
  id: number;
  cam: string;
  focusImageUrl: string;
  focusLogUrl: string;
  focusBand: string;
  succeeded: boolean;

  deserialize(json: any): this {
    this.id = json.id;
    this.cam = json.camera_name;
    this.focusImageUrl = json.focus_image_url;
    this.focusLogUrl = json.focus_log_url;
    this.focusBand = json.focus_band;
    this.succeeded = json.succeeded;

    return super.deserialize(json);
  }

  serialize(): any {
    return {
      id: this.id,
      cam: this.cam
    };
  }
}
