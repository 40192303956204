import { AbstractApiModel } from './abstract-api-model';

export class InsightAudit extends AbstractApiModel<InsightAudit> {
  auditType: string;
  version: number;
  eligibleViewportCount: number;
  entriesCompletedCount: number;
  insightAuditEntriesCount: number;
  issueTypes: string[];
  isComplete: boolean;
  targetPercentage: number;
  viewportAvailableCount: number;
  viewportCount: number;
  imageFetchTime: any;
  createdById: number;
  playExecutionId: number;

  deserialize(json: any): this {
    this.auditType = json.audit_type;
    this.version = json.version;
    this.eligibleViewportCount = json.eligible_viewport_count;
    this.entriesCompletedCount = json.entries_completed_count;
    this.insightAuditEntriesCount = json.insight_audit_entries_count;
    this.issueTypes = json.issue_types;
    this.isComplete = json.is_complete;
    this.targetPercentage = json.target_percentage;
    this.viewportAvailableCount = json.viewport_available_count;
    this.viewportCount = json.viewport_count;
    this.imageFetchTime = json.image_fetch_time;
    this.createdById = json.created_by_id;
    this.playExecutionId = json.play_execution_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      audit_type: this.auditType,
      target_percentage: this.targetPercentage,
      issue_types: this.issueTypes,
      image_fetch_time: this.imageFetchTime,
      play_execution_id: this.playExecutionId
    });
  }
}
