import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Pose, RotationType } from '../../models/pose';

export class RobotMarginObject extends MapObject {
  defaultOptions: any = {
    stroke: 'black',
    fill: 'transparent',
    strokeWidth: 1
  };

  constructor(public pose: Pose, options?: any) {
    super(options);
  }

  protected build(): fabric.Object {
    return new fabric.Group([this.createBackground(), this.createIndicator()], {
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      angle: this.pose.getRotation(RotationType.Degrees)
    });
  }

  protected createIndicator() {
    return new fabric.Polyline(
      [
        { x: this.pose.x - 8, y: this.pose.y + 19 },
        { x: this.pose.x - 8, y: this.pose.y + 26 },
        { x: this.pose.x + 8, y: this.pose.y + 26 }
      ],
      this.getOptions());
  }

  protected createBackground() {
    return new fabric.Rect({
      originX: 'center',
      originY: 'center',
      width: 52,
      height: 52,
      left: this.pose.x,
      top: this.pose.y,
      fill: 'transparent'
    });
  }
}
