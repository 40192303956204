import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class Network extends AbstractApiModel<Network> {
  configuration: any;
  skipValidation: boolean;

  deserialize(json: any): this {
    this.configuration = json.configuration;
    this.skipValidation = json.skip_validation;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      configuration: this.configuration,
      skip_validation: this.skipValidation
    });
  }
}
