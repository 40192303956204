<mat-card appearance="outlined" class="play-thumb" [ngClass]="showActive && (play?.active !== true) ? 'inactive-play' : 'active-play'">
  <div #header fxLayout="row" fxLayoutGap="10px" class="play-thumb-header" [ngStyle]="{'background': backgroundColor}">
    <div fxLayoutAlign="start center">
      <mat-icon svgIcon="{{play.playType == 'cleansweep' ? 'inspect_marty' : 'insight_marty'}}"></mat-icon>
    </div>

    <div fxLayout="column">
      <span class="event-info-title">{{play.name}}</span>
      <span class="event-info-subtitle">{{getPlayTypeName()}}</span>
    </div>

    <span class="toolbar-spacer"></span>

    <button *ngIf="showActions" mat-icon-button [matMenuTriggerFor]="playMenu" matTooltip="Play Actions" [matTooltipShowDelay]="600">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #playMenu="matMenu" [overlapTrigger]="false" [xPosition]="'before'">
      <ng-content></ng-content>
    </mat-menu>
  </div>

  <mat-card-content *ngIf="play">
    <a routerLink="../plays/{{play.id}}">
      <bt-play-map [playId]="play.id" size="thumb"></bt-play-map>
    </a>
  </mat-card-content>
</mat-card>
