import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatSlider } from '@angular/material/slider';

import { Viewport } from '../models/viewport';
import { ViewportImage } from '../models/viewport-image';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import * as _ from 'lodash';

@Component({
  selector: 'bt-viewport',
  templateUrl: './viewport.component.html',
  styleUrls: ['./viewport.component.scss']
})
export class ViewportComponent implements OnInit {
  public static BRIGHTNESS_MAX = 450;
  public static BRIGHTNESS_MIN = 50;
  public static BRIGHTNESS_DEFAULT = 130;
  public static HIGH_CONTRAST = 110;
  public static LOW_CONTRAST = 90;

  public readonly brightnessMax = ViewportComponent.BRIGHTNESS_MAX;
  public readonly brightnessMin = ViewportComponent.BRIGHTNESS_MIN;

  @ViewChild('viewport', { static: true }) viewportComponent: ElementRef;
  @ViewChild('viewportImage', { static: true }) viewportImage: ElementRef;
  @ViewChild('brightnessSlider') brightnessSlider: MatSlider;

  @Input() set brightnessControlVisible(value: boolean) {
    this._brightnessControlVisible = value;
  }

  get brightnessControlVisible(): boolean {
    return this._brightnessControlVisible;
  }

  @Input() set clickToZoom(value: boolean) {
    this._clickToZoom = value;
    if (value === false) {
      this.viewportComponent.nativeElement.style.cursor = 'pointer';
    }
  }

  get clickToZoom(): boolean {
    return this._clickToZoom;
  }

  @Input() set imageSrc(value: string) {
    this.setImageUrl(value);
  }

  @Output() imageComplete: EventEmitter<number> = new EventEmitter<number>();

  viewportOptions: any = {
    surroundingOpacity: 0.5,
    opacity: 0.7,
    strokeWidth: 10,
    stroke: 'greenyellow'
  };

  public filter: SafeStyle;
  public imageUrl: string;
  public imagePosition: string;
  public viewportImageUrl: string;
  public status: string;
  public scaleFactor: number;
  public hideNavControls = false;

  protected viewport: Viewport;
  protected lastValidUrl = '';
  protected _clickToZoom = true;
  protected _brightnessControlVisible = false;

  brightness = ViewportComponent.BRIGHTNESS_DEFAULT;
  contrast = ViewportComponent.HIGH_CONTRAST;

  transformOrigin: string;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.scaleFactor = 1;
    this.transformOrigin = '100% 100%';
    this.setFilter();
  }

  setViewport(viewport: Viewport) {
    this.viewport = viewport;
  }

  setImagePosition(position: string) {
    this.imagePosition = position;

    if (this.viewport) {
      const viewportImage = this.viewport.getViewportImage(this.imagePosition);

      this.status = viewportImage ? viewportImage.status : null;

      if (this.status === 'realized') {
        this.setImageUrl(viewportImage.imageUrl, viewportImage);
      }
      else {
        this.setImageUrl(null);
      }
    }
  }

  getImagePosition(): string {
    return this.imagePosition;
  }

  setImageUrl(url: string, viewportImage?: ViewportImage) {
    this.setZoomMode(false);

    if (url) {
      const image = new Image();
      this.lastValidUrl = url;

      image.onload = () => {
        if (url === this.lastValidUrl) {
          this.imageUrl = url;
          if (viewportImage) {
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = image.width;
            tempCanvas.height = image.height;

            this.imageComplete.emit(image.width / image.height);

            const tempContext = tempCanvas.getContext('2d');
            const stroke = this.viewport && this.viewport.hasIssue() ? this.viewportOptions.stroke : 'white';

            if (!_.isNil(viewportImage.viewportLocation)) {
              tempContext.globalAlpha = this.viewportOptions.surroundingOpacity;
              tempContext.fillRect(0, 0, image.width, image.height);
              tempContext.globalAlpha = this.viewportOptions.opacity;
              tempContext.strokeStyle = stroke;
              tempContext.lineWidth = this.viewportOptions.strokeWidth;
              tempContext.beginPath();

              const minX = _.min(viewportImage.viewportLocation.map(point => point.x));
              const minY = _.min(viewportImage.viewportLocation.map(point => point.y));
              const maxX = _.max(viewportImage.viewportLocation.map(point => point.x));
              const maxY = _.max(viewportImage.viewportLocation.map(point => point.y));
              tempContext.clearRect(minX, minY, maxX - minX, maxY - minY);

              viewportImage.viewportLocation.forEach((point, index) => {
                if (index === 0) {
                  tempContext.moveTo(point.x, point.y);
                }
                else {
                  tempContext.lineTo(point.x, point.y);
                }
              });

              tempContext.closePath();
              tempContext.stroke();
            }

            if (!_.isNil(viewportImage.tagLocation)) {
              tempContext.globalAlpha = this.viewportOptions.opacity;
              tempContext.strokeStyle = stroke;
              tempContext.lineWidth = this.viewportOptions.strokeWidth;
              tempContext.beginPath();

              const minX = _.min(viewportImage.tagLocation.map(point => point.x));
              const minY = _.min(viewportImage.tagLocation.map(point => point.y));
              const maxX = _.max(viewportImage.tagLocation.map(point => point.x));
              const maxY = _.max(viewportImage.tagLocation.map(point => point.y));
              tempContext.clearRect(minX, minY, maxX - minX, maxY - minY);

              viewportImage.tagLocation.forEach((point, index) => {
                if (index === 0) {
                  tempContext.moveTo(point.x, point.y);
                }
                else {
                  tempContext.lineTo(point.x, point.y);
                }
              });

              tempContext.closePath();
              tempContext.stroke();
            }

            this.viewportImageUrl = tempCanvas.toDataURL();
          }
          else {
            this.viewportImageUrl = null;
          }
        }
      };

      image.onerror = () => {
        this.imageUrl = null;
      };

      image.src = url;
    }
    else {
      this.imageUrl = null;
      this.viewportImageUrl = null;
    }
  }

  setZoomMode(zoom: boolean) {
    if (this.clickToZoom === true) {
      this.scaleFactor = zoom === true ? 3 : 1;
      this.hideNavControls = zoom;

      zoom === true ? this.viewportComponent.nativeElement.style.cursor = 'zoom-out' : this.viewportComponent.nativeElement.style.cursor = 'zoom-in';
    }
  }

  setFilter() {
    this.filter = this.sanitizer.bypassSecurityTrustStyle('contrast(' + this.contrast + '%) brightness(' + this.brightness + '%)');
  }

  setBrightness(value: number) {
    this.brightness = value;

    if (this.brightness > 300) {
      this.contrast = ViewportComponent.LOW_CONTRAST;
    }
    else {
      this.contrast = ViewportComponent.HIGH_CONTRAST;
    }
    this.setFilter();
  }

  mouseDown() {
    if (this.clickToZoom === true) {
      this.scaleFactor === 1 ? this.setZoomMode(true) : this.setZoomMode(false);
    }
  }

  mouseMove(event: MouseEvent) {
    if (this.clickToZoom) {
      const x = (event.offsetX / this.viewportComponent.nativeElement.clientWidth) * 100;
      const y = (event.offsetY / this.viewportComponent.nativeElement.clientHeight) * 100;

      this.transformOrigin = '' + x + '% ' + y + '%';
    }
  }
}
