<div fxLayoutAlign="start center">
  <mat-chip class="mur-roster" (click)="showList = !showList"
            [ngClass]="roster.length > capacity ? 'mur-roster-crowded' : 'mur-roster'"
            matTooltip="Number of current editors">
    <div fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-icon>person</mat-icon>

      <span *ngIf="!showList">{{ roster.length }}</span>

      <div *ngIf="showList" fxLayoutGap="10px">
        <mat-label *ngFor="let user of roster">{{user}}</mat-label>
      </div>
    </div>
  </mat-chip>
</div>
