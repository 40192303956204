import { CommonModule } from '@angular/common';
import { BtCommonModule } from '@ng-cloud/badger-core/common/bt-common.module';
import { NgModule } from '@angular/core';
import { UserDetailComponent } from '@ng-cloud/badger-core/user/user-detail/user-detail.component';
import { UserFormComponent } from '@ng-cloud/badger-core/user/user-form/user-form.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule
  ],
  declarations: [
    UserDetailComponent,
    UserFormComponent
  ],
  exports: [
    UserDetailComponent,
    UserFormComponent
  ]
})
export class BtUserModule {
}
