import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayExecutionSearchComponent } from './play-execution-search/play-execution-search.component';
import { BtCommonModule } from '@ng-cloud/badger-core/common/bt-common.module';


@NgModule({
  declarations: [
    PlayExecutionSearchComponent
  ],
  imports: [
    BtCommonModule,
    CommonModule
  ],
  exports: [
    PlayExecutionSearchComponent
  ]
})
export class BtSearchModule {
}
