import { concat, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Robot } from '@ng-cloud/badger-core/models/robot';
import { Play } from '@ng-cloud/badger-core/models/play';
import { ApiService, PagedResult } from './api.service';
import { Command } from '@ng-cloud/badger-core/models/command';
import { Heartbeat } from '@ng-cloud/badger-core/models/heartbeat';
import { PlayExecution } from '@ng-cloud/badger-core/models/play-execution';
import { CaptureEvent } from '@ng-cloud/badger-core/models/capture-event';
import { CaptureEventViewReview } from '@ng-cloud/badger-core/models/capture-event-view-review';
import { IdlePose, IdlePoseType } from '@ng-cloud/badger-core/models/idle-pose';
import { Certificate } from '@ng-cloud/badger-core/models/certificate';
import { SoftwareImage } from '@ng-cloud/badger-core/models/software-image';
import { SoftwareInfo } from '@ng-cloud/badger-core/models/software-info';
import { VisualizationData } from '@ng-cloud/badger-core/models/visualization-data';
import { RobotTelemetry } from '@ng-cloud/badger-core/models/robot-telemetry';
import { Store } from '@ng-cloud/badger-core/models/store';
import { RobotEvent } from '@ng-cloud/badger-core/models/robot-event';
import { Organization } from '@ng-cloud/badger-core/models/organization';
import { PathExecution } from '@ng-cloud/badger-core/models/path-execution';
import { RobotModel } from '@ng-cloud/badger-core/models/robot-model';
import { Note } from '@ng-cloud/badger-core/models/note';
import { FocusResult } from '@ng-cloud/badger-core/models/focus-result';
import { SystemCheckResult } from '@ng-cloud/badger-core/models/system-check-result';
import { CaptureEventAssessmentReview } from '@ng-cloud/badger-core/models/capture-event-assessment-review';
import { CalibrationData } from '@ng-cloud/badger-core/models/calibration-data';
import { LidarHeatmapData } from '@ng-cloud/badger-core/models/lidar-heatmap-data';

import * as _ from 'lodash';

@Injectable()
export class RobotService extends ApiService {
  /**
   * Fetch all Robots
   */
  getRobots(params?: any): Observable<Robot[]> {
    return this.list(Robot, 'robots', params);
  }

  /**
   * Fetch all Robots with Overview counts
   */
  getRobotsOverview(): Observable<{
    robots: Robot[],
    lifecycleCounts: any,
    controlStatusCounts: any,
    organizationCounts: any,
    solutionCounts: any,
    barbaseCounts: any,
    insightPayloadCounts: any,
    insightNetCounts: any
  }> {
    return this.withCache('robots/overview', {}, 25, () => this.http.get(this.url('robots/overview')).pipe(
      map((response: any) => ({
          robots: response.robots.map(source => {
            const robot = new Robot().deserialize(source);
            robot.store = new Store().deserialize(source.store);
            robot.store.organization = new Organization().deserialize(source.store.organization);
            robot.robotModel = robot.robotModel && new RobotModel().deserialize(source.robot_model);
            return robot;
          }),
          lifecycleCounts: response.lifecycle_state_counts,
          controlStatusCounts: response.control_status_counts,
          organizationCounts: response.organization_counts,
          solutionCounts: response.solution_counts,
          barbaseCounts: response.barbase_counts,
          insightNetCounts: response.nets_counts,
          insightPayloadCounts: response.insight_payload_counts
        })),
      catchError(err => this.handleError(err))));
  }

  /**
   * Fetch Robot data for the given id
   */
  getRobot(id: number): Observable<Robot> {
    return this.get(Robot, `robots/${id}`, 300);
  }

  /**
   * Fetch plays for given robot id
   */
  getPlays(robotId: number): Observable<Play[]> {
    return this.list(Play, `robots/${robotId}/plays`);
  }

  assignPlay(id: number, playId: number): Observable<any> {
    return this.http.post(this.url(`robots/${id}/plays/${playId}/assign`), {}).pipe(
      tap(() => this.deleteCachedRequests(`robots/${id}/plays`)),
      catchError(err => this.handleError(err)));
  }

  unassignPlay(id: number, playId: number): Observable<any> {
    return this.http.delete(this.url(`robots/${id}/plays/${playId}/unassign`)).pipe(
      tap(() => this.deleteCachedRequests(`robots/${id}/plays`)),
      catchError(err => this.handleError(err)));
  }

  /**
   * Command the robot to run the play
   */
  runPlay(robotId: number, playId: number, params?: any) {
    return this.http.post(this.url(`robots/${robotId}/plays/${playId}/run`), params).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Create Robot
   */
  createRobot(robotModelId: number, manufacturingFacilityId: number, buildDate: Date): Observable<Robot> {
    const body = {
      manufacturing_facility_id: manufacturingFacilityId,
      robot_model_id: robotModelId,
      build_date: buildDate
    };

    return this.http.post(this.url(`robots`), body).pipe(
      filter(response => response != null),
      map(response => new Robot().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  /**
   * Update Robot
   */
  updateRobot(robot: Robot): Observable<Robot> {
    return this.update(robot, `robots/${robot.id}`);
  }

  /**
   * Delete Robot
   */
  deleteRobot(robot: Robot): Observable<any> {
    return this.destroy(robot, `robots/${robot.id}`);
  }

  /**
   * Get command by id
   */
  getCommand(commandId: number, robotId: number): Observable<Command> {
    return this.get(Command, `robots/${robotId}/commands/${commandId}`, 300);
  }

  /**
   * Fetch all Command Types
   */
  getCommandGroups(): Observable<any> {
    return this.http.get(this.url(`commands/groups`)).pipe(
      catchError((err) => this.handleError(err)));
  }

  /**
   * Fetches all commands created for a robot within a given number of days (if days is 0 returns all commands for the robot)
   */
  getRobotCommands(id, params?: any): Observable<Command[]> {
    return this.list(Command, `robots/${id}/commands`, params);
  }

  /**
   * Paginate robot command list
   */
  pageRobotCommands(id, params?: any): Observable<PagedResult<Command>> {
    return this.page(Command, `views/robots/${id}/commands/page`, params);
  }

  /**
   * Fetch robot telemetries list
   */
  getRobotTelemetries(id, params?: any): Observable<RobotTelemetry[]> {
    return this.list(RobotTelemetry, `robots/${id}/telemetries`, params);
  }

  /**
   * Paginate robot telemetries list
   */
  pageRobotTelemetries(id, params?: any): Observable<PagedResult<RobotTelemetry>> {
    return this.page(RobotTelemetry, `views/robots/${id}/robot_telemetries/page`, params);
  }

  /**
   * Creates a command for a robot
   */
  createCommand(robotId, command: Command): Observable<Command> {
    return this.create(command, `robots/${robotId}/commands`);
  }

  cancelAll(robotId): Observable<any> {
    return this.deleteAll(`robots/${robotId}/commands`);
  }

  /**
   * Cancels a command for a robot
   */
  cancelCommand(robotId, command: Command): Observable<Command> {
    return this.destroy(command, `robots/${robotId}/commands/${command.id}`);
  }

  /**
   * Open websocket connection to listen for commands created for a robot
   */
  commandCreated(id: number): Observable<Command> {
    return this.channel(Command, 'CommandCreatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`robots/${id}/commands`, `views/robots/${id}/commands/page`])));
  }

  /**
   * Paginate robot event list
   */
  pageRobotEvents(id, params?: any): Observable<PagedResult<RobotEvent>> {
    return this.page(RobotEvent, `views/robots/${id}/robot_events/page`, params);
  }

  /**
   * Open websocket connection to listen for events created for a robot
   */
  eventCreated(id: number): Observable<RobotEvent> {
    return this.channel(RobotEvent, 'EventCreatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`robots/${id}/robot_events`, `views/robots/${id}/robot_events/page`])));
  }

  /**
   * Open websocket connection to listen for events updated for a robot
   */
  eventUpdated(id: number): Observable<RobotEvent> {
    return this.channel(RobotEvent, 'EventUpdatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`robots/${id}/robot_events`, `views/robots/${id}/robot_events/page`])));
  }

  /**
   * Open websocket connection to listen for commands updated for a robot
   */
  commandUpdated(id: number): Observable<Command> {
    return this.channel(Command, 'CommandUpdatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`robots/${id}/commands`, `views/robots/${id}/commands/page`])));
  }

  /**
   * Fetch heartbeat data for a robot
   */
  getHeartbeats(id: number, params?: any): Observable<Heartbeat[]> {
    return this.list(Heartbeat, `robots/${id}/heartbeats`, params, 10);
  }

  /**
   * Open websocket connection to listen for heartbeat messages from the given robot
   *
   * @param  id robot id
   * @param  skipLatest if true, the latest heartbeat will not be immediately broadcast when subscription is initiated
   * @return stream of newly created robot heartbeats
   */
  heartbeats(id: number, skipLatest: boolean = false): Observable<Heartbeat> {
    const key = this.cacheKey('RobotHeartbeatChannel', { robot_id: id });

    const incomingHeartbeats = this.channel(Heartbeat, 'RobotHeartbeatChannel', {
      robot_id: id,
      skip_latest: skipLatest
    }).pipe(
      tap(heartbeat => this.setCachedRequest(key, observableOf(heartbeat), Infinity)),
      tap(() => this.deleteCachedRequests(`robots/${id}/heartbeats`)));

    return this.hasCachedRequest(key) ? concat(this.getCachedRequest(key), incomingHeartbeats) : incomingHeartbeats;
  }

  /**
   * Open websocket connection to listen for snap messages from the given robot
   *
   * @param  id robot id
   * @return stream of newly created robot heartbeats
   */
  snaps(id: number): Observable<any> {
    return this.cable().channel('RobotSnapChannel', { robot_id: id })
      .received().pipe();
  }

  /**
   * This is for fetching the old style of visualization data and is being DEPRECATED in favor of requestVisualizationData in the command service
   * @param id a robot id
   */
  requestOldVisualizationData(id: number): Observable<RobotTelemetry> {
    return this.create(new RobotTelemetry().assign({ type: 'visualization' }), `robots/${id}/telemetries`);
  }

  /**
   * Open websocket connection to listen for visualization data messages from the given robot
   *
   * @param  id robot id
   * @return stream of newly created robot visualization data
   */
  visualizationData(id: number): Observable<VisualizationData> {
    return this.channel(RobotTelemetry, 'RobotTelemetryChannel', { robot_id: id }).pipe(
      filter(telemetry => ['visualization', 'barviz'].includes(telemetry.type)),
      map(telemetry => {
        telemetry.data['type'] = telemetry.type;
        telemetry.data['cdn_files'] = telemetry.cdnFiles;
        telemetry.data['created_at'] = telemetry.createdAt;
        return new VisualizationData().deserialize(telemetry.data);
      }));
  }

  /**
   * Open websocket connection to listen for calibration data messages from the given robot
   *
   * @param  id robot id
   * @return stream of newly created robot calibration data
   */
  lidarHeatmapData(id: number): Observable<LidarHeatmapData> {
    return this.channel(RobotTelemetry, 'RobotTelemetryChannel', { robot_id: id }).pipe(
      filter(telemetry => ['event'].includes(telemetry.type) && (_.startsWith(telemetry.data['message_id'], 'rotating_lidar.heatmap_generated'))),
      map(telemetry => {
        telemetry.data['type'] = telemetry.type;
        telemetry.data['cdn_files'] = telemetry.cdnFiles;
        telemetry.data['created_at'] = telemetry.createdAt;
        return new LidarHeatmapData().deserialize(telemetry.data);
      }));
  }

  /**
   * Open websocket connection to listen for calibration data messages from the given robot
   *
   * @param  id robot id
   * @return stream of newly created robot calibration data
   */
  calibrationData(id: number): Observable<CalibrationData> {
    return this.channel(RobotTelemetry, 'RobotTelemetryChannel', { robot_id: id }).pipe(
      filter(telemetry => ['event'].includes(telemetry.type) && (_.startsWith(telemetry.data['message_id'], 'depthcam_floor_calibration'))),
      map(telemetry => {
        telemetry.data['type'] = telemetry.type;
        telemetry.data['cdn_files'] = telemetry.cdnFiles;
        telemetry.data['created_at'] = telemetry.createdAt;
        return new CalibrationData().deserialize(telemetry.data);
      }));
  }

  /**
   * Open websocket connection to listen for RobotTelemetry messages of type incident from the given robot
   *
   * @param  id robot id
   * @return stream of newly created robot heartbeats
   */
  incidentReports(id: number): Observable<RobotTelemetry> {
    return this.channel(RobotTelemetry, 'RobotTelemetryChannel', { robot_id: id }).pipe(filter(telemetry => telemetry.type === 'incident'));
  }

  /**
   * Fetch play executions for given robot id
   */
  getPlayExecutions(params?: any): Observable<PlayExecution[]> {
    return this.list(PlayExecution, `play_executions`, params);
  }

  /**
   * Fetch play executions for given robot id, return maximum of 10 plays executions before and after given play execution
   */
  getPlayExecutionsForNav(id: number, params?: any): Observable<PlayExecution[]> {
    return this.list(PlayExecution, `play_executions/${id}/nav_play_executions`, params);
  }

  /**
   * Fetch play execution for given id
   */
  getPlayExecution(id: number): Observable<PlayExecution> {
    return this.get(PlayExecution, `play_executions/${id}`);
  }

  /**
   * Retry given play execution
   */
  retryPlayExecution(id: number): Observable<PlayExecution> {
    return this.http.post(this.url(`play_executions/${id}/retry`), {}).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Reprocess given play execution
   */
  reprocessPlayExecution(id: number): Observable<PlayExecution> {
    return this.http.post(this.url(`play_executions/${id}/reprocess`), {}).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * GET http://localhost:3000/api/web/v1/play_executions/[pe_id]/focus_results
   * @param id
   */
  getFocusResults(id: number): Observable<FocusResult[]> {
    return this.list(FocusResult, `play_executions/${id}/focus_results`);
  }

  /**
   * Paginate playExecutions list
   */
  pagePlayExecutions(id: number, params?: any): Observable<PagedResult<PlayExecution>> {
    return this.page(PlayExecution, `views/robots/${id}/play_executions/page`, params);
  }

  /**
   * Open websocket connection to listen for PlayExecutionCreated messages for given robot id
   *
   * @param  id robot id
   * @return stream of newly created play executions for a robot
   */
  playExecutionCreated(id: number): Observable<PlayExecution> {
    return this.channel(PlayExecution, 'PlayExecutionCreatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`play_executions`, `views/robots/${id}/play_executions/page`])));
  }

  /**
   * Open websocket connection to listen for PlayExecutionUpdated messages for given robot id
   *
   * @param  id robot id
   * @return stream of updated PlayExecutions for a robot
   */
  playExecutionUpdated(id: number): Observable<PlayExecution> {
    return this.channel(PlayExecution, 'PlayExecutionUpdatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests([`play_executions`, `views/robots/${id}/play_executions/page`])));
  }

  /**
   * Fetch play execution for given playExecutionId
   */
  getPathExecutions(playExecutionId: number): Observable<PathExecution[]> {
    return this.list(PathExecution, `path_executions`, { play_execution_id: playExecutionId });
  }

  /**
   * Open websocket connection to listen for PathExecutionCreated messages for given play_execution id
   *
   * @param  playExecutionId Play Execution ID
   * @return stream of newly created path executions for a play_execution
   */
  pathExecutionCreated(playExecutionId: number): Observable<PathExecution> {
    return this.channel(PathExecution, 'PathExecutionCreatedChannel', { play_execution_id: playExecutionId }).pipe(
      tap(() => this.deleteCachedRequests(`path_executions`)));
  }

  /**
   * Open websocket connection to listen for PathExecutionUpdated messages for given play_execution id
   *
   * @param  playExecutionId Play Execution ID
   * @return stream of updated  path executions for a play_execution
   */
  pathExecutionUpdated(playExecutionId: number): Observable<PathExecution> {
    return this.channel(PathExecution, 'PathExecutionUpdatedChannel', { play_execution_id: playExecutionId }).pipe(
      tap(() => this.deleteCachedRequests(`path_executions`)));
  }

  /**
   * Fetch a capture event view review by id
   */
  getCaptureEventReview(id: number): Observable<CaptureEventViewReview> {
    return this.get(CaptureEventViewReview, `capture_event_view_reviews/${id}`);
  }

  /**
   * Fetch a capture event assessment review by id
   */
  getCaptureEventAssessmentReview(id: number): Observable<CaptureEventAssessmentReview> {
    return this.get(CaptureEventAssessmentReview, `capture_event_assessment_reviews/${id}`);
  }

  /**
   * Fetch capture events for given play execution id
   */
  getCaptureEvents(playExecutionId: number): Observable<CaptureEvent[]> {
    return this.list(CaptureEvent, `views/play_executions/${playExecutionId}/capture_events_with_reviews`);
  }

  /**
   * Fetch capture events for given id
   */
  getCaptureEvent(id: number): Observable<CaptureEvent> {
    return this.get(CaptureEvent, `capture_events/${id}`);
  }

  /**
   * Generates a "report" for a list of capture_event ids
   */
  generateCaptureEventReport(idList: number[]): Observable<any> {
    return this.http.get(this.url(`capture_events/report`), {
      responseType: 'arraybuffer',
      params: ApiService.params({ 'capture_event_ids[]': idList })
    }).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Generates a "report" for all urgents within a play execution found by given id
   */
  generateUrgentReport(executionId: number): Observable<any> {
    return this.http.get(this.url(`play_executions/${executionId}/urgent_report`), {
      responseType: 'arraybuffer'
    }).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Open websocket connection to listen for CaptureEventCreated messages from the given PlayExecution
   *
   * @param  id play execution id
   * @return stream of newly created capture events
   */
  captureEventCreated(id: number): Observable<CaptureEvent> {
    return this.channel(CaptureEvent, 'CaptureEventCreatedChannel', { play_execution_id: id }).pipe(
      tap(() => this.deleteCachedRequests(`views/play_executions/${id}/capture_events_with_reviews`)));
  }

  /**
   * Open websocket connection to listen for CaptureEventUpdated messages from the given PlayExecution
   *
   * @param  id play execution id
   * @return stream of updated capture events
   */
  captureEventUpdates(id: number): Observable<CaptureEvent> {
    return this.channel(CaptureEvent, 'CaptureEventUpdatedChannel', { play_execution_id: id }).pipe(
      tap(captureEvent => this.deleteCachedRequests([`views/play_executions/${id}/capture_events_with_reviews`,
        'capture_event_view_reviews', `capture_events/${captureEvent.id}`])));
  }

  /**
   * Get software images associated with a robot id
   */
  getRobotSoftwareImages(id: number): Observable<SoftwareImage[]> {
    return this.list(SoftwareImage, `robots/${id}/robot_software_images`);
  }

  /**
   *  Get robot software images and set name associated with robot id
   */
  getRobotSoftwareSetImages(id: number): Observable<SoftwareInfo> {
    return this.get(SoftwareInfo, `robots/${id}/robot_software_set_images`, 0);
  }

  /**
   * Update Robot Software Image for given robot
   */
  updateRobotSoftwareImages(id: number, params: any): Observable<Robot> {
    return this.http.post(this.url(`robots/${id}/update_software`), params).pipe(
      catchError(err => this.handleError(err)));
  }

  getIdlePoses(id: number): Observable<IdlePose[]> {
    return this.list(IdlePose, `robots/${id}/idle_poses`);
  }

  getIdlePose(id: number, idlePoseType: IdlePoseType): Observable<IdlePose> {
    return this.list(IdlePose, `robots/${id}/idle_poses`, { pose_type: idlePoseType }).pipe(
      map(idlePoses => idlePoses[0]));
  }

  updateIdlePose(id: number, idlePose: IdlePose): Observable<IdlePose> {
    return this.update(idlePose, `robots/${id}/idle_poses/${idlePose.poseType}`);
  }

  getCertificates(id: number): Observable<Certificate[]> {
    return this.list(Certificate, `robots/${id}/certificates`);
  }

  /**
   * Open websocket connection to listen for certificates created for a robot
   */
  certificateCreated(id: number): Observable<Certificate> {
    return this.channel(Certificate, 'CertificateCreatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests(['robots/${id}/certificates'])));
  }

  /**
   * Open websocket connection to listen for certificates updated for a robot
   */
  certificateUpdated(id: number): Observable<Certificate> {
    return this.channel(Certificate, 'CertificateUpdatedChannel', { robot_id: id }).pipe(
      tap(() => this.deleteCachedRequests(['robots/${id}/certificates'])));
  }

  addRobots(store: Store): Observable<Robot[]> {
    return this.getRobots({ 'store_id': store.id }).pipe(
      tap((robots: Robot[]) => {
        robots.sort((a, b) => a.id - b.id);
        store.robots = robots;
      })
    );
  }

  getSkyboxOverview(): Observable<{
    robots: Robot[],
    lifecycleStateCounts: any,
    controlStatusCounts: any,
    eventCounts: any,
    organizationCounts: any,
    solutionCounts: any
  }> {
    return this.withCache('/views/skybox', {}, 25, () => this.http.get(this.url('/views/skybox')).pipe(
      map((response: any) => ({
          robots: response.robots.map(source => {
            const robot = new Robot().deserialize(source);
            robot.store = new Store().deserialize(source.store);
            robot.store.organization = new Organization().deserialize(source.store.organization);
            robot.events = source.events && source.events.map(event => new RobotEvent().deserialize(event));
            robot.robotModel = source.robot_model && new RobotModel().deserialize(source.robot_model);
            return robot;
          }),
          lifecycleStateCounts: response.lifecycle_state_counts,
          controlStatusCounts: response.control_status_counts,
          eventCounts: response.event_counts,
          organizationCounts: response.organization_counts,
          solutionCounts: response.solution_counts
        })),
      catchError(err => this.handleError(err))));
  }

  /**
   * Creates a Note
   */
  createNote(note: Note, robotId: number): Observable<Note> {
    return this.create(note, `robots/${robotId}/robot_notes`);
  }

  /**
   * Update a Note
   */
  updateNote(note: Note, robotId: number): Observable<Note> {
    return this.update(note, `robots/${robotId}/robot_notes/${note.id}`);
  }

  /**
   * Delete a Note
   */
  deleteNote(note: Note, robotId: number): Observable<Note> {
    return this.destroy(note, `robots/${robotId}/robot_notes/${note.id}`);
  }

  /**
   * Fetch notes for given robot id
   */
  getNotes(robotId: number): Observable<Note[]> {
    return this.list(Note, `robots/${robotId}/robot_notes`);
  }

  /**
   * Fetch robot events for given robot id
   */
  getRobotEvents(robotId: number): Observable<RobotEvent[]> {
    return this.list(RobotEvent, `robots/${robotId}/robot_events`);
  }

  /**
   * Fetch system check results for given robot id
   */
  getSystemCheckResult(robotId: number): Observable<SystemCheckResult> {
    return this.get(SystemCheckResult, `robots/${robotId}/commands/system_check_result`);
  }

  /**
   * Fetch robot models
   */
  getRobotModels(): Observable<RobotModel[]> {
    return this.list(RobotModel, `robot_models`);
  }

  /**
   * Create robot model
   */
  createRobotModel(robotModel: RobotModel): Observable<RobotModel> {
    return this.create(robotModel, `robot_models`);
  }

  /**
   * Update robot model
   */
  updateRobotModel(robotModel: RobotModel): Observable<RobotModel> {
    return this.update(robotModel, `robot_models/${robotModel.id}`);
  }

  /**
   * Get combined salt config
   */
  getRobotSaltConfig(robotId): Observable<any> {
    return this.http.get(this.url(`robots/${robotId}/salt_config`)).pipe(
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Get applied config
   */
  getRobotAppliedConfig(robotId): Observable<any> {
    return this.http.get(this.url(`robots/${robotId}/applied_config`)).pipe(
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Get Robot Hardware
   */
  getRobotHardwareConfiguration(robotId): Observable<any> {
    return this.http.get(this.url(`robots/${robotId}/hardware`)).pipe(
      catchError((err) => this.handleError(err))
    );
  }
}
