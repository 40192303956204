import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Point } from './interfaces/point';

export enum ViewportImagePosition {
  upLeft = 'up_left',
  upCenter = 'up_center',
  upRight = 'up_right',
  left = 'left',
  center = 'center',
  right = 'right',
  downLeft = 'down_left',
  downCenter = 'down_center',
  downRight = 'down_right'
}

export class ViewportImage extends AbstractApiModel<ViewportImage> {
  imageUrl: string;
  imageLabel: string;
  status: string;
  viewportLocation: Point[];
  tagLocation: Point[];

  deserialize(json: any): this {
    this.imageLabel = json.image_label;
    this.imageUrl = json.image_url;
    this.status = json.status || 'realized';
    this.viewportLocation = json.viewport_polygon;
    this.tagLocation = json.tag_polygon;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }
}
