import { RealogramViewport } from '@ng-cloud/badger-core/models/realogram-viewport';

export class Tag {
  id: string;
  sku: string;
  clazz: string;
  _width: number;
  _height: number;
  guid: string;
  guids: string[];
  xPosition: number;
  yPosition: number;
  disabled = false;
  ll: number[];
  userCreated = false;

  deserialize(json: any): this {
    this.id = json.guid;
    this._width = json.width;
    this._height = json.height;
    this.guid = json.guid;
    this.guids = json.guids;
    this.xPosition = json.ll[0];
    this.yPosition = json.ll[1];
    this.sku = json.id;
    this.ll = json.ll;
    this.clazz = json.class;

    return this;
  }

  serialize(): any {
    return {
      id: this.sku,
      guid: this.id,
      width: this.width,
      height: this.height,
      guids: this.guids,
      ll: this.ll,
      class: this.clazz
    };
  }

  fromRealogramViewport(viewport: RealogramViewport): this {
    this.id = viewport.id;
    this.sku = viewport.sku;
    this.width = viewport.tagWidth;
    this.height = viewport.tagHeight;
    this.guid = viewport.guid;
    this.xPosition = viewport.tagX;
    this.yPosition = viewport.tagY;
    this.clazz = null;

    return this;
  }

  public get width() {
    return this._width;
  }

  public set width(width: number) {
    this._width = Math.min(width, 5);
  }

  public get height() {
    return this._height;
  }

  public set height(height: number) {
    this._height = Math.min(height, 5);
  }
}
