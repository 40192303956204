import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  templateUrl: './confirm-banner.component.html',
  styleUrls: ['./confirm-banner.component.scss']
})
export class ConfirmBannerComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    public snackBarRef: MatSnackBarRef<ConfirmBannerComponent>
  ) {
  }

  confirm() {
    this.snackBarRef.dismissWithAction();
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
