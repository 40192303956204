import { fabric } from 'fabric';
import { MapObject } from './map-object';

export class SelectBorderObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    fill: 'transparent',
    stroke: 'darkorange',
    originX: 'center',
    originY: 'center',
    strokeWidth: 4
  };

  public sourceObj: MapObject;

  constructor(source: MapObject, options?: any) {
    super(options);
    this.sourceObj = source;
  }

  protected getInstanceOptions(): any {
    return {
      left: this.sourceObj.getFabric().left,
      top: this.sourceObj.getFabric().top,
      width: this.sourceObj.getFabric().width + 5,
      height: this.sourceObj.getFabric().height + 5
    };
  }

  protected build(): fabric.Object {
    return new fabric.Rect(this.getOptions());
  }
}
