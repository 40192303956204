import { Layers, MapLayer } from './map-layer';
import { MapGrouping } from '@ng-cloud/badger-core/map/groupings/map-grouping';
import { MapOverlayObject } from '@ng-cloud/badger-core/map/objects/map-overlay-object';
import { Map } from '../../models/map';

@Layers([
  { name: 'overlay', zIndex: 8 }
])
export class MapOverlayGrouping extends MapGrouping<Map, MapOverlayObject> {

  constructor(public options: any = {}, public layerName: string = 'overlay', public zIndex: number = 0) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createObject(map: Map): MapOverlayObject {
    return new MapOverlayObject(this.storeMap, this.options);
  }
}
