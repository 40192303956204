import { AbstractApiModel } from './abstract-api-model';
import { ScanEventIssueInsightReview } from './scan-event-issue-insight-review';
import { ScanEventIssueResolution } from './scan-event-issue-resolution';
import { Path } from './path';
import { Viewport } from './viewport';
import { Comment, Commentable } from '@ng-cloud/badger-core';
import * as _ from 'lodash';

export class ScanEventIssue extends AbstractApiModel<ScanEventIssue> implements Commentable {

  // Types
  static readonly DEFAULT_ISSUE = 'default_issue';
  static readonly PRICE_MISMATCH = 'price_mismatch';
  static readonly SALE_PRICE_MISMATCH = 'sale_price_mismatch';
  static readonly HOLE_OUT_OF_STOCK = 'hole_oos';
  static readonly ENHANCED_OUT_OF_STOCK = 'eOOS';
  static readonly NO_ISSUE = 'no_issue';
  static readonly VIEWPORT_TAG_NOT_SEEN = 'viewport-tag_not_seen';
  static readonly VIEWPORT_TAG_MISSING = 'viewport-tag_missing';
  static readonly VIEWPORT_TAG_NEW = 'viewport-tag_new';
  static readonly VIEWPORT_TAG_SWAP = 'viewport-tag_swap';
  static readonly UNCONFIRMED_PRODUCT = 'unconfirmed_product';
  static readonly TOP_STOCK_REVIEW = 'top_stock_review';

  // Meta-Types
  static readonly VIEWPORT_ISSUE = 'viewport_issue';
  static readonly OUT_OF_STOCK = 'out_of_stock';

  issueType: string;
  issueJson: any;
  scanEventId: number;
  viewportId: number;
  viewport: Viewport;

  metaType: string;
  sortOrdinal: number;
  path: Path;
  scanEventIssueReview: ScanEventIssueInsightReview;
  scanEventIssueResolution: ScanEventIssueResolution;
  comments: Comment[];

  deserialize(json: any): this {
    this.issueType = json.issue_type;
    this.issueJson = json.issue_json;
    this.viewportId = json.viewport_id;
    this.scanEventIssueReview = json.review && new ScanEventIssueInsightReview().deserialize(json.review);
    this.scanEventIssueResolution = json.resolution && new ScanEventIssueResolution().deserialize(json.resolution);
    // Switch statement for detecting meta type is needed regardless of how issue is being fetched
    switch (this.issueType) {
      case ScanEventIssue.HOLE_OUT_OF_STOCK:
      case ScanEventIssue.ENHANCED_OUT_OF_STOCK:
        this.metaType = ScanEventIssue.OUT_OF_STOCK;
        break;
      case ScanEventIssue.PRICE_MISMATCH:
      case ScanEventIssue.SALE_PRICE_MISMATCH:
        this.metaType = ScanEventIssue.PRICE_MISMATCH;
        break;
      case ScanEventIssue.VIEWPORT_TAG_NOT_SEEN:
      case ScanEventIssue.VIEWPORT_TAG_MISSING:
      case ScanEventIssue.VIEWPORT_TAG_NEW:
      case ScanEventIssue.VIEWPORT_TAG_SWAP:
        this.metaType = ScanEventIssue.VIEWPORT_ISSUE;
        break;
      default:
        this.metaType = this.issueType;
    }

    return super.deserialize(json);
  }

  getGeneralizedType(): string {
    let type = this.issueType;

    if (this.metaType === ScanEventIssue.VIEWPORT_ISSUE) {
      type = ScanEventIssue.VIEWPORT_ISSUE;
    }

    return type;
  }

  isAcceptedIssue(): boolean {
    if (this.metaType === ScanEventIssue.VIEWPORT_ISSUE) {
      return this.scanEventIssueReview.rejected;
    }
    return !_.isNil(this.scanEventIssueReview.rejected) && !this.scanEventIssueReview.rejected;
  }

  getCommentableType(): string {
    return 'ScanEventIssue';
  }

  getDisplayName(): string {
    return '';
  }
}
