import { AbstractApiModel } from './abstract-api-model';

export class ScanEventIssueResolution extends AbstractApiModel<ScanEventIssueResolution> {
  status: string;
  scanEventIssueId: number;
  updatedById: number;

  deserialize(json: any): this {
    this.status = json.status;
    this.scanEventIssueId = json.scan_event_issue_id;
    this.updatedById = json.updated_by_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return {
      status: this.status,
      scan_event_issue_id: this.scanEventIssueId,
      updated_by_id: this.updatedById
    };
  }
}
