<mat-card appearance="outlined" *ngIf="review" class="capture-event-review-detail-map-popup">
  <mat-card-content fxLayout="row">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" fxFlex="100%"
                   (selectedTabChange)="selectReview(+$event.tab.textLabel)" [(selectedIndex)]="activeTab"
                   animationDuration="0ms">
      <div *ngFor="let view of captureEvent.views">
        <mat-tab *ngFor="let tabReview of view.reviews" label="{{tabReview.id}}">
          <ng-template mat-tab-label>
            <div fxLayoutAlign="center center">
              {{view.viewType | startCase}} {{tabReview.reviewType?.toLowerCase() === 'neurala' ? '(Neurala)' : ''}}
              <mat-icon *ngIf="tabReview.isUrgent" class="mat-warn hazard-icon" matTooltip="Urgent Hazards Detected">
                warning
              </mat-icon>
              <mat-icon *ngIf="!tabReview.isUrgent && tabReview.hazards.length > 0" class="hazard-icon hazard-only"
                        matTooltip="Hazards Detected">warning
              </mat-icon>
            </div>
          </ng-template>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
            <div class="review-popup-info" fxLayout="column" fxFlexOrder.lt-sm="2">
              <ng-container
                *ngTemplateOutlet="reviewDetailTemplate; context: { $implicit: tabReview }"></ng-container>
              <mat-list class="" *ngIf="review.hazards.length > 0">
                <mat-list-item>
                  <span matListItemTitle>
                    Hazards
                    <mat-icon *ngIf="tabReview.isUrgent" class="mat-warn hazard-icon"
                              matTooltip="Urgent Hazards Detected">warning
                    </mat-icon>
                    <mat-icon *ngIf="!tabReview.isUrgent && tabReview.hazards.length > 0"
                              class="hazard-icon hazard-only"
                              matTooltip="Hazards Detected">warning
                    </mat-icon>
                  </span>
                </mat-list-item>
                <mat-list-item>
                  <span matListItemLine *ngFor="let hazard of review.hazards">{{hazard | startCase}}</span>
                </mat-list-item>
              </mat-list>
            </div>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="imageTabGroup" fxFlexOrder.lt-sm="1"
                           animationDuration="0ms">
              <mat-tab *ngFor="let image of review.images" label="{{getImageLabel(image.camera_label) | startCase}}">
                <img src="{{image.url}}" alt="review image"/>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-tab>
      </div>
    </mat-tab-group>
    <div fxFlex="40px">
      <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
