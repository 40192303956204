import { AbstractApiModel } from './abstract-api-model';

export class InspectAssessment extends AbstractApiModel<InspectAssessment> {
  identifier: string;
  description: string;
  assessment: string;
  extendedDescription: string;
  responses: string[];

  deserialize(json: any): this {
    this.identifier = json.identifier;
    this.description = json.description;
    this.extendedDescription = json.extended_description;
    this.assessment = json.assessment;
    this.responses = json.responses;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      identifier: this.identifier,
      description: this.description,
      assessment: this.assessment,
      responses: this.responses
    });
  }
}
