import { PlaySequence } from './play-sequence';
import { Pose } from './pose';
import { AbstractApiModel } from './abstract-api-model';
import { PlayVersion } from './play-version';
import { Store } from './store';
import { HasStore } from './interfaces/has-store';
import { Point } from './interfaces/point';

export class Play extends AbstractApiModel<Play> implements HasStore {
  static readonly TYPE_INSPECT = 'cleansweep';
  static readonly TYPE_INSIGHT = 'insight';
  static readonly TYPE_FOCUS = 'focus';
  static readonly PRICE_COMPLIANCE = 'price_compliance';
  static readonly OUT_OF_STOCK = 'out_of_stock';
  static readonly PLANOGRAM_COMPLIANCE = 'planogram_compliance';

  name: string;
  description: string;
  schedule: string;
  endSchedule: string;
  playType: string;
  storeId: number;
  currentVersionId: number;
  playVersion: PlayVersion;
  playVersions: PlayVersion[];
  triggerDistance: number;
  priceCompliance: boolean;
  oos: boolean;
  auditRequired: boolean;
  planogram: boolean;
  rosId: string;
  captureOnly: boolean;

  store: Store;
  public active = false;

  getPlaySequences(): PlaySequence[] {
    return this.playVersion.playSequences;
  }

  updateStartingPlaySequence(newStartSequence: PlaySequence) {
    const index = this.getPlaySequences().indexOf(newStartSequence);

    this.getPlaySequences().push.apply(this.getPlaySequences(), this.getPlaySequences().splice(0, index));
    this.getPlaySequences().forEach((seq, i) => seq.priority = i);
  }

  addPlaySequence(point: Point, fromSequence: PlaySequence, type: string = PlaySequence.POSE_TYPE): PlaySequence {
    const sequence = new PlaySequence();
    sequence.type = type;
    sequence.pose = Pose.fromPoint(point, fromSequence && fromSequence.pose.getRotation());

    const index = this.getPlaySequences().indexOf(fromSequence) + 1;
    this.getPlaySequences().splice(index, 0, sequence);
    this.getPlaySequences().forEach((seq, i) => seq.priority = i);

    return sequence;
  }

  deletePlaySequence(sequence: PlaySequence): void {
    const index = this.getPlaySequences().indexOf(sequence);
    this.getPlaySequences().splice(index, 1);
  }

  deserialize(json: any): this {
    this.name = json.name;
    this.description = json.description;
    this.schedule = json.schedule;
    this.endSchedule = json.end_schedule;
    this.playType = json.play_type;
    this.storeId = json.store_id;
    this.currentVersionId = json.current_version_id;
    this.playVersion = new PlayVersion().deserialize(json.play_version);
    this.auditRequired = json.audit_required;
    this.rosId = json.ros_id;
    this.captureOnly = json.capture_only;

    if (json.outputs && this.playType === Play.TYPE_INSIGHT) {
      json.outputs.forEach((value) => {
        switch (value) {
          case Play.PRICE_COMPLIANCE: {
            this.priceCompliance = true;
            break;
          }
          case Play.OUT_OF_STOCK: {
            this.oos = true;
            break;
          }
          case Play.PLANOGRAM_COMPLIANCE: {
            this.planogram = true;
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    if (json.play_versions) {
      this.playVersions = json.play_versions.map(version => new PlayVersion().deserialize(version));
    }

    return super.deserialize(json);
  }

  serialize(): any {
    const outputs = [];

    if (this.priceCompliance) {
      outputs.push(Play.PRICE_COMPLIANCE);
    }
    if (this.oos) {
      outputs.push(Play.OUT_OF_STOCK);
    }
    if (this.planogram) {
      outputs.push(Play.PLANOGRAM_COMPLIANCE);
    }

    const attrs = {
      name: this.name,
      description: this.description,
      schedule: this.schedule,
      end_schedule: this.endSchedule,
      play_type: this.playType,
      store_id: this.storeId,
      capture_only: this.captureOnly
    };

    if (this.playType === Play.TYPE_INSIGHT) {
      attrs['audit_required'] = this.auditRequired;
      attrs['outputs'] = outputs;
    }

    if (this.playVersion) {
      attrs['play_version'] = this.playVersion.serialize();
    }

    return Object.assign(super.serialize(), attrs);
  }

  static getPlayTypeName(playType: string) {
    let playTypeName = '';

    switch (playType) {
      case Play.TYPE_INSIGHT:
        playTypeName = 'InSight';
        break;
      case Play.TYPE_INSPECT:
        playTypeName = 'InSpect';
        break;
      case Play.TYPE_FOCUS:
        playTypeName = 'Focus';
        break;
    }

    return playTypeName;
  }

  getPlayIcon() {
    let icon;

    switch (this.playType) {
      case Play.TYPE_INSIGHT:
        icon = 'insight_marty';
        break;
      case Play.TYPE_INSPECT:
        icon = 'inspect_marty';
        break;
      case Play.TYPE_FOCUS:
        icon = 'insight_marty';
        break;
    }

    return icon;
  }
}
