import { merge as observableMerge, Observable, of as observableOf, onErrorResumeNextWith, throwError } from 'rxjs';
import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiModel } from '../models/api-model';
import { ErrorService } from './error.service';
import { ActionCableService } from 'angular2-actioncable';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class ApiService {
  static apis: Api[];
  apiName = 'core';
  apiBase = '';
  apiPath = '';

  protected cachedRequests: Map<string, CachedRequest> = new Map<string, CachedRequest>();

  constructor(
    protected http: HttpClient,
    protected cableService: ActionCableService,
    protected errorService: ErrorService,
    protected authService: AuthorizationService
  ) {
  }

  getApi() {
    const api: Api = _.find(ApiService.apis, { 'apiName': this.apiName });
    if (api) {
      this.apiBase = api.apiBase;
      this.apiPath = api.apiPath;
    }
  }

  list<T extends ApiModel>(type: new() => T, route: string, params = {}, cacheDuration: number = 30): Observable<T[]> {
    return this.withCache(route, params, cacheDuration, () => this.http.get(this.url(route), { params: ApiService.params(params) })).pipe(
      filter(response => !_.isNil(response)),
      map(response => response.map(source => new type().deserialize(source))),
      catchError(e => this.handleError(e)));
  }

  page<T extends ApiModel>(type: new() => T, route: string, params = {}, cacheDuration: number = 30): Observable<PagedResult<T>> {
    return this.withCache(route, params, cacheDuration, () => this.http.get(this.url(route), { params: ApiService.params(params) })).pipe(
      filter(response => !_.isNil(response)),
      map(response => ({
          data: response.data.map(source => new type().deserialize(source)),
          totalCount: response.total_count
        })),
      catchError(e => this.handleError(e)));
  }

  get<T extends ApiModel>(type: new() => T, route: string, cacheDuration: number = 60, params: any = {}): Observable<T> {
    return this.withCache(route, params, cacheDuration, () => this.http.get(this.url(route), { params: ApiService.params(params) })).pipe(
      filter(response => !_.isNil(response)),
      map(response => new type().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  patchGet<T extends ApiModel>(type: new() => T, route: string, params: any = {}): Observable<T> {
    return this.http.patch(this.url(route), null, { params: ApiService.params(params) }).pipe(
      filter(response => !_.isNil(response)),
      map(response => new type().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  blob(route: string, params = {}, cacheDuration: number = 60): Observable<HttpResponse<Blob>> {
    return this.withCache(route, params, cacheDuration, () => this.http.get<Blob>(this.url(route),
      {
        params: ApiService.params(params),
        observe: 'response',
        responseType: 'blob' as 'json'
      })).pipe(
      catchError(e => this.handleError(e)));
  }

  create<T extends ApiModel>(model: T, route: string): Observable<T> {
    return this.http.post(this.url(route), model.serialize()).pipe(
      tap(() => this.cachedRequests.clear()),
      filter(response => !_.isNil(response)),
      map(response => model.deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  update<T extends ApiModel>(model: T, route: string): Observable<T> {
    return this.http.patch(this.url(route), model.serialize()).pipe(
      tap(() => this.cachedRequests.clear()),
      filter(response => !_.isNil(response)),
      map(response => model.deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  destroy<T extends ApiModel>(model: T, route: string): Observable<T> {
    return this.http.delete(this.url(route), model.serialize()).pipe(
      tap(() => this.cachedRequests.clear()),
      map(response => response ? model.deserialize(response) : model),
      catchError(e => this.handleError(e)));
  }

  deleteAll<T extends ApiModel>(route: string): Observable<T> {
    return this.http.delete(this.url(route)).pipe(
      catchError(e => this.handleError(e)));
  }

  cable() {
    return this.cableService.cable(this.cableUrl(), this.authService.tokenCallback());
  }

  channel<T extends ApiModel>(type: new() => T, name: string, params?: any): Observable<T> {
    return this.cable().channel(name, params).received().pipe(
      map(response => {
        const data = typeof response === 'string' ? JSON.parse(response) : response;
        return new type().deserialize(data);
      }),
      catchError(e => this.handleError(e)));
  }

  protected collectObservable<T, K>(array: T[], collector: (T) => Observable<K>): Observable<K> {
    return observableMerge(...array.map(obj => collector(obj).pipe(onErrorResumeNextWith()))) as Observable<K>;
  }

  /**
   * Check for cached requests before performing the action defined in the request callback
   *
   * @param route API route
   * @param params object containing any GET params for the request
   * @param cacheDuration duration to cache the request in seconds
   * @param requestCallback function that should perform the request if not found in the cache
   */
  protected withCache(route: string, params: any, cacheDuration: number, requestCallback: () => Observable<any>): Observable<any> {
    this.expireCachedRequests();

    const key = this.cacheKey(route, params);

    if (this.hasCachedRequest(key)) {
      return this.getCachedRequest(key);
    }

    const request = requestCallback().pipe(share());
    // Add request to the cache while in flight
    this.setCachedRequest(key, request, 10);

    return request.pipe(tap(response => {
      if (cacheDuration) {
        this.setCachedRequest(key, observableOf(response), cacheDuration);
      }
      else {
        this.cachedRequests.delete(key);
      }
    }));
  }

  /**
   * Get cached request for the given key
   */
  protected getCachedRequest(key: string) {
    return this.cachedRequests.get(key).request;
  }

  /**
   * Store the given Observable in the request cache
   *
   * @param key cache key
   * @param request observable to cache
   * @param cacheDuration duration to cache the request in seconds
   */
  protected setCachedRequest(key: string, request: Observable<any>, cacheDuration: number) {
    this.cachedRequests.set(key, { request: request, expiry: Date.now() + (cacheDuration * 1000) });
  }

  /**
   * Checks if valid request exists in the cache for the given key.
   * Removes stale requests if necessary.
   */
  protected hasCachedRequest(key: string): boolean {
    return this.cachedRequests.has(key);
  }

  /**
   * Removes stale requests.
   */
  protected expireCachedRequests(): void {
    this.cachedRequests.forEach((val, key) => {
      if (val.expiry < Date.now()) {
        this.cachedRequests.delete(key);
      }
    });
  }

  /**
   * Delete all cached requests that begin with the given route(s)
   */
  protected deleteCachedRequests(routes: string | string[]): void {
    const routeArray: string[] = _.concat([], routes);

    this.cachedRequests.forEach((val, key) => {
      routeArray.forEach(route => {
        if (key.startsWith(route)) {
          this.cachedRequests.delete(key);
        }
      });
    });
  }

  protected cacheKey(route: string, params: any = {}) {
    const orderedParams = {};
    Object.keys(params).sort().forEach(key => {
      orderedParams[key] = params[key];
    });

    return _.join([route, JSON.stringify(orderedParams)]);
  }

  protected handleError(error: any): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      // Notify error service of server errors
      if (error.error instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        this.errorService.raise(JSON.parse(decoder.decode(error.error)));
      }
      else {
        this.errorService.raise(error.error);
      }
    }
    else {
      // Likely a client exception so log to console
      console.error(error);
    }

    return throwError(error);
  }

  url(route: string) {
    this.getApi();
    const basePath = Location.joinWithSlash(this.apiBase, this.apiPath);
    return Location.joinWithSlash(basePath, route);
  }

  cableUrl() {
    this.getApi();
    return Location.joinWithSlash(this.apiBase, 'cable');
  }

  static params(source: any, target: HttpParams = new HttpParams()): HttpParams {
    Object.keys(source).forEach((key: string) => {
      let value: any = source[key];

      if (_.isNil(value)) {
        return;
      }

      if (_.isArray(value)) {
        value.forEach(entry => target = ApiService.params({ [key]: entry }, target));
        return;
      }

      if (_.isObject(value)) {
        value = JSON.stringify(value);
      }
      else {
        value = value.toString();
      }

      target = target.append(key, value);
    });

    return target;
  }
}

export interface PagedResult<T> {
  data: T[];
  totalCount: number;
}

export interface CachedRequest {
  expiry: number;
  request: Observable<any>;
}

export interface Api {
  apiName: string;
  apiBase: string;
  apiPath: string;
}
