import { Component, Input, OnInit } from '@angular/core';
import { MapComponent } from '../map.component';
import { MapLayer } from '../groupings/map-layer';
import * as _ from 'lodash';

@Component({
  selector: 'bt-map-visibility-controls',
  templateUrl: './map-visibility-controls.component.html',
  styleUrls: ['./map-visibility-controls.component.scss']
})
export class MapVisibilityControlsComponent implements OnInit {
  @Input('map') mapComponent: MapComponent;
  layers: MapLayer[] = [];

  constructor() {
  }

  ngOnInit() {
    this.getLayers();
  }

  getLayers() {
    this.layers = this.mapComponent.getLayers().sort((a, b) => b.zIndex - a.zIndex);
  }

  toggleLayer(layer: string) {
    this.mapComponent.toggleLayer(layer);
  }

  enabledLayers(): MapLayer[] {
    return _.filter(this.layers, layer => layer.enabled === true);
  }
}
