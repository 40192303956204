import { AbstractApiModel } from './abstract-api-model';
import { ZoneName } from './zone-name';
import { Organization } from './organization';
import { HasOrganization } from './interfaces/has-organization';

export class ZoneTemplate extends AbstractApiModel<ZoneTemplate> implements HasOrganization {
  audioFileId: number;
  wetAudioFileId: string;
  dryAudioFileId: string;
  zoneNameId: number;
  organizationId: number;

  zoneName: ZoneName;
  organization: Organization;

  deserialize(json: any): this {
    this.audioFileId = json.audio_file_id;
    this.wetAudioFileId = json.wet_audio_file_id;
    this.dryAudioFileId = json.dry_audio_file_id;
    this.zoneNameId = json.zone_name_id;
    this.organizationId = json.organization_id;
    this.zoneName = new ZoneName().deserialize(json.zone_name);

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      audio_file_id: this.audioFileId,
      wet_audio_file_id: this.wetAudioFileId,
      dry_audio_file_id: this.dryAudioFileId,
      zone_name_id: this.zoneNameId,
      organization_id: this.organizationId
    });
  }
}
