import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { ApiModel } from '@ng-cloud/badger-core';

export class ScanEventIssueObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    fill: 'red',
    stroke: 'red',
    strokeWidth: 6,
    hasControls: false,
    radius: 20,
    originX: 'center',
    originY: 'center',
    shadow: {
      blur: 1,
      color: 'rgba(0, 0, 0, 1)',
      offsetX: 1,
      offsetY: 1
    }
  };

  private circle: fabric.Circle;

  constructor(public viewport: ApiModel, private points: Point[], config?: any) {
    super(config);
  }

  static getCenterPoint(points: Point[]) {
    let minX = Number.MAX_VALUE, minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE, maxY = Number.MIN_VALUE;

    points.forEach(point => {
      minX = Math.min(minX, point.x);
      maxX = Math.max(maxX, point.x);
      minY = Math.min(minY, point.y);
      maxY = Math.max(maxY, point.y);
    });

    return { x: (maxX + minX) / 2, y: (maxY + minY) / 2 };
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    let minX = Number.MAX_VALUE, minY = Number.MAX_VALUE;

    this.points.forEach(point => {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
    });

    this.circle = new fabric.Circle(options);
    this.circle.top = minY;
    this.circle.left = minX;
    return this.circle;
  }
}

