import { AbstractApiModel } from './abstract-api-model';

export class CaptureEventViewReview extends AbstractApiModel<CaptureEventViewReview> {
  startedAt: Date;
  finishedAt: Date;
  assignedAt: Date;
  isUrgent: boolean;
  hazards: string[];
  comment: string;
  managerStatus: string;
  reviewStatus: string;
  reviewerEmail: string;
  managerEmail: string;
  reviewType: string;

  images: any[];

  deserialize(json: any): this {
    this.startedAt = json.started_at;
    this.finishedAt = json.finished_at;
    this.assignedAt = json.assigned_at;
    this.isUrgent = json.is_urgent;
    this.hazards = json.hazards;
    this.comment = json.comment;
    this.managerStatus = json.manager_status;
    this.reviewStatus = json.review_status;
    this.reviewerEmail = json.reviewer;
    this.managerEmail = json.manager;
    this.reviewType = json.review_type;

    this.images = json.images;

    return super.deserialize(json);
  }
}
