import { AbstractApiModel } from './abstract-api-model';
import { Point } from './interfaces/point';
import { Zone } from './interfaces/zone';

export abstract class AbstractZone<T> extends AbstractApiModel<T> implements Zone {
  points: Point[];

  deserialize(json: any): this {
    this.points = json.points;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      points: this.points
    });
  }
}
