import { AbstractApiModel } from './abstract-api-model';

export class SoftwareImage extends AbstractApiModel<SoftwareImage> {
  snapName: string;
  buildNumber: string;
  versionNumber: string;
  manifest: any;

  deserialize(json: any): this {
    this.snapName = json.snap_name;
    this.buildNumber = json.build_number;
    this.versionNumber = json.version_number;
    this.manifest = json.manifest;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      snap_name: this.snapName,
      version_number: this.versionNumber
    });
  }
}
