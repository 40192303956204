import * as _ from 'lodash';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export enum TagPosition {
  TOP_LEFT = 'tl',
  TOP_CENTER = 'tc',
  TOP_RIGHT = 'tr',
  CENTER_LEFT = 'cl',
  CENTER_CENTER = 'cc',
  CENTER_RIGHT = 'cr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_CENTER = 'bc',
  BOTTOM_RIGHT = 'br',
}

export class Region extends AbstractApiModel<Region> {
  name = ''; string
  type = '';

  xPosition: number;
  yPosition: number;
  width: number;
  height: number;

  tagPosition: TagPosition;

  viewportOffsetX: number;
  viewportOffsetY: number;
  viewportWidth: number;
  viewportHeight: number;

  zOrder: number;

  deserialize(json: any): this {
    this.name = json.name;
    this.type = json.type;
    this.xPosition = json.x;
    this.yPosition = json.y;
    this.width = json.width;
    this.height = json.height;
    this.tagPosition = json.tag_position;
    this.viewportOffsetX = json.viewport_offset_x;
    this.viewportOffsetY = json.viewport_offset_y;
    this.viewportHeight = json.viewport_height;
    this.viewportWidth = json.viewport_width;

    if ((this.name == undefined) && this.type && (this.type.length > 0)) {
      this.migrateNameAndType(this.type);
    }
    return this;
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      type: this.type,
      x: this.xPosition,
      y: this.yPosition,
      width: this.width,
      height: this.height,
      tag_position: this.tagPosition,
      viewport_offset_x: this.viewportOffsetX,
      viewport_offset_y: this.viewportOffsetY,
      viewport_width: this.viewportWidth,
      viewport_height: this.viewportHeight
    });
  }

  getRegions(): string[] {
    return _.map(Region.definedRegions, region => region.name);
  }

  migrateNameAndType(oldType: string) {
    // older realograms didn't have a name field.  They
    // only had a type field.  The old type field is now the name
    // and types can be NON_SHELF, PRICING_ONLY, IGNORE, NO_SHELF
    // So here, we are going to look up the region using the old type field
    // and set the name and type from the result.
    const region = Region.getRegionForName(oldType)
    if (region) {
      this.name = region.name;
      this.type = region.type;
    } else {
      this.name = 'Custom'
      this.type = 'NON_SHELF'
    }
  }

  static getRegionForName(name: string): Region {
    const region = _.find(Region.definedRegions, definedRegion => definedRegion.name === name);
    if (region) {
      return _.cloneDeep(region);
    }
    return undefined;
  }

  static withDefaultValues(): Region {
    return _.cloneDeep(Region.definedRegions[0]);
  }

  static definedRegions =
    [
      new Region().deserialize({
        'name': 'Pegs - Generic',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs - One Inch Gap',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs - Gum',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 4,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs - Tooth Brush',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs - Candy',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 4,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 2x3',
        'type': 'NON_SHELF',
        'tag_position': 'tl',
        'viewport_width': 2,
        'viewport_height': 3,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 2x4',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 2,
        'viewport_height': 4,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 2x6',
        'type': 'NON_SHELF',
        'tag_position': 'tr',
        'viewport_width': 2,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 2x8',
        'type': 'NON_SHELF',
        'tag_position': 'bl',
        'viewport_width': 2,
        'viewport_height': 8,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 2x12',
        'type': 'NON_SHELF',
        'tag_position': 'bc',
        'viewport_width': 2,
        'viewport_height': 12,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x3',
        'type': 'NON_SHELF',
        'tag_position': 'br',
        'viewport_width': 3,
        'viewport_height': 3,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x4',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 4,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x6',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x8',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 8,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x10',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 3x16',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 16,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 4x6',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 4,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 4x10',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 4,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 6x6',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 6x10',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 10,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 6x12',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 12,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 6x18',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 18,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 8x6',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 8x12',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 12,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 8x16',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 16,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 8x24',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 24,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 10x3',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 10,
        'viewport_height': 3,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 12x12',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 12,
        'viewport_height': 12,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pegs 12x18',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 12,
        'viewport_height': 18,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Travel Baskets',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Cleaning',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 20,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pudding Cups',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 5.5,
        'viewport_height': 4.5,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Razors',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3.5,
        'viewport_height': 3.5,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Plates',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 8,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Cosmetics',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 4,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Tissues',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 8,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Mini-Shelf 6x6',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 6,
        'viewport_height': 6,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Mini-Shelf 12x12',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 12,
        'viewport_height': 12,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Mini-Shelf 18x18',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 18,
        'viewport_height': 18,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Barbells',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 2.5,
        'viewport_height': 8,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Full Region',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Tag Above Shelf',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Tag on Shelf',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'One Shelf Facing',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Contact Paper',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 7,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Single Shelf',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Ignore',
        'type': 'IGNORE',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'No Shelf',
        'type': 'NO_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Pricing Only',
        'type': 'PRICING_ONLY',
        'tag_position': 'tc',
        'viewport_width': 3,
        'viewport_height': 2,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      }),
      new Region().deserialize({
        'name': 'Custom',
        'type': 'NON_SHELF',
        'tag_position': 'tc',
        'viewport_width': 0,
        'viewport_height': 0,
        'viewport_offset_x': 0,
        'viewport_offset_y': 0
      })
    ];
}
