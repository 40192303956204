import { AbstractApiModel } from './abstract-api-model';

export abstract class AbstractInsightReview extends AbstractApiModel<AbstractInsightReview> {
  id: number;
  reviewer: string;
  reviewer_id: number;
  startedAt: Date;
  finishedAt: Date;
  status: string;

  deserialize(json: any): this {
    this.id = json.id;
    this.reviewer = json.reviewer;
    this.reviewer_id = json.reviewer_id;
    this.startedAt = json.started_at;
    this.finishedAt = json.finished_at;
    this.status = json.status;

    return super.deserialize(json);
  }

  serialize(): any {
    return {
      id: this.id,
      started_at: this.startedAt,
      finished_at: this.finishedAt
    };
  }

  abstract getType(): string;
}
