import { AbstractApiModel } from './abstract-api-model';
import { Point } from './interfaces/point';

export class Pose extends AbstractApiModel<Pose> implements Point {
  yaw: number; // euler rotation in radians

  constructor(public x: number = 0, public y: number = 0,
              rotation: any = 0, rotationType?: RotationType) {
    super();
    this.setRotation(rotation, rotationType);
  }

  static fromPoint(point: Point, rotation?: any, rotationType?: RotationType) {
    return new Pose(point.x, point.y, rotation, rotationType);
  }

  /**
   * Get rotation value in the given units/system.
   *
   * @param rotationType RotationType of return value
   * @return Pose rotation describe in given rotation type units
   */
  getRotation(rotationType: RotationType = RotationType.Radians): any {
    switch (rotationType) {
      case RotationType.Radians: {
        return this.yaw;
      }
      case RotationType.Degrees: {
        return this.radiansToDegrees(this.yaw);
      }
      case RotationType.Quaternion: {
        return { qZ: Math.sin(this.yaw / 2), qW: Math.cos(this.yaw / 2) };
      }
    }
  }

  /**
   * Helper to get rotation in degrees
   */
  getDegrees(): number {
    return this.getRotation(RotationType.Degrees);
  }

  /**
   * Set the rotation for this pose.
   *
   * @param rotation  pose rotation value
   * @param rotationType RotationType describing units of given rotation value
   */
  setRotation(rotation: any, rotationType: RotationType = RotationType.Radians): void {
    let yaw;
    switch (rotationType) {
      case RotationType.Radians: {
        yaw = +rotation;
        break;
      }
      case RotationType.Degrees: {
        yaw = this.degreesToRadians(rotation);
        break;
      }
      case RotationType.Quaternion: {
        const sinY = 2 * (rotation.qW * rotation.qZ);
        const cosY = 1 - 2 * (rotation.qZ * rotation.qZ);
        yaw = Math.atan2(sinY, cosY);
      }
    }

    this.yaw = this.normalizeAngle(yaw);
  }

  plus(pose: Pose): Pose {
    return new Pose(this.x + pose.x, this.y + pose.y, this.getRotation() + pose.getRotation());
  }

  minus(pose: Pose): Pose {
    return new Pose(this.x - pose.x, this.y - pose.y, this.getRotation() - pose.getRotation());
  }

  private radiansToDegrees(radians: number) {
    return radians * (180 / Math.PI);
  }

  private degreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }

  private normalizeAngle(angle: number): number {
    const normalized = Math.atan2(Math.sin(angle), Math.cos(angle));
    return normalized == -Math.PI ? Math.PI : normalized;
  }

  deserialize(json: any): this {
    this.x = json.position_x ? +json.position_x : +json.x;
    this.y = json.position_y ? +json.position_y : +json.y;
    this.setRotation(json.yaw);
    return super.deserialize(json);
  }

  serialize(): any {
    return {
      position_x: this.x,
      position_y: this.y,
      yaw: this.yaw
    };
  }
}

export enum RotationType {
  Radians,
  Degrees,
  Quaternion
}
