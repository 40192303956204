import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../common/alert-dialog/alert-dialog.component';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) {
  }

  alert(message: any): Observable<boolean> {
    const text = typeof message === 'string' ? message : JSON.stringify(message, undefined, 2);
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: { text: text }
    });

    return dialogRef.afterClosed();
  }

  confirm(message: string, confirmOnEnter = false): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { text: message, confirmOnEnter: confirmOnEnter }
    });

    return dialogRef.afterClosed();
  }
}
