<mat-sidenav-container class="map-sidenav-container" dir="ltr">
  <!-- dir="ltr" need to prevent memory leak, material2 #8862 -->
  <mat-sidenav #sidenav disableClose
               [autoFocus]="false"
               (openedChange)="endAnimation();"
               (openedStart)="hideControlsIfSmallScreen();"
               (closedStart)="showControls();"
               mode="push" class="map-sidenav mat-elevation-z5">
    <ng-content select="[sidenavContent]"></ng-content>
  </mat-sidenav>

  <div class="map-sidenav-collapse" (click)="toggle()">
    <div class="collapse-button mat-elevation-z2">
      <mat-icon class="collapse-icon">{{icon}}</mat-icon>
    </div>
  </div>
  <div #mapSidenavContent class="map-sidenav-content">
    <ng-content select="[mapContent]"></ng-content>
  </div>
</mat-sidenav-container>

