import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtCommonModule } from '@ng-cloud/badger-core/common/bt-common.module';
import { BtMapModule } from '@ng-cloud/badger-core/map/bt-map.module';
import { PlayExecutionMapComponent } from '@ng-cloud/badger-core/robot/play-execution-map/play-execution-map.component';
import { PlayExecutionNavComponent } from '@ng-cloud/badger-core/robot/play-execution-nav/play-execution-nav.component';
import { CaptureEventDetailComponent } from '@ng-cloud/badger-core/robot/capture-event-detail/capture-event-detail.component';
import {
  CaptureEventReviewDetailComponent
} from '@ng-cloud/badger-core/robot/capture-event-review-detail/capture-event-review-detail.component';
import { BatteryStatusComponent } from './battery-status/battery-status.component';
import { LiveMapComponent } from '@ng-cloud/badger-core/robot/live-map/live-map.component';
import {
  CaptureAssessmentReviewDetailComponent
} from '@ng-cloud/badger-core/robot/capture-assessment-review-detail/capture-assessment-review-detail.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule,
    BtMapModule
  ],
  declarations: [
    PlayExecutionMapComponent,
    PlayExecutionNavComponent,
    CaptureEventDetailComponent,
    CaptureEventReviewDetailComponent,
    BatteryStatusComponent,
    LiveMapComponent,
    CaptureAssessmentReviewDetailComponent
  ],
  exports: [
    PlayExecutionMapComponent,
    PlayExecutionNavComponent,
    CaptureEventDetailComponent,
    CaptureEventReviewDetailComponent,
    BatteryStatusComponent,
    LiveMapComponent,
    CaptureAssessmentReviewDetailComponent
  ]
})
export class BtRobotModule {
}
