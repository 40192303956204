import { MapGrouping } from './map-grouping';
import { MapObject } from '../objects/map-object';

export class MapLayer {
  name: string;
  zIndex: number;
  grouping: MapGrouping<any, MapObject>;
  standard = true;
  visible = true;
  enabled = true;

  constructor(args: any = {}) {
    Object.assign(this, args);
  }
}

export function Layers(definitions: any[]) {
  return <T extends new(...args: any[]) => any>(target: T) => {
    Object.defineProperty(target, 'layerDefinitions', { value: definitions, configurable: true });
  };
}
