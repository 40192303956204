import { MapGrouping } from './map-grouping';
import { RobotRouteObject } from '../objects/robot-route-object';
import { MapObject } from '../objects/map-object';
import { CaptureEvent } from '../../models/capture-event';
import { GroupEventType, MapGroupingEvent } from './map-grouping-event';
import { HasPose } from '../../models/interfaces/has-pose';
import { Pose } from '../../models/pose';
import { MapGroupEntry } from './map-group-entry';
import * as _ from 'lodash';
import { Layers } from './map-layer';

@Layers([
  { name: 'routes', zIndex: 20 }
])
export class RouteGrouping extends MapGrouping<Pose, RobotRouteObject> {

  groups: MapGrouping<HasPose, MapObject>[];
  prevEntry: MapGroupEntry<HasPose, MapObject>;

  constructor(groupings: MapGrouping<HasPose, MapObject>[], options = {}) {
    super(options);
    this.groups = groupings;
    for (const group of this.groups) {
      group.changed().subscribe(() => this.redraw());
    }
  }

  createObject(data: Pose): RobotRouteObject {
    let routeObj;
    if (this.prevEntry) {
      routeObj = new RobotRouteObject(this.storeMap.poseToPixels(this.prevEntry.data.pose), this.storeMap.poseToPixels(data), this.options);
    }
    return routeObj;
  }

  private redraw() {
    this.clearEntries();

    if (!this.storeMap) {
      this.storeMap = _.get(_.find(this.groups, 'storeMap'), 'storeMap');
    }

    const groupEntries = [].concat.apply([], this.groups.map(group => group.entries));
    this.prevEntry = null;

    for (const entry of this.sortEntryList(groupEntries)) {
      if (entry.data instanceof CaptureEvent && entry.data.skipped) {
        continue; // Don't draw routes for skipped captureEventCreated
      }

      const routeEntry = this.processData(entry.data.pose);
      if (routeEntry) {
        const startEntry = this.prevEntry;
        routeEntry.object.when(startEntry.object, 'moving', () => this.onMove(this.storeMap.poseToPixels(startEntry.data.pose), routeEntry.object.end, routeEntry.object));
        routeEntry.object.when(entry.object, 'moving', () => this.onMove(routeEntry.object.start, this.storeMap.poseToPixels(entry.data.pose), routeEntry.object));
        this.eventSource.next(new MapGroupingEvent(GroupEventType.added, routeEntry));
      }
      this.prevEntry = entry;
    }

    this.doFade();

    this.eventSource.next(new MapGroupingEvent(GroupEventType.changed));
  }

  protected doFade() {
    const routes = this.getObjects();
    routes.forEach((route, i) => {
      route.setFade(i, routes.length);
    });
  }

  protected onMove(start: Pose, end: Pose, obj: RobotRouteObject) {
    obj.start = start;
    obj.end = end;
    obj.getFabric().set({ x1: start.x, y1: start.y, x2: end.x, y2: end.y });
  }

  private sortEntryList(entryList: MapGroupEntry<HasPose, MapObject>[]): MapGroupEntry<HasPose, MapObject>[] {
    return _.sortBy(entryList, entry => this.getSortValue(entry.data));
  }
}
