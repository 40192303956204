import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, PagedResult } from '@ng-cloud/badger-core/services/api.service';
import { PlayExecution } from '@ng-cloud/badger-core/models/play-execution';
import { ScanEvent } from '@ng-cloud/badger-core/models/scan-event';
import { ScanEventIssue } from '@ng-cloud/badger-core/models/scan-event-issue';
import { Viewport } from '@ng-cloud/badger-core/models/viewport';
import { Facing } from '@ng-cloud/badger-core/models/facing';
import { TopStockImage } from '@ng-cloud/badger-core/models/top-stock-image';
import { BaseScan } from '@ng-cloud/badger-core/models/base-scan';
import { Realogram } from '@ng-cloud/badger-core/models/realogram';
import { ScanEventTag } from '@ng-cloud/badger-core/models/scan-event-tag';
import { InsightOutboundResult } from '@ng-cloud/badger-core/models/insight-outbound-result';
import { ScanEventIssueResolution } from '@ng-cloud/badger-core/models/scan-event-issue-resolution';
import { PushSubscription } from '@ng-cloud/badger-core/models/push_subscription';
import { catchError, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NonShelfViewport } from '@ng-cloud/badger-core/models/non-shelf-viewport';

@Injectable()
export class InsightService extends ApiService {

  apiName = 'insight';

  static cacheDuration = 600;

  reviewId = 1000;

  /**
   * Fetch play executions for given store id
   */
  getPlayExecutions(params?: any): Observable<PlayExecution[]> {
    return this.list(PlayExecution, `play_executions`, Object.assign(params, { job_type: 'insight' }), InsightService.cacheDuration);
  }

  /**
   * Paginate insight playExecutions list
   */
  pagePlayExecutions(id: number, params?: any): Observable<PagedResult<PlayExecution>> {
    return this.page(PlayExecution, `/play_executions/page`, Object.assign(params, { store_id: `${id}` }));
  }

  /**
   * Fetch play execution for given id
   */
  getPlayExecution(id: number): Observable<PlayExecution> {
    return this.get(PlayExecution, `play_executions/${id}`, InsightService.cacheDuration);
  }

  /**
   * Fetch Insight Results for a given Play Execution
   */
  getInsightOutboundResults(params?: any): Observable<InsightOutboundResult[]> {
    return this.list(InsightOutboundResult, `insight_outbound_results`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch base scans for given store id
   */
  getBaseScans(id: number, params?: any): Observable<BaseScan[]> {
    return this.list(BaseScan, `stores/${id}/base_scans`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch all base scans
   */
  getPriorityBaseScans(params?: any): Observable<BaseScan[]> {
    return this.list(BaseScan, `base_scans/priority_index`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch base scan by id
   */
  getBaseScan(id: number): Observable<BaseScan> {
    return this.get(BaseScan, `base_scans/${id}`, 0);
  }

  /**
   * Fetch realograms for given store id
   */
  getRealograms(id: number, params?: any): Observable<Realogram[]> {
    return this.list(Realogram, `stores/${id}/realograms`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch realogram by id
   */
  getRealogram(realogram_id: number): Observable<Realogram> {
    return this.get(Realogram, `realograms/${realogram_id}`, 0);
  }

  /**
   * Fetch realogram by for a given scan event
   */
  getRealogramForScanEvent(scan_event_id: number): Observable<Realogram> {
    return this.get(Realogram, `scan_events/${scan_event_id}/realogram`, 0);
  }

  /**
   * activates a copy of the given realogram.  copy is returned.
   */
  activateRealogram(realogram: Realogram): Observable<Realogram> {
    return this.create(realogram, `realograms/${realogram.id}/activate_copy`);
  }

  /**
   * Fetch base scan by ScanEvent id
   */
  getSyntheticBaseScan(scan_event_id: number): Observable<BaseScan> {
    return this.get(BaseScan, `scan_events/${scan_event_id}/base_scan`, 0);
  }

  /**
   * Update Base Scan
   */
  updateBaseScan(baseScan: BaseScan): Observable<BaseScan> {
    return this.update(baseScan, `base_scans/${baseScan.id}`);
  }

  /**
   * Update Base Scan
   */
  completeBaseScan(baseScan: BaseScan): Observable<BaseScan> {
    return this.update(baseScan, `base_scans/${baseScan.id}/complete`);
  }

  /**
   * Generate Base Scan's Panorama Annotations
   */
  generateBaseScan(baseScan: BaseScan): Observable<BaseScan> {
    return this.update(baseScan, `base_scans/${baseScan.id}/generate`);
  }

  /**
   * Fetch scan events for given play execution id
   */
  getScanEvents(playExecutionId: number, params?: any): Observable<ScanEvent[]> {
    return this.list(ScanEvent, `play_executions/${playExecutionId}/scan_events`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch scan event by scan event id
   */
  getScanEvent(id: number): Observable<ScanEvent> {
    return this.get(ScanEvent, `scan_events/${id}`, InsightService.cacheDuration);
  }

  /**
   * Update scan event
   */
  updateScanEvent(scanEvent: ScanEvent): Observable<ScanEvent> {
    return this.update(scanEvent, `scan_events/${scanEvent.id}`);
  }

  /**
   * Fetch viewports for a given scan event
   */
  getViewports(scanEventId: number, params?: any): Observable<ScanEventIssue[]> {
    return this.list(ScanEventIssue, `scan_events/${scanEventId}/viewports`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch viewport for a given viewport id
   */
  getViewport(viewportId: number): Observable<Viewport> {
    return this.get(Viewport, `viewports/${viewportId}`, InsightService.cacheDuration);
  }

  /**
   * Fetch scanEventTags for a given scan event
   */
  getScanEventTags(scanEventId: number, params?: any): Observable<ScanEventTag[]> {
    return this.list(ScanEventTag, `scan_events/${scanEventId}/scan_event_tags`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch scanEventTag for a given scanEventTag id
   */
  getScanEventTag(scanEventTagId: number): Observable<ScanEventTag> {
    return this.get(ScanEventTag, `scan_event_tags/${scanEventTagId}`, 0);
  }

  /**
   * Fetch issues for a given scan event
   */
  getScanEventIssues(scanEventId: number, params?: any): Observable<ScanEventIssue[]> {
    return this.list(ScanEventIssue, `scan_events/${scanEventId}/issues`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch viewports with insight data for a given scan event
   */
  getViewportCentricIssues(scanEventId: number, params?: any): Observable<Viewport[]> {
    return this.http.post(this.url(`scan_events/${scanEventId}/viewports/viewport_centric_insight`), params).pipe(
      filter(response => response != null),
      map((response: any[]) => response.map(source => new Viewport().deserialize(source))),
      catchError(e => this.handleError(e))
    );
  }

  /**
   * Fetch facings for a given scan event
   */
  getFacings(scanEventId: number, params?: any): Observable<Facing[]> {
    return this.list(Facing, `scan_events/${scanEventId}/facings`, params, InsightService.cacheDuration);
  }

  /**
   * Fetch top stock images for a given scan event
   */
  getTopStockImages(scanEventId: number, params?: any): Observable<TopStockImage[]> {
    return this.list(TopStockImage, `scan_events/${scanEventId}/top_stock_images`, params, InsightService.cacheDuration);
  }

  /**
   * Update TopStockImage
   */
  updateTopStockImage(topStockImage: TopStockImage) {
    this.update(topStockImage, `top_stock_images/${topStockImage.id}`).subscribe();
  }

  /**
   * Update Base Scan
   */
  updateNonShelfViewport(nonShelfViewport: NonShelfViewport): Observable<NonShelfViewport> {
    return this.create(nonShelfViewport, `non_shelf_viewports/update_default`);
  }

  /**
   * Fetch scan events for given play execution id
   */
  getScanEventIssue(id: number): Observable<ScanEventIssue> {
    return this.get(ScanEventIssue, `scan_event_issues/${id}`, InsightService.cacheDuration);
  }

  /**
   * Fetch an array of {id: ${product_id}, urls: []} for given params
   */
  getProductImageUrls(params?: any): Observable<any[]> {
    return this.http.get(this.url('products/product_image_urls'), { params: ApiService.params(params) }).pipe(
      filter(response => !_.isNil(response)),
      map(response => response),
      catchError(e => this.handleError(e)));
  }

  /**
   * Fetch the newest product image url for given params
   */
  getNewestProductImageUrl(params?: any): Observable<string> {
    return this.http.get(this.url('products/product_image_urls'), { params: ApiService.params(params) }).pipe(
      filter(response => !_.isNil(response)),
      map((response: any[]) => {
        for (let i = 0; i < response.length; i++) {
          const entry = response[i];
          if (entry['urls'][0]) {
            return entry['urls'][0];
          }
        }
        return '';
      }),
      catchError(e => this.handleError(e)));
  }

  /**
   * Search for viewport & tag within a play execution
   */
  viewportTagSearch(playExecutionId: number, params?: any): Observable<Viewport[]> {
    return this.list(Viewport, `play_executions/${playExecutionId}/search_for_tags`, params);
  }

  baseScanChanges(storeId: number): Observable<BaseScan> {
    return this.channel(BaseScan, 'BaseScanChannel', { store_id: storeId });
  }

  /**
   *  Post to create/update a scan event issue resolution
   *  scan_event_issue_id, scan_event_id, and status (done/declined/invalid) are expected as params with
   */
  resolveScanEventIssue(params?: any): Observable<ScanEventIssueResolution> {
    return this.http.post(this.url(`scan_event_issue_resolutions`), params)
      .pipe(
        filter(response => response != null),
        map((response: any) =>  new ScanEventIssueResolution().deserialize(response)),
        catchError(e => this.handleError(e))
      );
  }

  /**
   *  GET push subscriptions
   */
  getPushSubscriptions(params: any = {}): Observable<PushSubscription[]> {
    return this.list(PushSubscription, `push_subscriptions`, InsightService.cacheDuration, params);
  }

  /**
   *  GET push subscriptions
   */
  sendPushNotification(params: any = {}): Observable<any> {
    return this.http.get(this.url(`push_subscriptions/send_push`),params)
      .pipe(
        filter(response => response !=null),
        catchError(e => this.handleError(e))
      );
  }

  /**
   *  POST push subscription
   */
  createPushSubscription(params?: any): Observable<PushSubscription> {
    return this.http.post(this.url(`push_subscriptions`), params)
      .pipe(
        filter(response => response != null),
        map( (res: any) => new PushSubscription().deserialize(res)),
        catchError(e => this.handleError(e))
      );
  }
}
