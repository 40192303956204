import { CdnFile } from '@ng-cloud/badger-core';

export class CalibrationData {
  cdnFiles: CdnFile[];
  message: string;
  messageId: string;
  imageUrl: string;
  commandType: string;

  deserialize(json: any): this {
    this.cdnFiles = json.cdn_files;
    this.message = json.message;
    this.messageId = json.message_id;

    if (this.cdnFiles && this.cdnFiles.length) {
      this.imageUrl = this.cdnFiles[0].url;
    }

    if (this.messageId) {
      switch (this.messageId) {
        case 'depthcam_floor_calibration.proximity_front':
          this.commandType = 'calibrate_front_floor';
          break;
        case 'depthcam_floor_calibration.proximity_back':
          this.commandType = 'calibrate_back_floor';
          break;
        case 'depthcam_floor_calibration.proximity_front2':
          this.commandType = 'calibrate_front2_floor';
          break;
        case 'depthcam_floor_calibration.proximity_back2':
          this.commandType = 'calibrate_back2_floor';
          break;
      }
    }

    return this;
  }
}
