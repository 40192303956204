import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Pose, RotationType } from '../../models/pose';
import { Point } from '../../models/interfaces/point';
import { Map } from '../../models/map';

export class InspectFieldObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    hoverCursor: 'default',
    opacity: 0.4
  };

  private polygons: fabric.Group;

  constructor(public pose: Pose, public map: Map, options?: any) {
    super(options);
  }

  select() {
    this.getFabric().set('opacity', 1);
  }

  deselect() {
    this.getFabric().set('opacity', this.getOptions().opacity);
  }

  update(pose: Pose) {
    this.pose = pose;
    this.getFabric().remove(this.polygons);
    this.getFabric().addWithUpdate(this.buildPolygons());
  }

  protected build(): fabric.Object {
    return new fabric.Group([this.buildPolygons()], this.getOptions());
  }

  protected buildPolygons() {
    this.polygons = new fabric.Group([
      this.buildNearFieldPolygon(),
      this.buildMidFieldPolygon(),
      this.buildFarFieldPolygon()
    ]);

    return this.polygons;
  }

  protected buildNearFieldPolygon() {
    return new fabric.Polygon(this.calculatePoints(0.387, 4.599, 1.482, 6.696, -90),
      this.polygonSettings('red'));
  }

  protected buildMidFieldPolygon() {
    return new fabric.Polygon(this.calculatePoints(1.687, 11.606, 1.486, 8.315, -90),
      this.polygonSettings('green'));
  }

  protected buildFarFieldPolygon() {
    return new fabric.Polygon(this.calculatePoints(3.699, 20.272, 1.941, 9.664, -90),
      this.polygonSettings('blue'));
  }

  protected buildProximityFrontPolygon() {
    return new fabric.Polygon(this.calculatePoints(-0.135, 1.740, 2.157, 2.815),
      this.polygonSettings('blue'));
  }

  protected buildProximityRearPolygon() {
    return new fabric.Polygon(this.calculatePoints(0.165, -1.688, 2.164, 2.773),
      this.polygonSettings('green'));
  }

  private polygonSettings(color) {
    return {
      fill: color,
      opacity: 0.5
    };
  }

  private calculatePoints(minViewExtent: number, maxViewExtent: number,
                          minViewWidth: number, maxViewWidth: number, angle: number = 0): Point[] {
    const xMax = this.pose.x + maxViewExtent;
    const xMin = this.pose.x + minViewExtent;
    const yMaxLeft = this.pose.y + (maxViewWidth / 2);
    const yMaxRight = this.pose.y - (maxViewWidth / 2);
    const yMinLeft = this.pose.y + (minViewWidth / 2);
    const yMinRight = this.pose.y - (minViewWidth / 2);

    return [[xMax, yMaxLeft], [xMax, yMaxRight], [xMin, yMinRight], [xMin, yMinLeft]].map(coords => {
      const point = this.rotatePoint(coords[0], coords[1], this.pose, this.pose.getRotation(RotationType.Degrees) + angle);
      return this.map.pointToPixels(point);
    });
  }

  private rotatePoint(x: number, y: number, pivot: Point, angle: number): Point {
    const rad = angle * (Math.PI / 180);
    const sin = Math.sin(rad);
    const cos = Math.cos(rad);

    // translate point back to origin:
    const tx = x - pivot.x;
    const ty = y - pivot.y;

    // rotate point
    const xNew = tx * cos - ty * sin;
    const yNew = tx * sin + ty * cos;

    // translate point back:
    return { x: xNew + pivot.x, y: yNew + pivot.y };
  }
}
