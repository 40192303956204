import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core';
import { Fixture } from '@ng-cloud/badger-core/models/fixture';

export class ShelfObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 2;

  defaultOptions: any = {
    selectable: false,
    evented: false,
    lockScalingFlip: true,
    opacity: 0.8,
    stroke: 'limegreen',
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false
  };

  private polyline: fabric.Polyline;

  strokeWidth = ShelfObject.DEFAULT_STROKE_WIDTH;

  constructor(public fixture: Fixture, private points: Point[], config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = ShelfObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    this.polyline = new fabric.Polyline(this.points, options);
    this.polyline.data = this.fixture;

    return this.polyline;
  }
}
