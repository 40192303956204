import { CanDeactivateFn, CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { inject } from '@angular/core';
import { AuthorizationService, OrganizationService } from '@ng-cloud/badger-core';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

const  defaultRedirect = '/users/profile';
const defaultBetaRedirect = '/weasel';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const roleGuardFn: CanMatchFn = (route: Route, segments:UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthorizationService);

  return authService.validateAccess(getRoles(route)).pipe(take(1),
    tap(isAuthorized => isAuthorized || router.navigate([getRedirect(route)])));
}

export const betaAccessGuardFn: CanMatchFn = (route: Route, segments:UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthorizationService);

  return authService.validateExclusiveAccess(['beta_access']).pipe(take(1),
    tap(isAuthorized => isAuthorized || router.navigate([getBetaRedirect(route)])));

}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const organizationGuardFn: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthorizationService);
  const organizationService = inject(OrganizationService);

  return new Observable<any>(observer => {
    authService.getCurrentUser().subscribe(user => {
      if (user.organization_id) {
        organizationService.getOrganization(user.organization_id).subscribe(organization => {
          // noinspection JSIgnoredPromiseFromCall
          observer.next(organization?.orgType === getOrganizationType(route) || organization?.name === getOrganizationName(route) || router.navigate([getRedirect(route)]));
        });
      }
    });
  });
}

export const canDeactivateGuardFn: CanDeactivateFn<CanComponentDeactivate> = (component) => component?.canDeactivate ? component.canDeactivate() : true

function getRoles(route)  {
  return (route.data && route.data['roles']) || [];
}

function getRedirect(route) {
  return (route.data && route.data['guardRedirect']) || defaultRedirect;
}

function getBetaRedirect(route) {
  return (route.data && route.data['guardRedirect']) || defaultBetaRedirect;
}

function getOrganizationType(route) {
  return (route.data && route.data['orgType']) || '';
}

function getOrganizationName(route) {
  return (route.data && route.data['orgName']) || '';
}
