import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class ViewportReviewResult extends AbstractApiModel<ViewportReviewResult> {
  scanEventIssueReviewId: number;
  matchingSku: string;
  skuMatches: boolean;
  tagMissing: boolean;
  tagUnreadable: boolean;
  viewportAccurate: boolean;

  deserialize(json: any): this {
    this.scanEventIssueReviewId = json.scan_event_issue_review_id;
    this.matchingSku = json.matching_sku;
    this.skuMatches = json.sku_matches;
    this.tagMissing = json.tag_missing;
    this.tagUnreadable = json.tag_unreadable;
    this.viewportAccurate = json.viewport_accurate;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      matching_sku: this.matchingSku,
      sku_matches: this.skuMatches,
      tag_missing: this.tagMissing,
      tag_unreadable: this.tagUnreadable,
      viewport_accurate: this.viewportAccurate
    });
  }
}
