<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>{{title | startCase}}</h2>
  <button mat-icon-button [matDialogClose]="">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div *ngIf="images.length == 1 else multipleImages">
    <img class="image" src="{{images[0].url}}" alt="image"/>
  </div>
  <ng-template #multipleImages>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="selectedImageIndex">
      <mat-tab *ngFor="let image of images; let i = index"
               label="{{image.filename?.substring(0, image.filename.indexOf('.')) || ('image ' + (i + 1)) | startCase}}">
        <img class="image" src="{{image.url}}" alt=""/>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-dialog-content>
