import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { SectionObject } from '../objects/section-object';
import { Section } from '../../models/section';
import { PanoramaMetadata } from '../../models/panorama-metadata';

export class SectionGrouping extends MapGrouping<Section, SectionObject> {
  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'module ids', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  createObject(section: Section): SectionObject {
    const points = [{ x: section.left + (section.width / 2) - this.panoramaMetadata.xShelfAlign, y: 10 }];

    const mappedPoints = points.map(pt => this.storeMap.pointToPixels(pt, 1 / this.panoramaMetadata.dpi));
    return new SectionObject(section, mappedPoints, this.options);
  }
}
