import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Pose } from '../../models/pose';

export class RobotRouteObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    stroke: 'red',
    strokeWidth: 3,
    strokeLineCap: 'round',
    originX: 'center',
    originY: 'center'
  };

  start: Pose;
  end: Pose;

  constructor(start: Pose, end: Pose, config?: any) {
    super(config);
    this.start = start;
    this.end = end;
  }

  protected build(): fabric.Object {
    const start = this.start;
    const end = this.end;
    return new fabric.Line([start.x, start.y, end.x, end.y], this.getOptions());
  }
}
