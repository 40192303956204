import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Rectangle } from '@ng-cloud/badger-core';
import { Region } from '@ng-cloud/badger-core/models/region';

export class RegionObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 3;

  defaultOptions: any = {
    evented: false,
    cornerColor: 'white',
    cornerSize: 14,
    cornerStrokeColor: '#607d8b',
    cornerStrokeWidth: 5,
    fill: 'rgba(0, 0, 255, 0.2)',
    hasControls: false,
    hoverCursor: 'pointer',
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingFlip: true,
    opacity: 1.0,
    stroke: 'blue',
    strokeUniform: true,
    transparentCorners: false,
    labelOptions: {
      fontSize: 40,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      fill: 'white',
      stroke: 'white'
    }
  };

  regionGroup: fabric.Group;
  strokeWidth = RegionObject.DEFAULT_STROKE_WIDTH;

  constructor(public region: Region, public rectangle: Rectangle, config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = RegionObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
  }

  protected getInstanceOptions(): any {
    let color;
    switch (this.region.type) {
      case 'IGNORE':
        color = '255, 0, 0';
        break;
      case 'NO_SHELF':
        color = '255, 215, 0';
        break;
      case 'PRICING_ONLY':
        color = '0, 255, 0';
        break;
      default:
        color = '0, 0, 255';
        break;
    }

    return {
      stroke: 'rgba(' + color + ', 1.0)',
      fill: 'rgba(' + color + ', 0.2)',
      strokeUniform: true,
      width: this.rectangle.width,
      height: this.rectangle.height,
      left: this.rectangle.x,
      top: this.rectangle.y
    };
  }

  protected build(): fabric.Object {
    const objects: fabric.Object[] = [];
    const options = this.getOptions();

    const rect = new fabric.Rect(options);
    const zOrder = this.region.zOrder + 1;
    const text = new fabric.Text('' + zOrder, options.labelOptions);
    text.left = rect.left + (this.strokeWidth * 2);
    text.top = rect.top + (this.strokeWidth * 2);

    objects.push(rect);
    objects.push(text);

    this.regionGroup = new fabric.Group(objects, options);
    this.regionGroup.data = this.region;
    this.regionGroup.setControlVisible('mtr', false);

    return this.regionGroup;
  }
}
