import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapSidenavComponent } from './map-sidenav/map-sidenav.component';
import { BtCommonModule } from '../common/bt-common.module';
import { MapZoomControlsComponent } from './map-zoom-controls/map-zoom-controls.component';
import { MapSaveControlsComponent } from './map-save-controls/map-save-controls.component';
import { MapVisibilityControlsComponent } from './map-visibility-controls/map-visibility-controls.component';
import { MapComponent } from './map.component';
import { MapSelectControlsComponent } from './map-select-controls/map-select-controls.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule
  ],
  declarations: [
    MapComponent,
    MapSidenavComponent,
    MapZoomControlsComponent,
    MapSaveControlsComponent,
    MapVisibilityControlsComponent,
    MapSelectControlsComponent
  ],
  exports: [
    MapComponent,
    MapSidenavComponent,
    MapZoomControlsComponent,
    MapSaveControlsComponent,
    MapVisibilityControlsComponent,
    MapSelectControlsComponent
  ]
})
export class BtMapModule {
}
