import { AbstractApiModel } from './abstract-api-model';

export class ItemData extends AbstractApiModel<ItemData> {
  item_id: string;
  name: string;
  gtin_ids_count: number;

  deserialize(json: any): this {
    this.item_id = json.gtin_ids.sort().join(','); // sort since ids can come in different order, but we need save it
    this.name = json.name;
    this.gtin_ids_count = json.gtin_ids.length;
    return this;
  }
}


export class DDStore extends AbstractApiModel<DDStore> {
  name: string;
  storeId: number;
  client_store_id: number;
  country_code: string;
  state: string;
  address: string;
  city: string;

  deserialize(json: any): this {
    this.name = json.name;
    this.storeId = json.store_id;
    this.client_store_id = json.client_store_id;
    this.country_code = json.country_code;
    this.state = json.state;
    this.address = json.address;
    this.city = json.city;

    return this;
  }
}

export class DatesTrendData extends AbstractApiModel<DatesTrendData> {
  date: string;
  mean: number;
  median: number;
  data: { name: string; value: number; count: string, store_id: number, noData: boolean, osa_rate: number, viewports_count: number, analytics_rate: number }[];

  deserialize(json: any): this {
    this.date = json.date;
    this.data = json.data.map(i => ({name: i.name, value: i.rate ?? i.value, count: i.count, id: i.store_id ?? i.id, noData: i.noData, osa_rate: i.osa_rate, viewports_count: i.vp, analytics_rate: i.analytics_rate }));
    this.median = json.median;
    this.mean = json.mean;
    return this;
  }
}

export class FilterData extends AbstractApiModel<FilterData> {
  name: string;
  fid: number;
  is_default: boolean;
  filter_preset: any;

  public constructor(init?:Partial<FilterData>) {
    super();
    Object.assign(this, init);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      fid: this.fid,
      is_default: this.is_default,
      name: this.name,
      filter_preset: this.filter_preset,
    })
  }

  deserialize(json: any): this {
    this.fid = json.fid;
    this.name = json.name;
    this.is_default = json.is_default;
    this.filter_preset = json.filter_preset;
    return this;
  }
}

export class RetailStoreData extends AbstractApiModel<RetailStoreData> {
  name: string;
  item_id: number;

  deserialize(json: any): this {
    this.item_id = json.org_id;
    this.name = json.name;
    return this;
  }
}

export class FMCGOrgData extends AbstractApiModel<RetailStoreData> {
  name: string;
  item_id: number;

  deserialize(json: any): this {
    this.item_id = json.id;
    this.name = json.name;
    return this;
  }
}

export class DepartmentData extends AbstractApiModel<DepartmentData> {
  name: string;
  dep_name_id: number;

  deserialize(json: any): this {
    this.dep_name_id = json.department_id;
    this.name = json.name;
    return this;
  }
}

export class ConsumerGoodsData extends AbstractApiModel<ConsumerGoodsData> {
  name: string;
  fmcg_org_id: number;

  deserialize(json: any): this {
    this.fmcg_org_id = json.fmcg_org_id;
    this.name = json.name;
    return this;
  }
}

export class SaleStatusData extends AbstractApiModel<SaleStatusData> {
  name: string;
  status_id: number;

  deserialize(json: any): this {
    this.status_id = json.sale_status_id;
    this.name = json.name;
    return this;
  }
}

export class CategoryData extends AbstractApiModel<CategoryData> {
  category_id: number;
  category: string;
  products: number;

  deserialize(json: any): this {
    this.category_id = json.category_id;
    this.category = json.name;
    this.products = json.products;
    return this;
  }
}

export class DateRangeData extends AbstractApiModel<DateRangeData> {
  min: string;
  max: string;

  deserialize(json: any): this {
    this.min = json.start_date;
    this.max = json.end_date;
    return this;
  }
}

export class RateByCategoryData extends AbstractApiModel<RateByCategoryData> {
  category: string;
  rate: number;
  gtins: number;
  noData: boolean;
  mean: number;
  median: number;

  deserialize(json: any): this {
    this.category = json.name;
    this.rate = json.rate;
    this.gtins = json.products;
    this.noData = json.noData;
    this.mean = json.mean;
    this.median = json.median;
    return this;
  }
}

export class PosValueTrendData extends AbstractApiModel<PosValueTrendData> {
  date: string;
  value: number;
  osa_rate: number;
  noData: boolean;
  mean: number;
  median: number;

  deserialize(json: any): this {
    this.date = json.date;
    this.value = json.value;
    this.osa_rate = json.osa_rate;
    this.noData = json.noData;
    this.mean = json.mean;
    this.median = json.median;
    return this;
  }
}


export class TopCategoryData extends AbstractApiModel<TopCategoryData> {
  date: string;
  mean: number;
  median: number;
  data: { name: string; rate: number; count: string }[];

  deserialize(json: any): this {
    this.date = json.date;
    this.data = json.data;
    this.median = json.median;
    this.mean = json.mean;
    return this;
  }
}

export class TreeMapValue {
  name: string;
  parent: string;
  value: number;
  count: number;
  gtin?: string;
  storeId?: string;
}

export class RateByCategoryByProductData extends AbstractApiModel<RateByCategoryByProductData> {
  name: string;
  parent: string;
  value: number;
  count: number;
  gtin: string;
  storeId: string;

  deserialize(json: any): this {
    this.name = json.name;
    this.parent = json.parent;
    this.value = json.rate;
    this.count = json.count;
    this.gtin = json.gtin;
    this.storeId = json.store_id;
    return this;
  }
}

export class CsvReportData extends AbstractApiModel<CsvReportData> {
  S3_location: string;
  end_date: string;
  created_at: number;
  expired_at: number;
  report_type: string;
  start_date: string;
  state: number;
  rid: number;

  deserialize(json: any): this {
    this.S3_location = json.S3_location;
    this.created_at = json.created_at;
    this.expired_at = json.expired_at;
    this.report_type = json.report_type;
    this.state = json.state;
    this.start_date = json.start_date;
    this.end_date = json.end_date;
    this.rid = json.rid;
    return this;
  }
}

export class CsvReportUpdateState extends AbstractApiModel<CsvReportUpdateState> {
  state: number;
  id: number;

  public constructor(init?:Partial<CsvReportUpdateState>) {
    super();
    Object.assign(this, init);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      state: this.state,
      id: this.id
    })
  }
}

export class CsvReportUrlData extends AbstractApiModel<CsvReportUrlData> {
  url: string;

  deserialize(json: any): this {
    this.url = json.url;
    return this;
  }
}

export class DashboardConfigData extends AbstractApiModel<DashboardConfigData> {
  data;

  public constructor(init?:Partial<DashboardConfigData>) {
    super();
    Object.assign(this, init);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      ...this.data
    })
  }

  deserialize(json: any): this {
    this.data = json;
    return this;
  }
}

export class EmptyData extends AbstractApiModel<EmptyData> {
  serialize(): any {
    return Object.assign(super.serialize(), {})
  }

  deserialize(): this {
    return this;
  }
}

