<div fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="column">
    <form #orgForm>
      <mat-form-field class="inspect-review-org-form" color="accent" appearance="outline" subscriptSizing="dynamic">
        <mat-select [(value)]="selected" panelClass="selectPopupFull">
          <mat-option (onSelectionChange)="changeOrg($event)" [value]="'all'">General Queue</mat-option>
          <mat-option *ngFor='let org of orgList' [value]="org" (onSelectionChange)="changeOrg($event)"> {{ org.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <mat-chip-listbox *ngIf="pendingReviewCount >= 0" fxShow="false" fxShow.gt-xs fxLayout="column">
    <mat-chip [ngClass]="age > thresholdDuration ? 'inspect-review-urgent-chip' : 'inspect-review-chip'"
              matTooltip="Inspect Pending Reviews -- Oldest Incomplete Review">
      <mat-icon matChipAvatar svgIcon="inspect"></mat-icon>
      <ng-container>{{pendingReviewCount}} - {{age | conciseDuration}}</ng-container>
    </mat-chip>
  </mat-chip-listbox>
</div>
