import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Map } from '@ng-cloud/badger-core/models/map';
import { LaserScan } from '@ng-cloud/badger-core/models/laser-scan';
import * as _ from 'lodash';

export class LaserScanObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    hoverCursor: 'default'
  };

  constructor(public laserScan: LaserScan, public map: Map, options?: any) {
    super(options);
  }

  protected build(): fabric.Object {
    let angle = this.laserScan.angleMin;
    const angleIncrement = this.laserScan.angleIncrement;
    const pose = this.laserScan.pose;
    const points = [];
    const options = this.getOptions();

    this.laserScan.ranges.forEach((range) => {
      if (_.isFinite(range)) {
        const pixel = this.map.pointToPixels({
          x: pose.x + (range * Math.cos((angle + pose.yaw))),
          y: pose.y + (range * Math.sin((angle + pose.yaw)))
        });

        points.push(new fabric.Rect({
          left: pixel.x,
          top: pixel.y,
          width: 1.5,
          height: 1.5,
          fill: options['fill'] || 'magenta',
          originX: 'center',
          originY: 'center',
          strokeWidth: 0
        }));
      }
      angle += angleIncrement;
    });

    return new fabric.Group(points, options);
  }
}
