import { AbstractApiModel } from './abstract-api-model';

export class Certificate extends AbstractApiModel<Certificate> {
  status: string;
  serialNumber: string;

  robotId: number;

  deserialize(json: any): this {
    this.status = json.status;
    this.serialNumber = json.serial_number;
    this.robotId = json.robot_id;
    return super.deserialize(json);
  }
}
