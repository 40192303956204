import { AfterContentInit, Component, ContentChild, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorService } from '@ng-cloud/badger-core/services/error.service';
import { FieldErrorsComponent } from '@ng-cloud/badger-core/common/field-errors/field-errors.component';

@Component({
  selector: 'bt-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {
  @Input() errorName: string;
  @ContentChild(FormControlName) formControl: FormControlName;
  @ContentChild(FieldErrorsComponent) fieldErrors: FieldErrorsComponent;
  private subscription: Subscription;

  constructor(private errorService: ErrorService) {
  }

  ngAfterContentInit() {
    if (!this.formControl) {
      return;
    }
    if (!this.errorName) {
      this.errorName = '' + this.formControl.name;
    }
    if (this.fieldErrors) {
      this.fieldErrors.control = this.formControl.control;
    }
    this.subscription = this.errorService.errorsFor(this.errorName).subscribe(errors => {
      this.formControl.control.setErrors(errors);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
