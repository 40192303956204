import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { Viewport } from '../../models/viewport';
import { ViewportComponent } from '../viewport.component';
import { ViewportImagePosition } from '../../models/viewport-image';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'bt-viewport-thumb-selection',
  templateUrl: './viewport-thumb-selection.component.html',
  styleUrls: ['./viewport-thumb-selection.component.scss']
})

export class ViewportThumbSelectionComponent implements OnChanges {
  @Input() viewportComponent: ViewportComponent;
  @Input() viewport: Viewport;

  @ViewChild('viewportThumbLeft', { static: true }) viewportThumbLeftComponent: ViewportComponent;
  @ViewChild('viewportThumbCenter', { static: true }) viewportThumbCenterComponent: ViewportComponent;
  @ViewChild('viewportThumbRight', { static: true }) viewportThumbRightComponent: ViewportComponent;

  imageSelection = new SelectionModel<ViewportComponent>(false, []);

  ngOnChanges() {
    if (this.viewportComponent) {
      this.setViewportImages(this.viewport);
    }
  }

  viewportThumbnailClicked(viewportThumbComponent: ViewportComponent) {
    if (viewportThumbComponent.status === 'realized') {
      this.imageSelection.select(viewportThumbComponent);
      this.viewportComponent.setImagePosition(viewportThumbComponent.getImagePosition());
    }
  }

  setViewportImages(viewport: Viewport) {
    if (viewport) {
      this.viewportComponent.setViewport(viewport);
      this.viewportComponent.setImagePosition(ViewportImagePosition.center);
      this.viewportThumbLeftComponent.setViewport(viewport);
      this.viewportThumbLeftComponent.setImagePosition(ViewportImagePosition.left);
      this.viewportThumbCenterComponent.setViewport(viewport);
      this.viewportThumbCenterComponent.setImagePosition(ViewportImagePosition.center);
      this.viewportThumbRightComponent.setViewport(viewport);
      this.viewportThumbRightComponent.setImagePosition(ViewportImagePosition.right);

      if (this.viewportThumbCenterComponent.status == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbCenterComponent);
      }
      else if (this.viewportThumbLeftComponent.status == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbLeftComponent);
      }
      else if (this.viewportThumbRightComponent.status == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbRightComponent);
      }
      else {
        this.viewportComponent.setViewport(null);
        this.viewportComponent.setImageUrl(null);
      }
    }
    else {
      this.viewportComponent.setViewport(null);
      this.viewportComponent.setImageUrl(null);
      this.viewportThumbLeftComponent.setViewport(null);
      this.viewportThumbLeftComponent.setImageUrl(null);
      this.viewportThumbCenterComponent.setViewport(null);
      this.viewportThumbCenterComponent.setImageUrl(null);
      this.viewportThumbRightComponent.setViewport(null);
      this.viewportThumbRightComponent.setImageUrl(null);
    }
  }
}
