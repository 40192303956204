<div class="age-queue-chips-lpad">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
    <div *ngIf='filter != "insight"' fxLayoutAlign="start center">
      <mat-chip *hasAccess="['admin', 'skybox_level_1']"
                matTooltip="Oldest Incomplete Inspect Review for All Orgs"
                [ngClass]="ageInspect > inspectThresholdDuration ? 'review-age-urgent-chip' : 'review-age-chip'">
        <mat-icon matChipAvatar svgIcon="inspect"></mat-icon>
        <mat-icon matChipTrailingIcon>functions</mat-icon>
        <ng-container>{{ageInspect | conciseDuration}}</ng-container>
      </mat-chip>
    </div>
    <div *ngIf='filter != "inspect"' fxLayoutAlign="start center">
      <mat-chip *hasAccess="['insight_moderator', 'admin', 'skybox_level_1']"
                matTooltip="Oldest Incomplete Insight Review for All Orgs"
                [ngClass]="ageInsight > insightThresholdDuration ? 'review-age-urgent-chip' : 'review-age-chip'">
        <mat-icon matChipAvatar svgIcon="insight"></mat-icon>
        <mat-icon matChipTrailingIcon>functions</mat-icon>
        <ng-container>{{ageInsight | conciseDuration}}</ng-container>
      </mat-chip>
    </div>
  </div>
</div>
