import { fabric } from 'fabric';
import { Pose } from '../../models/pose';
import { MapObject } from './map-object';
import { Path } from '../../models/path';
import * as _ from 'lodash';

export class PathObject extends MapObject {
  readonly defaultColor = '#3080BA';

  defaultOptions: any = {
    selectable: false,
    evented: false,
    hasControls: false,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    hoverCursor: 'pointer',
    borderScaleFactor: 3,
    opacity: 1.0,
    lineOptions: {
      stroke: this.defaultColor,
      strokeWidth: 8,
      originX: 'center',
      originY: 'center',
      opacity: .7
    },
    labelOptions: {
      selectable: false,
      evented: false,
      lockScalingX: true,
      lockScalingY: true,
      fontSize: 19,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      background: 'white',
      fill: 'black'
    },
    endpointOptions: {
      fill: 'rgba(0, 0, 0, 0.01)',
      stroke: 'rgba(0, 0, 0, 0.8)',
      strokeWidth: 1,
      selectable: false,
      evented: false,
      lockScalingX: true,
      lockScalingY: true,
      lockMovementX: false,
      lockMovementY: false,
      perPixelTargetFind: true,
      hasControls: true,
      originX: 'center',
      originY: 'center',
      radius: 3
    }
  };

  path: Path;
  startPose: Pose;
  endPose: Pose;

  constructor(path: Path, startPose, endPose, config?: any) {
    super(config);
    this.path = path;
    this.startPose = startPose;
    this.endPose = endPose;
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    const angle = Math.atan2((this.startPose.y - this.endPose.y), (this.startPose.x - this.endPose.x)) + Math.PI;
    const angleDegrees = angle * 180 / Math.PI;
    const pathOffset = 4;
    const textOffset = 10;

    const pathLine = new fabric.Line([this.startPose.x, this.startPose.y, this.endPose.x, this.endPose.y], _.assign({}, options.lineOptions, {
      opacity: 1,
      strokeWidth: 1
    }));

    const visionLine = new fabric.Line([this.startPose.x - Math.sin(angle) * pathOffset, this.startPose.y + Math.cos(angle) * pathOffset,
      this.endPose.x - Math.sin(angle) * pathOffset, this.endPose.y + Math.cos(angle) * pathOffset], options.lineOptions);

    const zoneLabel = new fabric.Text(this.path.name || '', options.labelOptions);
    zoneLabel.left = this.startPose.x + (.4 * zoneLabel.height) * Math.sin(angle) + (.5 * zoneLabel.width + textOffset) * Math.cos(angle);
    zoneLabel.top = this.startPose.y - (.4 * zoneLabel.height) * Math.cos(angle) + (.5 * zoneLabel.width + textOffset) * Math.sin(angle);
    zoneLabel.rotate(90 < angleDegrees && angleDegrees < 270 ? angleDegrees + 180 : angleDegrees);

    const startCircle = new fabric.Circle(_.assign({
      top: this.startPose.y,
      left: this.startPose.x,
      angle: angleDegrees + 90
    }, options.endpointOptions));

    const startCenter = new fabric.Circle({
      top: this.startPose.y,
      left: this.startPose.x,
      radius: .5,
      originX: 'center',
      originY: 'center',
      fill: options.lineOptions['stroke']
    });

    const startObj = new fabric.Group([startCircle, startCenter], options.endpointOptions);

    const endCircle = new fabric.Circle(_.assign({
      top: this.endPose.y,
      left: this.endPose.x,
      angle: angleDegrees + 90
    }, options.endpointOptions));

    const endCenter = new fabric.Circle({
      top: this.endPose.y,
      left: this.endPose.x,
      radius: .5,
      originX: 'center',
      originY: 'center',
      fill: options.lineOptions['stroke']
    });

    const endObj = new fabric.Group([endCircle, endCenter], options.endpointOptions);

    return new fabric.Group([visionLine, pathLine, startObj, endObj, zoneLabel], options);
  }
}
