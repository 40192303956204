import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ReviewService } from '@ng-cloud/badger-core/services/review.service';
import { Subscription } from 'rxjs';
import { Organization, OrganizationSelectionService, OrganizationService } from '@ng-cloud/badger-core';
import * as _ from 'lodash';

@Component({
  selector: 'bt-queue-chips',
  templateUrl: './queue-chips.component.html',
  styleUrls: ['./queue-chips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueueChipsComponent implements OnInit, OnDestroy {
  pendingReviewCount: number;
  age: moment.Duration;
  orgList: Organization[] = [];
  selected;
  orgParameters = {};

  readonly thresholdDuration: moment.Duration = moment.duration(30, 'seconds');
  private statSubscription: Subscription;
  private orgSelectSubscription: Subscription;

  constructor(private reviewService: ReviewService,
              private organizationService: OrganizationService,
              private orgSelectionService: OrganizationSelectionService) {
  }

  ngOnInit() {
    this.orgSelectSubscription = this.orgSelectionService.sharedOrgParams.subscribe(orgParams => {
      this.orgParameters = orgParams;
      // Get initial stats
      this.reviewService.getStatsByOrg(this.orgParameters).subscribe(response => this.updateReviewStats(response));
      if (this.statSubscription) {
        this.statSubscription.unsubscribe();
      }
      // Ongoing stat changes
      this.statSubscription = this.reviewService.stats(this.orgParameters).subscribe(response => this.updateReviewStats(response));
    });
    this.organizationService.getOrganizations({ isolate_moderation_queue: true }).subscribe(response => {
      this.updateOrgList(response);
      this.findLastSelectedOrg();
    });
  }

  ngOnDestroy() {
    this.statSubscription && this.statSubscription.unsubscribe();
    this.orgSelectSubscription && this.orgSelectSubscription.unsubscribe();
    this.reviewService.cable().disconnect();
  }

  updateReviewStats(json: any) {
    const ageNotDispatched = moment.duration(json['ageOldestNotDispatched']);
    const ageNotAssigned = moment.duration(json['ageOldestNotAssigned']);

    this.age = ageNotDispatched > ageNotAssigned ? ageNotDispatched : ageNotAssigned;
    this.pendingReviewCount = json['pendingModeratorNotDispatchedCount'] + json['pendingSupervisorUnassignedCount'];
  }

  updateOrgList(orgs: Organization[]) {
    this.orgList = orgs;
  }

  changeOrg(org: any) {
    if (org.isUserInput) {
      if (org.source.value !== 'all' && org.source.value !== null) {
        this.orgParameters = { org_guid: org.source.value.guid };
        localStorage.setItem('inspect-org-switcher', org.source.value.guid);
      }
      else {
        this.orgParameters = {};
        localStorage.removeItem('inspect-org-switcher');
      }
      this.orgSelectionService.updateOrgParams(this.orgParameters);
    }
  }

  findLastSelectedOrg() {
    const selectedOrg = _.find(this.orgList, { guid: localStorage.getItem('inspect-org-switcher') });

    if (selectedOrg) {
      this.selected = selectedOrg;
      this.orgParameters = { org_guid: selectedOrg.guid };
      this.orgSelectionService.updateOrgParams(this.orgParameters);
    }
    else {
      this.selected = 'all';
    }
  }
}
