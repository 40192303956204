import { AbstractApiModel } from './abstract-api-model';
import { Organization } from './organization';
import { HasOrganization } from './interfaces/has-organization';
import { ScanConfiguration } from './scan-configuration';

export class InsightConfiguration extends AbstractApiModel<InsightConfiguration> implements HasOrganization {
  organizationId: number;
  uploadAllTagImagesEnabled: boolean;
  robotDistance: number;
  autoAcceptConfidentHoles: boolean;
  autoAcceptTagNewWithoutSku: boolean;
  useRoundTripViewports: boolean;
  autoDetectTopTags: boolean;
  autoCombineTags: boolean;
  suppressPriceIssuesMissingBarcodes: boolean;
  scanConfigurations: ScanConfiguration[];
  multiBandExpansion: number;
  segmentSettings: any;
  stalePriceThreshold: number;
  shelfTagJustification: string;
  minViewportY: number;

  organization: Organization;

  deserialize(json: any): this {
    this.organizationId = json.organization_id;
    this.uploadAllTagImagesEnabled = json.upload_all_tag_images_enabled;
    this.robotDistance = json.robot_distance;
    this.autoAcceptConfidentHoles = json.auto_accept_confident_holes;
    this.autoAcceptTagNewWithoutSku = json.auto_accept_tag_new_without_sku;
    this.useRoundTripViewports = json.use_round_trip_viewports;
    this.autoDetectTopTags = json.auto_detect_top_tags;
    this.autoCombineTags = json.auto_combine_tags;
    this.suppressPriceIssuesMissingBarcodes = json.suppress_price_issues_missing_barcodes;
    this.shelfTagJustification = json.shelf_tag_justification;
    this.scanConfigurations = json.scan_configurations && json.scan_configurations.map(scan => new ScanConfiguration().deserialize(scan));
    this.multiBandExpansion = json.multi_band_expansion || 0.3;
    this.segmentSettings = json.segment_settings;
    this.stalePriceThreshold = json.stale_price_threshold || 1;
    this.minViewportY = json.min_viewport_y || 5.0;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      organization_id: this.organizationId,
      upload_all_tag_images_enabled: this.uploadAllTagImagesEnabled,
      robot_distance: this.robotDistance,
      auto_accept_confident_holes: this.autoAcceptConfidentHoles,
      auto_accept_tag_new_without_sku: this.autoAcceptTagNewWithoutSku,
      use_round_trip_viewports: this.useRoundTripViewports,
      auto_detect_top_tags: this.autoDetectTopTags,
      auto_combine_tags: this.autoCombineTags,
      suppress_price_issues_missing_barcodes: this.suppressPriceIssuesMissingBarcodes,
      shelf_tag_justification: this.shelfTagJustification,
      scans: this.scanConfigurations.map(scan => scan.serialize()),
      multi_band_expansion: this.multiBandExpansion,
      segment_settings: this.segmentSettings,
      stale_price_threshold: this.stalePriceThreshold,
      min_viewport_y: this.minViewportY
    });
  }
}
