<div fxLayout="row" fxLayoutAlign="start center">
  <span *ngIf="showOrgSwitcher" fxLayout="column">
    <form #orgForm>
      <mat-form-field class="insight-review-org-form" color="accent" appearance="outline" subscriptSizing="dynamic">
        <mat-select [(value)]="selected">
          <mat-option class="insight-review-org-selected" (onSelectionChange)="changeOrg($event)" [value]="'all'">General Queue</mat-option>
          <mat-option class="insight-review-org-selected" *ngFor='let org of orgList' [value]="org"
                      (onSelectionChange)="changeOrg($event)"> {{org.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </span>
  <div fxLayout="row"
       fxShow="{{media.isActive(componentBreakpoint)}}" fxLayoutGap="8px">
    <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isPriceReviewUrgent"
              matTooltip="Pending Price Reviews" (click)="chipSelected('price_mismatch')">
      <mat-icon matChipAvatar svgIcon="price_mismatch"></mat-icon>
      <ng-container>{{unassignedPriceReviewCount}} - {{priceReviewAge | conciseDuration}}</ng-container>
    </mat-chip>

    <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isSalePriceReviewUrgent"
              matTooltip="Pending Sale Price Reviews" (click)="chipSelected('sale_price_mismatch')">
      <mat-icon matChipAvatar svgIcon="sale_price_mismatch"></mat-icon>
      <ng-container>{{unassignedSalePriceReviewCount}} - {{salePriceReviewAge | conciseDuration}}</ng-container>
    </mat-chip>

    <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isOosReviewUrgent"
              matTooltip="Pending Hole Reviews" (click)="chipSelected('hole_oos')">
      <mat-icon matChipAvatar svgIcon="hole_oos"></mat-icon>
      <ng-container>{{unassignedOOSReviewCount}} - {{oosReviewAge | conciseDuration}}</ng-container>
    </mat-chip>

    <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isEoosReviewUrgent"
              matTooltip="Pending Product Identifications" (click)="chipSelected('eOOS')">
      <mat-icon matChipAvatar svgIcon="eOOS"></mat-icon>
      <ng-container>{{unassignedEOOSReviewCount}} - {{eoosReviewAge | conciseDuration}}</ng-container>
    </mat-chip>

    <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isViewportReviewUrgent"
              matTooltip="Pending Viewport Reviews" (click)="chipSelected('viewport')">
      <mat-icon matChipAvatar svgIcon="viewport"></mat-icon>
      <ng-container>{{unassignedViewportReviewCount}} - {{viewportReviewAge | conciseDuration}}</ng-container>
    </mat-chip>
  </div>
  <button #reviewsButton mat-flat-button fxHide="{{media.isActive(componentBreakpoint)}}"
          [ngClass]="isTotalReviewUrgent ? 'reviews-button-urgent' : 'reviews-button'"
          #reviewsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="InsightReviewChipsMenu"
          matTooltip="Insight Reviews For Selected Orgs">
    <mat-icon svgIcon="insight" [ngClass]="isTotalReviewUrgent ? 'review-chip-urgent-icon' : 'review-chip-icon'"></mat-icon>
    <span *ngIf="media.isActive('gt-xs')">Reviews</span>
  </button>
  <mat-menu fxHide fxShow.gt-xs #InsightReviewChipsMenu [overlapTrigger]="false" class="insight-review-chips-mat-menu">
    <mat-chip-listbox class="mat-mdc-chip-set-stacked">
      <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isPriceReviewUrgent"
                matTooltip="Pending Price Reviews For Selected Orgs" (click)="chipSelected('price_mismatch')">
        <mat-icon matChipAvatar svgIcon="price_mismatch"></mat-icon>
        {{unassignedPriceReviewCount}} - {{priceReviewAge | duration}}
      </mat-chip>

      <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isSalePriceReviewUrgent"
                matTooltip="Pending Sale Price Reviews For Selected Orgs" (click)="chipSelected('sale_price_mismatch')">
        <mat-icon matChipAvatar svgIcon="sale_price_mismatch"></mat-icon>
        {{unassignedSalePriceReviewCount}} - {{salePriceReviewAge | duration}}
      </mat-chip>

      <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isOosReviewUrgent"
                matTooltip="Pending Hole Reviews" (click)="chipSelected('hole_oos')">
        <mat-icon matChipAvatar svgIcon="hole_oos"></mat-icon>
        {{unassignedOOSReviewCount}} - {{oosReviewAge | duration}}
      </mat-chip>

      <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isEoosReviewUrgent"
                matTooltip="Pending Product Identifications" (click)="chipSelected('eOOS')">
        <mat-icon matChipAvatar svgIcon="eOOS"></mat-icon>
        {{unassignedEOOSReviewCount}} - {{eoosReviewAge | duration}}
      </mat-chip>

      <mat-chip class="insight-review-chip" [class.insight-review-urgent-chip]="isViewportReviewUrgent"
                matTooltip="Pending Viewport Reviews" (click)="chipSelected('viewport')">
        <mat-icon matChipAvatar svgIcon="viewport"></mat-icon>
        {{unassignedViewportReviewCount}} - {{viewportReviewAge | duration}}
      </mat-chip>
    </mat-chip-listbox>
  </mat-menu>
</div>
