import { MapGrouping } from './map-grouping';
import { PoseObject } from '@ng-cloud/badger-core/map/objects/pose-object';
import { HasPose } from '@ng-cloud/badger-core/models/interfaces/has-pose';
import { Pose } from '@ng-cloud/badger-core/models/pose';
import { MapGroupEntry } from './map-group-entry';
import { Layers } from './map-layer';
import * as _ from 'lodash';

@Layers([
  { name: 'poses', zIndex: 30 }
])
export class PoseGrouping<T extends HasPose> extends MapGrouping<T, PoseObject> {

  maximum: number;

  constructor(options: any = {}) {
    super(options);
    this.changed().subscribe(() => this.decorate());
  }

  addData(data: T): MapGroupEntry<T, PoseObject> {
    const entry = super.addData(data);

    if (this.maximum) {
      while (this.getEntryCount() > this.maximum) {
        this.removeByObject(this.getObjects()[0]);
      }
    }

    return entry;
  }

  createObject(data: T): PoseObject {
    const poseObj = new PoseObject(this.storeMap.poseToPixels(data.pose), this.options);
    poseObj.robotOptions = this.options.robotOptions || {};
    poseObj.robotOrientationOptions = this.options.robotOrientationOptions || {};
    poseObj.poseChanged.subscribe((pose: Pose) => {
      const entryForPose = this.entries.find(entry => entry.object == poseObj);
      entryForPose.data.pose = this.storeMap.poseToMeters(pose);
    });

    return poseObj;
  }

  decorate(): void {
    const poses = this.getObjects();
    poses.forEach((pose, i) => {
      pose.resetStatus();

      if (i === 0) {
        pose.setFirst();
      }
      if (i === poses.length - 1) {
        pose.setLast();
      }

      pose.setFade(i, poses.length);
    });
  }

  currentPose(): Pose {
    const currentObject = _.last(this.getObjects());
    return currentObject && currentObject.pose;
  }
}
