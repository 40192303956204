import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { DashboardConfigData, IWidgetData } from '../../shared/analytic-widgets/types';
import { AnalyticsService, IPagination } from '@ng-cloud/badger-core';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

export interface IAnalyticsFilters {
  stores: number[];
  categories: number[];
  products: number[];
  organization: number;
  goods: number[];
  departments: number[];
}

export type TimeGroup = 'days' | 'weeks' | 'months' | 'years';

export interface IDateRangeGroup {
  start_time: string | null;
  end_time: string | null;
  group: TimeGroup;
}

@Injectable({
  providedIn: 'root'
})
export class TopFilterService {

  constructor(private analyticsService: AnalyticsService) {
  }

  private filters: BehaviorSubject<IAnalyticsFilters> = new BehaviorSubject<IAnalyticsFilters>(undefined);
  private dateRangeGroup = new BehaviorSubject<IDateRangeGroup>(undefined);
  private saleStatus: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private loaderStatus = new BehaviorSubject<boolean>(false);
  private applyFilters = new BehaviorSubject<boolean>(false);

  private dashboardsConfig: BehaviorSubject<DashboardConfigData> = new BehaviorSubject<DashboardConfigData>(null);
  private orgType: string;

  private appliedFilters : { filters: IAnalyticsFilters; dateGroup: IDateRangeGroup; saleStatus: number; } = {
    filters: null,
    dateGroup: null,
    saleStatus: undefined
  };

  setOrgType(type: string) {
    this.orgType = type;
  }

  getOrgType(): string {
    return this.orgType;
  }

  getFilters(): Observable<IAnalyticsFilters> {
    return this.filters.asObservable().pipe(filter(v => !!v));
  }

  getFiltersValue(): IAnalyticsFilters {
    return this.filters.getValue();
  }

  setFilters(value: IAnalyticsFilters): void {
    const currFilters = this.filters.getValue();
    this.filters.next(value);

    // initial filters set
    if (!currFilters && !this.dashboardsConfig.getValue().general.auto_apply_filters) {
      // if auto apply disabled - apply changes for the first time:
      this.applyChanges();
    }

    if (this.dashboardsConfig.getValue().general.auto_apply_filters) {
      this.appliedFilters.filters = value;
    }
  }

  getDateRangeGroup(): Observable<IDateRangeGroup> {
    return this.dateRangeGroup.asObservable().pipe(filter(v => !!v));
  }

  getDateRangeGroupValue(): IDateRangeGroup {
    return this.dateRangeGroup.getValue();
  }

  setDateRangeGroup(value: IDateRangeGroup): void {
    this.dateRangeGroup.next(value);

    if (this.dashboardsConfig.getValue() && this.dashboardsConfig.getValue().general.auto_apply_filters) {
      this.appliedFilters.dateGroup = value;
    }
  }

  getSalesStatus(): Observable<number> {
    return this.saleStatus.asObservable();
  }

  getSalesStatusValue(): number {
    return this.saleStatus.getValue();
  }

  setSalesStatus(value: number): void {
    this.saleStatus.next(value);

    if (this.dashboardsConfig.getValue().general.auto_apply_filters) {
      this.appliedFilters.saleStatus = value;
    }
  }

  getLoaderStatus(): Observable<boolean> {
    return this.loaderStatus.asObservable();
  }

  setLoaderStatus(value: boolean): void {
    this.loaderStatus.next(value);
  }

  getDashboardsConfigValue() {
    return this.dashboardsConfig.getValue();
  }

  getDashboardsConfig() {
    return this.dashboardsConfig.asObservable();
  }

  setDashboardsConfig(value: DashboardConfigData, save = false) {

    this.dashboardsConfig.next(value);

    if (save) {
       this.analyticsService.saveDashboardConfiguration(this.dashboardsConfig.getValue(), this.orgType).subscribe();
    }
  }

  isFiltersChangedAfterApply() : boolean {
    return !_.isEqual(this.appliedFilters.filters, this.getFiltersValue()) ||
       !_.isEqual(this.appliedFilters.dateGroup, this.getDateRangeGroupValue()) ||
       this.appliedFilters.saleStatus != this.getSalesStatusValue();
  }

  applyChanges() {
    this.appliedFilters.filters = this.getFiltersValue();
    this.appliedFilters.dateGroup = this.getDateRangeGroupValue();
    this.appliedFilters.saleStatus = this.getSalesStatusValue();

    this.applyFilters.next(true);
    this.applyFilters.next(false);
  }

  public combineFilters<T>(chartData: IWidgetData<T>): Observable<[IPagination, IAnalyticsFilters, IDateRangeGroup, number]> {
    return combineLatest([
      chartData.widgetConfig,
      this.getFilters().pipe(
        distinctUntilChanged((x, y) => _.isEqual(x, y)),
        tap(() => {
          chartData.resetPage$.next();
        })),
      this.getDateRangeGroup().pipe(
        distinctUntilChanged((x, y) => x != undefined && x.end_time == y.end_time && x.start_time == y.start_time && x.group == y.group),
        tap(() => {
          chartData.resetPage$.next();
        })),
      this.getSalesStatus().pipe(
        distinctUntilChanged(),
        tap(() => {
          chartData.resetPage$.next();
        })),
      this.applyFilters.asObservable() //index 4 - flag for apply
    ]).pipe(
      //tap(val => console.log('combineFilters', val, this.getAutoApplyValue())),
      filter((val) => this.dashboardsConfig.getValue().general.auto_apply_filters || val[4]), // ignore changes if no auto-apply or no apply flag
      debounceTime(100),
      map((val) => [val[0], val[1], val[2], val[3]]) // remove the last observable
    );
  }
}

