<mat-dialog-content fxLayout="row" fxLayoutGap="25px" style="width: 1000px; height: 75vh;">
  <bt-commentable-list [commentables]="commentables" [initialSelection]="initialSelection" (commentableSelect)="commentableSelected($event)" fxFlex="20%"></bt-commentable-list>
  <mat-divider vertical></mat-divider>

  <div fxLayout="column" fxFlex="75%" fxFlexFill>
    <h2 mat-dialog-title>Comments</h2>

    <bt-comment-list #commentList [ngStyle]="{'height': 'calc(100% - ' + 135 + 'px)'}"></bt-comment-list>

    <mat-dialog-actions align="end">
      <button mat-stroked-button [matDialogClose]="">Close</button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
