import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService {

  constructor() {
  }

  readonly smallScreenCutoff = 961;

  isSmallScreen() {
    return window.innerWidth < this.smallScreenCutoff;
  }

  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }
}
