import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { Fixture } from '../../models/fixture';
import { ShelfObject } from '../../map/objects/shelf-object';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';

export class ShelfGrouping extends MapGrouping<Fixture, ShelfObject> {

  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'fixtures', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  createObject(fixture: Fixture): ShelfObject {
    const xPosition = fixture.absoluteX - this.panoramaMetadata.xShelfAlign;
    const yPosition = fixture.yPosition - this.panoramaMetadata.yShelfAlign;

    const points = [
      { x: xPosition, y: yPosition },
      { x: xPosition + fixture.width, y: yPosition }];

    const mappedPoints = points.map(pt => this.storeMap.pointToPixels(pt, 1 / this.panoramaMetadata.dpi));

    return new ShelfObject(fixture, mappedPoints, this.options);
  }
}
