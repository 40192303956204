export class Image {
  url: string;
  description: string;

  constructor(source: any) {
    if (source) {
      this.url = source.url;
      this.description = source.camera_label;
    }
  }
}
