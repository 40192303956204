import { AbstractApiModel } from './abstract-api-model';

export class InsightOutboundResult extends AbstractApiModel<InsightOutboundResult> {
  destination: string;
  sentAt: Date;
  playExecutionId: number;
  scanEventId: number;
  resultFileId: number;
  priceIssueCount: number;
  salePriceIssueCount: number;
  holeIssueCount: number;
  wrongIssueCount: number;
  resultFileURL: string;

  deserialize(json: any): this {
    this.destination = json.destination;
    this.sentAt = json.sent_at && new Date(json.sent_at);
    this.playExecutionId = json.play_execution_id;
    this.scanEventId = json.scan_event_id;
    this.resultFileId = json.result_file_id;
    this.priceIssueCount = json.price_issue_count;
    this.salePriceIssueCount = json.sale_price_issue_count;
    this.holeIssueCount = json.hole_issue_count;
    this.wrongIssueCount = json.wrong_issue_count;
    this.resultFileURL = json.result_file_url;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }
}
