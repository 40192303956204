import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { AnalyticsService, CsvReportData, CsvReportUrlData } from '@ng-cloud/badger-core';
import { CsvReportState } from '../../shared/analytic-widgets/types';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CsvReportsService {
  private reportList = new BehaviorSubject([]);
  private skipNumber = 0;
  private pollSubs: Subscription;
  private orgType: string;

  private reportPageActive = false;
  private anyReady = false;
  private firstLoad = true;

  constructor(private analyticsService: AnalyticsService,
              private toastr: ToastrService
  ) {
  }

  setOrgType(type: string) {
    this.orgType = type;
  }

  startPolling() {
    this.updateList();
    this.skipNumber = 0;

    if (!this.pollSubs) {

      this.pollSubs = interval(3000).subscribe(n => {
        if (this.skipNumber == 0) {
          this.skipNumber = this.getNextSkipNumber(n);
          this.updateList();
        }
        else {
          --this.skipNumber;
        }
      });
    }
  }

  // polling interval must be 3,6,9,12,12... seconds, so skip some occurances for 3 sec interval
  getNextSkipNumber(n: number): number {
    if (n == 0) {
      return 1;
    }
    else if (n == 2) {
      return 2;
    }
    return 3;
  }

  updateList() {
    this.analyticsService.getCsvReportList({ page: 1, per: 10 }, this.orgType)
      .subscribe({
        next: res => {
          this.processReportsList(res.data);
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 404 || err.status == 400) {  // means no data or wrong org
            if (this.pollSubs) {
              this.pollSubs.unsubscribe();
              this.pollSubs = null;
            }
          }
        }
      });
  }

  getReports(): Observable<CsvReportData[]> {
    return this.reportList.asObservable();
  }

  // called only if Report Page becomes active
  markAllSeen() {
    this.reportPageActive = true;

    // mark as seen locally to update list immediately, to overcome network/API delays
    const list = this.reportList.getValue();
    list.forEach(i => {
      if (i.state == CsvReportState.READY) {
        i.state = CsvReportState.SEEN;
      }
    });
    this.reportList.next(list);

    if (this.anyReady) {
      this.analyticsService.updateCsvReportState(null /* null stands for ALL */, CsvReportState.SEEN, this.orgType)
        .subscribe(() => this.updateList());
    }
  }

  leaveReportsPage() {
    this.reportPageActive = false;
  }

  markDownloaded(id: number) {
    this.analyticsService.updateCsvReportState(id, CsvReportState.DOWNLOADED, this.orgType)
      .subscribe(() => this.updateList());
  }

  getCsvReportUrl(id: number): Observable<CsvReportUrlData> {
    return this.analyticsService.getCsvReportUrl(id, this.orgType);
  }

  downloadReport(id: number) {
    this.getCsvReportUrl(id)
      .subscribe(res => {
        this.markDownloaded(id);
        window.open(res.url, '_self');
      });
  }

  rerunReport(id: number) {
    this.analyticsService.updateCsvReportState(id, CsvReportState.IN_PROGRESS, this.orgType)
      .subscribe(() => {
        this.startPolling();
      });
  }

  processReportsList(data: CsvReportData[]) {
    // check if new ready reports:
    this.anyReady = false;
    const currReports = this.reportList.getValue();

    data.forEach(i => {
      if (i.state == CsvReportState.READY) {
        this.anyReady = true;
        const report = currReports.find(x => x.rid === i.rid);
        if ((!report && !this.firstLoad) || (report && report.state == CsvReportState.IN_PROGRESS)) {

          // new ready report or was in progress - show notification
          this.toastr.show(
            `By Day from ${i.start_date} - ${i.end_date}`,
            i.report_type,
            {
              enableHtml: true,
              toastClass: 'toast-custom-message mat-elevation-z8',
              timeOut: 10000,
              payload: {  // pass id to toastr component
                id: i.rid,
                failed: i.S3_location == null ? true : false
              }
            },

            i.S3_location == null ? 'toast-error' : 'toast-success'
          );
        }
        if (this.reportPageActive) {
          // mark locally as seen
          i.state = CsvReportState.SEEN;
        }
      }
    });

    this.reportList.next(data);

    if (this.reportPageActive) {
      this.markAllSeen();
    }

    // if no reports in progress - stop polling
    if (!data.find(i => i.state === CsvReportState.IN_PROGRESS)) {
      if (this.pollSubs) {
        this.pollSubs.unsubscribe();
        this.pollSubs = null;
      }
    }

    this.firstLoad = false;
  }
}
