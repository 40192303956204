import { AbstractApiModel } from './abstract-api-model';
import { Organization } from './organization';
import { HasOrganization } from './interfaces/has-organization';
import { InspectAssessment } from '@ng-cloud/badger-core/models/inspect-assessment';

export class AssessmentTemplate extends AbstractApiModel<AssessmentTemplate> implements HasOrganization {
  inspectAssessmentIdentifier: string;
  inspectAssessmentId: number;
  organizationId: number;

  inspectAssessment: InspectAssessment;
  organization: Organization;

  deserialize(json: any): this {
    this.inspectAssessmentId = json.inspect_assessment_id;
    this.organizationId = json.organization_id;
    this.inspectAssessment = new InspectAssessment().deserialize(json.inspect_assessment);
    this.inspectAssessmentIdentifier = this.inspectAssessment.identifier;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      organization_id: this.organizationId,
      inspect_assessment_identifier: this.inspectAssessment.identifier
    });
  }
}
