import { AbstractApiModel } from './abstract-api-model';
import { PlaySequence } from './play-sequence';

export class PlayVersion extends AbstractApiModel<PlayVersion> {
  playSequences: PlaySequence[] = [];

  deserialize(json: any): this {
    if (!json) {
      return null;
    }

    if (json.play_sequences) {
      this.playSequences = json.play_sequences.map(seq => new PlaySequence().deserialize(seq));
      this.playSequences.sort((a, b) => a.priority - b.priority);
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return {
      play_sequences_attributes: this.playSequences.map(seq => seq.serialize())
    };
  }
}
