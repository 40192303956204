import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppForm, AuthorizationService, Comment, Commentable, CommentService } from '@ng-cloud/badger-core';

@Component({
  selector: 'bt-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements OnInit {
  form: AppForm<Comment>;
  formGroup: UntypedFormGroup;
  comment: Comment;
  commentable: Commentable;
  existingComment: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentFormComponent>,
    public dialog: MatDialog,
    protected authService: AuthorizationService,
    protected commentService: CommentService
  ) {
    this.comment = data.comment;
    this.commentable = data.commentable;
    this.formGroup = this.createFormGroup();
    this.form = new AppForm(this.formGroup, this.comment, new Comment(), comment => this.onSuccess(comment));
  }

  ngOnInit() {
    if (this.comment.id) {
      this.existingComment = true;
    }
  }

  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      userId: new UntypedFormControl(this.authService.getCurrentUserId(), Validators.required),
      body: new UntypedFormControl(this.comment.body, Validators.required)
    });
  }

  onSuccess(comment: Comment) {
    this.dialogRef.close(comment);
  }

  create(): void {
    if (this.existingComment) {
      this.form.submit(comment => this.commentService.updateComment(comment));
    }
    else {
      this.form.submit(comment => this.commentService.createComment(comment, this.commentable));
    }
  }
}
