import { MapGrouping } from './map-grouping';
import { HasPose } from '../../models/interfaces/has-pose';
import { Pose } from '../../models/pose';
import * as _ from 'lodash';
import { Layers } from './map-layer';
import { PoseGrouping } from '@ng-cloud/badger-core/map/groupings/pose-grouping';
import { RobotMarginObject } from '@ng-cloud/badger-core/map/objects/robot-margin-object';

@Layers([
  { name: 'margins', zIndex: 25 }
])
export class RobotMarginGrouping extends MapGrouping<Pose, RobotMarginObject> {

  constructor(public poseGrouping: PoseGrouping<HasPose>, options = {}) {
    super(options);
    this.poseGrouping.changed().subscribe(() => this.setData(_.map(this.poseGrouping.getObjects(), 'pose')));
    this.poseGrouping.added().subscribe((event) => {
      event.entry.object.poseChanged.subscribe(pose => this.replaceData(pose));
    });
  }

  createObject(pose: Pose): RobotMarginObject {
    return new RobotMarginObject(pose, this.options);
  }
}
