import { AbstractApiModel } from './abstract-api-model';

export class CdnFile extends AbstractApiModel<CdnFile> {
  url: string;
  filename: string;
  type: CdnFileType;

  deserialize(json: any): this {
    this.url = json.url;
    this.filename = json.filename;
    this.type = <CdnFileType>CdnFileType[json.content_type];

    return super.deserialize(json);
  }
}

export enum CdnFileType {
  image = 'image',
  bag = 'bag'
}
