import { MapGrouping } from './map-grouping';
import { InspectFieldObject } from '../objects/inspect-field-object';
import { Pose } from '../../models/pose';
import { ApiModel } from '../../models/api-model';
import { HasPose } from '../../models/interfaces/has-pose';
import { GroupEventType, MapGroupingEvent } from './map-grouping-event';
import { MapObject } from '../objects/map-object';
import { Layers } from './map-layer';

@Layers([
  { name: 'cameraFields', zIndex: 10 }
])
export class InspectFieldGrouping extends MapGrouping<Pose, InspectFieldObject> {

  constructor(public poseGrouping: MapGrouping<ApiModel & HasPose, MapObject>, options = {}) {
    super(options);
    poseGrouping.changed().subscribe(() => this.redraw());
  }

  createObject(data: Pose): InspectFieldObject {
    return new InspectFieldObject(data, this.storeMap);
  }

  private redraw() {
    this.clearEntries();
    this.storeMap = this.storeMap || this.poseGrouping.storeMap;

    for (const entry of this.poseGrouping.entries) {
      const fieldEntry = this.processData(entry.data.pose);
      if (fieldEntry) {
        // Remove and replace the field object if the associated pose moves or rotates
        ['moving', 'rotating'].forEach(event => {
          fieldEntry.object.when(entry.object, event, () => {
            fieldEntry.object.update(entry.data.pose);
          });
        });

        // Change opacity when selected
        fieldEntry.object.when(entry.object, 'selected', () => fieldEntry.object.select());
        fieldEntry.object.when(entry.object, 'deselected', () => {
          fieldEntry.object.deselect();
        });

        this.eventSource.next(new MapGroupingEvent(GroupEventType.added, fieldEntry));
      }
    }

    this.eventSource.next(new MapGroupingEvent(GroupEventType.changed));
  }
}
