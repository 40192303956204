import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Section } from '@ng-cloud/badger-core/models/section';
import { fabric } from 'fabric';

export class SectionObject extends MapObject {
  static DEFAULT_FONT_SIZE = 60;

  defaultOptions: any = {
    selectable: true,
    evented: true,
    editable: true,
    lockScalingFlip: true,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    opacity: 0.7,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    padding: 0,
    borderScaleFactor: 2.0,
    fontSize: SectionObject.DEFAULT_FONT_SIZE,
    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
    fontWeight: 'bold',
    fill: 'white',
    originX: 'center',
    originY: 'center'
  };

  public sectionIdLabel: fabric.IText;

  constructor(public section: Section, private points: Point[], config?: any) {
    super(config);
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    const scaleFactor = options.scaleFactor ? options.scaleFactor : 1;

    Object.assign(options, {
      fontSize: SectionObject.DEFAULT_FONT_SIZE * scaleFactor
    });

    this.sectionIdLabel = new fabric.IText('' + this.section.id, options);
    this.sectionIdLabel.top = this.points[0].y;
    this.sectionIdLabel.left = this.points[0].x;

    return this.sectionIdLabel;
  }
}
