import { fabric } from 'fabric';
import { Pose } from '../../models/pose';
import { MapObject } from './map-object';
import * as _ from 'lodash';

export class RobotObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    originX: 'center',
    originY: 'center',
    width: 16,
    height: 16,
    fill: 'royalblue',
    stroke: 'black',
    strokeLineJoin: 'round'
  };

  pose: Pose;

  constructor(pose: Pose, config?: any) {
    super(config);
    this.pose = pose;
  }

  protected getInstanceOptions(): any {
    return {
      left: this.pose.x,
      top: this.pose.y
    };
  }

  protected build(): fabric.Object {
    const point = new fabric.Point(this.pose.x, this.pose.y);
    const footprint = _.map([
      new fabric.Point(0.3702, 0),
      new fabric.Point(0.3649, -0.1736),
      new fabric.Point(0.3421, -0.2338),
      new fabric.Point(0.288, -0.2651),
      new fabric.Point(0.0114, -0.2794),
      new fabric.Point(-0.2594, -0.2651),
      new fabric.Point(-0.3108, -0.2394),
      new fabric.Point(-0.3364, -0.1853),
      new fabric.Point(-0.3453, 0),
      new fabric.Point(-0.3364, 0.1853),
      new fabric.Point(-0.3108, 0.2394),
      new fabric.Point(-0.2594, 0.2651),
      new fabric.Point(0.0114, 0.2794),
      new fabric.Point(0.288, 0.2651),
      new fabric.Point(0.3421, 0.2338),
      new fabric.Point(0.3649, 0.1736)
    ], (offset) => point.add(offset.divide(0.05)));

    return new fabric.Polygon(footprint, this.getOptions());
  }
}
