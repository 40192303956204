import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AfterPlay, DialogService, InsightType, InspectType, Play, RobotService } from '@ng-cloud/badger-core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'bt-play-outputs-form',
  templateUrl: './play-outputs-form.component.html',
  styleUrls: ['./play-outputs-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlayOutputsFormComponent implements OnInit {
  Play = Play;
  AfterPlay = AfterPlay;
  InspectType = InspectType;
  InsightType = InsightType;

  formGroup: UntypedFormGroup;
  robotId: number;
  play: Play;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlayOutputsFormComponent>,
    public dialogService: DialogService,
    public robotService: RobotService
  ) {
  }

  ngOnInit() {
    this.robotId = this.data.id;
    this.play = this.data.play;
    this.formGroup = this.createFormGroup();
  }

  createFormGroup(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      shelfDistanceDelta: new UntypedFormControl(),
      afterPlay: new UntypedFormControl(null, Validators.required)
    });

    switch (this.play.playType) {
      case Play.TYPE_INSPECT:
        formGroup.addControl('inspectType', new UntypedFormControl(null, Validators.required));
        break;
      case Play.TYPE_INSIGHT:
        formGroup.addControl('insightOutputs', new UntypedFormGroup({
          hole_oos: new UntypedFormControl(),
          eOOS: new UntypedFormControl(),
          price_mismatch: new UntypedFormControl(),
          sale_price_mismatch: new UntypedFormControl()
        }));
        break;
    }

    return formGroup;
  }

  getPlayOutputs(): any[] {
    if (this.play.playType === Play.TYPE_INSIGHT) {
      return _.compact(_.map(this.formGroup.value.insightOutputs, (value, key) => value ? key : null));
    }
    else if (this.play.playType === Play.TYPE_INSPECT) {
      return [this.formGroup.value.inspectType];
    }

    return null;
  }

  getShelfDistanceDelta(): number {
    const delta = parseFloat(this.formGroup.value.shelfDistanceDelta);
    return isNaN(delta) ? 0.0 : delta;
  }

  runPlay() {
    const params = {
      'play_outputs': this.getPlayOutputs(),
      'shelf_distance_delta': this.getShelfDistanceDelta(),
      'after_play': this.formGroup.value.afterPlay
    };

    this.dialogRef.close();

    this.robotService.runPlay(this.robotId, this.play.id, params).subscribe(() => {
      this.dialogService.alert(`Run Play command sent for:  '${this.play.name}'\nTrack progress on the Robot Commands page.`);
    });
  }
}
