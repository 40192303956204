import { AbstractApiModel } from './abstract-api-model';

export class VerificationTask extends AbstractApiModel<VerificationTask> {
  name: string;
  instruction: string;
  priority: number;
  commandType: string;
  verificationType: VerificationType;
  timeout: number;


  deserialize(json: any): this {
    this.name = json.name;
    this.instruction = json.instruction;
    this.priority = json.priority;
    this.commandType = json.command_type;
    this.verificationType = json.verification_type;
    this.timeout = json.timeout;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      instruction: this.instruction,
      priority: this.priority,
      command_type: this.commandType,
      verification_type: this.verificationType,
      timeout: this.timeout
    });
  }
}

export enum VerificationType {
  manual = 'manual',
  diagnostic = 'diagnostic',
  hybrid = 'hybrid',
  service = 'service',
}
