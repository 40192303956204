
<div class="checkbox" [ngClass]="{ 'checkbox-right' : sticked, 'disabled': disabled }">
    <label >
        <input type="checkbox" [disabled]="disabled" class="switcher" value="{{value}}" (click)="onClick()">
        <span class="switcher toggle" [ngClass]="value ? 'checked' : '' ">
            <div class="circle" [ngClass]="value ? 's_checked' : '' "> </div>
        </span>
        {{caption}}
    </label>
</div>
