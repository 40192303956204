import { AbstractZone } from './abstract-zone';

export class NavZone extends AbstractZone<NavZone> {
  static readonly KEEP_IN_MODE = 'keep_in';
  static readonly KEEP_OUT_MODE = 'keep_out';

  mode: string;
  description: string;

  deserialize(json: any): this {
    this.mode = json.mode;
    this.description = json.description;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      mode: this.mode,
      description: this.description
    });
  }
}
