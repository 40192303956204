import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { InsightAudit } from '../models/insight-audit';
import { InsightAuditPath } from '../models/insight-audit-path';
import { InsightAuditEntry } from '../models/insight-audit-entry';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InsightAuditService extends ApiService {

  apiName = 'insight';

  /**
   * Fetch Insight Audits using given params
   */
  getInsightAudits(params?: any): Observable<InsightAudit[]> {
    return this.list(InsightAudit, `insight_audits`, params);
  }

  /**
   * Fetch Insight Audit using given id
   */
  getInsightAudit(auditId: number): Observable<InsightAudit> {
    return this.get(InsightAudit, `insight_audits/${auditId}`);
  }

  /**
   * Create InsightAudit
   */
  createInsightAudit(insightAudit: InsightAudit): Observable<InsightAudit> {
    return this.create(insightAudit, 'insight_audits');
  }

  /**
   * Adds entries to an insight audit based on list of scanIds passed in
   */
  addEntriesByScanIdsToInsightAudit(insightAudit: InsightAudit, scanEventIds: number[]): Observable<InsightAudit> {
    return this.http.patch(this.url(`/insight_audits/${insightAudit.id}/add_entries_by_scan_event_ids`),
      { scan_event_ids: scanEventIds }).pipe(
      filter(response => response != null),
      map(response => new InsightAudit().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  /**
   * Fetch Insight Audit Paths using given params
   */
  getInsightAuditPaths(params?: any): Observable<InsightAuditPath[]> {
    return this.list(InsightAuditPath, `insight_audit_paths`, params);
  }

  /**
   * Open websocket connection to listen for InsightAuditPath changes on an InsightAudit
   */
  insightAuditPathsChange(auditId: number): Observable<InsightAuditPath> {
    return this.channel(InsightAuditPath, 'InsightAuditPathChannel', { insight_audit_id: auditId }).pipe(
      tap(() => this.deleteCachedRequests([`insight_audit_paths`])));
  }

  /**
   * Fetch Insight Audit Entries using given params
   */
  getInsightAuditEntries(params?: any): Observable<InsightAuditEntry[]> {
    return this.list(InsightAuditEntry, `insight_audit_entries`, params);
  }

  /**
   * Update InsightAuditEntry
   */
  updateInsightAuditEntry(insightAuditEntry: InsightAuditEntry): Observable<InsightAuditEntry> {
    return this.update(insightAuditEntry, `insight_audit_entries/${insightAuditEntry.id}`);
  }

  /**
   * Generates an audit "report" for an insight audit id
   */
  generateInsightAuditReports(id: number): Observable<any> {
    return this.http.get(this.url(`insight_audits/${id}/reports`), {
      responseType: 'arraybuffer'
    }).pipe(catchError((err) => this.handleError(err)));
  }
}
