import { MapGrouping } from './map-grouping';
import { Layers } from './map-layer';
import { IdlePoseObject } from '../objects/idle-pose-object';
import { IdlePose } from '../../models/idle-pose';

@Layers([
  { name: 'idlePoses', zIndex: 7 }
])
export class IdlePoseGrouping extends MapGrouping<IdlePose, IdlePoseObject> {

  createObject(idlePose: IdlePose): IdlePoseObject {
    return new IdlePoseObject(idlePose, this.storeMap.poseToPixels(idlePose.pose), this.options);
  }
}
