import { Observable, of as observableOf, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '@ng-cloud/badger-core/models/user';
import { LibLoginService, WhoamiData } from '@badgertechnologies/liblogin.js';

@Injectable()
export class AuthorizationService {
  private currentUser: User;

  constructor(
    private liblogin: LibLoginService
  ) {
    this.liblogin.userData.subscribe({
      next: (whoami) => this.saveUserData(whoami),
      error: () => this.clearUserData()
    });
  }

  private saveUserData(whoami: WhoamiData) {
    const u = new User();
    u.email = whoami.email;
    u.organizationId = whoami.organization_id;
    u.roles = whoami.roles;
    u.rolesString = whoami.roles.join(', ');
    u.id = whoami.id;
    u.lastSignInAt = whoami.last_sign_in_at;
    u.signInCount = whoami.sign_in_count;
    this.currentUser = u;
  }

  private clearUserData() {
    delete this.currentUser;
  }

  signOut(): void {
    this.liblogin.logout().catch(err => console.error(err));
  }

  validateToken(): Observable<boolean> {
    return this.liblogin.validAuth.asObservable();
  }

  validateAccess(roleNames: string[]): Observable<boolean> {
    return this.validateToken().pipe(
      switchMap(() => this.accessToAny(roleNames)),
      catchError(() => {
        this.signOut();
        return observableOf(false);
      }));
  }

  validateExclusiveAccess(roleNames: string[]): Observable<boolean> {
    return this.validateToken().pipe(
      switchMap(() => this.accessToAny(roleNames, false)),
      catchError(() => {
        this.signOut();
        return observableOf(false);
      }));
  }

  accessToAny(roleNames: string[], includeAdmin = true): Observable<boolean> {
    const allowedRoles = includeAdmin?  ['admin'].concat(roleNames) : roleNames;
    return this.currentRoles().pipe(map(roles => allowedRoles.some(allowed => roles.includes(allowed))));
  }

  currentRoles(): Observable<string[]> {
    return this.liblogin.roles.asObservable();
  }

  getCurrentUserId(): number {
    return this.currentUser?.id;
  }

  hasCurrentUser(): boolean {
    return !!this.currentUser;
  }

  getCurrentUserOrgId(): number {
    return this.currentUser?.organizationId;
  }

  getCurrentUser(): Observable<any> {
    return this.liblogin.userData.asObservable();
  }

  tokenCallback(): any {
    return (
      () => ({
          token: this.liblogin.token()
        })
    ).bind(this);
  }
}
