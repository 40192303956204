import { AbstractApiModel } from './abstract-api-model';
import { Organization } from './organization';
import { HasRetailOrganization } from '@ng-cloud/badger-core/models/interfaces/has-retail-organization';

export class PointOfSaleReport extends AbstractApiModel<PointOfSaleReport> implements HasRetailOrganization {
  retailOrganizationId: number;
  fmcgOrganizationId: number;
  reportOwnerOrganizationId: number;
  startTime: Date;
  endTime: Date;
  categoryId: number;
  reportFileId: number;
  downloadUrl: string;
  retailerOrg: Organization;
  fileName: string;

  setFileNameFromDownloadUrl(): void {
    const startPos = this.downloadUrl.indexOf('pos_reports/') + 'pos_reports/'.length;
    const endPos = this.downloadUrl.indexOf('?', startPos);
    this.fileName = this.downloadUrl.substring(startPos, endPos);
  }

  deserialize(json: any): this {
    this.retailOrganizationId = json.retail_organization_id;
    this.fmcgOrganizationId = json.fmcg_organization_id;
    this.reportOwnerOrganizationId = json.report_owner_organization_id;
    this.startTime = json.start_time;
    this.endTime = json.end_time;
    this.categoryId = json.category_id;
    this.reportFileId = json.report_file_id;
    this.downloadUrl = json.url;
    this.setFileNameFromDownloadUrl();
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      retail_organization_id: this.retailOrganizationId,
      fmcg_organization_id: this.fmcgOrganizationId,
      report_owner_organization_id: this.reportOwnerOrganizationId,
      start_time: this.startTime,
      end_time: this.endTime,
      category_id: this.categoryId,
      report_file_id: this.reportFileId,
      presigned_url: this.downloadUrl
    });
  }
}
