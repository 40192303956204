import { ApiModel } from './api-model';
import * as _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class AbstractApiModel<T> implements ApiModel {
  id: number;
  revision: number;
  createdAt: Date;
  updatedAt: Date;

  serialize(): any {
    return {
      id: this.id,
      revision: this.revision,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    };
  }

  deserialize(json: any): this {
    this.id = json.id && +json.id;
    this.revision = json.revision && +json.revision;
    this.createdAt = json.created_at && new Date(json.created_at);
    this.updatedAt = json.updated_at && new Date(json.updated_at);
    return this;
  }

  assign(params: any): this {
    return Object.assign(this, params);
  }

  sortValue(): number {
    return this.id;
  }

  static naturalSort = (a: string, b: string, delimiter = ' ') => {
    const splitA = a.split(delimiter);
    const splitB = b.split(delimiter);
    for (let i = 0; i < _.min([splitA.length, splitB.length]); i++) {
      const compareA = parseInt(splitA[i], 10) || splitA[i].toLowerCase();
      const compareB = parseInt(splitB[i], 10) || splitB[i].toLowerCase();
      if (compareA < compareB) {
        return -1;
      }
      if (compareA > compareB) {
        return 1;
      }
    }

    return 0;
  };
}
