import { takeWhile } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Robot } from '@ng-cloud/badger-core/models/robot';
import { Map } from '@ng-cloud/badger-core/models/map';
import { MapComponent } from '@ng-cloud/badger-core/map/map.component';
import { HeartbeatGrouping } from '@ng-cloud/badger-core/map/groupings/heartbeat-grouping';
import { RouteGrouping } from '@ng-cloud/badger-core/map/groupings/route-grouping';
import { StoreService } from '@ng-cloud/badger-core/services/store.service';
import { RobotService } from '@ng-cloud/badger-core/services/robot.service';
import { IdlePoseGrouping } from '@ng-cloud/badger-core/map/groupings/idle-pose-grouping';
import * as moment from 'moment';

@Component({
  selector: 'bt-live-map',
  templateUrl: './live-map.component.html',
  styleUrls: ['./live-map.component.scss']
})
export class LiveMapComponent implements OnInit, OnDestroy {
  @Input() robotId: number;
  @Input() routeLength = 30;
  robot: Robot;
  storeMap: Map;

  mapComponent: MapComponent;
  subscription: Subscription;
  private alive = true;

  canvasOptions: any = {
    defaultCursor: 'pointer',
    allowTouchScrolling: true
  };

  heartbeatGrouping: HeartbeatGrouping = new HeartbeatGrouping({
    hasIndicator: true,
    hasFade: true
  });
  routeGrouping: RouteGrouping = new RouteGrouping([this.heartbeatGrouping], {
    strokeWidth: 6,
    hasFade: true
  });
  idlePoseGrouping: IdlePoseGrouping = new IdlePoseGrouping();

  constructor(
    protected robotService: RobotService,
    protected storeService: StoreService
  ) {
    this.heartbeatGrouping.maximum = this.routeLength;
    this.heartbeatGrouping.changed().subscribe(() => {
      this.mapComponent && this.mapComponent.panAndZoomTo(this.heartbeatGrouping.currentPose());
    });
  }

  ngOnInit() {
    this.robotService.getRobot(this.robotId).subscribe((robot) => {
      this.robot = robot;
      this.storeService.addStore(robot).subscribe();
      this.storeService.getMap(robot.storeId).subscribe(map => this.storeMap = map);

      this.subscription = this.robotService.heartbeats(this.robotId).pipe(
        takeWhile(() => this.alive))
        .subscribe(heartbeat => this.heartbeatGrouping.addData(heartbeat));

      this.robotService
        .getHeartbeats(this.robotId, { created_after: moment().subtract(5, 'minutes') })
        .subscribe(heartbeats => this.heartbeatGrouping.setData(heartbeats));
    });

    this.robotService.getIdlePoses(this.robotId).subscribe(idlePoses => this.idlePoseGrouping.setData(idlePoses));
  }

  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.routeGrouping);
    this.mapComponent.addGrouping(this.heartbeatGrouping);
    this.mapComponent.addGrouping(this.idlePoseGrouping);
    this.mapComponent.render();
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
    this.alive = false;
  }
}
