import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-duration-format';
import { Pose, RotationType } from './pose';
import { AbstractApiModel } from './abstract-api-model';
import { HasPose } from './interfaces/has-pose';

export class Heartbeat extends AbstractApiModel<Heartbeat> implements HasPose {
  robotId: number;
  data: any;
  pose: Pose;
  timestamp: Date;
  nextPlayTime: Date;
  uptime: string;
  batteryPercentage: number;
  batteryStatus: string;
  controlStatus: string;
  controlSubStatus: string;
  fullControlStatus: string;
  vpnState: string;
  controlFeedback: any[] = [];
  serviceModeEnabled: boolean;
  freeWheelState: boolean;
  driveSpace: any[] = [];
  firmwareVersions: any[] = [];
  resourceManager: any[] = [];
  softwareVersions: any[];
  payloadSoftwareVersions: any[];
  navZones: number[] = [];

  isPaused: boolean;
  isStopped: boolean;
  isSoftStopped: boolean;
  isCautionCone: boolean;
  isNavigatingToHazard: boolean;
  isAutonomous: boolean;
  operationalMode: string;

  deserialize(json: any): this {
    this.robotId = json.robot_id;
    this.data = json.data;
    this.pose = new Pose(
      _.get(this.data, 'pose.translation.x') as number,
      _.get(this.data, 'pose.translation.y') as number,
      {
        qZ: _.get(this.data, 'pose.rotation.z') as number,
        qW: _.get(this.data, 'pose.rotation.w') as number
      },
      RotationType.Quaternion
    );

    if (_.has(this.data, 'ros_timestamp.secs')) {
      this.timestamp = new Date(this.data.ros_timestamp.secs * 1000);
    }
    else {
      this.timestamp = this.createdAt;
    }

    if (_.has(this.data, 'control.nextPlayTime.secs')) {
      this.nextPlayTime = new Date(this.data.control.nextPlayTime.secs * 1000);
    }

    if (_.has(this.data, 'truck.uptime')) {
      this.uptime = moment.duration(+this.data.truck.uptime * 1000).format();
    }

    if (_.has(this.data, 'battery.percentage')) {
      this.batteryPercentage = +this.data.battery.percentage * 100;
    }

    this.batteryStatus = _.get(this.data, 'battery.status');
    this.controlStatus = _.get(this.data, 'control.status');
    this.controlSubStatus = _.get(this.data, 'control.substatus');
    this.fullControlStatus = _.filter([this.controlStatus, this.controlSubStatus]).join('-');

    this.vpnState = _.get(this.data, 'vpn.state');
    if (_.has(this.data, 'control.feedback') && _.isObject(this.data.control.feedback)) {
      _.forOwn(this.data.control.feedback, (value, key) => this.controlFeedback.push({ name: key, value: value }));
    }
    this.serviceModeEnabled = _.get(this.data, 'control.service_mode_enabled');
    this.operationalMode = _.get(this.data, 'control.operating_mode');
    this.freeWheelState = _.get(this.data, 'motor.free_wheel');

    if (_.has(this.data, 'truck.free_space')) {
      _.forOwn(this.data.truck.free_space, (value, key) => this.driveSpace.push({ drivePath: key, space: value }));
    }

    if (_.has(this.data, 'firmware')) {
      _.forOwn(this.data.firmware, (value, key) => this.firmwareVersions.push({ name: key, version: value }));
    }

    if (_.has(this.data, 'payload_snaps')) {
      this.payloadSoftwareVersions = _.sortBy(_.slice(this.data.payload_snaps), 'name');
    }

    if (_.has(this.data, 'resource_manager')) {
      _.forOwn(this.data.resource_manager, (resource) => _.forOwn(resource, (value, key) => this.resourceManager.push({ name: key, status: value.status, ready: value.ready })));
    }

    this.softwareVersions = _.sortBy(_.slice(this.data.snaps), 'name');

    this.navZones = this.data.nav_zones;

    this.isSoftStopped = this.data.soft_estop;
    this.isStopped = _.get(this.data, 'safety.estop_enabled') || this.isSoftStopped;
    this.isPaused = this.controlSubStatus === 'Paused';

    if (this.controlStatus === 'WARNINGCONE') {
      if (this.controlSubStatus === 'At location (waiting for release)') {
        this.isCautionCone = true;
      }
      else {
        this.isNavigatingToHazard = true;
      }
    }

    return super.deserialize(json);
  }

  sortValue(): number {
    return +this.timestamp;
  }
}
