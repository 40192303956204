import * as _ from 'lodash';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';

export const zoomType = {
  auto: 'auto',
  fill: 'fill',
  fit: 'fit',
  fixed: 'fixed',

  isValid: (zoom: string | number | Point) => {
    if (_.isNumber(zoom) || zoomType.isPoint(zoom)) {
      return true;
    }

    return Object.values(zoomType).includes(zoom);
  },

  isComputed: (zoom: string | number | Point): zoom is string => _.isString(zoom),

  isDynamic: (zoom: string | number | Point) => {
    if (zoomType.isPoint(zoom)) {
      return true;
    }

    return [zoomType.fill, zoomType.fit, zoomType.auto]
      .includes(zoomType[zoom]);
  },

  isFullscreen: (zoom: string | number | Point) => {
    if (zoomType.isPoint(zoom)) {
      return false;
    }

    return ![zoomType.fill, zoomType.fit, zoomType.fixed]
      .includes(zoomType[zoom]);
  },

  isPoint: (zoom: string | number | Point): zoom is Point => {
    if (!zoom || typeof zoom === 'string' || typeof zoom === 'number') {
      return false;
    }
    return zoom.x !== undefined && zoom.y !== undefined;
  }
};
