import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'bt-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss']
})
export class FieldErrorsComponent {
  @Input() control: UntypedFormControl;

  constructor() {
  }

  getErrors(): string[] {
    if (this.control && this.control.touched && this.control.errors) {
      const errors = this.control.errors;
      const keys = Object.keys(this.control.errors);

      if (errors.minlength && errors.minlength.requiredLength) {
        return [`Minimal length is ${errors.minlength.requiredLength} symbols`];
      }
      else if (keys.length && errors[keys[0]].msg) {
        return [errors[keys[0]].msg];
      }
      else return keys;
    }
    return [];
  }
}
