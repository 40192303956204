import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from '@ng-cloud/badger-core/common/form-errors/form-errors.component';
import { FieldErrorsComponent } from '@ng-cloud/badger-core/common/field-errors/field-errors.component';
import { AlertMessageComponent } from '@ng-cloud/badger-core/common/alert-message/alert-message.component';
import { AlertDialogComponent } from '@ng-cloud/badger-core/common/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '@ng-cloud/badger-core/common/confirm-dialog/confirm-dialog.component';
import { ConfirmBannerComponent } from '@ng-cloud/badger-core/common/confirm-banner/confirm-banner.component';
import { FormFieldComponent } from '@ng-cloud/badger-core/common/form-field/form-field.component';
import { MultiSelectComponent } from '@ng-cloud/badger-core/common/multi-select/multi-select.component';
import { NavbarComponent } from '@ng-cloud/badger-core/common/navbar/navbar.component';
import { ConciseDurationPipe } from '@ng-cloud/badger-core/common/pipes/concise-duration.pipe';
import { ConversionPipe } from '@ng-cloud/badger-core/common/pipes/conversion.pipe';
import { DurationPipe } from '@ng-cloud/badger-core/common/pipes/duration-pipe';
import { SolutionPipe } from '@ng-cloud/badger-core/common/pipes/solution.pipe';
import { StartCasePipe } from '@ng-cloud/badger-core/common/pipes/start-case.pipe';
import { BooleanComponent } from '@ng-cloud/badger-core/common/boolean/boolean.component';
import { HasAccessDirective } from '@ng-cloud/badger-core/common/directives/has-access.directive';
import { ImageViewerDialogComponent } from '@ng-cloud/badger-core/common/image-viewer-dialog/image-viewer-dialog.component';
import { SpinnerOverlayComponent } from '@ng-cloud/badger-core/common/spinner-overlay/spinner-overlay.component';
import { QueueChipsComponent } from '@ng-cloud/badger-core/common/queue-chips/queue-chips.component';
import { InsightQueueChipsComponent } from '@ng-cloud/badger-core/common/insight-queue-chips/insight-queue-chips.component';
import { ProgressOverlayComponent } from '@ng-cloud/badger-core/common/progress-overlay/progress-overlay.component';
import { MultiUserRoomComponent } from '@ng-cloud/badger-core/common/multi-user-room/multi-user-room.component';
import { RobotFocusPlayDetailsComponent } from './robot-focus-play-details/robot-focus-play-details.component';
import { AgeQueueChipsComponent } from '@ng-cloud/badger-core/common/age-queue-chips/age-queue-chips.component';
import { CustomDateFormatDirective } from '@ng-cloud/badger-core/common/custom-date-format/custom-date-format.directive';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { MultiSelectPopupDialogComponent } from './multi-select/multi-select-popup/multi-select-popup.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule
  ],
  declarations: [
    FormFieldComponent,
    FormErrorsComponent,
    FieldErrorsComponent,
    AlertMessageComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ConfirmBannerComponent,
    ImageViewerDialogComponent,
    MultiSelectComponent,
    NavbarComponent,
    ConciseDurationPipe,
    DurationPipe,
    SolutionPipe,
    StartCasePipe,
    ConversionPipe,
    BooleanComponent,
    HasAccessDirective,
    SpinnerOverlayComponent,
    QueueChipsComponent,
    InsightQueueChipsComponent,
    MultiUserRoomComponent,
    ProgressOverlayComponent,
    RobotFocusPlayDetailsComponent,
    AgeQueueChipsComponent,
    CustomDateFormatDirective,
    SlideToggleComponent,
    MultiSelectPopupDialogComponent
  ],
  exports: [
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FormFieldComponent,
    FormErrorsComponent,
    FieldErrorsComponent,
    MultiSelectComponent,
    NavbarComponent,
    SpinnerOverlayComponent,
    ConciseDurationPipe,
    ConversionPipe,
    DurationPipe,
    SolutionPipe,
    StartCasePipe,
    BooleanComponent,
    HasAccessDirective,
    QueueChipsComponent,
    InsightQueueChipsComponent,
    MultiUserRoomComponent,
    ProgressOverlayComponent,
    RobotFocusPlayDetailsComponent,
    AgeQueueChipsComponent,
    CustomDateFormatDirective,
    SlideToggleComponent,
    MultiSelectPopupDialogComponent
  ]
})
export class BtCommonModule {
}
