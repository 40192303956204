import { AbstractApiModel } from './abstract-api-model';
import { SoftwareImage } from './software-image';

export class SoftwareInfo extends AbstractApiModel<SoftwareInfo> {
  setName: string;
  images: SoftwareImage[];

  deserialize(json: any): this {
    this.setName = json.set_name;
    this.images = json.images ? json.images.map(image => new SoftwareImage().deserialize(image)) : [];
    return super.deserialize(json);
  }
}
