import { Pose } from './pose';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class IdlePose extends AbstractApiModel<IdlePose> {
  pose: Pose;
  poseType: IdlePoseType;

  deserialize(json: any): this {
    this.pose = json.pose && new Pose().deserialize(json.pose);
    this.poseType = json.pose_type;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      pose_type: this.poseType,
      pose_attributes: this.pose && this.pose.serialize()
    });
  }

  sortValue(): number {
    return this.poseType === IdlePoseType.Park ? 0 : 1;
  }
}

export enum IdlePoseType {
  Dock = 'dock',
  Park = 'park'
}

