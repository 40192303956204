import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { PanoramaAnnotations } from '@ng-cloud/badger-core/models/panorama-annotations';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';
import { Tag } from '@ng-cloud/badger-core/models/tag';
import { Realogram } from '@ng-cloud/badger-core/models/realogram';
import { PanoramaContainer } from '@ng-cloud/badger-core/models/interfaces/panorama-container';
import { Store } from './store';

export class BaseScan extends AbstractApiModel<BaseScan> implements PanoramaContainer {
  status: string;
  startedAt: Date;
  finishedAt: Date;
  playExecutionId: number;
  scanEventId: number;
  pathId: number;
  pathName: string;
  pathLength: number;
  scanLength: number;
  panoramaImageFileId: number;
  realogramId: number;
  tagData: Tag[] = [];
  panoramaMetadata: PanoramaMetadata;
  panoramaAnnotations: PanoramaAnnotations;
  panoramaImageUrl: string;
  realogram: Realogram;
  synthetic: boolean;
  promotedForReview: boolean;
  dismissed: boolean;
  assigneeId: number;
  assigneeEmail: string;
  idTagCount: number;
  noIdTagCount: number;
  totalTagCount: number;
  lockVersion: number;
  promotionReason: string;
  fixtureChangeData: string;
  submittedAt: Date;
  submittedBy: number;
  submittedByEmail: string;
  storeId: number;
  orgName: string;
  storeName: string;
  priority: number;

  store: Store;

  deserialize(json: any): this {
    this.status = json.status;
    this.startedAt = json.started_at && new Date(json.started_at);
    this.finishedAt = json.finished_at && new Date(json.finished_at);
    this.playExecutionId = json.play_execution_id;
    this.scanEventId = json.scan_event_id;
    this.pathId = json.path_id;
    this.pathName = json.path_name;
    this.pathLength = json.path_length;
    this.scanLength = json.scan_length;
    this.panoramaImageFileId = json.panorama_image_file_id;
    this.realogramId = json.realogram_id;
    this.synthetic = json.synthetic;
    this.promotedForReview = json.promoted_for_review;
    this.dismissed = json.dismissed;
    this.assigneeId = json.assignee_id;
    this.assigneeEmail = null;
    this.lockVersion = json.lock_version;
    this.totalTagCount = json.total_tags_count;
    this.idTagCount = json.total_ids_count;
    this.noIdTagCount = this.totalTagCount - this.idTagCount;
    this.submittedAt = json.submitted_at;
    this.submittedBy = json.submitted_by;
    this.storeId = json.store_id;
    this.priority = json.priority;

    if (json.tag_data) {
      json.tag_data.tags.forEach((tag) => {
        this.tagData.push(new Tag().deserialize(tag));
      });
    }

    this.realogram = json.realogram && new Realogram().deserialize(json.realogram);
    this.panoramaMetadata = json.panorama_metadata && new PanoramaMetadata().deserialize(json.panorama_metadata);
    this.panoramaImageUrl = json.panorama_image_url;
    this.panoramaAnnotations = (this.realogram && this.realogram.panoramaAnnotations) ||
      (json.panorama_annotations && new PanoramaAnnotations().deserialize(json.panorama_annotations));
    this.promotionReason = json.promotion_reason;
    this.fixtureChangeData = json.fixture_change_data;

    return super.deserialize(json);
  }

  serialize(): any {
    const baseScanData = {
      assignee_id: this.assigneeId,
      dismissed: this.dismissed,
      lock_version: this.lockVersion,
      submitted_at: this.submittedAt,
      submitted_by: this.submittedBy
    };
    const annotationData = this.panoramaAnnotations ? { panorama_annotations: this.panoramaAnnotations.serialize() } : {};
    return Object.assign(super.serialize(), baseScanData, annotationData);
  }
}
