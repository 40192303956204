import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { RealogramBorderObject } from '../objects/realogram-border-object';
import { BorderOrientation, RealogramBorder } from '../../models/realogram-border';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';

export class RealogramBorderGrouping extends MapGrouping<RealogramBorder, RealogramBorderObject> {

  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'segments', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  createObject(border: RealogramBorder): RealogramBorderObject {
    const xPosition = border.orientation === BorderOrientation.VERTICAL ? border.position - this.panoramaMetadata.xShelfAlign : 0;
    const yPosition = border.orientation === BorderOrientation.VERTICAL ? 0 : border.position - this.panoramaMetadata.yShelfAlign;
    const xLength = border.orientation === BorderOrientation.VERTICAL ? 0 : border.length;
    const yLength = border.orientation === BorderOrientation.VERTICAL ? border.length - this.panoramaMetadata.yShelfAlign : 0;

    const points = [
      { x: xPosition, y: yPosition },
      { x: xPosition + xLength, y: yPosition + yLength }];

    const mappedPoints = points.map(pt => this.storeMap.pointToPixels(pt, 1 / this.panoramaMetadata.dpi));
    if (border.orientation === BorderOrientation.HORIZONTAL) {
      mappedPoints[1].x -= mappedPoints[0].x;
      mappedPoints[0].x = 0;
    }
    return new RealogramBorderObject(border, mappedPoints, this.options);
  }
}
