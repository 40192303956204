import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

import * as _ from 'lodash';

export enum AuditEntryTypes {
  viewport = 'viewport',
  priceMismatch = 'price_mismatch',
  salePriceMismatch = 'sale_price_mismatch',
  outOfStock = 'hole_oos',
  incorrectProduct = 'eOOS'
}

export class InsightAuditEntry extends AbstractApiModel<InsightAuditEntry> {
  auditorPrice: string;
  issueType: string;
  judgmentCall: boolean;
  reason: string[];
  rejected: boolean;
  status: string;
  moderatorCorrect: boolean;
  robotCorrect: boolean;
  solutionCorrect: boolean;
  auditorId: number;
  insightAuditId: number;
  pathId: number;
  scanEventIssueId: number;
  scanEventIssueReviewId: number;
  viewportId: number;

  deserialize(json: any): this {
    this.auditorPrice = json.auditor_price;
    this.issueType = json.issue_type;
    this.judgmentCall = json.judgment_call;
    this.reason = json.reason ? json.reason : [];
    this.rejected = json.rejected;
    this.status = json.status;
    this.moderatorCorrect = json.moderator_correct;
    this.robotCorrect = json.robot_correct;
    this.solutionCorrect = json.solution_correct;
    this.auditorId = json.auditor_id;
    this.insightAuditId = json.insight_audit_id;
    this.pathId = json.path_id;
    this.scanEventIssueId = json.scan_event_issue_id;
    this.scanEventIssueReviewId = json.scan_event_issue_review_id;
    this.viewportId = json.viewport_id;

    if ((this.issueType === 'pano_check') && (!_.isNil(this.reason))) {
      this.reason[0] = this.reason[0] === 'minor' ? 'major' : this.reason[0];
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      auditor_price: this.auditorPrice,
      judgment_call: this.judgmentCall,
      reason: this.reason,
      rejected: this.rejected,
      status: this.status === 'suppressed' ? this.status : null
    });
  }
}
