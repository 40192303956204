import { MapGrouping } from './map-grouping';
import { Layers, MapLayer } from './map-layer';
import { StoreZone } from '@ng-cloud/badger-core/models/store-zone';
import { SelectedZoneObject } from '@ng-cloud/badger-core';

@Layers([
  { name: 'selectedStoreZones', zIndex: 20 }
])
export class SelectedStoreZoneGrouping extends MapGrouping<StoreZone, SelectedZoneObject> {
  static readonly LOWER_LAYER = 'lowerLayer';
  static readonly UPPER_LAYER = 'upperLayer';

  constructor(public options: any = {}, public layerName: string = 'selectedZones', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(data: StoreZone): SelectedZoneObject {
    const points = data.points.map(pt => this.storeMap.pointToPixels(pt));
    return new SelectedZoneObject(data, points, this.options);
  }
}
