import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import intersections from 'sweepline-intersections';
import polylabel from 'polylabel';
import * as _ from 'lodash';

export class PolygonUtilities {
  static isPolygonComplex(points: Point[]): boolean {
    const coordinates = _.map(points, point => [point.x, point.y]);
    coordinates.push(coordinates[0]);
    return intersections({ type: 'Polygon', coordinates: [coordinates] }, false).length > 0;
  }

  static getLabelCenter(points: Point[], precision = 1): Point {
    const center = polylabel([_.map(points, point => [point.x, point.y])], precision);
    return { x: center[0], y: center[1] };
  }
}
