import { Component, Input, OnInit } from '@angular/core';
import { Map } from '../../models/map';
import { MapComponent } from '../../map/map.component';
import { ScanEventIssueGrouping } from '../../map/groupings/scan-event-issue-grouping';
import { ScanEventIssue } from '../../models/scan-event-issue';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'bt-issue-map',
  templateUrl: './issue-map.component.html',
  styleUrls: ['./issue-map.component.scss']
})
export class IssueMapComponent implements OnInit {
  @Input() scanEventIssue: ScanEventIssue;
  @Input() storeMap: Map;
  @Input() size: string;
  @Input() zoom: string | number;

  mapComponent: MapComponent;

  scanEventIssueGrouping: ScanEventIssueGrouping = new ScanEventIssueGrouping();

  constructor(
    protected storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.scanEventIssueGrouping.storeMap = this.storeMap;
    this.scanEventIssueGrouping.addData(this.scanEventIssue);
  }

  onMapLoad(mapComponent: MapComponent) {
    this.mapComponent = mapComponent;
    this.mapComponent.addGrouping(this.scanEventIssueGrouping);
    this.mapComponent.render();
    this.mapComponent.fillBoundingArea();
    this.panAndZoomToIssue();
  }

  public panAndZoomToIssue() {
    const points = ScanEventIssueGrouping.getPoints(this.scanEventIssue, this.storeMap);
    this.mapComponent.panAndZoomTo({ x: points[0].x, y: points[0].y });
  }
}
