import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

export class AppForm<T> {
  constructor(
    public formGroup: UntypedFormGroup,
    public data: any,
    public model: T,
    public success?: (T) => void,
    public error?: (any) => void
  ) {
    Object.assign(this.model, this.data);
  }

  submit(handler: (T) => Observable<T>) {
    // Force update the form since child form values are lost otherwise
    this.formGroup.updateValueAndValidity({ emitEvent: true });

    if (this.formGroup.valid) {
      _.mergeWith(this.model, this.formGroup.value, (modelValue, formValue) => {
        if (_.isArray(modelValue)) {
          return formValue;
        }
      });

      handler(this.model).subscribe({
        next: this.success,
        error: this.error
      });
    }
  }
}
