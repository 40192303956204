import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavZone } from '@ng-cloud/badger-core/models/nav-zone';
import { NavZoneGroup } from '@ng-cloud/badger-core/models/nav-zone-group';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import * as _ from 'lodash';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class NavZoneService extends ApiService {

  getNavZones(id: number | number[]): Observable<NavZone[]> {
    const ids = _.castArray(id);
    return !_.isEmpty(ids) ? this.list(NavZone, 'nav_zones', { 'id[]': ids }) : observableOf<NavZone[]>([]);
  }

  getNavZoneGroups(params?: any): Observable<NavZoneGroup[]> {
    return this.list(NavZoneGroup, 'nav_zone_groups', params);
  }

  getNavZoneGroup(id: number, versionId?: number): Observable<NavZoneGroup> {
    const url = versionId ? `nav_zone_groups/${id}/versions/${versionId}` : `nav_zone_groups/${id}`;
    return this.get(NavZoneGroup, url, 300);
  }

  createNavZoneGroup(navZoneGroup: NavZoneGroup): Observable<NavZoneGroup> {
    return this.create(navZoneGroup, 'nav_zone_groups');
  }

  updateNavZoneGroup(navZoneGroup: NavZoneGroup): Observable<NavZoneGroup> {
    return this.update(navZoneGroup, `nav_zone_groups/${navZoneGroup.id}`);
  }

  activate(id: number): Observable<any> {
    return this.http.post(this.url(`nav_zone_groups/${id}/activate`), {}).pipe(
      tap(() => this.deleteCachedRequests(`nav_zone_groups/${id}`)),
      catchError(err => this.handleError(err)));
  }

  getNavZoneGroupWithVersions(id: number): Observable<NavZoneGroup> {
    return this.get(NavZoneGroup, `nav_zone_groups/${id}/versions`);
  }

  createNewVersion(navZoneGroup: NavZoneGroup): Observable<NavZoneGroup> {
    return this.create(navZoneGroup, `nav_zone_groups/${navZoneGroup.id}/versions`);
  }
}
