import { AbstractApiModel } from './abstract-api-model';
import { CaptureEventView } from './capture-event-view';
import { Pose, RotationType } from './pose';
import * as _ from 'lodash';
import { HasPose } from './interfaces/has-pose';

export class CaptureEvent extends AbstractApiModel<CaptureEvent> implements HasPose {
  capturedAt: Date;
  locationLabel: string;
  processStatus: string;
  playExecutionId: number;
  imageCount: number;
  zoneDescription: string;
  skipped: boolean;
  pose: Pose;
  status: CaptureEventReviewStatus;
  assessmentStatus: CaptureEventAssessmentStatus;

  views: CaptureEventView[];

  deserialize(json: any): this {
    this.capturedAt = json.captured_at && new Date(json.captured_at);
    this.locationLabel = json.location_label;
    this.processStatus = json.process_status;
    this.playExecutionId = json.play_execution_id;
    this.imageCount = json.image_count;
    this.zoneDescription = json.zone_description;
    this.skipped = json['skipped?'];
    this.pose = new Pose(
      _.get(json.robot_json, 'sequence.position.x'),
      _.get(json.robot_json, 'sequence.position.y'),
      {
        qZ: _.get(json.robot_json, 'sequence.orientation.z') as number,
        qW: _.get(json.robot_json, 'sequence.orientation.w') as number
      },
      RotationType.Quaternion
    );
    this.views = json.views ? json.views.map(view => new CaptureEventView().deserialize(view)) : [];
    this.status = this.views ? this.getStatus() : CaptureEventReviewStatus.skipped;
    this.assessmentStatus = this.views ? this.getAssessmentStatus() : null;

    return super.deserialize(json);
  }

  getStatus(): CaptureEventReviewStatus {
    if (this.skipped) {
      return CaptureEventReviewStatus.skipped;
    }

    let hasUnsuppressed = false;
    let status;
    for (const view of this.views) {
      for (const review of view.reviews) {
        if (review.reviewType !== 'neurala') {
          if (review.hazards.length > 0) {
            status = CaptureEventReviewStatus.hazardOnly;
          }
          if (review.isUrgent) {
            return CaptureEventReviewStatus.urgent;
          }
        }
        if (review.reviewStatus != 'suppressed') {
          hasUnsuppressed = true;
        }
      }
    }

    if (!status) {
      if (!hasUnsuppressed) {
        status = CaptureEventReviewStatus.suppressed;
      }
      else {
        status = this.processStatus == 'pending' ? CaptureEventReviewStatus.incomplete : CaptureEventReviewStatus.noHazards;
      }
    }

    return status;
  }

  getAssessmentStatus(): CaptureEventAssessmentStatus {
    for (const view of this.views) {
      if (view.assessmentReviews.length > 0) {
        const review = view.assessmentReviews[0];
        return review.status == 'processed' ? CaptureEventAssessmentStatus.processed : CaptureEventAssessmentStatus.incomplete;
      }
    }
  }

  sortValue(): number {
    return +this.capturedAt;
  }

  getReviewDetails(): string[] {
    const details: any[] = [];

    if (this.views) {
      _.forEach(this.views, view => {
        _.forEach(view.reviews, review => {
          details.push(_.map(review.hazards, hazard => _.startCase(hazard)));
        });
        _.forEach(view.assessmentReviews, assessmentReview => {
          details.push(`${assessmentReview.assessment?.description} - ${_.startCase(assessmentReview.assessmentResult)}`);
        });
      });
    }

    return _.uniq(_.flatten(details));
  }
}

export enum CaptureEventReviewStatus {
  incomplete = 'incomplete',
  noHazards = 'noHazards',
  hazardOnly = 'hazardOnly',
  skipped = 'skipped',
  urgent = 'urgent',
  suppressed = 'suppressed'
}

export enum CaptureEventAssessmentStatus {
  incomplete = 'pending assessments',
  processed = 'processed assessments'
}
