import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Facing } from '../../models/facing';

export class FacingObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 2;

  strokeWidth = FacingObject.DEFAULT_STROKE_WIDTH;

  defaultOptions: any = {
    selectable: false,
    evented: false,
    lockScalingFlip: true,
    borderColor: 'white',
    borderScaleFactor: 4.0,
    padding: 8,
    fill: 'transparent',
    stroke: 'orange',
    opacity: 1.0,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    hoverCursor: 'pointer',
    shadow: {
      blur: 5,
      color: 'rgba(0, 0, 0, 0.8)',
      offsetX: 1,
      offsetY: 1
    }
  };

  rect: fabric.Rect;

  constructor(public facing: Facing, private points: Point[], config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = FacingObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
  }

  protected build(): fabric.Object {
    this.rect = new fabric.Rect(this.getOptions());
    this.rect.data = this.facing;
    return this.rect;
  }

  protected getInstanceOptions(): any {
    return {
      width: Math.abs(this.points[0].x - this.points[1].x) - this.strokeWidth,
      height: Math.abs(this.points[0].y - this.points[2].y) - this.strokeWidth,
      left: this.points[0].x,
      top: this.points[2].y
    };
  }
}
