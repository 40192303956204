import { CdnFile } from '@ng-cloud/badger-core';

export class LidarHeatmapData {
  cdnFiles: CdnFile[];
  message: string;
  messageId: string;
  imageUrl: string;

  deserialize(json: any): this {
    this.cdnFiles = json.cdn_files;
    this.message = json.message;
    this.messageId = json.message_id;

    if (this.cdnFiles && this.cdnFiles.length) {
      this.imageUrl = this.cdnFiles[0].url;
    }

    return this;
  }
}
