import { catchError, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { ApiService } from './api.service';
import { Review } from '../models/review';
import { CaptureEventAssessmentReview } from '../models/capture-event-assessment-review';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends ApiService {
  apiName = 'inspect';

  //TODO: this is a duplicate, so we need it?
  getStats(params: any = {}): Observable<any> {
    return this.http.get(this.url('/capture_event_view_reviews/stats_by_org'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => this.processStatsResults(response)));
  }

  getStatsByOrg(params?: any): Observable<any> {
    return this.http.get(this.url('/capture_event_view_reviews/stats_by_org'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => this.processStatsResults(response)));
  }

  getAssessmentStats(params?: any): Observable<any> {
    return this.http.get(this.url('/capture_event_assessment_reviews/stats_by_org'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => this.processAssessmentStatsResults(response)));
  }

  getAssessmentStatsByOrg(params?: any): Observable<any> {
    return this.http.get(this.url('/capture_event_assessment_reviews/stats_by_org'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => this.processAssessmentStatsResults(response)));
  }

  stats(params?: any): Observable<any> {
    return this.cable().channel('ModeratorQueueSizeChannel', params)
      .received().pipe(map(response => this.processStatsResults(JSON.parse(response))));
  }

  assessmentStats(params?: any): Observable<any> {
    return this.cable().channel('AssessmentQueueSizeChannel', params)
      .received().pipe(map(response => this.processAssessmentStatsResults(JSON.parse(response))));
  }

  stats_age(params?: any): Observable<any> {
    return this.cable().channel('ModerationQueueAgeChannel', params)
      .received().pipe(map(response => this.processStatsAgeResults(response)));
  }

  getReviews(params = {}): Observable<Review[]> {
    return this.list(Review, `/capture_event_view_reviews`, params);
  }

  getReview(reviewId): Observable<Review> {
    return this.get(Review, `/capture_event_view_reviews/${reviewId}`);
  }

  getNextModeratorReview(): Observable<Review> {
    return this.get(Review, `/capture_event_view_reviews/next`, 0);
  }

  updateReview(review: Review): Observable<Review> {
    return this.update(review, `/capture_event_view_reviews/${review.id}`);
  }

  getNextTask(params?: any): Observable<Review> {
    return this.patchGet(Review, `/capture_event_view_reviews/next_task`, params);
  }

  getNextPendingUrgentReview(): Observable<Review> {
    return this.get(Review, `/capture_event_view_reviews/next_urgent`, 0);
  }

  updateSupervisorUrgent(review: Review, is_urgent: boolean, hazardImageSelection): Observable<Review> {
    const status = is_urgent ? 'approved' : 'declined';
    return this.http.post(this.url(`/capture_event_view_reviews/${review.id}/validate_urgent`),
      { manager_status: status, hazard_image_selection: hazardImageSelection }).pipe(
      filter(response => response != null),
      map(response => new Review().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  getAssessmentReviews(params = {}): Observable<CaptureEventAssessmentReview[]> {
    return this.list(CaptureEventAssessmentReview, `/capture_event_assessment_reviews`, params);
  }

  getAssessmentReview(reviewId): Observable<CaptureEventAssessmentReview> {
    return this.get(CaptureEventAssessmentReview, `/capture_event_assessment_reviews/${reviewId}`);
  }

  getNextAssessmentTask(params?: any): Observable<CaptureEventAssessmentReview> {
    return this.patchGet(CaptureEventAssessmentReview, `/capture_event_assessment_reviews/next_task`, params);
  }

  updateAssessmentReview(review: CaptureEventAssessmentReview): Observable<CaptureEventAssessmentReview> {
    return this.update(review, `/capture_event_assessment_reviews/${review.id}`);
  }

  private processStatsResults(response) {
    return {
      pendingModeratorNotDispatchedCount: _.get(response, ['stat', 'moderator_pending', 'not_dispatched'], 0),
      pendingSupervisorUnassignedCount: _.get(response, ['stat', 'supervisor_pending', 'not_assigned'], 0),
      ageOldestNotDispatched: _.get(response, ['stat', 'queue_age', 'age_oldest_not_dispatched'], 0),
      ageOldestNotAssigned: _.get(response, ['stat', 'queue_age', 'age_oldest_not_assigned'], 0)
    };
  }

  private processAssessmentStatsResults(response) {
    return {
      pendingNotDispatchedCount: _.get(response, ['stat', 'reviews_pending', 'not_dispatched'], 0)
    };
  }

  private processStatsAgeResults(response) {
    return {
      inspectQueueAge: _.get(response, ['inspect'], 0),
      insightQueueAge: _.get(response, ['insight'], 0)
    };
  }
}
