import { tap } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFormComponent } from '../user-form/user-form.component';
import { User } from '@ng-cloud/badger-core/models/user';
import { UserService } from '@ng-cloud/badger-core/services/user.service';
import { OrganizationService } from '@ng-cloud/badger-core/services/organization.service';
import { DialogService } from '@ng-cloud/badger-core/services/dialog.service';
import { MessageService } from '@ng-cloud/badger-core/services/message.service';
import { AuthorizationService } from '@ng-cloud/badger-core/services/authorization.service';
import { TitleService } from '@ng-cloud/badger-core/services/title.service';
import * as _ from 'lodash';

@Component({
  selector: 'bt-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailComponent implements OnInit {
  id: number;
  user: User;
  currentUserId: number;
  roleOptions: string[];
  hasInsightAnalyticsAccess = false;
  portal;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private userService: UserService,
    private organizationService: OrganizationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private authService: AuthorizationService,
    private titleService: TitleService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.authService.validateToken().subscribe(() => {
        this.authService.getCurrentUser().subscribe(currentUserData => {
          if (currentUserData.roles.includes('admin') || currentUserData.roles.includes('organization_admin')) {
            this.userService.getRoles().subscribe(roles => this.roleOptions = roles);
          }
          if (currentUserData.roles.includes('insight_analytics')) {
            this.hasInsightAnalyticsAccess = true;
          }
          this.currentUserId = currentUserData.id;
          this.id = +params.get('id') || this.currentUserId;
          this.userService.getUser(this.id).pipe(
            tap(user => {
              this.titleService.setTitle(user.uid);
              if (user.organizationId) {
                this.organizationService.addOrganization(user).subscribe();
              }
            }))
            .subscribe({ next: currentUser => this.user = currentUser, error: () => this.router.navigate(['/']) });
        });
      });
    });

    this.route.data.subscribe(data => {
      if (data && data.portal) {
        this.portal = data.portal;
      }
    });
  }

  openEdit(): void {
    const dialogRef = this.dialog.open(UserFormComponent, {
      data: { user: this.user, roleOptions: this.roleOptions }
    });
    dialogRef.afterClosed().subscribe(result => this.user = result || this.user);
  }

  getRoles() {
    return this.user.roles.length === 0 ? 'N/A' : _.join(this.user.roles, ', ');
  }

  onConfigDashboard() {
    this.userService.triggerConfigureDashboard();
  }
}

