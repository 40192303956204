import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtCommonModule } from '../common/bt-common.module';
import { BtMapModule } from '../map/bt-map.module';
import { StoreMapComponent } from './store-map/store-map.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule,
    BtMapModule
  ],
  declarations: [
    StoreMapComponent
  ],
  exports: [
    StoreMapComponent
  ]
})
export class BtStoreModule {
}
