<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>{{title | startCase}}</h2>
  <button mat-icon-button [matDialogClose]="">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <bt-issue-map [scanEventIssue]="scanEventIssue" [storeMap]="storeMap" [size]="size"></bt-issue-map>
</mat-dialog-content>
