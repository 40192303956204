import { AfterViewInit, Component, HostListener, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements AfterViewInit {
  @ViewChild('okayBtn') okayButton: MatButton;
  text: string;
  confirmOnEnter = false;
  result = false; // Enter key can set result to true so keydown event will close dialog with approval

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.text = data.text;
    this.confirmOnEnter = data.confirmOnEnter;
  }

  ngAfterViewInit(): void {
    if (this.confirmOnEnter) {
      this.okayButton.focus();
    }
  }

  @HostListener('window:keydown.enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.confirmOnEnter) {
        this.okay();
      }
      else {
        this.dialogRef.close(false);
      }

    }
  }

  okay() {
    this.result = true;
    this.dialogRef.close(true);
  }
}
