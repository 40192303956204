import { fabric } from 'fabric';
import { Pose } from '../../models/pose';
import { MapObject } from './map-object';

export class RobotOrientationObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    fill: 'black',
    stroke: '#EEE',
    strokeWidth: 0.5,
    originX: 'center',
    originY: 'center',
    angle: 90
  };

  pose: Pose;

  constructor(pose: Pose, config?: any) {
    super(config);
    this.pose = pose;
  }

  protected getInstanceOptions(): any {
    return {
      top: this.pose.y,
      left: this.pose.x + 10.5,
      height: 4,
      width: 8
    };
  }

  protected build(): fabric.Object {
    return new fabric.Triangle(this.getOptions());
  }
}
