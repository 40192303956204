import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Tag } from '@ng-cloud/badger-core/models/tag';
import { Point } from '@ng-cloud/badger-core';

export class TagObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 1;

  defaultOptions: any = {
    selectable: false,
    cornerColor: 'white',
    cornerSize: 14,
    cornerStrokeColor: '#607d8b',
    cornerStrokeWidth: 5,
    lockRotation: true,
    lockScalingFlip: true,
    stroke: 'blue',
    strokeUniform: true,
    transparentCorners: false,
    evented: true,
    fill: 'transparent',
    opacity: 0.95,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    hoverCursor: 'pointer'
  };

  tagGroup: fabric.Group;
  tagRect: fabric.Rect;

  hashedColor: string;

  strokeWidth = TagObject.DEFAULT_STROKE_WIDTH;

  constructor(public tag: Tag, private points: Point[], config?: any) {
    super(config);

    this.hashedColor = TagObject.getUniqueColor(this.tag.sku.toString());

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = TagObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    const objects: fabric.Object[] = [];

    this.tagRect = new fabric.Rect(Object.assign(options, this.getTagRectOptions()));
    objects.push(this.tagRect);

    Object.assign(options, { visible: this.tag.disabled });
    objects.push(new fabric.Polyline(
      [
        { x: this.points[0].x, y: this.points[0].y },
        { x: this.points[2].x - this.strokeWidth, y: this.points[2].y + this.strokeWidth }
      ],
      options));

    objects.push(new fabric.Polyline(
      [
        { x: this.points[1].x, y: this.points[1].y },
        { x: this.points[3].x + this.strokeWidth, y: this.points[3].y + this.strokeWidth }
      ],
      options));

    this.tagGroup = new fabric.Group(objects, options);
    this.tagGroup.data = this.tag;
    this.tagGroup.setControlVisible('mtr', false);

    return this.tagGroup;
  }

  protected getTagRectOptions(): any {
    return {
      width: Math.abs(this.points[0].x - this.points[1].x) - this.strokeWidth,
      height: Math.abs(this.points[0].y - this.points[2].y) - this.strokeWidth,
      left: this.points[0].x,
      top: this.points[2].y
    };
  }

  getUniqueColor() {
    return this.hashedColor;
  }

  getTypeColor() {
    if (this.tag.userCreated) {
      return 'orange';
    }
    return this.tag.sku === '' ? 'red' : 'yellow';
  }

  static getUniqueColor(uniquer: string): string {
    let hash = 0;
    for (let i = 0; i < uniquer.length; i++) {
      hash = uniquer.charCodeAt(i) + ((hash << 5) - hash);
    }

    let colors = [hash % 256, (hash / 256) % 256, (hash / 256 / 256) % 256];
    const adj = 256 - Math.max(...colors);
    colors = colors.map(color => color + adj);

    return 'rgb(' + colors[0] + ',' + colors[1] + ',' + colors[2] + ')';
  }
}
