import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { BorderOrientation, BorderType, RealogramBorder } from '@ng-cloud/badger-core/models/realogram-border';

export class RealogramBorderObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 2;
  static DEFAULT_BACKGROUND_WIDTH = 80;

  defaultOptions: any = {
    selectable: true,
    evented: true,
    lockScalingFlip: true,
    opacity: 0.8,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    padding: 0,
    borderScaleFactor: 2.0
  };

  borderOptions: any = {
    originX: 'center',
    originY: 'center'
  };

  borderLineOptions: any = {
    opacity: 1.0,
    originX: 'center',
    originY: 'center'
  };

  borderGroup: fabric.Group;

  strokeWidth = RealogramBorderObject.DEFAULT_STROKE_WIDTH;
  backgroundWidth = RealogramBorderObject.DEFAULT_BACKGROUND_WIDTH;

  constructor(public border: RealogramBorder, private points: Point[], config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = RealogramBorderObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    this.backgroundWidth = RealogramBorderObject.DEFAULT_BACKGROUND_WIDTH * scaleFactor;
    Object.assign(this.borderOptions, { strokeWidth: this.backgroundWidth });
  }

  protected build(): fabric.Object {
    const options = this.getOptions();
    const objects: fabric.Object[] = [];

    Object.assign(options, {
      lockMovementX: this.border.orientation === BorderOrientation.HORIZONTAL,
      lockMovementY: this.border.orientation === BorderOrientation.VERTICAL
    });

    Object.assign(this.borderLineOptions, {
      stroke: this.getStroke(this.border.type),
      strokeWidth: this.border.type === BorderType.PANORAMA ? this.strokeWidth * 2 : this.strokeWidth,
      strokeDashArray: this.border.type === BorderType.SECTION ? [20, 10] : [0, 0]
    });

    const border = new fabric.Line([this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y], this.borderOptions);
    objects.push(border);

    const borderLine = new fabric.Line([this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y], this.borderLineOptions);
    objects.push(borderLine);

    // Group
    this.borderGroup = new fabric.Group(objects, options);
    this.borderGroup.data = this.border;

    return this.borderGroup;
  }

  setBorderType(borderType: BorderType) {
    this.border.type = borderType;

    this.borderGroup.getObjects().forEach(element => {
      element.set({
        strokeDashArray: borderType === BorderType.SECTION ? [20, 10] : ''
      });
    });
  }

  getStroke(type: BorderType) {
    let color;
    switch (type) {
      case BorderType.PANORAMA: {
        color = 'magenta';
        break;
      }
      case BorderType.SECTION: {
        color = 'cyan';
        break;
      }
      case BorderType.SEGMENT: {
        color = 'cyan';
        break;
      }
      case BorderType.SEGMENT_WARN: {
        color = 'orange';
        break;
      }
    }
    return color;
  }
}
