import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KeycloakGuard } from '@badgertechnologies/liblogin.js';
import { organizationGuardFn, roleGuardFn } from '@ng-cloud/badger-core/common/guards/functional-guards';
import { ThemeBuddyComponent } from './theme-buddy/theme-buddy/theme-buddy.component';

const routes: Routes = [
  {
    path: 'queued-base-scans',
    loadChildren: () => import('./base-scan-queue/base-scan-queue.module').then(m => m.BaseScanQueueModule),
    data: { roles: ['admin', 'realogram_editor'] },
    canMatch: [roleGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'stores',
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule),
    data: { roles: ['user', 'client_portal', 'auditor', 'insight_auditor', 'external_insight_auditor', 'realogram_editor'] },
    canMatch: [roleGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'themes',
    component: ThemeBuddyComponent,
    data: { roles: ['admin'] },
    canMatch: [roleGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    data: { roles: ['user', 'client_portal'] },
    canMatch: [roleGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics-fmcg/analytics-fmcg.module').then(m => m.AnalyticsFmcgModule),
    data: { roles: ['insight_analytics'], orgType: 'fmcg' },
    canMatch: [roleGuardFn, organizationGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'analytics-retail',
    loadChildren: () => import('./analytics-retail/analytics-retail.module').then(m => m.AnalyticsRetailModule),
    data: { roles: ['insight_analytics'], orgType: 'retail' },
    canMatch: [roleGuardFn, organizationGuardFn],
    canActivate: [KeycloakGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [KeycloakGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: 'stores' },
  { path: 'restricted', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})]
})
export class AppRoutingModule {
}
