import { AbstractApiModel } from './abstract-api-model';
import { CaptureEventViewReview } from './capture-event-view-review';
import { CaptureEventAssessmentReview } from './capture-event-assessment-review';

export class CaptureEventView extends AbstractApiModel<CaptureEventView> {
  viewType: string;
  processedAt: Date;

  reviews: CaptureEventViewReview[];
  assessmentReviews: CaptureEventAssessmentReview[];
  images: any[];

  deserialize(json: any): this {
    this.viewType = json.view_type;
    this.processedAt = json.processed_at;

    this.reviews = json.reviews ? json.reviews.map(review => new CaptureEventViewReview().deserialize(review)) : [];

    this.assessmentReviews = json.assessment_reviews ? json.assessment_reviews.map(review => new CaptureEventAssessmentReview().deserialize(review)) : [];

    return super.deserialize(json);
  }
}
