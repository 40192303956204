import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'bt-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SlideToggleComponent),
    multi: true
  }]
})
export class SlideToggleComponent implements ControlValueAccessor {
  @Input()
  get checked(): boolean {
    return this.value;
  }

  set checked(value_: boolean) {
    this.value = value_;
  }

  @Input() disabled = false;

  @Input() sticked = false;
  @Input() caption = '';

  value = true;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  onClick() {
    this.value = !this.value;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: any): void {
    if (obj != null) {
      this.value = obj;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
