import { MapGrouping } from './map-grouping';
import { CaptureEventObject } from '../objects/capture-event-object';
import { CaptureEvent, CaptureEventAssessmentStatus, CaptureEventReviewStatus } from '../../models/capture-event';
import { GroupEventType, MapGroupingEvent } from './map-grouping-event';
import { MapGroupEntry } from './map-group-entry';
import { Layers, MapLayer } from './map-layer';

@Layers([
  { name: CaptureEventAssessmentStatus.incomplete, zIndex: 46 },
  { name: CaptureEventAssessmentStatus.processed, zIndex: 47 },
  { name: CaptureEventReviewStatus.incomplete, zIndex: 41 },
  { name: CaptureEventReviewStatus.noHazards, zIndex: 42 },
  { name: CaptureEventReviewStatus.hazardOnly, zIndex: 43 },
  { name: CaptureEventReviewStatus.skipped, zIndex: 44 },
  { name: CaptureEventReviewStatus.suppressed, zIndex: 45, standard: false },
  { name: CaptureEventReviewStatus.urgent, zIndex: 48 },
  { name: 'captureEvents', zIndex: 38, standard: false }
])
export class CaptureEventGrouping extends MapGrouping<CaptureEvent, CaptureEventObject> {

  constructor(options = {}) {
    super(options);
    CaptureEventObject.onLoad(() => this.onCaptureEventLoad());
  }

  createObject(data: CaptureEvent): CaptureEventObject {
    return new CaptureEventObject(data, this.storeMap.poseToPixels(data.pose), this.options);
  }

  protected layerIncludesEntry(layer: MapLayer, entry: MapGroupEntry<CaptureEvent, CaptureEventObject>): boolean {
    if (entry.data.assessmentStatus === undefined) {
      return ['captureEvents', entry.data.status].includes(layer.name);
    }
    else {
      return ['captureEvents', entry.data.assessmentStatus].includes(layer.name);
    }
  }

  // Most of the time there will not be any objects before this load is triggered, but just in case we need to recreate if there are
  private onCaptureEventLoad() {
    const newEntryList = [];
    for (const entry of this.entries) {
      this.eventSource.next(new MapGroupingEvent(GroupEventType.removed, entry));
      const newEntry = { data: entry.object.captureEvent, object: this.createObject(entry.object.captureEvent) };
      newEntryList.push(newEntry);
      this.eventSource.next(new MapGroupingEvent(GroupEventType.added, newEntry));
    }
    this.entries = newEntryList;
  }
}
