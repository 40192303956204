<div #commentList class="comment-list" fxLayout="column">
  <mat-table #table class="comment-table" [dataSource]="dataTable" matSort matSortActive="date" matSortDirection="desc" matSortDisableClear>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
      <mat-cell fxLayout="row" *matCellDef="let comment">
        <div fxLayout="column" fxLayoutGap="5px" class="comment-info">
          <span class="comment-date">{{comment.updatedAt | date:'short'}}</span>
          <span class="comment-user">{{comment.user?.email}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef> Comment</mat-header-cell>
      <mat-cell fxLayout="row" *matCellDef="let comment">
        <div class="comment-body">
          {{comment.body}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-icon-button fxLayoutAlign="center end" (click)="openCreate()" matTooltip="Add Comment" [matTooltipShowDelay]="600">
            <mat-icon>add_comment</mat-icon>
          </button>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let comment">
        <div fxLayout="row">
          <button (click)="openEdit(comment)" matTooltip="Edit Comment" [matTooltipShowDelay]="600" *hasAccess="['admin', 'skybox_level_1']"
                  mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="deleteComment(comment)" matTooltip="Delete Comment" [matTooltipShowDelay]="600"
                  *hasAccess="['admin', 'skybox_level_1']" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataTable?.displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataTable?.displayedColumns;"></mat-row>
  </mat-table>
</div>

