import { AbstractApiModel } from './abstract-api-model';
import { BarcodeDigest } from '@ng-cloud/badger-core/models/barcode-digest';

export class Organization extends AbstractApiModel<Organization> {
  name: string;
  hazardApiUrl: string;
  warningConeEnabled = false;
  smsAlertsEnabled = false;
  faceBlurThreshold: number;
  blurMode: string;
  orgType: string;
  guid: string;
  isolateModerationQueue = false;
  nsInspectEnabled = false;
  cleanConfidenceLevel: number;
  secondPaDelay: number;
  barcodeDigest: BarcodeDigest = new BarcodeDigest();
  saltConfiguration: any;

  deserialize(json: any): this {
    this.name = json.name;
    this.hazardApiUrl = json.hazard_api_url;
    this.warningConeEnabled = json.warning_cone_enabled;
    this.smsAlertsEnabled = json.sms_alerts_enabled;
    this.faceBlurThreshold = json.face_blur_threshold;
    if (json.barcode_digest) {
      this.barcodeDigest = new BarcodeDigest().deserialize(json.barcode_digest);
    }
    this.blurMode = json.blur_mode;
    this.orgType = json.org_type;
    this.guid = json.guid;
    this.isolateModerationQueue = json.isolate_moderation_queue;
    this.nsInspectEnabled = json.ns_inspect_enabled;
    this.cleanConfidenceLevel = json.clean_confidence_level;
    this.secondPaDelay = json.second_pa_delay;
    this.saltConfiguration = json.salt_config;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      hazard_api_url: this.hazardApiUrl,
      warning_cone_enabled: this.warningConeEnabled,
      sms_alerts_enabled: this.smsAlertsEnabled,
      face_blur_threshold: this.faceBlurThreshold,
      blur_mode: this.blurMode,
      org_type: this.orgType,
      isolate_moderation_queue: this.isolateModerationQueue,
      ns_inspect_enabled: this.nsInspectEnabled,
      clean_confidence_level: this.cleanConfidenceLevel,
      second_pa_delay: this.secondPaDelay,
      barcode_digest: this.barcodeDigest.serialize(),
      salt_config: this.saltConfiguration
    });
  }
}
