import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
  static appTitle = '';
  private baseTitle: string;
  private fullTitle: string;

  constructor(private title: Title) {
  }

  setTitle(title: string) {
    this.set(title);
    this.baseTitle = title;
  }

  appendTitle(title: string) {
    this.set(`${this.baseTitle} ${title}`);
  }

  resetTitle() {
    this.set(this.baseTitle);
  }

  getBaseTitle() {
    return this.baseTitle;
  }

  private set(title: string) {
    const newTitle = `${title} - ${TitleService.appTitle}`;
    if (newTitle !== this.fullTitle) {
      this.title.setTitle(newTitle);
      this.fullTitle = newTitle;
    }
  }
}
