<div #commentableList class="commentable-list" fxLayout="column">
  <form #filters class="filter-form" [formGroup]="commentableTable.filter" fxLayout="row">
    <mat-form-field class="filter-text-field" color="accent" appearance="outline" subscriptSizing="dynamic">
      <mat-label>Search</mat-label>
      <input #filterTextField matInput formControlName="filter">
      <button matSuffix mat-icon-button *ngIf="textFilter.value" (click)="clearInputTextField()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon *ngIf="!textFilter.value" matSuffix>search</mat-icon>
    </mat-form-field>
  </form>

  <mat-table #table [dataSource]="commentableTable" class="commentable-table" matSort
             matSortActive="name"
             matSortDirection="asc"
             matSortDisableClear>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{commentableType}}</mat-header-cell>
      <mat-cell *matCellDef="let commentable">
        <div>{{commentable.getDisplayName()}}</div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></mat-header-row>
    <mat-row #matRow *matRowDef="let commentable; columns: getDisplayedColumns()"
             (click)="selectCommentable(commentable)"
             [attr.commentableId]="commentable.id"
             [ngClass]="commentableSelection.isSelected(commentable) ? 'selected' : ''">
    </mat-row>
  </mat-table>
</div>

