import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapComponent } from '../map.component';
import { MatButtonToggle } from '@angular/material/button-toggle';
import * as Hammer from 'hammerjs';
import { Point } from '../../models/interfaces/point';

@Component({
  selector: 'bt-map-zoom-controls',
  templateUrl: './map-zoom-controls.component.html',
  styleUrls: ['./map-zoom-controls.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapZoomControlsComponent implements OnInit, OnDestroy {
  @Input() map: MapComponent;
  @ViewChild('autoControl', { static: true }) autoControl: MatButtonToggle;
  protected hammerManager: Hammer;

  private zoomLevels: number[] = [
    0.15, 0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5
  ];

  ngOnInit() {
    if (this.map) {
      this.map.getCanvasContainer().addEventListener('mousewheel', (e: WheelEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (Math.abs(e.deltaY) > 30) {
          this.zoom(e.deltaY < 0, { x: e.offsetX, y: e.offsetY });
        }
      });

      this.hammerManager = new Hammer.Manager(this.map.getCanvasContainer());
      this.hammerManager.add(new Hammer.Pinch());
      let lastScale = 1;
      let pinchCenter;
      this.hammerManager.on('pinch', (e) => {
        pinchCenter = pinchCenter || e.center;
        if (Math.abs(e.scale - lastScale) > .1) {
          this.zoom(e.scale > lastScale, pinchCenter);
          lastScale = e.scale;
        }
      });
      this.hammerManager.on('pinchend', () => {
        pinchCenter = null;
        lastScale = 1;
      });
    }
  }

  ngOnDestroy() {
    this.hammerManager && this.hammerManager.destroy();
  }

  toggleAutoZoom() {
    const zoom = this.autoControl.checked ? 'auto' : 1;
    this.map.setZoom(zoom);
  }

  zoom(zoomIn: boolean, point: Point = null) {
    const currentZoom = this.map.getCanvas().getZoom();
    const levels = zoomIn ? this.zoomLevels : this.zoomLevels.slice().reverse();

    for (const level of levels) {
      if (zoomIn ? level > currentZoom : level < currentZoom) {
        this.map.setZoom(level, point);
        return;
      }
    }
  }
}
