import { MapGrouping } from './map-grouping';
import { Layers } from './map-layer';
import { ZoneObject } from '@ng-cloud/badger-core/map/objects/zone-object';
import { NavZone } from '@ng-cloud/badger-core/models/nav-zone';
import * as _ from 'lodash';

@Layers([
  { name: 'navZones', zIndex: 6 }
])
export class NavZoneGrouping extends MapGrouping<NavZone, ZoneObject> {
  private readonly DEFAULT_FONT_SIZE = 14;

  readonly modeColors = {
    [NavZone.KEEP_OUT_MODE]: '#be3232',
    [NavZone.KEEP_IN_MODE]: '#307930'
  };

  private prevStaleCheck: number[] = [];

  createObject(data: NavZone): ZoneObject {
    const points = data.points.map(pt => this.storeMap.pointToPixels(pt));
    return new ZoneObject(data, points, Object.assign(this.getColor(data), this.options));
  }

  protected getFontSize(): number {
    return Math.max(Math.ceil(this.storeMap.width / 150), this.DEFAULT_FONT_SIZE);
  }

  isStale(navZoneIDs: number[]): boolean {
    if (!navZoneIDs) {
      return false;
    }
    const sortedIds = navZoneIDs.sort();
    const stale = !_.isEqual(sortedIds, this.prevStaleCheck);
    this.prevStaleCheck = sortedIds;
    return stale;
  }

  protected getColor(navZone: NavZone) {
    return {
      polygonOptions: { fill: this.modeColors[navZone.mode] },
      labelOptions: { textBackgroundColor: this.modeColors[navZone.mode], fontSize: this.getFontSize() }
    };
  }
}
