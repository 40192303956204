import { Planogram } from '@ng-cloud/badger-core/models/planogram';
import { PanoramaAnnotations } from '@ng-cloud/badger-core/models/panorama-annotations';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';
import { PanoramaContainer } from '@ng-cloud/badger-core/models/interfaces/panorama-container';

export class Realogram extends AbstractApiModel<Realogram> implements PanoramaContainer {
  planograms: Planogram[] = [];
  panoramaAnnotations: PanoramaAnnotations;
  panoramaMetadata: PanoramaMetadata;
  panoramaImageUrl: string;
  activeStart: Date;
  activeEnd: Date;
  active: boolean;
  createdBy: number;
  baseScanId: number;
  storeId: number;
  name: string;

  deserialize(json: any): this {
    if (json.planograms) {
      json.planograms.forEach((planograms) => {
        this.planograms.push(new Planogram().deserialize(planograms));
      });
    }

    if (json.panorama_annotations) {
      this.panoramaAnnotations = new PanoramaAnnotations().deserialize(json.panorama_annotations);
    }

    this.panoramaImageUrl = json.panorama_image_url;

    if (json.panorama_metadata) {
      this.panoramaMetadata = new PanoramaMetadata().deserialize(json.panorama_metadata);
    }

    this.activeEnd = json.active_end && new Date(json.active_end);
    this.activeStart = json.active_start && new Date(json.active_start);
    this.active = json.active;
    this.createdBy = json.created_by;
    this.baseScanId = json.base_scan_id;
    this.storeId = json.store_id;
    this.name = json.name;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      planograms: this.planograms,
      panorama_annotations: this.panoramaAnnotations.serialize(),
      panorama_metadata: this.panoramaMetadata.serialize(),
      panorama_image_url: this.panoramaImageUrl,
      active_start: this.activeStart,
      active_end: this.activeEnd,
      active: this.active,
      created_by: this.createdBy,
      base_scan_id: this.baseScanId,
      store_id: this.storeId,
      name: this.name
    });
  }
}
