import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class InspectPoseAssessment extends AbstractApiModel<InspectPoseAssessment> {
  inspectAssessmentIdentifier: string;
  playSequenceId: number;
  cameraLabel: string[];

  deserialize(json: any): this {
    this.inspectAssessmentIdentifier = json.inspect_assessment_identifier;
    this.cameraLabel = json.camera_label;
    this.playSequenceId = json.play_sequence_id;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      inspect_assessment_identifier: this.inspectAssessmentIdentifier,
      camera_label: this.cameraLabel,
      play_sequence_id: this.playSequenceId
    });
  }
}
