import { Layers } from './map-layer';
import { LaserScan } from '@ng-cloud/badger-core/models/laser-scan';
import { LaserScanObject } from '@ng-cloud/badger-core/map/objects/laser-scan-object';
import { MapGrouping } from '@ng-cloud/badger-core/map/groupings/map-grouping';

@Layers([
  { name: 'laserScans', zIndex: 9 }
])
export class LaserScanGrouping extends MapGrouping<LaserScan, LaserScanObject> {

  createObject(laserScan: LaserScan): LaserScanObject {
    return new LaserScanObject(laserScan, this.storeMap, this.options);
  }
}
