import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@ng-cloud/badger-core/services/authorization.service';
import { ThemeService } from '@ng-cloud/badger-core/services/theme.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'bt-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() title: string;
  @Input() appIcon: string;
  @Input() defaultTheme = 'badger-dark';

  username = '';
  currentTheme = '';

  @ViewChild('profileMenuButton') set profileMenuButton(profileMenuButton: MatButton) {
    this._profileMenuButton = profileMenuButton;
    if (this._profileMenuButton) {
      this.resizeObservable.observe(this._profileMenuButton._elementRef.nativeElement);
    }
  }

  _profileMenuButton: MatButton;
  @ViewChild('profileMenuTrigger') profileMenuTrigger: MatMenuTrigger;

  private resizeObservable: ResizeObserver;

  constructor(public authService: AuthorizationService,
              public themeService: ThemeService,
              private router: Router,
              private elRef: ElementRef) {
    this.authService.getCurrentUser().subscribe(user => this.username = user.email);
    this.currentTheme = themeService.getCurrentTheme();
  }

  signOut(): void {
    this.authService.signOut();
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.themeService.setDefaultTheme(this.defaultTheme);
  }

  ngAfterViewInit(): void {
    this.createResizeObservable();
  }

  createResizeObservable() {
    this.resizeObservable = new ResizeObserver(entries => {
      if (entries[0].contentRect.width === 0) {
        this.profileMenuTrigger.closeMenu();
      }
    });
  }

  ngOnDestroy() {
    this.resizeObservable.disconnect();
  }

  get height(): number {
    return this.elRef.nativeElement.offsetHeight;
  }

  toggleTheme(theme: any) {
    this.themeService.setCurrentTheme(theme);
  }
}
