import { Component, ContentChild, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MapComponent } from '../map.component';
import { DeviceService } from '@ng-cloud/badger-core/services/device.service';

@Component({
  selector: 'bt-map-sidenav',
  templateUrl: './map-sidenav.component.html',
  styleUrls: ['./map-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapSidenavComponent implements OnInit {
  @ContentChild(MapComponent) mapComponent: MapComponent;
  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;
  @Input() autoResizeHeight = true;

  @HostListener('window:resize') onResize() {
    this.autoHeight();
    this.autoContentWidth();
    this.configureSideNav();
  }

  icon = 'chevron_right';
  smallScreen: boolean;
  animation = null;

  constructor(protected deviceService: DeviceService) {
  }

  ngOnInit() {
    this.autoHeight();
    this.configureSideNav();
    this.sidenav.openedChange.subscribe(() => this.autoContentWidth());
  }

  autoHeight() {
    if (this.autoResizeHeight) {
      const sidenav = <HTMLElement>document.getElementsByClassName('map-sidenav-container')[0];
      const navbar = <HTMLElement>document.getElementById('navbar');
      const height = window.innerHeight - navbar.offsetHeight;
      sidenav.style.height = `${height}px`;
    }
  }

  autoContentWidth() {
    const content = <HTMLElement>document.getElementsByClassName('map-sidenav-content')[0];
    const width = window.innerWidth - content.getBoundingClientRect().left;
    content.style.width = `${width}px`;
  }

  configureSideNav() {
    const smallScreen = this.deviceService.isSmallScreen();
    if (this.smallScreen != smallScreen) {
      if (!smallScreen) {
        this.showControls();
      }
      if (this.sidenav.opened == smallScreen) {
        this.toggle();
      }
      this.smallScreen = smallScreen;
    }
  }

  toggle() {
    this.animation = setInterval(() => this.zoomMap(), 10);
    // noinspection JSIgnoredPromiseFromCall
    this.sidenav.toggle();
    this.icon = this.sidenav.opened ? 'chevron_left' : 'chevron_right';
  }

  endAnimation() {
    clearInterval(this.animation);
  }

  showControls() {
    for (const control of <HTMLScriptElement[]>Array.from(document.querySelectorAll('.map-control'))) {
      control.style.visibility = 'visible';
    }
  }

  hideControlsIfSmallScreen() {
    if (this.smallScreen) {
      for (const control of <HTMLScriptElement[]>Array.from(document.querySelectorAll('.map-control'))) {
        control.style.visibility = 'hidden';
      }
    }
  }

  zoomMap() {
    this.autoContentWidth();
    this.mapComponent && this.mapComponent.maximize();
  }
}
