import { AbstractInsightReview } from '@ng-cloud/badger-core/models/abstract-insight-review';
import { TopStockImage } from '@ng-cloud/badger-core/models/top-stock-image';

export class TopStockImageReview extends AbstractInsightReview {
  reviewCompleted: boolean;
  topStockImageId: number;
  topStockImage: TopStockImage;

  deserialize(json: any): this {
    this.reviewCompleted = json.review_completed;
    this.topStockImageId = json.top_stock_image_id;
    this.topStockImage = new TopStockImage().deserialize(json.top_stock_image);
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      review_completed: this.reviewCompleted,
      top_stock_image: this.topStockImage.serialize()
    });
  }

  getType(): string {
    return 'top_stock';
  }
}
