import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class OrganizationSelectionService {
  private orgParams = new BehaviorSubject({});
  private insightOrgParams = new BehaviorSubject({});

  sharedOrgParams = this.orgParams.asObservable();
  sharedInsightOrgParams = this.insightOrgParams.asObservable();

  constructor() {
  }

  updateOrgParams(orgParams: any) {
    this.orgParams.next(orgParams);
  }

  updateInsightOrgParams(params: any) {
    this.insightOrgParams.next(params);
  }
}
