import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class PushSubscription extends AbstractApiModel<PushSubscription> {
  name: string;
  subscription: any;

  deserialize(json: any): this {
    this.name = json.name;
    this.subscription = json.subscription;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      subscription: this.subscription
    });
  }
}
