import { Image } from './image';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { InspectAssessment } from '@ng-cloud/badger-core/models/inspect-assessment';

export class CaptureEventAssessmentReview extends AbstractApiModel<CaptureEventAssessmentReview> {
  id: number;
  startedAt: Date;
  finishedAt: Date;
  assignedAt: Date;
  capturedAt: Date;
  comment: string;
  assessmentId: number;
  assessmentResult: string;
  assessmentVersion: number;
  status: string;
  reviewerEmail: string;
  captureEventId: number;
  storeId: number;
  robotName: string;
  zoneDescription: string;
  images: Image[] = [];

  assessment: InspectAssessment;

  deserialize(json: any): this {
    this.id = json.id;
    this.startedAt = json.started_at;
    this.finishedAt = json.finished_at;
    this.assignedAt = json.assigned_at;
    this.capturedAt = json.captured_at;
    this.captureEventId = json.capture_event?.id;
    this.storeId = json.capture_event?.store_id;
    this.zoneDescription = json.capture_event?.store_zone?.description;
    this.robotName = json.capture_event?.robot_name;
    this.comment = json.comment;
    this.status = json.status;
    this.assessmentId = json.inspect_assessment_id;
    this.assessmentResult = json.assessment_result;
    this.assessmentVersion = json.assessment_version;
    this.assessmentId = json.inspect_assessment_id;
    this.reviewerEmail = json.reviewer;

    if (json.images) {
      json.images.forEach((image) => {
        this.images.push(new Image(image));
      });
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return {
      id: this.id,
      started_at: this.startedAt,
      finished_at: this.finishedAt,
      comment: this.comment,
      assessment_result: this.assessmentResult
    };
  }

  addAssessment(assessment: InspectAssessment) {
    this.assessment = assessment;
  }
}
