import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { DialogService } from '@ng-cloud/badger-core/services/dialog.service';

@Component({
  selector: 'bt-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertMessageComponent {
  text: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<AlertMessageComponent>,
    private dialogService: DialogService
  ) {
    this.text = data.text;
  }

  showDetails() {
    this.dialogService.alert(this.data);
  }
}
