export const dev: any = {
  keycloak: {
    keycloak: 'https://www.identity.btdev.team',
    realm: 'preview',
    clientId: 'ng-cloud'
  }
};
export const prod: any = {
  keycloak: {
    keycloak: 'https://www.identity.badger-service.com',
    realm: 'fleet',
    clientId: 'ng-cloud'
  }
};
