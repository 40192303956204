import { Pose } from './pose';
import { LaserScan } from './laser-scan';
import { Costmap } from './costmap';
import * as _ from 'lodash';
import { CdnFile, HasPose } from '@ng-cloud/badger-core';

export class VisualizationData implements HasPose {
  pose: Pose;
  navGoal: Pose;
  frontLaserScan: LaserScan;
  topLaserHighScan: LaserScan;
  topFrontLaserHighScan: LaserScan;
  topFrontLaserLowScan: LaserScan;
  localCostmap: Costmap;
  poseError: string;
  navGoalError: string;
  laserScanError: string;
  localCostmapError: string;

  cdnFiles: CdnFile[];
  createdAt: Date;

  deserialize(json: any): this {
    this.pose = _.has(json, 'current_pose.pose') ? new Pose().deserialize(json.current_pose.pose) : null;
    this.navGoal = _.has(json, 'nav_goal.pose') ? new Pose().deserialize(json.nav_goal.pose) : null;
    if (json.type && json.type == 'visualization') {
      this.frontLaserScan = _.has(json, 'laser_scan.ranges') ? new LaserScan().deserialize(json.laser_scan) : null;
      this.topFrontLaserLowScan = _.has(json, 'top_front_laser_low_scan.ranges') ? new LaserScan().deserialize(json.top_front_laser_low_scan) : null;
    }
    else {
      this.frontLaserScan = _.has(json, 'front_laser_scan.ranges') ? new LaserScan().deserialize(json.front_laser_scan) : null;
      this.topLaserHighScan = _.has(json, 'top_laser_high_scan.ranges') ? new LaserScan().deserialize(json.top_laser_high_scan) : null;
      this.topFrontLaserLowScan = _.has(json, 'top_front_laser_low_scan.ranges') ? new LaserScan().deserialize(json.top_front_laser_low_scan) : null;
      this.topFrontLaserHighScan = _.has(json, 'top_front_laser_high_scan.ranges') ? new LaserScan().deserialize(json.top_front_laser_high_scan) : null;
    }
    this.localCostmap = _.has(json, 'local_costmap.data') ? new Costmap().deserialize(json.local_costmap) : null;
    this.poseError = _.get(json.current_pose, 'error');
    this.navGoalError = _.get(json.nav_goal, 'error');
    this.laserScanError = _.get(json.laser_scan, 'error');
    this.localCostmapError = _.get(json.local_costmap, 'error');
    this.cdnFiles = json.cdn_files;
    this.createdAt = json.created_at;

    return this;
  }
}
