import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PointOfSaleReport } from '@ng-cloud/badger-core/models/point-of-sale-report';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PointOfSaleReportService extends ApiService {

  /**
   * Fetch all PointOfSaleReports
   */
  getPointOfSaleReports(params?: any): Observable<PointOfSaleReport[]> {
    return this.list(PointOfSaleReport, 'point_of_sale_reports', params, 300);
  }

  /**
   * Fetch PointOfSaleReport data for the given id
   */
  getPointOfSaleReport(id: number): Observable<PointOfSaleReport> {
    return this.get(PointOfSaleReport, `point_of_sale_reports/${id}`, 600);
  }

}
