import { filter, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

/**
 * Service to broadcast and subscribed to errors
 **/
@Injectable()
export class ErrorService {
  private errorSource = new Subject<any>();
  private subscribedNames: string[] = [];

  /**
   * Broadcast a new error to the error stream.
   *
   * Any format can be broadcast, but the common error
   * handling components expect the following formats:
   *
   *  Exceptions:
   *    {"exception": "exception string"}
   *
   *  Alerts:
   *    {"error": "error string"},
   *    {"error": ['error string']},
   *    {"errors": "error string"},
   *    {"errors": ['error string']},
   *
   *  Errors:
   *    {"errors": {
   *      "property1": ["prop 1 error 1", "prop 2 error 2"],
   *      "property2": ["prop 2 error 1", "prop 2 error 2"]
   *    }
   */
  raise(error: any) {
    this.errorSource.next(error);
    console.error(error);
  }

  /**
   * Emits all errors broadcast to the stream;
   */
  all(): Observable<any> {
    return this.errorSource.asObservable();
  }

  /**
   * Emits all errors from the stream matching the exceptions format.
   */
  exceptions(): Observable<any> {
    return this.filterExceptions();
  }

  /**
   * Emits all errors from the stream matching the alerts format.
   */
  alerts(): Observable<string> {
    return this.filterErrors(false).pipe(map(obj => _.isArray(obj) ? obj[0] : obj));
  }

  /**
   * Emits all errors from the stream matching the errors format.
   */
  errors(): Observable<any> {
    return this.filterErrors();
  }

  /**
   * Emits an array of messages for all base errors and, optionally,
   * validation errors for properties that have not been subscribed to elsewhere.
   */
  messages(unsubscribedOnly = false): Observable<string[]> {
    return (unsubscribedOnly ? this.unsubscribedErrors() : this.errors()).pipe(
      map(errors => _.flatten(_.values(errors))));
  }

  /**
   * Emits an array of messages for validation errors matching the given property name.
   */
  messagesFor(name: string): Observable<string[]> {
    this.subscribedNames.push(name);
    return this.errors().pipe(
      map(obj => obj[name] || []),
      finalize(() => _.pull(this.subscribedNames, name)));
  }

  /**
   * Emits errors for the given property name in the format expected by Angular FormControls
   */
  errorsFor(name: string): Observable<any> {
    return this.messagesFor(name).pipe(
      filter(messages => messages.length > 0),
      map(messages => _.zipObject(messages, new Array(messages.length).fill(true))));
  }

  /**
   * Emits validation errors for properties that have not been subscribed to elsewhere.
   */
  unsubscribedErrors(): Observable<any> {
    return this.errors().pipe(
      map(errors => _.pickBy(errors, (v, name) => !this.subscribedNames.includes(name))));
  }

  // Helper to separate exceptions from alerts/errors
  private filterExceptions(exceptions = true) {
    return this.all().pipe(
      filter(obj => _.isPlainObject(obj) && _.has(obj, 'exception') == exceptions));
  }

  // Helper to separate errors from alerts
  private filterErrors(errors = true) {
    return this.filterExceptions(false).pipe(
      filter(obj => _.has(obj, 'errors') || _.has(obj, 'error')),
      map(obj => obj['errors'] || obj['error']),
      filter(obj => _.isPlainObject(obj) === errors));
  }
}
