import { AbstractApiModel } from './abstract-api-model';
import { Store } from './store';
import { SoftwareImage } from './software-image';
import { Heartbeat } from './heartbeat';
import { HasStore } from './interfaces/has-store';
import { HasRobotModel } from './interfaces/has-robot-model';
import { ManufacturingFacility } from './manufacturing-facility';
import { HasManufacturingFacility } from './interfaces/has-manufacturing-facility';
import { Certificate } from './certificate';
import { RobotEvent } from './robot-event';
import { RobotModel } from './robot-model';
import * as _ from 'lodash';

export class Robot extends AbstractApiModel<Robot> implements HasStore, HasManufacturingFacility, HasRobotModel {
  name: string;
  serialNumber: string;
  storeId: number;
  robotModelId: number;
  manufacturingFacilityId: number;
  status: string;
  lifecycleState: string;
  lifecycleStateReason: string;
  solution: string;
  features: string[];
  barvizRate: number;
  saltConfiguration: any;
  barBaseVersion: string;
  insightPayloadVersion: string;
  insightNetVersion: string;

  store: Store;
  robotModel: RobotModel;
  software: SoftwareImage[];
  latestHeartbeat: Heartbeat;
  manufacturingFacility: ManufacturingFacility;
  certificates: Certificate[];
  events: RobotEvent[];

  deserialize(json: any): this {
    this.name = json.name;
    this.serialNumber = json.serial_number;
    this.solution = json.solution;
    this.storeId = json.store_id;
    this.software = json.software ? json.software.map(software => new SoftwareImage().deserialize(software)) : [];
    this.latestHeartbeat = json.latest_heartbeat && new Heartbeat().deserialize(json.latest_heartbeat);
    this.robotModelId = json.robot_model_id;
    this.manufacturingFacilityId = json.manufacturing_facility_id;
    this.lifecycleState = json.lifecycle_state;
    this.features = json.features;
    this.barvizRate = json.barviz_rate;
    this.saltConfiguration = json.salt_config;
    if (json.snaps?.length > 0) {
      const selectedSnap = _.find(json.snaps, snap => {
        if (snap.name === 'bar-base') {
          return true;
        }
        else {
          return snap.name === 'truck-image';
        }
      });
      this.barBaseVersion = selectedSnap?.version;
    }
    if (json.payload_snaps?.length > 0) {
      const insightPayload = _.find(json.payload_snaps, payload => payload.name === 'insight-payload');
      this.insightPayloadVersion = insightPayload?.version;

      const insightNet = _.find(json.payload_snaps, net => net.name === 'insight-nets');
      this.insightNetVersion = insightNet?.version;
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      store_id: this.storeId,
      robot_model_id: this.robotModelId,
      barviz_rate: this.barvizRate || 0,
      salt_config: this.saltConfiguration
    });
  }

  static robotSort = (a: string[], b: string[]) => a.toString().localeCompare(b.toString(), undefined, {numeric: true});
}
