import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { FacingObject } from '@ng-cloud/badger-core/map/objects/facing-object';
import { Facing } from '@ng-cloud/badger-core/models/facing';

export class FacingGrouping extends MapGrouping<Facing, FacingObject> {

  constructor(public options: any = {}, public layerName: string = 'facings', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(facing: Facing): FacingObject {
    const points = facing.shelfLocation.map(pt => this.storeMap.pointToPixels(pt));

    return new FacingObject(facing, points, this.options);
  }
}
