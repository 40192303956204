import { AfterViewInit, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ReviewService } from '@ng-cloud/badger-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bt-age-queue-chips',
  templateUrl: './age-queue-chips.component.html',
  styleUrls: ['./age-queue-chips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgeQueueChipsComponent implements AfterViewInit, OnDestroy {
  @Input() filter = '';

  constructor(private reviewService: ReviewService) {
  }

  readonly inspectThresholdDuration: moment.Duration = moment.duration(30, 'seconds');
  readonly insightThresholdDuration: moment.Duration = moment.duration(30, 'minutes');
  ageInspect: moment.Duration = moment.duration(0);
  ageInsight: moment.Duration = moment.duration(0);

  private statSubscription: Subscription;

  ngAfterViewInit(): void {
    // Ongoing stat changes
    setTimeout(() => this.statSubscription = this.reviewService.stats_age().subscribe(response => this.updateReviewAgeStats(response)), 1000);
  }

  ngOnDestroy() {
    this.statSubscription && this.statSubscription.unsubscribe();
    this.reviewService.cable().disconnect();
  }

  updateReviewAgeStats(response: any) {
    this.ageInspect = moment.duration(response['inspectQueueAge']);
    this.ageInsight = moment.duration(response['insightQueueAge']);
  }
}
