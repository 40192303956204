import { from as observableFrom, merge as observableMerge, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Map } from '../../models/map';
import { GroupEventType, MapGroupingEvent } from './map-grouping-event';
import { MapGroupEntry } from './map-group-entry';
import { MapObject } from '../objects/map-object';
import * as _ from 'lodash';
import { MapLayer } from './map-layer';

export abstract class MapGrouping<T, K extends MapObject> {
  storeMap: Map;
  entries: MapGroupEntry<T, K>[] = [];
  protected eventSource: Subject<MapGroupingEvent<T, K>> = new Subject();
  protected dataPendingProcessing: T[] = [];
  protected layers: MapLayer[];

  constructor(public options: any = {}) {
    this.options = Object.assign({}, options);
    this.layers = _.map(this.constructor['layerDefinitions'], (layerDef: any) => new MapLayer(Object.assign({}, layerDef, { grouping: this })));
  }

  protected abstract createObject(data: any): K;

  setData(dataPoints: T[]): MapGroupEntry<T, K>[] {
    dataPoints = _.sortBy<T>(dataPoints, dp => this.getSortValue(dp));

    this.clearEntries();
    if (!this.storeMap) {
      this.dataPendingProcessing = dataPoints.slice(0);
    }
    else {
      for (const data of dataPoints) {
        const entry = this.processData(data);
        this.eventSource.next(new MapGroupingEvent(GroupEventType.added, entry));
      }
    }

    this.notifyChanged();
    return this.entries;
  }

  addData(data: T): MapGroupEntry<T, K> {
    let entry;

    if (!this.storeMap) {
      this.dataPendingProcessing.push(data);
    }
    else {
      entry = this.processData(data);
      this.eventSource.next(new MapGroupingEvent(GroupEventType.added, entry));
    }

    this.notifyChanged();
    return entry;
  }

  clearData() {
    this.clearEntries();

    this.notifyChanged();
  }

  getByData(data: T): MapGroupEntry<T, K> {
    return this.entries[this.getIndexOfData(data)];
  }

  getByObject(obj: K): MapGroupEntry<T, K> {
    return this.entries[this.getIndexOfObject(obj)];
  }

  removeByData(data: T): MapGroupEntry<T, K> {
    const entry = this.removeEntry(this.getIndexOfData(data));
    if (entry) {
      this.eventSource.next(new MapGroupingEvent(GroupEventType.removed, entry));
      this.notifyChanged();
    }
    return entry;
  }

  removeByObject(obj: K): MapGroupEntry<T, K> {
    const entry = this.removeEntry(this.getIndexOfObject(obj));
    this.eventSource.next(new MapGroupingEvent(GroupEventType.removed, entry));

    this.notifyChanged();
    return entry;
  }

  replaceData(data: T): MapGroupEntry<T, K> {
    let entry = this.removeEntry(this.getIndexOfData(data));
    this.eventSource.next(new MapGroupingEvent(GroupEventType.removed, entry));
    entry = this.processData(data);
    this.eventSource.next(new MapGroupingEvent(GroupEventType.added, entry));

    this.notifyChanged();
    return entry;
  }

  getData(): T[] {
    return this.sortEntries().map(entry => entry.data);
  }

  getObjects(): K[] {
    return this.sortEntries().map(entry => entry.object);
  }

  getEntries(): MapGroupEntry<T, K>[] {
    return this.sortEntries();
  }

  getEntryCount(): number {
    return this.entries.length;
  }

  getLayer(name: string): MapLayer {
    return _.find(this.layers, layer => layer.name === name);
  }

  getLayers(includeNonStandard = false): MapLayer[] {
    return includeNonStandard ? this.layers : this.layers.filter(layer => layer.standard);
  }

  setStoreMap(map: Map): void {
    this.storeMap = map;

    for (const data of this.dataPendingProcessing) {
      const entry = this.processData(data);
      this.eventSource.next(new MapGroupingEvent(GroupEventType.added, entry));
    }
    this.dataPendingProcessing.length = 0;

    this.notifyChanged();
  }

  events(): Observable<MapGroupingEvent<T, K>> {
    return observableMerge(observableFrom(this.entries.map(entry => new MapGroupingEvent(GroupEventType.added, entry))), this.eventSource);
  }

  eventsFor(layer: MapLayer): Observable<MapGroupingEvent<T, K>> {
    return this.events().pipe(filter(event => event.entry && this.layerIncludesEntry(layer, event.entry)));
  }

  added(): Observable<MapGroupingEvent<T, K>> {
    return this.events().pipe(filter(event => event.eventType == GroupEventType.added));
  }

  removed(): Observable<MapGroupingEvent<T, K>> {
    return this.events().pipe(filter(event => event.eventType == GroupEventType.removed));
  }

  changed(): Observable<MapGroupingEvent<T, K>> {
    return this.events().pipe(filter(event => event.eventType == GroupEventType.changed));
  }

  render(): Observable<MapGroupingEvent<T, K>> {
    return this.events().pipe(filter(event => event.eventType == GroupEventType.render));
  }

  destroy(): void {
    this.eventSource.complete();
  }

  protected processData(data: T): MapGroupEntry<T, K> {
    let entry: MapGroupEntry<T, K>;
    const obj = this.createObject(data);
    if (obj) {
      entry = new MapGroupEntry(data, obj);
      this.entries.push(entry);
    }

    return entry;
  }

  protected removeEntry(idx: number): MapGroupEntry<T, K> {
    const entry = this.entries[idx];
    if (entry) {
      this.entries.splice(idx, 1);
    }
    return entry;
  }

  protected getIndexOfData(data: T): number {
    return this.entries.findIndex(dataEntry => this.getIdentifier(dataEntry.data) === this.getIdentifier(data));
  }

  protected getIndexOfObject(obj: K): number {
    return this.entries.findIndex(data => data.object == obj);
  }

  protected clearEntries(): void {
    this.dataPendingProcessing.length = 0;
    for (const obj of this.entries.map(entry => entry.object)) {
      const entry = this.removeEntry(this.getIndexOfObject(obj));
      this.eventSource.next(new MapGroupingEvent(GroupEventType.removed, entry));
    }
  }

  protected sortEntries() {
    this.entries = _.sortBy<MapGroupEntry<T, K>>(this.entries, entry => this.getSortValue(entry.data));
    return this.entries;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected layerIncludesEntry(layer: MapLayer, entry: MapGroupEntry<T, K>): boolean {
    return true;
  }

  protected notifyChanged() {
    this.eventSource.next(new MapGroupingEvent(GroupEventType.changed));
    this.eventSource.next(new MapGroupingEvent(GroupEventType.render));
  }

  protected getIdentifier(data: any): any {
    return _.isNil(data.id) ? data : data.id;
  }

  protected getSortValue(data: any): number {
    return data.sortValue ? data.sortValue() : 0;
  }
}
