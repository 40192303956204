import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/*
 * Uses lodash to convert string to startCase (converts camelcase, snakecase, hyphencase to english)
 * Takes a string
 * Usage:
 *   value | startCase
 * Example:
 *   {{ 'camelCase' | startCase }}
 *   formats to: Camel Case
 * Example:
 *   {{ 'snake_case' | startCase }}
 *   formats to: Snake Case
 * Example:
 *   {{ 'hyphen-case' | startCase }}
 *   formats to: Hyphen Case
*/
@Pipe({ name: 'startCase' })
export class StartCasePipe implements PipeTransform {
  transform(value: string): string {
    return _.startCase(value);
  }
}
