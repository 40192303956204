import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subscription, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MultiUserRoomService extends ApiService {
  enterRoom(roomName: string): Observable<any> {
    return new Observable((observer) => {
      const roomChannel = this.cable()
        .channel('MultiUserRoomChannel', { room_name: roomName });
      let timerSubscription: Subscription = null;

      roomChannel
        .connected()
        .subscribe(() => timerSubscription = timer(0, 5_000).subscribe(
          () => roomChannel.send({ cmd: 'remain' })
        ));

      const recvSubscription = roomChannel
        .received()
        .pipe(
          catchError(e => this.handleError(e))
        )
        .subscribe(msg => observer.next(msg));

      return () => {
        if (recvSubscription) {
          recvSubscription.unsubscribe();
        }
        if (timerSubscription) {
          timerSubscription.unsubscribe();
        }
        if (roomChannel) {
          roomChannel.unsubscribe();
        }
      };
    });
  }
}
