import { Layers } from './map-layer';
import { Costmap } from '@ng-cloud/badger-core/models/costmap';
import { CostmapObject } from '@ng-cloud/badger-core/map/objects/costmap-object';
import { MapGrouping } from '@ng-cloud/badger-core/map/groupings/map-grouping';

@Layers([
  { name: 'localCostmap', zIndex: 8 }
])
export class CostmapGrouping extends MapGrouping<Costmap, CostmapObject> {

  createObject(costmap: Costmap): CostmapObject {
    return new CostmapObject(costmap, this.storeMap, this.options);
  }
}
