import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Play } from '../models/play';
import { Observable } from 'rxjs';

@Injectable()
export class PlayService extends ApiService {

  getPlays(params?: any): Observable<Play[]> {
    return this.list(Play, '/plays', params);
  }

  getPlay(id: number, versionId?: number): Observable<Play> {
    const url = versionId ? `/plays/${id}/versions/${versionId}` : `/plays/${id}`;
    return this.get(Play, url, 300);
  }

  createPlay(play: Play): Observable<Play> {
    return this.create(play, '/plays');
  }

  updatePlay(play: Play): Observable<Play> {
    return this.update(play, `/plays/${play.id}`);
  }

  getPlayWithVersions(id: number): Observable<Play> {
    return this.get(Play, `/plays/${id}/versions`);
  }

  createNewVersion(play: Play): Observable<Play> {
    return this.create(play, `/plays/${play.id}/versions`);
  }
}
