import { first } from 'rxjs/operators';
import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { CaptureEvent, CaptureEventAssessmentStatus, CaptureEventReviewStatus } from '../../models/capture-event';
import { Pose, RotationType } from '../../models/pose';
import { Subject } from 'rxjs';
import { RobotOrientationObject } from './robot-orientation-object';

export class CaptureEventObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    hoverCursor: 'pointer',
    originX: 'center',
    originY: 'center',
    perPixelTargetFind: true,
    borderScaleFactor: 3,
    borderColor: 'darkorange'
  };

  captureEvent: CaptureEvent;
  pose: Pose;
  fill: string;
  static load = new Subject<void>();
  static svgImage: fabric.Object = null;
  static svgAssessmentImage: fabric.Object = null;

  constructor(captureEvent: CaptureEvent, pose: Pose, config?: any) {
    super(config);
    this.captureEvent = captureEvent;
    this.pose = pose;
    this.fill = this.selectFill();
  }

  static onLoad(callback: () => void) {
    CaptureEventObject.load.asObservable().pipe(first()).subscribe(callback);
    CaptureEventObject.loadImages();
  }

  static loadImages() {
    fabric.loadSVGFromURL('assets/camera.svg', function(objects, options) {
      const svgGroup = fabric.util.groupSVGElements(objects, options);
      svgGroup.scaleToHeight(18);
      CaptureEventObject.svgImage = svgGroup;
      CaptureEventObject.load.next();
    });
    fabric.loadSVGFromURL('assets/assignment.svg', function(objects, options) {
      const svgGroup = fabric.util.groupSVGElements(objects, options);
      svgGroup.scaleToHeight(18);
      CaptureEventObject.svgAssessmentImage = svgGroup;
      CaptureEventObject.load.next();
    });

  }

  protected getInstanceOptions(): any {
    return {
      left: this.pose.x,
      top: this.pose.y
    };
  }

  protected build(): fabric.Object {
    const circle = this.createCircle();
    const orientationObj = this.createRobotOrientationObject();

    const orientationGroup = new fabric.Group([this.createBackground(), circle, orientationObj.getFabric()], this.getOptions());
    orientationGroup.angle = this.pose.getRotation(RotationType.Degrees);

    const captureImageObj = (this.captureEvent.assessmentStatus != null) ? this.createAssessmentImageSVGObject() : this.createCaptureImageSVGObject();

    return new fabric.Group(
      [orientationGroup, captureImageObj], this.getOptions()
    );
  }

  protected selectFill(): string {
    if (this.captureEvent.getAssessmentStatus() == null) {
      switch (this.captureEvent.status) {
        case CaptureEventReviewStatus.urgent:
          for (const view of this.captureEvent.views) {
            if (view.reviews.findIndex(review => review.isUrgent && review.managerStatus == 'approved') >= 0) {
              return 'orangered';
            }
          }
          return 'orange';
        case CaptureEventReviewStatus.suppressed:
          return 'lightsteelblue';
        case CaptureEventReviewStatus.skipped:
          return 'gray';
        case CaptureEventReviewStatus.hazardOnly:
          return 'yellow';
        case CaptureEventReviewStatus.incomplete:
          return 'royalblue';
        case CaptureEventReviewStatus.noHazards:
          return 'lightgreen';
        default:
          return 'royalblue';
      }
    }
    else {
      switch (this.captureEvent.assessmentStatus) {
        case (CaptureEventAssessmentStatus.incomplete):
          return 'royalblue';
        default:
          return 'darkgreen';
      }
    }
  }

  protected createCircle() {
    return new fabric.Circle({
      originX: 'center',
      originY: 'center',
      radius: 16,
      left: this.pose.x,
      top: this.pose.y,
      fill: this.fill,
      stroke: 'black',
      hoverCursor: 'pointer',
      perPixelTargetFind: true
    });
  }

  protected createBackground() {
    return new fabric.Rect({
      originX: 'center',
      originY: 'center',
      width: 50,
      height: 50,
      left: this.pose.x,
      top: this.pose.y,
      fill: 'transparent'
    });
  }

  protected createCaptureImageSVGObject() {
    const svgClone = fabric.util.object.clone(CaptureEventObject.svgImage);
    svgClone.set(this.getOptions());
    return svgClone;
  }

  protected createAssessmentImageSVGObject() {
    const svgClone = fabric.util.object.clone(CaptureEventObject.svgAssessmentImage);
    svgClone.set(this.getOptions());
    return svgClone;
  }

  protected createRobotOrientationObject() {
    const orientationObj = new RobotOrientationObject(this.pose, Object.assign(this.getOptions, { fill: this.fill, stroke: 'black' }));
    orientationObj.getFabric().left = this.pose.x + 20;

    return orientationObj;
  }
}
