import { Component } from '@angular/core';

@Component({
  selector: 'badger-portal-theme-buddy',
  templateUrl: './theme-buddy.component.html',
  styleUrls: ['./theme-buddy.component.scss']
})
export class ThemeBuddyComponent {

  colors = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A100',
    'A200',
    'A400',
    'A700'
  ];

  foregrounds = [
    'base',
    'divider',
    'dividers',
    'disabled',
    'disabled-button',
    'disabled-text',
    'elevation',
    'hint-text',
    'secondary-text',
    'icon',
    'icons',
    'text ',
    'slider-min',
    'slider-off',
    'slider-off-active'
  ];

  backgrounds = [
    'status-bar',
    'app-bar',
    'background',
    'hover',
    'card',
    'dialog',
    'disabled-button',
    'raised-button',
    'focused-button',
    'selected-button',
    'selected-disabled-button',
    'disabled-button-toggle',
    'unselected-chip',
    'disabled-list-option',
    'tooltip'
  ];
}
