import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Barcode } from '@ng-cloud/badger-core/models/barcode';
import * as _ from 'lodash';

export class TopStockImage extends AbstractApiModel<TopStockImage> {
  barcodes: Barcode[] = [];
  imageUrl: string;
  sequenceId: string;
  reviewed: boolean;
  dirty = false;

  deserialize(json: any): this {
    this.sequenceId = json.sequence;
    this.reviewed = json.reviewed;
    this.imageUrl = json.image_url;

    if (json.barcodes) {
      json.barcodes.forEach((barcode) => {
        this.barcodes.push(new Barcode().deserialize(barcode));
      });
    }

    this.checkReviewed();

    return super.deserialize(json);
  }

  serialize(): any {
    this.checkReviewed();

    return Object.assign(super.serialize(), {
      reviewed: this.reviewed,
      barcodes: _.map(this.barcodes, barcode => barcode.serialize())
    });
  }

  checkReviewed() {
    this.reviewed = !_.some(this.barcodes, barcode => _.isNil(barcode.rejected));
  }
}
