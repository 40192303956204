import { Injectable } from '@angular/core';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { Observable } from 'rxjs';
import { FmcgEntitlement } from '../models/fmcg-entitlement';

@Injectable({
  providedIn: 'root'
})
export class FmcgEntitlementService extends ApiService {
  /**
   *  Fetch all FMCG Entitlements for a given retail org
   */
  getFmcgEntitlements(id: number, params?: any): Observable<FmcgEntitlement[]> {
    return this.list(FmcgEntitlement, `fmcg_entitlements`, { retail_organization_id: id }, params);
  }

  /**
   * Fetch FMCG entitlement data for the given id
   */
  getFmcgEntitlement(id: number): Observable<FmcgEntitlement> {
    return this.get(FmcgEntitlement, `fmcg_entitlements/${id}`, 600);
  }

  /**
   * Create FMCG entitlement
   */
  createFmcgEntitlement(entitlement: FmcgEntitlement): Observable<FmcgEntitlement> {
    return this.create(entitlement, `fmcg_entitlements`);
  }

  /**
   * Update FMCG entitlement
   */
  updateFmcgEntitlement(entitlement: FmcgEntitlement): Observable<FmcgEntitlement> {
    return this.update(entitlement, `fmcg_entitlements/${entitlement.id}`);
  }

  /**
   * Delete FMCG entitlement
   */
  deleteFmcgEntitlement(entitlement: FmcgEntitlement): Observable<FmcgEntitlement> {
    return this.destroy(entitlement, `fmcg_entitlements/${entitlement.id}`);
  }
}
