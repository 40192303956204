import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class PanoramaMetadata extends AbstractApiModel<PanoramaMetadata> {
  dpi: number;
  key: number;
  width: number;
  bucket: string;
  height: number;
  source: string;
  inliers: number;
  matches: number;
  scanIDs: number;
  shelfLL: number[];
  shelfUR: number[];
  scanCount: number;
  realogramIDs: number;
  shelfCorners: number[];
  realogramCount: number;
  xShelfAlign: number;
  yShelfAlign: number;
  units: string;

  deserialize(json: any): this {
    this.dpi = json.dpi;
    this.key = json.key;
    this.width = json.width;
    this.bucket = json.bucket;
    this.height = json.height;
    this.source = json.source;
    this.inliers = json.inliers;
    this.matches = json.matches;
    this.scanIDs = json.scanIDs;
    this.shelfLL = json.shelf_ll;
    this.shelfUR = json.shelf_ur;
    this.scanCount = json.scanCount;
    this.realogramIDs = json.realogramIDs;
    this.shelfCorners = json.shelf_corners;
    this.realogramCount = json.realogramCount;
    this.units = json.units;
    this.xShelfAlign = this.shelfLL[0];
    this.yShelfAlign = this.shelfLL[1];

    if (this.units && this.units === 'meters') {
      this.xShelfAlign = this.metersToInches(this.xShelfAlign);
      this.yShelfAlign = this.metersToInches(this.yShelfAlign);
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      dpi: this.dpi,
      key: this.key,
      width: this.width,
      bucket: this.bucket,
      height: this.height,
      source: this.source,
      inliers: this.inliers,
      matches: this.matches,
      scanIDs: this.scanIDs,
      shelf_ll: this.shelfLL,
      shelf_ur: this.shelfUR,
      scanCount: this.scanCount,
      shelf_corners: this.shelfCorners,
      realogramCount: this.realogramCount
    });
  }

  public metersToInches(meters: number): number {
    return meters * 39.3701;
  }
}
