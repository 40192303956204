import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Zone } from '@ng-cloud/badger-core/models/interfaces/zone';
import { ApiModel, SelectedStoreZoneGrouping } from '@ng-cloud/badger-core';
import { NavZone } from '../../models/nav-zone';
import { StoreZone } from '../../models/store-zone';
import * as _ from 'lodash';

export class SelectedZoneObject extends MapObject {
  borderWidth = 6;

  defaultOptions: any = {
    opacity: 1.0,
    selectable: false,
    evented: false,
    lockScalingFlip: true,
    borderScaleFactor: 2.5,
    padding: 4,
    polygonOptions: {
      fill: '#13A1DA0D',
      stroke: '#13A1DA',
      strokeWidth: this.borderWidth,
      strokeUniform: true
    },
    labelOptions: {
      fontSize: 12,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      background: 'white',
      fill: 'white',
      shadow: new fabric.Shadow({
        color: 'black',
        blur: 1,
        offsetX: 1,
        offsetY: 1
      })
    }
  };

  private polygon: fabric.Polygon;
  private zoneGroup: fabric.Group;
  private zoneLabel: fabric.Text;
  static readonly minSize = 12.5;

  constructor(public zone: ApiModel & Zone, private points: Point[], config?: any) {
    super(config);
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    if (options.layer) {
      switch (options.layer) {
        case SelectedStoreZoneGrouping.LOWER_LAYER:
          options.polygonOptions.stroke = '#00000000';
          break;
        case SelectedStoreZoneGrouping.UPPER_LAYER:
          options.polygonOptions.fill = '#00000000';
          break;
      }
    }
    this.polygon = new fabric.Polygon(this.points, options.polygonOptions);
    this.polygon.data = this.zone;

    let description: string;

    if (this.zone instanceof NavZone) {
      description = this.zone.description;
    }
    else if (this.zone instanceof StoreZone) {
      description = this.zone.zoneTemplate ? this.zone.zoneTemplate.zoneName.description : '';
    }
    else {
      description = _.get(this.zone, 'description', '');
    }

    this.zoneLabel = new fabric.Text(description || '', options.labelOptions);
    this.zoneLabel.left = this.polygon.left + (this.polygon.width + this.borderWidth) / 2;
    this.zoneLabel.top = this.polygon.top + (this.polygon.height + this.borderWidth) / 2;
    this.zoneGroup = new fabric.Group([this.polygon, this.zoneLabel], options);

    return this.zoneGroup;
  };
}
