import { AbstractApiModel } from './abstract-api-model';

export class BarcodeDigest extends AbstractApiModel<BarcodeDigest> {
  skuLength: number;
  prefixLength: number;
  suffixLength: number;

  deserialize(json: any): this {
    this.skuLength = json.sku_length;
    this.prefixLength = json.prefix_length;
    this.suffixLength = json.suffix_length;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      sku_length: this.skuLength,
      prefix_length: this.prefixLength,
      suffix_length: this.suffixLength
    });
  }
}
