import { Fixture } from '@ng-cloud/badger-core/models/fixture';

export class Segment {
  width: number;
  offset: number;
  fixtureWidth: number;
  fixtures: Fixture[] = [];

  absoluteX: number;
  height: number;

  deserialize(json: any): this {
    this.width = json.width;
    this.offset = json.offset;
    this.fixtureWidth = json.fixtureWidth;

    if (json.fixtures) {
      json.fixtures.forEach((fixture) => {
        this.fixtures.push(new Fixture().deserialize(fixture));
      });
    }

    return this;
  }
}
