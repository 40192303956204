import { fromEvent as observableFromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Map } from '@ng-cloud/badger-core/models/map';
import { Costmap } from '@ng-cloud/badger-core/models/costmap';
import { RotationType } from '@ng-cloud/badger-core/models/pose';

export class CostmapObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    hoverCursor: 'default',
    originX: 'center',
    originY: 'center',
    strokeWidth: 0
  };

  constructor(public costmap: Costmap, public map: Map, options?: any) {
    super(options);
  }

  protected getInstanceOptions(): any {
    const costmapPose = this.map.poseToPixels(this.costmap.pose);
    return {
      left: costmapPose.x,
      top: costmapPose.y,
      angle: costmapPose.getRotation(RotationType.Degrees)
    };
  }

  protected build(): fabric.Object {
    const width = this.costmap.width;
    const height = this.costmap.height;
    const data = this.costmap.data;

    // Create a temporary canvas to draw costmap image
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempContext = tempCanvas.getContext('2d');
    const tempImageData = tempContext.createImageData(width, height);

    for (let i = 0; i < data.length; i += 1) {
      // Image needs to be flipped vertically to find the right pixel
      // One pixel occupies 4 entries in the image data array (RGBA)
      const row = 1 + Math.floor(i / width);
      const pixel = ((data.length - (row * width)) + (i % width)) * 4;
      const cost = data[i];

      tempImageData.data.set(this.getRGB(cost), pixel);
    }

    tempContext.putImageData(tempImageData, 0, 0);

    const image = document.createElement('img') as HTMLImageElement;
    const onLoad = observableFromEvent(image, 'load').pipe(take(1));
    image.src = tempCanvas.toDataURL();

    return new fabric.Image(image, Object.assign(this.getOptions(), {
      width: width,
      height: height,
      onLoad: onLoad
    }));
  }

  private getRGB(cost: number): number[] {
    if (cost > 99) {
      // Light blue - definite collision
      return [0, 153, 255, 204];
    }
    else if (cost > 50) {
      // Dark blue - possible collision
      return [0, 0, 139, 180];
    }
    else if (cost) {
      // Red to blue gradient - probably fine
      const gradient = 2 * cost / 60;
      return [139 * Math.pow(gradient, 2) / 2, 20, 210 * (1.67 - gradient), 400 * (cost / 100)];
    }

    // Transparent - free space
    return [0, 0, 0, 0];
  }
}
