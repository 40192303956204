<h2 mat-dialog-title>{{user.email ? user.email : 'Create User'}}</h2>

<form [formGroup]="formGroup" (ngSubmit)="user.id ? update() : create()" fxLayout="column">

  <mat-dialog-content>
    <div *ngIf="user" class="user-form-panel">
      <bt-form-field *ngIf="!user.id">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Email</mat-label>
          <input matInput name="email" formControlName="email" placeholder="Email" required/>
          <mat-error *ngIf="formGroup.get('email').touched && formGroup.get('email').hasError('required')">
            Email Required
          </mat-error>
          <mat-error *ngIf="formGroup.get('email').touched && formGroup.get('email').hasError('email')">
            Invalid Email Format
          </mat-error>
        </mat-form-field>
        <bt-form-errors></bt-form-errors>
      </bt-form-field>

      <bt-form-field *hasAccess="['admin']" errorName="organization">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Organization</mat-label>
          <mat-select formControlName="organizationId" panelClass="selectPopupFull">
            <mat-option *ngFor="let org of orgs" [value]="org.id">
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <bt-field-errors></bt-field-errors>
      </bt-form-field>

      <bt-form-field *hasAccess="['organization_admin']">
        <mat-form-field appearance="outline" *ngIf="roleOptions.length > 0" fxFill>
          <mat-label>Roles</mat-label>
          <mat-select formControlName="roles" panelClass="selectPopupFull" multiple>
            <mat-option *ngFor="let role of roleOptions" [value]="role">{{role | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <bt-field-errors></bt-field-errors>
      </bt-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit"
            [disabled]="formGroup.invalid">{{user.id ? "Save" : 'Create'}}</button>
  </mat-dialog-actions>
</form>
