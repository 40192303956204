import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UserService extends ApiService {

  private configureDashboard = new Subject<void>();

  private currentUserHomeStoreId: number | null = null;


  setCurrentUserHomeStoreId(storeId: number) {
    this.currentUserHomeStoreId = storeId;
  }

  getCurrentUserHomeStoreId(): number | null {
    return this.currentUserHomeStoreId;
  }

  /**
   * Fetch all Users
   */
  getUsers(params?: any): Observable<User[]> {
    return this.list(User, 'users', params);
  }

  /**
   * Fetch User data for the given id
   */
  getUser(id: number): Observable<User> {
    return this.get(User, `users/${id}`, 300);
  }

  /**
   * Create User
   */
  createUser(user: User): Observable<User> {
    return this.create(user, 'users');
  }

  /**
   * Update User
   */
  updateUser(user: User): Observable<User> {
    return this.update(user, `users/${user.id}`);
  }

  /**
   * Update User HomeStore
   */
  updateUserHomeStore(user: User) :Observable<User> {
    return this.update(user, `users/${user.id}/update_home_store`);
  }

  /**
   * Delete User
   */
  deleteUser(user: User): Observable<any> {
    return this.destroy(user, `users/${user.id}`);
  }

  getRoles(): Observable<string[]> {
    return this.http.get(this.url('users/roles')).pipe(
      filter(response => response != null)
    ) as Observable<string[]>;
  }

  getConfigureDashboard(): Observable<void> {
    return this.configureDashboard.asObservable();
  }

  triggerConfigureDashboard() {
    this.configureDashboard.next();
  }

}
