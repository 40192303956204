import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { ViewportObject } from '@ng-cloud/badger-core/map/objects/viewport-object';
import { Viewport } from '@ng-cloud/badger-core/models/viewport';

export class ViewportGrouping extends MapGrouping<Viewport, ViewportObject> {

  constructor(public options: any = {}, public layerName: string = 'viewports', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(viewport: Viewport): ViewportObject {
    const points = viewport.shelfLocation.map(pt => this.storeMap.pointToPixels(pt));

    return new ViewportObject(viewport, points, this.options);
  }
}
