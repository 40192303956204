import { AbstractApiModel } from './abstract-api-model';

export class SystemCheck extends AbstractApiModel<SystemCheck> {
  check: string;
  status: string;
  message: string;

  deserialize(json: any): this {
    this.check = json.check;
    this.status = json.status;
    this.message = json.message;

    return this;
  }
}
