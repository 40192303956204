import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@ng-cloud/badger-core/models/user';
import { AppForm } from '@ng-cloud/badger-core/common/app-form';
import { UserService } from '@ng-cloud/badger-core/services/user.service';
import { OrganizationService } from '@ng-cloud/badger-core/services/organization.service';
import { Organization } from '@ng-cloud/badger-core/models/organization';

@Component({
  selector: 'bt-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserFormComponent {
  user: User;
  form: AppForm<User>;
  formGroup: UntypedFormGroup;
  roleOptions: string[] = [];
  orgs: Organization[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserFormComponent>,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {
    // this.userService.getRoles().subscribe(roles => this.roleOptions = roles);
    this.roleOptions = data.roleOptions;
    this.organizationService.getOrganizations().subscribe(organizations => this.orgs = organizations);
    this.user = data.user;

    this.formGroup = this.createFormGroup();
    this.form = new AppForm(this.formGroup, this.user, new User(), user => this.onSuccess(user));
  }

  create(): void {
    this.form.submit(user => this.userService.createUser(user));
  }

  update(): void {
    this.form.submit(user => this.userService.updateUser(user));
  }

  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(this.user.email, Validators.email),
      organizationId: new UntypedFormControl(this.user.organizationId),
      roles: new UntypedFormControl(this.user.roles)
    });
  }

  onSuccess(user: User) {
    user.organization = this.orgs.find(org => org.id == user.organizationId);
    this.dialogRef.close(user);
  }
}
