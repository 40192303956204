import { AbstractApiModel } from './abstract-api-model';
import { SystemCheck } from '@ng-cloud/badger-core/models/system-check';

export class SystemCheckResult extends AbstractApiModel<SystemCheckResult> {
  finishedAt: Date;
  systemChecks: SystemCheck[] = [];

  deserialize(json: any): this {
    this.finishedAt = json.finished_at;
    if (json.results) {
      json.results.forEach((systemCheck) => {
        this.systemChecks.push(new SystemCheck().deserialize(systemCheck));
      });
    }
    return this;
  }
}
