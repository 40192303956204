import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'bt-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss']
})
export class BooleanComponent implements OnChanges {
  @Input() value: boolean;
  @Input() nullIsFalse: string;
  @Input() trueColor: string;
  @Input() falseColor: string;

  icon: string;
  colorClass: string;

  ngOnChanges() {
    if (this.value) {
      this.icon = 'check';
      this.colorClass = !_.isNil(this.trueColor) && 'true-color';
    }
    else if (this.value === false || !_.isNil(this.nullIsFalse)) {
      this.icon = 'clear';
      this.colorClass = !_.isNil(this.falseColor) && 'false-color';
    }
    else {
      this.icon = null;
      this.colorClass = null;
    }
  }
}
