import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasAccess]'
})
export class HasAccessDirective implements OnInit {
  private roles: string[] = [];
  private isDrawn = false;
  private denied;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthorizationService
  ) {
  }

  @Input() set hasAccess(roles: string[]) {
    this.roles = roles || [];
  }

  @Input() set hasAccessDenied(value) {
    this.denied = value;
  }

  ngOnInit() {
    this.authService.accessToAny(this.roles).subscribe(isAuthorized => this.renderTemplate(isAuthorized));
  }

  private renderTemplate(isAuthorized: boolean) {
    if (isAuthorized && !this.isDrawn) { // If authorized add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.isDrawn = true;
    }
    else if (!isAuthorized) {
      if (this.denied && !this.isDrawn) { // If there is denied template draw it instead
        this.viewContainer.createEmbeddedView(this.denied);
        this.isDrawn = true;
      }
      else if (this.isDrawn && !this.denied) { // Else if anything was drawn before clear it
        this.viewContainer.clear();
        this.isDrawn = false;
      }
    }
  }
}
