import { BehaviorSubject, merge, Observable, of as observableOf, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { debounceTime, map, takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { PagedResult } from '@ng-cloud/badger-core/services/api.service';

export interface Sortable {
  sort: MatSort;
}

export interface Pageable {
  paginator: MatPaginator;
}

export interface Filterable {
  filter: UntypedFormGroup;
}

export class DataTable<T> implements DataSource<T> {
  protected _dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _displayedColumns: string[] = [];
  private _mediaObserver: MediaObserver;
  private _mediaSubscription: Subscription;

  constructor(public columnDefs: Column[]) {
  }

  connect(): Observable<T[]> {
    this.queryDisplayedColumns();
    return this.renderData;
  }

  disconnect(): void {
    this._mediaSubscription && this._mediaSubscription.unsubscribe();
    this._dataSubject.complete();
  }

  get data(): T[] {
    return this._dataSubject.value;
  }

  set data(data: T[]) {
    this._dataSubject.next(data);
  }

  get renderData(): Observable<T[]> {
    return this._dataSubject.asObservable();
  }

  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  get mediaObserver(): MediaObserver {
    return this._mediaObserver;
  }

  set mediaObserver(media: MediaObserver) {
    this._mediaObserver = media;
    this._mediaSubscription = this.mediaObserver.asObservable().subscribe(() => this.queryDisplayedColumns());
  }

  column(name: string) {
    const column = this.columnDefs.find(col => col.name === name);
    column || console.error(`Data Table: No column defined with name "${name}"`);
    return column;
  }

  private queryDisplayedColumns(): void {
    this._displayedColumns.length = 0;
    this.columnDefs.forEach(column => {
      if (!column.mediaQuery || (this.mediaObserver && this.mediaObserver.isActive(column.mediaQuery))) {
        this._displayedColumns.push(column.name);
      }
    });
  }
}

export class ClientSideDataTable<T> extends DataTable<T> implements Sortable, Pageable, Filterable {
  private _sort: MatSort;
  private _paginator: MatPaginator;
  private _filter: UntypedFormGroup;
  private _dataSource = new MatTableDataSource<T>();

  constructor(public columnDefs: Column[]) {
    super(columnDefs);
    this._dataSource.sortData = (data: T[], sort: MatSort): T[] => this.sortData(data, sort);
    this._dataSource.filterPredicate = (data: T, filter: string) => this.filterData(data, filter);
  }

  connect(): Observable<T[]> {
    this._dataSource.connect().subscribe((data) => this._dataSubject.next(data));
    return super.connect();
  }

  disconnect(): void {
    this._dataSource.disconnect();
    super.disconnect();
  }

  get sort(): MatSort {
    return this._sort;
  }

  set sort(sort: MatSort) {
    this._sort = sort;
    this._dataSource.sort = sort;
  }

  get paginator(): MatPaginator {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
    this._dataSource.paginator = paginator;
  }

  get filter(): UntypedFormGroup {
    return this._filter;
  }

  set filter(filter: UntypedFormGroup) {
    this._filter = filter;
    this.filter.valueChanges.subscribe(() => this.applyFilter());
    this.applyFilter();
  }

  get data(): T[] {
    return this._dataSource.data;
  }

  set data(data: T[]) {
    this._dataSource.data = data.slice();
  }

  get dataSource(): MatTableDataSource<T> {
    return this._dataSource;
  }

  private sortData(data: T[], sort: MatSort): T[] {
    const active = sort.active;
    const direction = sort.direction;

    if (!active || direction == '') {
      return data;
    }

    const sortable = sort.sortables.get(sort.active);
    const ascending = sortable && sortable.start !== 'desc';

    return data.sort((a, b) => {
      const valueA = this.sortValue(a, active);
      const valueB = this.sortValue(b, active);

      const columnComparator = this.column(active).comparator;
      let comparatorResult = 0;

      if (valueA != valueB) {
        if (_.isNil(valueA)) {
          comparatorResult = ascending ? -1 : 1;
        }
        else if (_.isNil(valueB)) {
          comparatorResult = ascending ? 1 : -1;
        }
        else if (columnComparator) {
          comparatorResult = columnComparator(valueA, valueB);
        }
        else {
          comparatorResult = valueA > valueB ? 1 : -1;
        }
      }
      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  }

  private sortValue(data: T, name: string) {
    return this.column(name).sortValue(data);
  }

  private applyFilter() {
    this._dataSource.filter = JSON.stringify(this.filter.value);
  }

  private filterData(data: T, json: string) {
    const filters = JSON.parse(json);

    for (const filterName in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, filterName)) {
        let filterValue = filters[filterName];

        if (filterValue) {
          let meetsFilter = false;

          filterValue = filterValue.toLowerCase();
          if (filterName == 'filter') {
            meetsFilter = this.columnDefs.some(col => col.filterValue(data).includes(filterValue));
          }
          else {
            meetsFilter = this.column(filterName).filterValue(data) == filterValue;
          }

          if (!meetsFilter) {
            return false;
          }
        }
      }
    }

    return true;
  }
}

export class ServerSideDataTable<T> extends DataTable<T> implements Sortable, Pageable, Filterable {
  sort: MatSort;
  paginator: MatPaginator;
  filter: UntypedFormGroup;
  totalCount = 0;
  private _alive = true;

  connect() {
    // If the user changes the sort order, reset back to the first page.
    this.sort && this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    return super.connect();
  }

  disconnect() {
    this._alive = false;
    super.disconnect();
  }

  get params(): Observable<any> {
    let paramStream = observableOf({});

    [_.get(this.sort, 'sortChange'), _.get(this.paginator, 'page'), _.get(this.filter, 'valueChanges')].forEach(subStream => {
      if (subStream) {
        paramStream = merge(paramStream, subStream);
      }
    });

    return paramStream.pipe(
      takeWhile(() => this._alive),
      debounceTime(150),
      map(() => Object.assign({}, _.get(this.filter, 'value', {}), {
        page: _.get(this.paginator, 'pageIndex', 0) + 1,
        per: _.get(this.paginator, 'pageSize'),
        order: _.get(this.sort, 'active'),
        dir: _.get(this.sort, 'direction')
      }))
    );
  }

  set page(result: PagedResult<T>) {
    this.data = result.data;
    this.totalCount = result.totalCount;
  }
}

export class Column {
  property: string;
  sortBy: (data: any) => any;
  filterBy: (data: any) => any;
  comparator: (dataA, dataB) => any;
  mediaQuery: string;

  constructor(public name: string, params: any = {}) {
    this.property = params.property || name;
    this.sortBy = params.sortBy;
    this.filterBy = params.filterBy;
    this.comparator = params.comparator;
    this.mediaQuery = params.mediaQuery;
  }

  sortValue(data: any): any {
    if (this.sortBy) {
      return this.sortBy(data);
    }

    const val = _.get(data, this.property);

    if (_.isNil(val)) {
      return null;
    }

    if (['number', 'boolean'].includes(typeof val) || val.constructor === Date) {
      return val;
    }

    if (_.isArray(val)) {
      return val.length;
    }

    return val.toString().toLowerCase();
  }

  filterValue(data: any): string {
    let val: string;

    if (this.filterBy) {
      val = this.filterBy(data);
    }
    else {
      val = _.get(data, this.property);
    }

    if (_.isNil(val)) {
      return '';
    }

    if (typeof val == 'boolean') {
      val = val ? this.name : ' ';
    }

    if (_.isArray(val)) {
      val = val.length ? this.name : '';
    }

    return val.toString().toLowerCase();
  }
}
