import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AnalyticsService,
  AuthorizationService,
  BtCommonModule,
  BtMapModule,
  BtSearchModule,
  CanDeactivateGuard,
  CustomIconService,
  CustomReportsService,
  DeviceService,
  DialogService,
  ErrorService,
  InsightService,
  MessageService,
  MultiUserRoomService,
  OrganizationGuard,
  OrganizationSelectionService,
  OrganizationService,
  PlayService,
  RobotService,
  RoleGuard,
  StoreService,
  TimezoneService,
  ThemeService,
  TitleService,
  UserService
} from '@ng-cloud/badger-core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ActionCableService } from 'angular2-actioncable';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HotkeyModule } from 'angular2-hotkeys';
import { LibLoginConfig, LibLoginInterceptor, LibLoginModule } from '@badgertechnologies/liblogin.js';
import { ToastrModule } from 'ngx-toastr';
import { CsvReportToastComponent } from "./shared/report.toastr.component";
import { ThemeBuddyComponent } from './theme-buddy/theme-buddy/theme-buddy.component';


@NgModule({ exports: [
        BrowserAnimationsModule,
        BtCommonModule,
        HotkeyModule
    ],
    declarations: [
        AppComponent,
        ThemeBuddyComponent
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        BtCommonModule,
        BtMapModule,
        BtSearchModule,
        ToastrModule.forRoot({
            toastComponent: CsvReportToastComponent,
        }),
        CommonModule,
        HotkeyModule.forRoot({ cheatSheetCloseEsc: false }),
        {
            ngModule: LibLoginModule,
            providers: [
                {
                    provide: LibLoginConfig, useValue: {
                        fleet: environment.apiBase,
                        ...environment.keycloak
                    }
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: LibLoginInterceptor,
                    multi: true
                }
            ]
        }], providers: [
        ActionCableService,
        AuthorizationService,
        CanDeactivateGuard,
        CustomIconService,
        CustomReportsService,
        DeviceService,
        DialogService,
        ErrorService,
        InsightService,
        MessageService,
        MultiUserRoomService,
        OrganizationGuard,
        OrganizationService,
        OrganizationSelectionService,
        PlayService,
        RobotService,
        RoleGuard,
        StoreService,
        TimezoneService,
        ThemeService,
        TitleService,
        UserService,
        AnalyticsService,
        DatePipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
