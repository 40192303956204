import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { Region } from '../../models/region';
import { RegionObject } from '../../map/objects/region-object';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';

export class RegionGrouping extends MapGrouping<Region, RegionObject> {

  strokeWidth = RegionObject.DEFAULT_STROKE_WIDTH;
  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'regions', public zIndex: number = 1) {
    super(options);

    const scaleFactor = options.scaleFactor ? options.scaleFactor : 1;
    this.strokeWidth = RegionObject.DEFAULT_STROKE_WIDTH * scaleFactor;

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  createObject(region: Region): RegionObject {
    return new RegionObject(region, this.getRectangleForRegion(region), this.options);
  }

  updateObject(regionObject: RegionObject) {
    regionObject.rectangle = this.getRectangleForRegion(regionObject.region);
  }

  getRectangleForRegion(region: Region) {
    const xPosition = region.xPosition - this.panoramaMetadata.xShelfAlign;
    const yPosition = region.yPosition - this.panoramaMetadata.yShelfAlign + region.height;

    const point = this.storeMap.pointToPixels({ x: xPosition, y: yPosition }, 1 / this.panoramaMetadata.dpi);

    return {
      'x': point.x,
      'y': point.y,
      'width': this.storeMap.mToPx(region.width, 1 / this.panoramaMetadata.dpi) - this.strokeWidth,
      'height': this.storeMap.mToPx(region.height, 1 / this.panoramaMetadata.dpi) - this.strokeWidth
    };
  }
}
