import { Image } from './image';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class Review extends AbstractApiModel<Review> {
  id: number;
  reviewer: string;
  captured_at: Date;
  started_at: Date;
  finished_at: Date;
  urgent: boolean;
  hazards: string[];
  images: Image[] = [];
  robot_name: string;

  deserialize(json: any): this {
    this.id = json.id;
    this.reviewer = json.reviewer;
    this.captured_at = json.capture_event ? json.capture_event.captured_at : null;
    this.started_at = json.started_at;
    this.finished_at = json.finished_at;
    this.urgent = json.is_urgent;
    this.hazards = json.hazards;
    this.robot_name = json.capture_event ? json.capture_event.robot_name : null;

    if (json.images) {
      json.images.forEach((image) => {
        this.images.push(new Image(image));
      });
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return {
      id: this.id,
      started_at: this.started_at,
      finished_at: this.finished_at,
      is_urgent: this.urgent,
      hazards: this.hazards
    };
  }
}
