import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { Commentable, CommentListComponent, DialogService } from '@ng-cloud/badger-core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bt-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements AfterViewInit {
  @ViewChild('commentList') commentList: CommentListComponent;

  public commentables: Commentable[];
  protected initialSelection: Commentable;
  protected selectedCommentable: Commentable;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentsComponent>,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {
    this.commentables = data.commentables;
    this.initialSelection = data.initialSelection;
  }

  ngAfterViewInit(): void {
    if (this.selectedCommentable) {
      this.commentList.commentable = this.selectedCommentable;
    }
  }

  commentableSelected(commentable: Commentable) {
    this.selectedCommentable = commentable;
    this.commentList && (this.commentList.commentable = this.selectedCommentable);
  }
}
