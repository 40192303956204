import { prod as keycloak } from '../../../fleet-admin/src/environments/environment.keycloak';

export const environment = {
  production: true,
  apis: [
    {
      apiName: 'core',
      apiBase: 'https://fleet.badger-technologies.com',
      apiPath: 'api/web/v1'
    },
    {
      apiName: 'insight',
      apiBase: 'https://fleet.badger-technologies.com',
      apiPath: 'api/insight/v1'
    },
    {
      apiName: 'inspect',
      apiBase: 'https://fleet.badger-technologies.com',
      apiPath: 'api/web/v1'
    }
  ],
  apiBase: 'https://fleet.badger-technologies.com',
  apiPath: 'api/insight/v1',
  apiBaseAnalytics: 'https://aapi.badger-technologies.com',
  apiPathAnalytics: 'api/insight/v4',
  ...keycloak
};
