<div class="map-control zoom-controls">
  <mat-card class="icon-panel auto-panel">
    <mat-button-toggle #autoControl (change)="toggleAutoZoom()" [checked]="map?.getZoom()?.toString() === 'auto'">
      <mat-icon>fullscreen</mat-icon>
    </mat-button-toggle>
  </mat-card>
  <mat-card class="icon-panel">
    <button mat-icon-button (click)="zoom(true)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-divider></mat-divider>
    <button mat-icon-button (click)="zoom(false)">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card>
</div>
