import { AbstractApiModel } from './abstract-api-model';
import { User } from '../models/user';

export class Comment extends AbstractApiModel<Comment> {
  commentableId: number;
  commentableType: string;
  userId: number;
  body: string;
  revision: number;

  user: User;

  deserialize(json: any): this {
    this.commentableId = json.commentable_id;
    this.commentableType = json.commentable_type;
    this.userId = json.user_id;
    this.body = json.body;
    this.revision = json.revision;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      commentable_id: this.commentableId,
      commentable_type: this.commentableType,
      body: this.body
    });
  }
}
