import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api2Service } from '@ng-cloud/badger-core/services/api2.service';
import {
  CategoryData,
  ConsumerGoodsData,
  CsvReportData,
  CsvReportUpdateState,
  CsvReportUrlData,
  DashboardConfigData,
  DateRangeData,
  DatesTrendData,
  DDStore,
  DepartmentData,
  EmptyData,
  FilterData,
  ItemData,
  PosValueTrendData,
  RateByCategoryByProductData,
  RateByCategoryData,
  RetailStoreData,
  SaleStatusData
} from '@ng-cloud/badger-core/models/analytics';
import { map } from 'rxjs/operators';
import { PagedResult } from './api.service';
import { AnalyticGroup, AnalyticsType } from '@ng-cloud/badger-core/models/interfaces/analytics';

@Injectable()
export class AnalyticsService extends Api2Service {

  getFilters(params: any, orgType: string): Observable<PagedResult<FilterData>> {
    return this.page(FilterData, `${orgType}/ui_filters/presets/page`, params, 0);
  }

  getFilterDetails(params: any, orgType: string): Observable<FilterData> {
    return this.get(FilterData, `${orgType}/ui_filters/presets/details`, 0, params);
  }

  deleteFilter(id: number, orgType: string) {
    const params: FilterData = new FilterData({ fid: id });
    return this.destroy(params, `${orgType}/ui_filters/presets/config`, params);
  }

  createFilter(params, orgType: string) {
    const filterData: FilterData = new FilterData({ name: params.name, filter_preset: params.filter_preset, is_default: false });
    return this.create(filterData, `${orgType}/ui_filters/presets/config`);
  }

  updateFilter(params, orgType: string) {
    const filterData: FilterData = new FilterData({
      fid: params.id,
      name: params.name,
      is_default: params.is_default,
      filter_preset: params.filter_preset
    });
    return this.update(filterData, `${orgType}/ui_filters/presets/config`);
  }

  getItems(params: any, orgType: string): Observable<PagedResult<ItemData>> {
    return this.page(ItemData, `${orgType}/ui_filters/products/page`, params, 600);
  }

  getStores(params: any, orgType: string): Observable<PagedResult<DDStore>> {
    return this.page(DDStore, `${orgType}/ui_filters/stores/page`, params, 600);
  }

  getRetailOrgs(params?: any): Observable<PagedResult<RetailStoreData>> {
    return this.page(RetailStoreData, 'fmcg/ui_filters/retail_orgs/page', params, 600);
  }

  getCategories(params: any, orgType: string): Observable<PagedResult<CategoryData>> {
    return this.page(CategoryData, `${orgType}/ui_filters/categories/page`, params, 600);
  }

  getSaleStatuses(params: any, orgType: string): Observable<PagedResult<SaleStatusData>> {
    return this.page(SaleStatusData, `${orgType}/ui_filters/sale_statuses/page`, params, 600);
  }

  getConsumerGoods(params?: any): Observable<PagedResult<ConsumerGoodsData>> {
    return this.page(ConsumerGoodsData, `/retail/ui_filters/consumer/page`, params, 600);
  }

  getDateRange(params: any = {}, orgType: string): Observable<DateRangeData> {
    return this.get(DateRangeData, `${orgType}/ui_filters/date_range/page`, 600, params);
  }

  getTopRateByCategory(params: any, orgType: string, analytic: AnalyticsType): Observable<PagedResult<RateByCategoryData>> {
    return this.page(RateByCategoryData, `analytics/${orgType}/${analytic}/categories/range/page`, params, 600);
  }

  // Tree Map
  getTopRateByCategoryByProduct(params: any, orgType: string, analytic: AnalyticsType): Observable<RateByCategoryByProductData[]> {
    return this.page(RateByCategoryByProductData, `analytics/${orgType}/${analytic}/treemap/page`, params, 600).pipe(map(r => r.data));
  }

  getTrendChartData(params: any, orgType: string, analytic: AnalyticsType,
                    analyticGroup: AnalyticGroup, analyticSubGroup: AnalyticGroup = null): Observable<PagedResult<DatesTrendData>> {
    return this.page(DatesTrendData, `/analytics/${orgType}/${analytic}/${analyticGroup}${analyticSubGroup ? '/' + analyticSubGroup : ''}/trend/page`, params, 600);
  }

  getPosTrendData(params: any, orgType: string, type: AnalyticGroup): Observable<PagedResult<PosValueTrendData>> {
    return this.page(PosValueTrendData, `analytics/${orgType}/pos/${type}/trend/page`, params, 600);
  }

  getRangeDataCsv(params: any, orgType: string, analytic: AnalyticsType, analyticGroup: AnalyticGroup): Observable<EmptyData> {
    return this.get(EmptyData, `/analytics/${orgType}/${analytic}/${analyticGroup}/range/csv`, 0, params);
  }

  getGetTrendDataCsv(params: any, orgType: string, analytic: AnalyticsType, analyticGroup: AnalyticGroup,
                     analyticSubGroup: AnalyticGroup = null): Observable<EmptyData> {
    return this.get(EmptyData, `/analytics/${orgType}/${analytic}/${analyticGroup}${analyticSubGroup ? '/' + analyticSubGroup : ''}/trend/csv`, 0, params);
  }

  getRetailStores(params?: any): Observable<PagedResult<DDStore>> {
    return this.page(DDStore, 'retail/ui_filters/stores/page', params, 300);
  }

  getRetailDepartments(params?: any): Observable<PagedResult<DepartmentData>> {
    return this.page(DepartmentData, 'retail/ui_filters/departments/page', params, 300);
  }

  getRetailDateRange(params?: any): Observable<DateRangeData> {
    return this.get(DateRangeData, 'retail/ui_filters/date_range/page', params);
  }

  //----------------------------------------------------
  //      CSV reports APIs
  //----------------------------------------------------
  getCsvReportList(params: any, orgType: string): Observable<PagedResult<CsvReportData>> {
    return this.page(CsvReportData, `${orgType}/csv_reports/page`, params, 0);
  }

  updateCsvReportState(id: number, state: number, orgType: string) {
    const params: CsvReportUpdateState = new CsvReportUpdateState({ id, state });
    return this.update(params, `${orgType}/csv_reports/state`);
  }

  getCsvReportUrl(id: number, orgType: string): Observable<CsvReportUrlData> {
    return this.get(CsvReportUrlData, `${orgType}/csv_reports/url`, 60, { rid: id });
  }

  getDashboardConfiguration(orgType: string): Observable<any> {
    return this.get(DashboardConfigData, `${orgType}/config`).pipe(
      map(res => res.data)
    );
  }

  saveDashboardConfiguration(params, orgType: string) {
    const reqParams: DashboardConfigData = new DashboardConfigData({ data: params });
    return this.update(reqParams, `${orgType}/config`);
  }

}
