import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssueMapComponent } from './issue-map/issue-map.component';
import { BtCommonModule } from '../common/bt-common.module';
import { BtMapModule } from '../map/bt-map.module';
import { IssueMapDialogComponent } from './issue-map-dialog/issue-map-dialog.component';
import { IssueMapThumbComponent } from './issue-map-thumb/issue-map-thumb.component';

@NgModule({
  declarations: [IssueMapComponent, IssueMapDialogComponent, IssueMapThumbComponent],
  exports: [IssueMapComponent, IssueMapThumbComponent],
  imports: [
    CommonModule,
    BtCommonModule,
    BtMapModule
  ]
})
export class BtIssueModule {
}
