import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, AssessmentTemplate, InspectAssessment, InspectPoseAssessment } from '@ng-cloud/badger-core';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InspectAssessmentService extends ApiService {


  /**
   * Fetch InspectAssessments based on params
   */
  getInspectAssessments(params?: any): Observable<InspectAssessment[]> {
    return this.list(InspectAssessment, `inspect_assessments`, params);
  }

  /**
   * Create InspectAssessment based on params
   */
  createInspectAssessment(inspectAssessment: InspectAssessment): Observable<InspectAssessment> {
    return this.create(inspectAssessment, `inspect_assessments`);
  }

  /**
   * Create assessment templates for list of inspect assessments and an organization
   */
  batchCreateTemplates(body): Observable<AssessmentTemplate[]> {
    return this.http.post<AssessmentTemplate[]>(this.url(`inspect_assessment_templates/batch_create`), body).pipe(
      filter(response => response != null),
      map(result => result.map(json => new AssessmentTemplate().deserialize(json))),
      catchError(e => this.handleError(e)));
  }

  /**
   * Fetch InspectAssessment
   */
  getInspectAssessment(id: number): Observable<InspectAssessment> {
    return this.get(InspectAssessment, `inspect_assessments/${id}`);
  }

  /**
   * Update InspectAssessments
   */
  updateInspectAssessment(inspectAssessment: InspectAssessment): Observable<InspectAssessment> {
    return this.update(inspectAssessment, `inspect_assessments/${inspectAssessment.id}`);
  }

  /**
   * Destroy InspectAssessments
   */
  deleteInspectAssessment(inspectAssessment): Observable<any> {
    return this.destroy(inspectAssessment, `inspect_assessments/${inspectAssessment.id}`);
  }

  /**
   * Fetch assessment templates based on params
   */
  getAssessmentTemplates(params?: any): Observable<AssessmentTemplate[]> {
    return this.list(AssessmentTemplate, `inspect_assessment_templates`, params);
  }

  /**
   * Update AssessmentTemplate
   */
  updateAssessmentTemplate(template: AssessmentTemplate): Observable<AssessmentTemplate> {
    return this.update(template, `inspect_assessment_templates/${template.id}`);
  }

  /**
   * Destroy Assessment Template
   */
  deleteTemplate(assessmentTemplate): Observable<any> {
    return this.destroy(assessmentTemplate, `inspect_assessment_templates/${assessmentTemplate.id}`);
  }

  /**
   *  Fetch All Inspect Pose Assessments
   */
  getInspectPoseAssessments(params?: any): Observable<InspectPoseAssessment[]> {
    return this.list(InspectPoseAssessment, `inspect_pose_assessments`, params);
  }

  /**
   * Batch update Inspect Pose Assessments
   */
  batchUpdateInspectPoseAssessments(body): Observable<InspectPoseAssessment[]> {
    return this.http.post<InspectPoseAssessment[]>(this.url(`inspect_pose_assessments/batch_update`), body).pipe(
      filter(response => response != null),
      map(result => result.map(json => new InspectPoseAssessment().deserialize(json))),
      catchError(e => this.handleError(e)));
  }

}
