export class Section {
  id: string;
  right: number;
  left: number;
  width: number;
  segmentBoundary: boolean;

  height: number;

  deserialize(json: any): this {
    this.id = json.id;
    this.right = json.right;
    this.left = json.left;
    this.width = json.width;
    this.segmentBoundary = json.segment_boundary;

    return this;
  }

  serialize(): any {
    return {
      id: this.id,
      right: this.right,
      left: this.left,
      width: this.width,
      segment_boundary: this.segmentBoundary
    };
  }
}
