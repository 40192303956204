import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { Tag } from '../../models/tag';
import { TagObject } from '../../map/objects/tag-object';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';

export class TagGrouping extends MapGrouping<Tag, TagObject> {
  static readonly TYPE = 'type';
  static readonly UNIQUE = 'unique';

  colorMode = TagGrouping.TYPE;

  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'tags', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  setColorMode(displayMode: string) {
    this.colorMode = displayMode;

    this.getObjects().forEach(tagObject => {
      this.setTagColor(tagObject, displayMode === TagGrouping.TYPE ? tagObject.getTypeColor() : tagObject.getUniqueColor());
    });
  }

  createObject(tag: Tag): TagObject {
    const xPosition = tag.xPosition - this.panoramaMetadata.xShelfAlign;
    const yPosition = tag.yPosition - this.panoramaMetadata.yShelfAlign;

    const points = [
      { x: xPosition, y: yPosition },
      { x: xPosition + tag.width, y: yPosition },
      { x: xPosition + tag.width, y: yPosition + tag.height },
      { x: xPosition, y: yPosition + tag.height }];

    const mappedPoints = points.map(pt => this.storeMap.pointToPixels(pt, 1 / this.panoramaMetadata.dpi));
    const tagObject = new TagObject(tag, mappedPoints, this.options);

    this.setTagColor(tagObject, this.colorMode === TagGrouping.TYPE ? tagObject.getTypeColor() : tagObject.getUniqueColor());

    return tagObject;
  }

  setTagColor(tagObject: TagObject, color: string) {
    tagObject.getFabric().getObjects().forEach(tagElement => {
      tagElement.set({
        stroke: color
      });
    });
  }
}
