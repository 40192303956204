import { Component, Input, OnInit } from '@angular/core';
import { ErrorService } from '../../services/error.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bt-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})

export class FormErrorsComponent implements OnInit {
  @Input() errorName;
  errors: Observable<string[]>;

  constructor(private errorService: ErrorService) {
  }

  ngOnInit() {
    this.errors = this.errorName ?
      this.errorService.messagesFor(this.errorName) : this.errorService.messages(true);
  }
}
