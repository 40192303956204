import { fabric } from 'fabric';
import { Pose, RotationType } from '../../models/pose';
import { MapObject } from './map-object';
import { RobotObject } from './robot-object';
import { RobotOrientationObject } from './robot-orientation-object';
import { Observable, Subject } from 'rxjs';
import { Point } from '../../models/interfaces/point';

export class PoseObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    hoverCursor: 'pointer',
    lockScalingX: true,
    lockScalingY: true,
    originX: 'center',
    originY: 'center',
    cornerSize: 16,
    cornerColor: 'royalblue',
    borderColor: 'royalblue',
    borderScaleFactor: 2.5,
    perPixelTargetFind: true,
    hasIndicator: false,
    hasFirstColor: false,
    hasLastColor: false,
    hasFade: false,
    firstColor: 'darkgreen',
    lastColor: 'darkred',
    cautionConeColor: 'darkorange',
    navigatingToHazardColor: 'gold',
    stoppedColor: 'red'
  };

  pose: Pose;
  private poseSource: Subject<Pose> = new Subject<Pose>();
  poseChanged: Observable<Pose> = this.poseSource.asObservable();

  robotObject: RobotObject;
  robotOptions: any = {};
  robotOrientationObject: RobotOrientationObject;
  robotOrientationOptions: any = {};
  background: fabric.Rect;
  indicator: fabric.Circle;

  isFirst = false;
  isLast = false;
  isStopped = false;
  isCautionCone = false;
  isNavigatingToHazard = false;

  readonly RADIUS_MIN = 16;
  readonly RADIUS_MAX = 24;
  private animating = false;
  private upstroke = true;

  constructor(pose: Pose, options?: any) {
    super(options);
    this.pose = pose;
  }

  setFirst(): void {
    this.isFirst = true;
    this.decorate();
  }

  setLast(): void {
    this.isLast = true;
    this.decorate();
    this.animateIndicator();
  }

  setCautionCone(): void {
    this.isCautionCone = true;
    this.decorate();
  }

  setNavigatingToHazard(): void {
    this.isNavigatingToHazard = true;
    this.decorate();
  }

  setStopped(): void {
    this.isStopped = true;
    this.decorate();
  }

  resetStatus(): void {
    this.isFirst = false;
    this.isLast = false;
    this.decorate();
  }

  getRobotFabric() {
    if (!this.robotObject) {
      this.robotObject = this.createRobotObject();
    }
    return this.robotObject.getFabric();
  }

  getRobotOrientationFabric() {
    if (!this.robotOrientationObject) {
      this.robotOrientationObject = this.createRobotOrientationObject();
    }
    return this.robotOrientationObject.getFabric();
  }

  getPosition(): Point {
    let center = this.getRobotFabric().getCenterPoint();
    let group = this.fabricObject;

    while (group) {
      center = center.add(group.getCenterPoint());
      group = group.group;
    }

    return center;
  }

  move(direction: number) {
    const obj = this.getFabric();
    if (direction === 0) {
      obj.set('left', obj.get('left') - 1);
    }
    else if (direction === 1) {
      obj.set('top', obj.get('top') - 1);
    }
    else if (direction === 2) {
      obj.set('left', obj.get('left') + 1);
    }
    else if (direction === 3) {
      obj.set('top', obj.get('top') + 1);
    }
    obj.setCoords();
    obj.fire('moving');
  }

  destroy() {
    this.poseSource.complete();
    super.destroy();
  }

  protected getInstanceOptions(): any {
    return {
      angle: this.pose.getRotation(RotationType.Degrees)
    };
  }

  protected build(): fabric.Object {
    return new fabric.Group([
        this.createBackground(),
        this.getRobotFabric(),
        this.getRobotOrientationFabric(),
        this.createIndicator()
      ],
      this.getOptions());
  }

  protected setup(): void {
    this.decorate();

    ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb'].forEach((control) => {
      this.fabricObject.setControlVisible(control, false);
    });

    this.on('moving', () => this.onMove());
    this.on('rotating', () => this.onRotate());
  }

  protected onMove() {
    const newPosition = this.getPosition();
    this.pose.x = newPosition.x;
    this.pose.y = newPosition.y;
    this.poseSource.next(this.pose);
  }

  protected onRotate() {
    this.pose.setRotation(this.fabricObject.angle, RotationType.Degrees);
    this.poseSource.next(this.pose);
  }

  protected createBackground() {
    this.background = new fabric.Rect({
      originX: 'center',
      originY: 'center',
      width: 52,
      height: 52,
      left: this.pose.x,
      top: this.pose.y,
      fill: 'transparent'
    });
    return this.background;
  }

  protected createIndicator() {
    this.indicator = new fabric.Circle({
      radius: this.RADIUS_MIN,
      originX: 'center',
      originY: 'center',
      width: 52,
      height: 52,
      left: this.pose.x,
      top: this.pose.y,
      fill: 'transparent',
      stroke: this.robotObject.getOptions().fill,
      strokeWidth: 2,
      opacity: 0
    });
    return this.indicator;
  }

  protected createRobotObject() {
    return new RobotObject(this.pose, this.robotOptions);
  }

  protected createRobotOrientationObject() {
    return new RobotOrientationObject(this.pose, this.robotOrientationOptions);
  }

  decorate() {
    if (this.robotObject) {
      let color = this.robotObject.getOptions().fill;

      if (this.isStopped) {
        color = this.getOptions().stoppedColor;
      }
      else if (this.isCautionCone) {
        color = this.getOptions().cautionConeColor;
      }
      else if (this.isNavigatingToHazard) {
        color = this.getOptions().navigatingToHazardColor;
      }
      else if (this.isFirst && this.getOptions().hasFirstColor) {
        color = this.getOptions().firstColor;
      }
      else if (this.isLast && this.getOptions().hasLastColor) {
        color = this.getOptions().lastColor;
      }

      this.robotObject.getFabric().set('fill', color);

      if (this.isLast && this.getOptions().hasIndicator) {
        this.indicator.set('stroke', color);
        this.indicator.set('opacity', 1);
      }
      else {
        this.indicator.set('opacity', 0);
      }
    }
  }

  private animateIndicator(cycles = 0) {
    if (this.animating || !this.indicator) {
      return;
    }

    this.animating = true;

    fabric.util.animate({
      startValue: this.upstroke ? this.RADIUS_MIN : this.RADIUS_MAX,
      endValue: this.upstroke ? this.RADIUS_MAX : this.RADIUS_MIN,
      duration: this.upstroke ? 1000 : 600,
      onChange: (value) => {
        this.indicator.set('radius', value);
        this.render();
      },
      onComplete: () => {
        this.animating = false;
        this.upstroke = !this.upstroke;
        if (cycles < 3) {
          // continue for two full beats
          setTimeout(() => this.animateIndicator(cycles + 1), this.upstroke ? 500 : 1);
        }
      }
    });
  }
}
