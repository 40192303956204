import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { fabric } from 'fabric';
import { Map } from '../models/map';
import { MapObject } from './objects/map-object';
import * as _ from 'lodash';
import { GroupEventType } from './groupings/map-grouping-event';
import { MapLayer } from './groupings/map-layer';
import { MapGrouping } from './groupings/map-grouping';
import { ApiModel } from '../models/api-model';
import * as Hammer from 'hammerjs';
import { Point } from '../models/interfaces/point';
import { zoomType } from './zoom-type';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectBorderObject } from '@ng-cloud/badger-core/map/objects/select-border-object';
import { DeviceService } from '../services/device.service';

@Component({
  selector: 'bt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @Input() protected map: Map;
  @Input() protected zoom: string | number | Point;
  @Input() protected splitMap = false;
  @Input() protected applyFilters = false;
  @Input() protected filters: string;
  @Input() protected size: string;
  @Input('width') protected clientWidth: number;
  @Input('height') protected clientHeight: number;
  @Input() protected canvasOptions = {};
  @Input() pan: boolean;

  @Output() load: EventEmitter<MapComponent> = new EventEmitter();
  @Output() mapPan: EventEmitter<fabric.Point> = new EventEmitter();

  @HostListener('window:resize') onResize() {
    this.maximize();
  }

  @ViewChild('mapCanvas', { static: true })
  protected mapCanvas: ElementRef;
  protected canvas: fabric.Canvas;
  protected groupings: MapGrouping<ApiModel, MapObject>[] = [];
  protected layers: MapLayer[] = [];
  protected objects: MapObject[] = [];
  protected selectedObjects: MapObject[] = [];
  protected objectCounter = 0;
  protected width: number;
  protected height: number;
  protected originalZoom: string | number | Point;
  protected mapMoved = false;

  protected defaultCanvasOptions = {
    selection: false,
    stateful: false,
    renderOnAddRemove: false,
    backgroundColor: '#CDCDCD',
    altActionKey: null,
    fireRightClick: true
  };
  protected hammerManager: Hammer;

  @ViewChild(MatMenuTrigger, { static: true })
  private contextMenuTrigger: MatMenuTrigger;
  contextMenuOptions: MapContextMenuOption[];

  constructor(public element: ElementRef, protected deviceService: DeviceService) {
  }

  ngAfterViewInit() {
    this.zoom = zoomType.isValid(this.zoom) ? this.zoom : this.getDefaultZoom();
    this.originalZoom = this.zoom;
    this.pan = _.isNil(this.pan) ? zoomType.isFullscreen(this.zoom) : this.pan;
    const options = Object.assign({}, this.defaultCanvasOptions, this.canvasOptions);
    this.canvas = new fabric.Canvas(this.mapCanvas.nativeElement, options);

    this.loadMapImage();
    this.addEventListeners();

    if (this.splitMap) {
      this.getBodyElement().classList.add('bt-fullscreen-map');
    }
    else {
      if (zoomType.isFullscreen(this.zoom)) {
        this.getBodyElement().classList.add('bt-fullscreen-map');
      }
      else {
        this.getCanvasContainer().classList.add('bt-mini-map');
      }
    }
  }

  ngOnDestroy() {
    this.load.complete();
    for (const object of this.objects) {
      object.destroy();
    }
    for (const grouping of this.groupings) {
      grouping.destroy();
    }
    this.getBodyElement().classList.remove('bt-fullscreen-map');
    this.hammerManager.destroy();
    this.canvas.clear();
    this.canvas.removeListeners();
  }

  public clearAll() {
    this.clearObjects();
    this.groupings.length = 0;
    this.layers.length = 0;
    this.selectedObjects.length = 0;
  }

  /**
   * Add a custom MapObject to the map
   * Optional, specify a layer and whether to re-render the canvas.
   */
  public addObject(object: MapObject, layerOrZ?: string | number, render: boolean = false): void {
    const fabricObj = object.getFabric();
    fabricObj.mapObject = object;
    this.addFabric(fabricObj, layerOrZ, render);
    this.objects.push(object);
    object.renderRequested().subscribe(() => this.render());
  }

  /**
   * Add a regular Fabric.js Object to the map.
   * Optional, specify a layer and whether to re-render the canvas.
   */
  public addFabric(fabricObj: fabric.Object, layerOrZ?: string | number, render: boolean = false) {
    fabricObj.zIndex = this.getZIndex(layerOrZ);
    fabricObj.visible = this.getVisible(layerOrZ);
    fabricObj.objectId = this.objectCounter++;
    fabricObj.onLoad && fabricObj.onLoad.subscribe(() => this.render());
    this.canvas.add(fabricObj);
    render && this.render();
  }

  /**
   * Remove a MapObject from the map
   * Optional, specify whether to re-render the canvas.
   */
  public removeObject(object: MapObject, render: boolean = false) {
    this.destroyObject(object);
    _.pull(this.objects, object);
    if (this.selectedObjects.includes(object)) {
      this.deselectObject(object);
    }
    render && this.render();
  }

  /**
   *
   * Remove all MapObjects from the map.
   * This does not remove Fabric Objects. Optional, specify whether to re-render the canvas.
   */
  public clearObjects(render: boolean = false): void {
    this.objects.forEach(obj => this.destroyObject(obj));
    this.objects.length = 0;
    render && this.canvas.renderAll();
  }

  /**
   * Mark a MapObject as selected.
   *
   * Note: This is separate from Fabric's notion of active objects.
   * This is useful when needing greater control over the selection UI for a Map.
   */
  public selectObject(object: MapObject, render: boolean = false): void {
    const borderObj = new SelectBorderObject(object);
    this.addObject(borderObj, this.findLayer(object.getFabric().zIndex).name, render);
    this.objects.push(borderObj);
    this.selectedObjects.push(object);
  }

  /**
   * Remove selected status from a MapObject.
   *
   * Note: This is separate from Fabric's notion of active objects.
   * This is useful when needing greater control over the selection UI for a Map.
   */
  public deselectObject(object: MapObject, render: boolean = false): void {
    _.pull(this.selectedObjects, object);
    const borderObj = this.objects.find(obj => obj instanceof SelectBorderObject && obj.sourceObj == object);
    _.pull(this.objects, borderObj);
    this.removeObject(borderObj, render);
  }

  /**
   * Toggle selected status for a MapObject.
   *
   * Note: This is separate from Fabric's notion of active objects.
   * This is useful when needing greater control over the selection UI for a Map.
   */
  public toggleObjectSelection(object: MapObject, render: boolean = false): void {
    if (!this.selectedObjects.includes(object)) {
      this.selectObject(object, render);
    }
    else {
      this.deselectObject(object, render);
    }
  }

  /**
   * Mark all MapObjects as selected. This does not apply to Fabric objects.
   *
   * Note: This is separate from Fabric's notion of active objects.
   * This is useful when needing greater control over the selection UI for a Map.
   */
  public selectAll(render: boolean = false): void {
    for (const object of this.getObjects().filter(obj => !(obj instanceof SelectBorderObject))) {
      this.selectObject(object);
    }
    render && this.render();
  }

  /**
   * Remove selected status from all MapObjects.
   *
   * Note: This is separate from Fabric's notion of active objects.
   * This is useful when needing greater control over the selection UI for a Map.
   */
  public deselectAll(render: boolean = false): void {
    for (const object of this.getSelectedObjects()) {
      this.deselectObject(object);
    }
    render && this.render();
  }

  /**
   * Show the layer with the given name or z-index
   */
  public showLayer(layerOrZ: string | number) {
    this.toggleLayer(layerOrZ, true);
  }

  /**
   * Hide the layer with the given name or z-index
   */
  public hideLayer(layerOrZ: string | number) {
    this.toggleLayer(layerOrZ, false);
  }

  /**
   *  Toggle the visibility of the layer with the given name or z-index
   */
  public toggleLayer(layerOrZ: string | number, visible?: boolean) {
    const isVisible = _.isNil(visible) ? !this.getVisible(layerOrZ) : visible;
    this.setVisible(layerOrZ, isVisible);

    this.canvas.renderAll();
  }

  /**
   * Create a new named layer at the given z-index
   */
  public createLayer(name: string, zIndex: number, visible: boolean = true): MapLayer {
    const newLayer = new MapLayer({ name: name, zIndex: zIndex, visible: visible });
    this.layers.push(newLayer);
    return newLayer;
  }

  /**
   * Create a new named layer as specified by the given MapLayer object.
   */
  public addLayer(mapLayer: MapLayer, visible: boolean = true, enabled: boolean = true): void {
    const newLayer = new MapLayer(Object.assign({}, mapLayer, { visible: visible, enabled: enabled }));
    this.layers.push(newLayer);

    if (newLayer.grouping) {
      if (!newLayer.grouping.storeMap) {
        newLayer.grouping.setStoreMap(this.map);
      }
      newLayer.grouping.eventsFor(newLayer).subscribe(event => {
        if (event.eventType == GroupEventType.added) {
          this.addObject(event.entry.object, newLayer.name);
        }
        else if (event.eventType == GroupEventType.removed) {
          this.removeObject(event.entry.object);
        }
      });

      if (this.groupings.indexOf(newLayer.grouping) < 0) {
        newLayer.grouping.render().subscribe(() => this.render());
        this.groupings.push(newLayer.grouping);
      }
    }
  }

  /**
   * Create all default named layers specified by the given MapGrouping.
   */
  public addGrouping(mapGrouping: MapGrouping<any, MapObject>, visible: boolean = true, enabled: boolean = true): void {
    mapGrouping.getLayers().forEach(layer => this.addLayer(layer, visible, enabled));
  }

  /**
   * Remove all MapObjects from the given named layer or z-index.
   */
  public clearLayer(layerOrZ: string | number, render: boolean = false): void {
    this.getObjectsForLayer(layerOrZ).forEach(obj => this.removeObject(obj));
    render && this.canvas.renderAll();
  }

  /**
   * Render map canvas, preserving object ordering.
   */
  public render(): void {
    this.canvas._objects.sort(this.compareObjects);
    this.canvas.renderAll();
  }

  /**
   * Place an event handler only on the map canvas.
   * Any events of the given event type performed on objects on the map will be ignored.
   */
  public on(eventType: string, callback: (event: any, canvas: fabric.Canvas) => void) {
    this.canvas.on(eventType, function(e) {
      if (!e.target) {
        callback(e, this);
      }
    });
  }

  /**
   * Get underlying Fabric Canvas
   */
  public getCanvas(): fabric.Canvas {
    return this.canvas;
  }

  /**
   * Get canvas HTML element.
   */
  public getCanvasElement(): ElementRef {
    return this.mapCanvas;
  }

  /**
   * Get parent element of canvas HTML element.
   */
  public getCanvasContainer(): Element {
    return this.mapCanvas.nativeElement.parentElement;
  }

  /**
   * Get the Store Map.
   */
  public getMap(): Map {
    return this.map;
  }

  public setMap(map: Map): void {
    this.map = map;
    this.loadMapImage();
  }

  /**
   * Get layer for the given name or z-index.
   */
  public getLayer(layerOrZ: string | number): MapLayer {
    const layer = this.findLayer(layerOrZ);
    return layer && new MapLayer(layer);
  }

  /**
   * Get all map layers.
   */
  public getLayers(): MapLayer[] {
    return this.layers.slice();
  }

  /**
   * Get all custom MapObjects.
   */
  public getObjects(): MapObject[] {
    return this.objects.slice();
  }

  /**
   * Get all MapObjects marked as selected with the custom selection interface.
   * Note: This is separate from Fabric's notion of active objects.
   */
  public getSelectedObjects(): MapObject[] {
    return this.selectedObjects.slice();
  }

  /**
   * Get all MapObjects within the specified named layer or z-index
   */
  public getObjectsForLayer(layerOrZ: string | number): MapObject[] {
    const zIndex = this.getZIndex(layerOrZ);
    return this.objects.filter(obj => obj.getFabric().zIndex === zIndex);
  }

  /**
   * Get current map zoom level.
   * This can either be a pre-defined zoomType or a number where 1 is the original zoom.
   */
  public getZoom(): string | number | Point {
    return this.zoom;
  }

  /**
   * Set the map zoom level.
   * This can either be a pre-defined zoomType or a number where 1 is the original zoom.
   */
  public setZoom(zoom, point: Point = null): void {
    if (zoom !== this.zoom && !zoomType.isPoint(zoom)) {
      this.mapMoved = true;
    }

    this.zoom = zoom;

    if (zoomType.isComputed(this.zoom)) {
      // First resize the canvas if necessary
      if (zoom == zoomType.fixed) {
        this.resizeCanvas(this.width, this.height);
      }
      else {
        this.fillBoundingArea();
      }

      // Set the zoom level for the canvas size
      this.zoomToFit(this.canvas.width, this.canvas.height);

      if (this.zoom == zoomType.auto) {
        this.canvas.absolutePan(new fabric.Point(0, 0));
        this.canvas.renderAll();
      }
    }
    else if (zoomType.isPoint(this.zoom)) {
      // Not the most scientific formula, but it works empirically
      this.zoomCanvas(0.003 * this.canvas.getWidth() / Math.max(0.5, Math.log(this.map.width / 100)));
    }
    else {
      this.zoomCanvas(this.zoom as number, point);
    }
  }

  /**
   * Resize the canvas to take up available area.
   * If necessary, adjust the zoom level to fit the map to the new canvas size.
   */
  public maximize(): void {
    if (this.zoom != zoomType.fixed) {
      const rect = this.fillBoundingArea();

      if (zoomType.isPoint(this.zoom)) {
        this.panAndZoomTo(this.zoom as Point);
      }
      else if (zoomType.isDynamic(this.zoom)) {
        this.zoomToFit(rect.width, rect.height);
      }
    }
  }

  /**
   * Pan and zoom to a point on the map such that it can be easily viewed.
   */
  public panAndZoomTo(point: Point): void {
    if (point && !this.mapMoved) {
      const pt = new fabric.Point(point.x, point.y);
      this.setZoom(pt);

      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();
      const canvasZoom = this.canvas.getZoom();

      const margin = new fabric.Point(canvasWidth, canvasHeight).multiply(0.2 / canvasZoom);
      const tl = this.canvas.vptCoords.tl.add(margin);
      const br = this.canvas.vptCoords.br.subtract(margin);

      if (tl.x > pt.x || pt.x > br.x || tl.y > pt.y || pt.y > br.y) {
        const centerPoint = new fabric.Point(canvasWidth, canvasHeight).divide(2);
        const panPoint = pt.multiply(canvasZoom).subtract(centerPoint);
        this.canvas.absolutePan(panPoint);
      }

      this.canvas.renderAll();
    }
  }

  // Pan the canvas vertically/horizontally if object is not visible.
  public panTo(object: fabric.Object) {
    if (object) {
      const tlCanvas = this.canvas.vptCoords.tl;
      const brCanvas = this.canvas.vptCoords.br;
      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();
      const zoom = Math.min(this.canvas.getZoom(), 1);
      const maxPanX = (this.width * zoom) - canvasWidth;
      const maxPanY = (this.height * zoom) - canvasHeight;
      const panCushion = 100 * zoom;

      const tlObject = object.aCoords.tl;
      const brObject = object.aCoords.br;
      const panPoint = new fabric.Point(tlCanvas.x, tlCanvas.y);

      let panRequired = false;

      if (tlCanvas.x > tlObject.x) {
        panRequired = true;
        panPoint.x = Math.max((tlObject.x * zoom) - panCushion, 0);
      }
      else if (brCanvas.x < brObject.x) {
        panRequired = true;
        panPoint.x = Math.min((tlObject.x * zoom) + panCushion, maxPanX);
      }
      else {
        panPoint.x = Math.max((tlObject.x * zoom) - panCushion, 0);
      }

      if (tlCanvas.y > tlObject.y) {
        panRequired = true;
        panPoint.y = Math.max((tlObject.y * zoom) - panCushion, 0);
      }
      else if (brCanvas.y < brObject.y) {
        panRequired = true;
        panPoint.y = Math.min((tlObject.y * zoom) + panCushion, maxPanY);
      }

      if (panRequired) {
        this.canvas.absolutePan(panPoint);
        this.canvas.renderAll();
      }
    }
  }

  // Load map image and set initial dimensions
  protected loadMapImage(): void {
    if (this.map) {
      fabric.Image.fromURL(this.map.getUrl(this.size), image => {
        const element = image.getElement();
        this.width = this.clientWidth || element.width;
        this.height = this.clientHeight || element.height;

        // To preserve the local coordinate space, the map image
        // needs to be stretched to the full map size before adding it to the canvas
        image.set({ scaleX: this.map.width / this.width, scaleY: this.map.height / this.height });

        if (this.applyFilters && this.filters && this.mapCanvas.nativeElement) {
          this.mapCanvas.nativeElement.style.filter = this.filters;
        }

        this.canvas.setBackgroundImage(image, () => {
          this.setZoom(this.zoom);
          this.load.emit(this);
        });
      }, this.applyFilters ? { crossOrigin: 'anonymous' } : null);
    }
    else {
      this.canvas.setBackgroundImage(null, () => this.canvas.renderAll());
    }
  }

  protected addEventListeners(): void {
    // Disable right click context menu and (optionally) provide a custom one
    this.getCanvasContainer().addEventListener('contextmenu', (e: MouseEvent) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.openContextMenu(e);
      return false;
    });

    // Triggers a start for the possibility of a "click" event that is created below
    let clickOnly = true;
    this.getCanvasContainer().addEventListener('mousedown', () => {
      clickOnly = true;
    });

    // Detects when only a click action is taken (becomes false when movement to pan while mousedown)
    this.getCanvasContainer().addEventListener('click', (e) => {
      if (clickOnly) {
        this.canvas.fire('mouse:click', { target: this.canvas.findTarget(e), e: e });
      }
    });

    // Hammer events for panning using touch or click and drag
    this.hammerManager = new Hammer(this.getCanvasContainer());
    this.hammerManager.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }));
    this.hammerManager.add(new Hammer.Press({ time: 500 }));

    let lastPanX = 0;
    let lastPanY = 0;
    // Reset values offsets to 0,0 for the pan
    this.hammerManager.on('panstart', () => {
      clickOnly = false;
      lastPanX = 0;
      lastPanY = 0;
    });

    // This event will pan the map relative to the position of the last known event position or if none the panstart
    this.hammerManager.on('pan', (e) => {
      if (this.pan && !this.canvas.getActiveObject()) {
        const delta = new fabric.Point(e.deltaX - lastPanX, e.deltaY - lastPanY);
        lastPanX = e.deltaX;
        lastPanY = e.deltaY;
        this.canvas.relativePan(delta);
        this.canvas.renderAll();
        this.mapPan.emit(delta);

        // Exit zooming to point when map is panned
        this.mapMoved = true;
        if (zoomType.isPoint(this.zoom)) {
          this.zoom = this.canvas.getZoom();
        }
      }
    });

    this.hammerManager.on('press', (e) => this.openContextMenu(e));
  }

  // Display a context menu if one is specified on the target object
  protected openContextMenu(event: MouseEvent) {
    const target = this.canvas.findTarget(event);

    if (target && target.contextMenu) {
      this.contextMenuOptions = target.contextMenu;
      this.contextMenuTrigger.openMenu();
      const contextMenu = document.getElementsByClassName('map-context-menu')[0] as HTMLElement;
      contextMenu.style.position = 'fixed';
      contextMenu.style.left = `${event.clientX}px`;
      contextMenu.style.top = `${event.clientY}px`;
    }
  }

  // Set canvas zoom level and re-render. Optionally zoom to a specific point.
  protected zoomCanvas(zoom: number, point: Point = null): void {
    if (point) {
      this.canvas.zoomToPoint(point, zoom);
    }
    else {
      this.canvas.setZoom(zoom);
    }
    this.canvas.renderAll();
    this.canvas.calcOffset();
  }

  // Zoom canvas such that the map fits the given bounding box, preserving aspect ratio.
  protected zoomToFit(boundingWidth: number, boundingHeight: number): void {
    let scale = 1;

    if (this.map.width > boundingWidth || this.map.height > boundingHeight) {
      scale = Math.min(boundingWidth / this.map.width, boundingHeight / this.map.height);
    }

    if ((this.splitMap) && (this.map.height > boundingHeight)) {
      scale = boundingHeight / this.map.height;
    }

    this.zoomCanvas(scale);
  }

  // Set canvas dimensions
  protected resizeCanvas(width, height): void {
    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
  }

  // Resize canvas to fill available area
  public fillBoundingArea(): any {
    const rect = this.getBoundingDimensions();
    this.resizeCanvas(rect.width, rect.height);
    return rect;
  }

  // Calculate available area for the map canvas
  private getBoundingDimensions(): any {
    const bounds = { width: this.width, height: this.height };

    if (this.clientWidth || this.clientHeight) {
      if (this.clientWidth) {
        bounds.width = this.clientWidth;
      }
      if (this.clientHeight) {
        bounds.height = this.clientHeight;
      }
    }
    else if (zoomType.isFullscreen(this.originalZoom)) {
      const rect = this.mapCanvas.nativeElement.getBoundingClientRect();
      const sibling = this.element.nativeElement.parentElement.nextElementSibling;
      bounds.width = window.innerWidth - rect.left;
      bounds.height = (sibling ? sibling.getBoundingClientRect().top : window.innerHeight) - rect.top;
    }
    else if (zoomType.isDynamic(this.originalZoom)) {
      const rect = this.element.nativeElement.parentElement.getBoundingClientRect();
      if (this.pan) {
        bounds.width = rect.width;
        bounds.height = rect.height;
      }
      else {
        bounds.width = Math.min(this.width, rect.width);
        bounds.height = Math.min(this.height, rect.height);
      }

      if (this.originalZoom == zoomType.fill) {
        bounds.height = Math.min(this.height, this.height * (bounds.width / this.width));
      }
    }

    return bounds;
  }

  // Resolve the default zoom level if one isn't specified
  protected getDefaultZoom() {
    if (this.size || this.width || this.height) {
      return zoomType.fixed;
    }

    return 1;
  }

  // Comparator for object ordering when rendering map
  private compareObjects(a, b) {
    return (a.zIndex || 0) - (b.zIndex || 0) ||
      (a.objectId || 0) - (b.objectId || 0);
  }

  // Remove object from canvas and call tear down logic
  private destroyObject(obj: MapObject) {
    this.canvas.remove(obj.getFabric());
    obj.destroy();
  }

  // Get document's body element
  private getBodyElement() {
    return document.getElementsByTagName('body')[0];
  }

  // Find the layer object for the given name or z-index
  private findLayer(layerOrZ: string | number): MapLayer {
    return this.layers.find(layer => layer.name === layerOrZ || layer.zIndex === layerOrZ);
  }

  // Get the z-index for the given name. For convenience this also takes a z-index.
  private getZIndex(layerOrZ: string | number): number {
    return _.get(this.findLayer(layerOrZ), 'zIndex') as number || Number(layerOrZ) || 0;
  }

  // Determines if the given layer is visible
  public getVisible(layerOrZ: string | number): boolean {
    return _.get(this.findLayer(layerOrZ), 'visible', true) as boolean;
  }

  // Toggle visibility of the given layer's objects and writes the status to the layer object
  private setVisible(layerOrZ: string | number, visible: boolean): void {
    _.set(this.findLayer(layerOrZ), 'visible', visible);
    this.getObjectsForLayer(layerOrZ).forEach(obj => {
      obj.getFabric().visible = visible;
    });
  }
}

export interface MapContextMenuOption {
  option: string;
  click: () => void;
  disabled?: boolean;
}
