import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  loadCustomIcons() {
    this.addCustomIcon('barcode', '../assets/barcode.svg');
    this.addCustomIcon('badger_logo', '../assets/badger_logo.svg');
    this.addCustomIcon('battery_low', '../assets/battery/battery_low.svg');
    this.addCustomIcon('battery_20', '../assets/battery/battery_20.svg');
    this.addCustomIcon('battery_30', '../assets/battery/battery_30.svg');
    this.addCustomIcon('battery_50', '../assets/battery/battery_50.svg');
    this.addCustomIcon('battery_60', '../assets/battery/battery_60.svg');
    this.addCustomIcon('battery_80', '../assets/battery/battery_80.svg');
    this.addCustomIcon('battery_90', '../assets/battery/battery_90.svg');

    this.addCustomIcon('battery_charging_low', '../assets/battery/battery_charging_low.svg');
    this.addCustomIcon('battery_charging_20', '../assets/battery/battery_charging_20.svg');
    this.addCustomIcon('battery_charging_30', '../assets/battery/battery_charging_30.svg');
    this.addCustomIcon('battery_charging_50', '../assets/battery/battery_charging_50.svg');
    this.addCustomIcon('battery_charging_60', '../assets/battery/battery_charging_60.svg');
    this.addCustomIcon('battery_charging_80', '../assets/battery/battery_charging_80.svg');
    this.addCustomIcon('battery_charging_90', '../assets/battery/battery_charging_90.svg');
    this.addCustomIcon('battery_charging_full', '../assets/battery/battery_charging_full.svg');

    this.addCustomIcon('battery_alert', '../assets/battery/battery_alert.svg');
    this.addCustomIcon('battery_full', '../assets/battery/battery_full.svg');
    this.addCustomIcon('battery_unknown', '../assets/battery/battery_unknown.svg');
  }

  addCustomIcon(iconName: string, iconUrl: string) {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)
    );
  }
}
