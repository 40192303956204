import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthorizationService } from '../../services/authorization.service';
import { OrganizationService } from '../../services/organization.service';

@Injectable()
export class OrganizationGuard  {
  readonly defaultRedirect = '/users/profile';

  constructor(private authService: AuthorizationService,
              private orgService: OrganizationService,
              private router: Router) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.validate(routeSnapshot.routeConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.validate(route);
  }

  protected validate(route: Route): Observable<any> {
    return new Observable<any>(observer => {
      this.authService.getCurrentUser().subscribe(user => {
        if (user.organization_id) {
          this.orgService.getOrganization(user.organization_id).subscribe(organization => {
            // noinspection JSIgnoredPromiseFromCall
            observer.next(organization?.orgType === this.getOrganizationType(route) || this.router.navigate([this.getRedirect(route)]));
          });
        }
      });
    });
  }

  private getOrganizationType(route) {
    return (route.data && route.data['orgType']) || '';
  }

  private getRedirect(route) {
    return (route.data && route.data['guardRedirect']) || this.defaultRedirect;
  }
}
