import { RealogramViewport } from '@ng-cloud/badger-core/models/realogram-viewport';

export class Fixture {
  name: string;
  type: string;
  xPosition: number;
  yPosition: number;
  width: number;
  height: number;
  depth: number;
  realogramViewports: RealogramViewport[] = [];

  absoluteX: number;

  deserialize(json: any): this {
    this.name = json.name;
    this.type = json.type;
    this.xPosition = json.x;
    this.yPosition = json.y;
    this.width = json.width;
    this.height = json.height;
    this.depth = json.depth;

    if (json.positions) {
      json.positions.forEach((realogramViewport) => {
        this.realogramViewports.push(new RealogramViewport().deserialize(realogramViewport));
      });
    }

    return this;
  }
}
