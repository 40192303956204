<div *ngIf="user">
  <div fxLayout>
    <mat-card appearance="outlined" fxFlex="50%" fxFlex.lt-md="100%">
      <mat-card-header>
        <mat-card-title class="card-title">
          <span> {{user.email}} </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list class="detail-list">
          <mat-list-item>
            <span matListItemTitle>{{user.organization?.name}}</span>
            <span matListItemLine>Organization</span>
          </mat-list-item>
          <mat-list-item *hasAccess="['organization_admin']">
            <span matListItemTitle>{{getRoles() | titlecase}}</span>
            <span matListItemLine>Roles</span>
          </mat-list-item>
          <mat-list-item>
            <span matListItemTitle>{{user.lastSignInAt | date: "short"}}</span>
            <span matListItemLine>Last Sign In</span>
          </mat-list-item>
          <mat-list-item>
            <span matListItemTitle>{{user.signInCount}}</span>
            <span matListItemLine>Sign In Count</span>
          </mat-list-item>
        </mat-list>
        <mat-card-actions>
          <ng-container *ngIf="roleOptions?.length > 0">
            <button *hasAccess="['organization_admin']" mat-stroked-button (click)="openEdit()"><mat-icon>edit</mat-icon>Edit</button>
          </ng-container>
          <button *ngIf="hasInsightAnalyticsAccess && ((portal == 'badger-portal') || (portal == 'weasel-portal'))" mat-stroked-button (click)="onConfigDashboard()"><mat-icon>settings</mat-icon>Configure Analytics</button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>
