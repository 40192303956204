import { take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';

@Injectable()
export class RoleGuard {

  constructor(private authService: AuthorizationService,
              private router: Router) {
  }

  readonly defaultRedirect = '/users/profile';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.validate(route);
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.validate(routeSnapshot.routeConfig);
  }

  protected validate(route: Route): Observable<boolean> {
    return this.authService.validateAccess(this.getRoles(route)).pipe(take(1),
      tap(isAuthorized => isAuthorized || this.router.navigate([this.getRedirect(route)])));
  }

  private getRoles(route) {
    return (route.data && route.data['roles']) || [];
  }

  private getRedirect(route) {
    return (route.data && route.data['guardRedirect']) || this.defaultRedirect;
  }
}
