import { MapGrouping } from './map-grouping';
import { Layers } from './map-layer';
import { ScanEvent } from '@ng-cloud/badger-core';
import { ZoneObject } from '@ng-cloud/badger-core/map/objects/zone-object';

@Layers([
  { name: 'storeZones', zIndex: 5 }
])
export class ScanEventGrouping extends MapGrouping<ScanEvent, ZoneObject> {

  createObject(data: ScanEvent): ZoneObject {
    const x0 = data.path.startX;
    const x1 = data.path.endX;
    const y0 = data.path.startY;
    const y1 = data.path.endY;

    let dx = x1 - x0;
    let dy = y1 - y0;

    const lineLength = Math.sqrt((dx * dx + dy * dy));
    dx /= lineLength;
    dy /= lineLength;

    const thickness = 1.0;
    const px = 0.5 * thickness * (-dy);
    const py = 0.5 * thickness * dx;

    const pointsArray = [
      { x: x0 + px, y: y0 + py },
      { x: x1 + px, y: y1 + py },
      { x: x1 - px, y: y1 - py },
      { x: x0 - px, y: y0 - py }
    ];

    const points = pointsArray.map(pt => this.storeMap.pointToPixels(pt));

    return new ZoneObject(data, points, Object.assign(this.getColor(data.issueCount), this.options));
  }

  protected getColor(issueCount: number) {

    const color = issueCount === 0 ? 'green' : 'red';

    return { polygonOptions: { fill: color } };
  }
}

