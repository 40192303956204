import { AbstractApiModel } from './abstract-api-model';

export class RobotEvent extends AbstractApiModel<Event> {
  eventType: string;
  message: string;
  active: boolean;
  level: string;
  createdAt: Date;
  updatedAt: Date;

  deserialize(json: any): this {
    this.eventType = json.event_type;
    this.level = json.level;
    this.message = json.message;
    this.active = json.active;
    this.createdAt = json.created_at;
    this.updatedAt = json.updated_at;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      event_type: this.eventType,
      message: this.message
    });
  }
}
