import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TopFilterService } from '../services/top-filter.service';
import { DashboardConfigData, DashboardConfigItem, fmcgChartTypes, retailChartTypes } from '../../shared/analytic-widgets/types';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditDashboardFormComponent } from './edit-dashboard-form/edit-dashboard-form.component';
import * as _ from 'lodash';
import { DialogService } from '@ng-cloud/badger-core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'insight-configure-dashboards',
  templateUrl: './configure-dashboards.component.html',
  styleUrls: ['./configure-dashboards.component.scss'],
  animations: [
    trigger('moveState', [
      state('moveUp', style({
        transform: 'translateY(-40px)',
        zIndex: 1000,
      })),
      state('moveDown', style({
        transform: 'translateY(40px)',
        zIndex: 1000,
      })),
      state('static', style({
        transform: 'translateY(0)',
        zIndex: 0
      })),
      transition('* => moveUp', animate('100ms ease-in')),
      transition('* => moveDown', animate('100ms ease-out')),
      transition('* => static', animate('0ms linear'))
    ])
  ]
})
export class ConfigureDashboardsComponent implements OnInit {

  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

  staticDashboards = [];

  configData: DashboardConfigData;
  anyActive = {};
  moveStates = {};
  numItems = 5;      // top items number

  maxItems = 0;
  itemHeight = 41; //px
  maxTabs = 9;  // 4 static + 5 custom
  readonly maxCustom = 5;

  tabOrderEditMode = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: unknown,
    public dialogRef: MatDialogRef<ConfigureDashboardsComponent>,
    private dialogService: DialogService,
    public dialog: MatDialog,
    private topFiltersService: TopFilterService) {
    // save config after closing
    dialogRef.afterClosed().subscribe(() => {
      if (this.numItems >= 3 && this.numItems <= 15 && /^-?[0-9][^.]*$/.test(this.numItems.toString())) {
        this.configData.general.top_items_num = this.numItems;
      }

      const tmpConfig: DashboardConfigData = _.cloneDeep(this.configData);

      tmpConfig.dashboard.forEach(item => {
        // delete states for animation:
        item.content.forEach(i => {
          delete i['moveState'];
        });
      });

      this.topFiltersService.setDashboardsConfig(tmpConfig, true);
    });
  }

  ngOnInit(): void {
    this.maxTabs = this.topFiltersService.getOrgType() == 'fmcg' ? 9 : 8; // FMCG has additional tab - POS
    this.staticDashboards = this.topFiltersService.getOrgType() == 'fmcg' ? ['OSA', 'OOS', 'PM', 'POS'] : ['OSA', 'OOS', 'PM'];

    this.topFiltersService.getDashboardsConfig()
      .subscribe(config => {

        if (config) {
          this.numItems = config.general.top_items_num;

          const configData = _.cloneDeep(config);

          configData.dashboard.forEach(dboard => {
            // init states for animation:
            dboard.content.forEach(i => i['moveState'] = 'static');

            this.anyActive[dboard.name] = true;
            this.updateMainToggleState(dboard.name, dboard.content);

            if (dboard.content.length > this.maxItems) {
              this.maxItems = dboard.content.length;
            }
          });

          this.configData = configData;
        }
      });
  }

  onNumItemsBlur() {
    if (this.numItems < 3 || this.numItems > 15 || !/^-?[0-9][^.]*$/.test(this.numItems.toString())) {
      // reset numItems if wrong range
      this.numItems = this.configData.general.top_items_num;
    }
  }

  get isTopNumberValid(): boolean {
     return this.numItems >= 3 && this.numItems <= 15 && /^-?[0-9][^.]*$/.test(this.numItems.toString());
  }

  // sort with preserving of the first item
  sortList(items: DashboardConfigItem[]) {
    const newList = items.slice(1);
    newList.sort((a, b) => a.state === b.state ? 0 : a.state ? -1 : 1);
    Object.assign(items, [items[0], ...newList]);
  }

  onTabEdit() {
    this.tabOrderEditMode = !this.tabOrderEditMode;
  }

  updateMainToggleState(type: string, items: DashboardConfigItem[]) {
    let anyActive = false;

    const newList = items.slice(1);
    newList.forEach(i => {
      if (i.state) {
        anyActive = true;
      }
    });

    if (!anyActive) {
      // switch off main tab toggle if all charts toggles are off
      items[0].state = false;
    }

    this.anyActive[type] = anyActive;
  }

  onItemToggle(e, dboard, item, index) {
    item.state = !item.state;

    let animationStarted = false;

    if (item.state) {
      // if not top and upper item also disabled
      if (index > 1 && !dboard.content[index - 1].state) {
        animationStarted = true;
        item.moveState = 'moveUp';
      }
    } else {
      // if not bottom and down item enabled
      if (index != 0 && index < (dboard.content.length - 1) && dboard.content[index + 1].state) {
        animationStarted = true;
        item.moveState = 'moveDown';
      }
    }

    if (item.name != dboard.content[0].name) {
      this.updateMainToggleState(dboard.name, dboard.content);
    }
    setTimeout(() => {
      item.moveState = 'static';
      if (!animationStarted) {
        this.sortList(dboard.content);
      }
    }, 150);
  }

  animationMoveDone(e, dboard) {
    if (e.toState == 'static') {
      this.sortList(dboard.content);
    }
  }

  drop(event: CdkDragDrop<unknown>, dboard) {
    if (event.previousIndex != 0 && event.currentIndex != 0) {
      moveItemInArray(dboard.content, event.previousIndex, event.currentIndex);
    }
  }

  onShowInterpolatedToggle() {
    this.configData.general.interpolated_data = !this.configData.general.interpolated_data;
  }

  onAutoApplyToggle() {
    this.configData.general.auto_apply_filters = !this.configData.general.auto_apply_filters;
  }

  onDeleteDashboard(dashboardItem) {
    this.dialogService.confirm(`Are you sure you want to delete "${dashboardItem.name}" dashboard?`).subscribe(confirmed => {
      if (confirmed) {
        const i = this.configData.dashboard.findIndex(e => e.name == dashboardItem.name);
        if (i != -1) {
          this.configData.dashboard.splice(i, 1);
        }

        // update max items
        this.maxItems = 0;
        this.configData.dashboard.forEach(item => {
          if (item.content.length > this.maxItems) {
            this.maxItems = item.content.length;
          }
        });

        this.matTabGroup.selectedIndex = 0;
      }
    });
  }

  onEditDashboard(dboard) {
    const dialogRef = this.dialog.open(EditDashboardFormComponent, {
      data: {
        title: dboard ? dboard.name : '',
        name: dboard ? dboard.content[0].name : '',
        items: this.topFiltersService.getOrgType() == 'fmcg' ? fmcgChartTypes : retailChartTypes,
        dashboards: this.configData.dashboard,
        selected: dboard ? dboard.content.map(v => v.name).slice(1) : [],
      },
      width: '900px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        if (dboard) {  // existing dashboard, NOTE: title can be changed!
          let savedItems: DashboardConfigItem[] = [...dboard.content];
          const newItems: DashboardConfigItem[] = result.items.map(i => ({ name: i, state: true }));

          // update state for existing items
          newItems.forEach(i => {
            const oldItem = savedItems.find(o => o.name == i.name);
            if (oldItem) {
              i.state = oldItem.state;
            }
          });

          const onlyNewItems = newItems.filter(i => !savedItems.find(o => i.name == o.name));
          savedItems = savedItems.filter(i => newItems.find(o => o.name == i.name));

          let newItem = this.configData.dashboard.find(i => i.name == result.title);
          if (newItem) {  // existing dashboard
            newItem.content = [{ name: result.name, state: savedItems[0].state },
            ...savedItems, ...onlyNewItems];

          } else { // new dashboard
            this.configData.dashboard.push({
              name: result.title, content: [{ name: result.name, state: savedItems[0].state },
              ...savedItems, ...onlyNewItems]
            });
            newItem = this.configData.dashboard.find(i => i.name == result.title);
          }

          this.sortList(newItem.content);

          // delete old type if Abbreviation was changed
          if (dboard.name != result.title) {
            const i = this.configData.dashboard.findIndex(e => e.name == dboard.name);
            if (i != -1) {
              this.configData.dashboard.splice(i, 1);
            }

            this.updateMainToggleState(result.title, newItem.content);
          }
        } else {
          // new dashboard
          this.configData.dashboard.push({
            name: result.title, content: [{ name: result.name, state: true },
            ...result.items.map(i => ({ name: i, state: true }))]
          });
          const newItem = this.configData.dashboard.find(i => i.name == result.title);


          this.updateMainToggleState(result.title, newItem.content);

          // select the last tab
          this.matTabGroup.selectedIndex = this.configData.dashboard.length;
        }

        // update max items
        this.maxItems = 0;
        this.configData.dashboard.forEach(item => {
          if (item.content.length > this.maxItems) {
            this.maxItems = item.content.length;
          }
        });
      } else {
        // cancel edit
        if (!dboard) { // if was add
          this.matTabGroup.selectedIndex = 0;
        }
      }
    });

  }

  onMoveLeft(dboard): void {
    const i = this.configData.dashboard.findIndex(e => e.name == dboard.name);

    if (i <= (this.maxTabs - this.maxCustom)) {
      return;  // can't move left
    }

    moveItemInArray(this.configData.dashboard, i, i - 1);
    this.configData.dashboard = _.cloneDeep(this.configData.dashboard);
    if (this.matTabGroup.selectedIndex == i + 1) {
      this.matTabGroup.selectedIndex = i;
    }
  }

  onMoveRight(dboard): void {
    const i = this.configData.dashboard.findIndex(e => e.name == dboard.name);

    if (i >= (this.configData.dashboard.length - 1)) {
      return;  // can't move right
    }

    moveItemInArray(this.configData.dashboard, i, i + 1);
    this.configData.dashboard = _.cloneDeep(this.configData.dashboard);
    if (this.matTabGroup.selectedIndex == i + 1) {
      this.matTabGroup.selectedIndex = i + 2;
    }
  }


  calcDialogHeight(): number {
    let h = this.maxItems * this.itemHeight + 70 + 20 + 46;
    if (h > 700) {
      h = 700;
    }

    return h;
  }

  calcEditButtonHeight(item): number {
    if (!item) {
      return 0;
    }
    return (this.maxItems - item.content.length) * this.itemHeight + 36 + 10;
  }

  trackItem(index: number, item) {
    return item.name;
  }

  onClose() {
    this.dialogRef.close(null);
  }
}
