import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Observable, Subject } from 'rxjs';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Zone } from '@ng-cloud/badger-core/models/interfaces/zone';
import { ApiModel, PolygonUtilities } from '@ng-cloud/badger-core';
import { NavZone } from '../../models/nav-zone';
import { StoreZone } from '../../models/store-zone';
import * as _ from 'lodash';

export class ZoneObject extends MapObject {
  static readonly minSize = 12.5;

  defaultOptions: any = {
    opacity: 1.0,
    selectable: false,
    evented: false,
    lockScalingFlip: true,
    cornerSize: 16,
    cornerColor: 'royalblue',
    borderColor: 'royalblue',
    borderScaleFactor: 2.5,
    padding: 4,
    perPixelTargetFind: true,
    polygonOptions: {
      fill: 'blue',
      opacity: 0.30,
      strokeWidth: 1,
      stroke: '#000000',
      perPixelTargetFind: true
    },
    labelOptions: {
      fontSize: 12,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      textBackgroundColor: '#484b4fAA',
      fill: 'white',
      perPixelTargetFind: true
    }
  };

  private polygon: fabric.Polygon;
  private zoneGroup: fabric.Group;
  private zoneLabel: fabric.Text;
  private minScaleX: number;
  private minScaleY: number;
  private scaling = false;

  private zoneSource: Subject<ZoneObject> = new Subject<ZoneObject>();
  zoneChanged: Observable<ZoneObject> = this.zoneSource.asObservable();

  constructor(public zone: ApiModel & Zone, private points: Point[], config?: any) {
    super(config);
  }

  destroy() {
    this.zoneSource.complete();
    super.destroy();
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    this.polygon = new fabric.Polygon(this.points, options.polygonOptions);
    this.polygon.data = this.zone;

    let description: string;

    if (this.zone instanceof NavZone) {
      description = this.zone.description ? this.zone.description : (this.zone as NavZone).mode === NavZone.KEEP_IN_MODE ? 'Keep In' : 'Keep Out';
    }
    else if (this.zone instanceof StoreZone) {
      description = this.zone.zoneTemplate ? this.zone.zoneTemplate.zoneName.description : '';
    }
    else {
      description = _.get(this.zone, 'description', '');
    }

    description = _.isNil(description) ? '' : ' ' + description + ' ';

    this.zoneLabel = new fabric.Text(description, options.labelOptions);
    const labelCenter = PolygonUtilities.getLabelCenter(this.points, 1);

    this.zoneLabel.left = labelCenter.x;
    this.zoneLabel.top = labelCenter.y;

    this.zoneGroup = new fabric.Group([this.polygon, this.zoneLabel], options);

    this.minScaleX = ZoneObject.minSize / this.zoneGroup.width;
    this.minScaleY = ZoneObject.minSize / this.zoneGroup.height;

    return this.zoneGroup;
  }

  protected setup(): void {
    this.on('moving', () => this.notifyChange());
    this.on('rotating', () => this.notifyChange());
    this.on('scaling', () => {
      this.scaling = true;
      this.setFill();
      this.notifyChange();
    });
    this.on('modified', () => {
      this.zoneGroup.set({
        scaleX: Math.max(this.zoneGroup.scaleX, this.minScaleX),
        scaleY: Math.max(this.zoneGroup.scaleY, this.minScaleY)
      });
      this.zoneGroup.setCoords();
      this.scaling = false;
      this.polygon.set('fill', this.getOptions().polygonOptions.fill);
      this.render();
      this.notifyChange();
    });

    this.zoneChanged.subscribe(() => {
        const labelScaleX = this.zoneGroup.width / (this.zoneGroup.width * this.zoneGroup.scaleX);
        const labelScaleY = this.zoneGroup.height / (this.zoneGroup.height * this.zoneGroup.scaleY);
        this.zoneLabel.set({ angle: -this.zoneGroup.angle, scaleX: labelScaleX, scaleY: labelScaleY });
      }
    );
  }

  protected notifyChange() {
    this.zoneSource.next(this);
  }

  getPoints(): Point[] {
    const matrix = this.polygon.calcTransformMatrix();

    return this.polygon.points
      .map((pt) => new fabric.Point(
        pt.x - this.polygon.pathOffset.x,
        pt.y - this.polygon.pathOffset.y))
      .map((pt) => fabric.util.transformPoint(pt, matrix));
  }

  protected setFill() {
    const color = this.isValid() ? this.getOptions().polygonOptions.fill : 'gray';
    if (color !== this.polygon.fill) {
      this.polygon.set('fill', color);
      this.render();
    }
  }

  private isValid() {
    return this.zoneGroup.scaleX >= this.minScaleX && this.zoneGroup.scaleY >= this.minScaleY;
  }

  static isValidZone(width: number, height: number): boolean {
    return width >= ZoneObject.minSize && height >= ZoneObject.minSize;
  }
}
