import { Section } from './section';
import { AbstractApiModel } from './abstract-api-model';
import { Region } from './region';
import { RealogramViewport } from './realogram-viewport';

import * as _ from 'lodash';
import { Tag } from '@ng-cloud/badger-core/models/tag';

export class PanoramaAnnotations extends AbstractApiModel<PanoramaAnnotations> {
  static readonly MODULE_DIRECTION_LEFT_TO_RIGHT = 'left_to_right';
  static readonly MODULE_DIRECTION_RIGHT_TO_LEFT = 'right_to_left';

  aisleName: string;
  blacklistedTags: string[] = [];
  userCreatedTags: Tag[] = [];
  dpi: number;
  editedIds: number[] = [];
  globalBottomShelfDepth: number;
  globalDepth: number;
  globalFixtureWidths: number;
  globalHeight: number;
  globalShelfHeight: number;
  heightPx: number;
  localHeights: any[] = [];
  manualViewports: RealogramViewport[] = [];
  regions: Region[] = [];
  pathId: number;
  pathRevision: number;
  planogramLeft: number;
  planogramRight: number;
  sections: Section[] = [];
  segmentBreaks: number[] = [];
  tagHeight: number;
  tagWidth: number;
  widthPx: number;
  tagZOffset: number;
  shelfLL: number[];
  moduleIdStart: number;
  moduleIdDirection: string;

  deserialize(json: any): this {
    this.dpi = json.dpi;
    this.pathId = json.path_id;
    this.pathRevision = json.path_revision;
    this.aisleName = json.aisle_name;
    this.widthPx = json.width_px;
    this.heightPx = json.height_px;
    this.globalDepth = json.global_depth;
    this.globalHeight = json.global_height;
    this.globalShelfHeight = json.global_shelf_height;
    this.globalFixtureWidths = json.global_fixture_widths;
    this.globalBottomShelfDepth = json.global_bottom_shelf_depth;
    this.tagWidth = json.tag_width;
    this.tagHeight = json.tag_height;
    this.planogramLeft = json.plano_left;
    this.planogramRight = json.plano_right;
    this.localHeights = json.local_heights;
    this.editedIds = json.editedIds;
    this.segmentBreaks = json.segment_breaks;
    this.blacklistedTags = json.blacklisted_tags;
    this.tagZOffset = json.tag_z_offset;
    this.shelfLL = json.shelf_ll;
    this.moduleIdStart = json.module_id_start;
    this.moduleIdDirection = json.module_id_direction;
    if (!this.moduleIdStart || !this.moduleIdDirection) {
      this.setModuleIdFromSections(json.sections);
    }

    if (json.sections) {
      json.sections.forEach((module) => {
        this.sections.push(new Section().deserialize(module));
      });
    }

    if (json.user_created_tags) {
      json.user_created_tags.forEach((tag) => {
        this.userCreatedTags.push(new Tag().deserialize(tag));
      });
    }

    let regions = json.regions
    if (!regions) {
      // needed for backward compatability with older realograms
      regions = json.non_shelf_regions
    }
    if (regions) {
      regions.forEach((regionJson, index) => {
        const region = new Region().deserialize(regionJson);
        region.zOrder = index;
        this.regions.push(region);
      });
    }

    if (json.manual_viewports) {
      json.manual_viewports.forEach((viewport) => {
        this.manualViewports.push(new RealogramViewport().deserialize(viewport));
      });
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      dpi: this.dpi,
      path_id: this.pathId,
      path_revision: this.pathRevision,
      aisle_name: this.aisleName,
      width_px: this.widthPx,
      height_px: this.heightPx,
      global_depth: this.globalDepth,
      global_height: this.globalHeight,
      global_shelf_height: this.globalShelfHeight,
      global_fixture_widths: this.globalFixtureWidths,
      global_bottom_shelf_depth: this.globalBottomShelfDepth,
      tag_width: this.tagWidth,
      tag_height: this.tagHeight,
      plano_left: this.planogramLeft,
      plano_right: this.planogramRight,
      local_heights: this.localHeights,
      editedIds: this.editedIds,
      segment_breaks: this.segmentBreaks,
      sections: _.map(this.sections, section => section.serialize()),
      blacklisted_tags: this.blacklistedTags,
      tag_z_offset: this.tagZOffset,
      shelf_ll: this.shelfLL,
      user_created_tags: _.map(this.userCreatedTags, tag => tag.serialize()),
      regions: _.map(this.regions, region => region.serialize()),
      manual_viewports: _.map(this.manualViewports, viewport => viewport.serialize()),
      module_id_start: this.moduleIdStart,
      module_id_direction: this.moduleIdDirection
    });
  }

  setModuleIdFromSections(jsonSections: any) {
    this.moduleIdStart = 1;
    this.moduleIdDirection = PanoramaAnnotations.MODULE_DIRECTION_LEFT_TO_RIGHT;
    if (jsonSections.length > 0) {
      const firstSection : any = _.first(jsonSections);
      const firstId = parseInt(firstSection.id, 10);
      if (!isNaN(firstId)) {
        const lastSection : any = _.last(jsonSections)
        const lastId = parseInt(lastSection.id, 10);
        if (firstId <= lastId) {
          this.moduleIdStart = firstId;
          this.moduleIdDirection = PanoramaAnnotations.MODULE_DIRECTION_LEFT_TO_RIGHT;
        } else {
          this.moduleIdStart = lastId;
          this.moduleIdDirection = PanoramaAnnotations.MODULE_DIRECTION_RIGHT_TO_LEFT;
        }
      }
    }
  }
}
