import { Layers, MapLayer } from './map-layer';
import { Pose } from '@ng-cloud/badger-core/models/pose';
import { Path } from '@ng-cloud/badger-core/models/path';
import { PathObject } from '../objects/path-object';
import { MapGrouping } from './map-grouping';

@Layers([
  { name: 'paths', zIndex: 25 }
])
export class PathGrouping extends MapGrouping<Path, PathObject> {

  constructor(public options: any = {}, public layerName: string = 'paths', public zIndex: number = 25) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(data: Path): PathObject {
    const startPose = this.storeMap.poseToPixels(new Pose(data.startX, data.startY));
    const endPose = this.storeMap.poseToPixels(new Pose(data.endX, data.endY));

    return new PathObject(data, startPose, endPose, this.options);
  }
}
