import { AbstractApiModel } from './abstract-api-model';
import { HasOrganization, Organization } from '@ng-cloud/badger-core';

export class User extends AbstractApiModel<User> implements HasOrganization {
  email: string;
  signInCount: number;
  currentSignInAt: Date;
  lastSignInAt: Date;
  currentSignInIp: string;
  lastSignInIp: string;
  uid: string;
  roles: string[];
  organizationId: number;
  rolesString: string;
  homeStoreId: number;
  organization: Organization;


  deserialize(json: any): this {
    this.email = json.email;
    this.signInCount = json.sign_in_count;
    this.currentSignInAt = json.current_sign_in_at;
    this.lastSignInAt = json.last_sign_in_at;
    this.currentSignInIp = json.current_sign_in_ip;
    this.lastSignInIp = json.last_sign_in_ip;
    this.uid = json.uid;
    this.roles = json.roles;
    this.rolesString = json.roles.toString();
    this.organizationId = json.organization_id;
    this.homeStoreId = json.home_store_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      email: this.email,
      sign_in_count: this.signInCount,
      current_sign_in_at: this.currentSignInAt,
      last_sign_in_at: this.lastSignInAt,
      current_sign_in_ip: this.currentSignInIp,
      last_sign_in_ip: this.lastSignInIp,
      uid: this.uid,
      roles: this.roles,
      organization_id: this.organizationId,
      home_store_id: this.homeStoreId
    });
  }
}
