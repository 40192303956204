import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { AlertMessageComponent } from '../common/alert-message/alert-message.component';
import { ConfirmBannerComponent } from '../common/confirm-banner/confirm-banner.component';

@Injectable()
export class MessageService {
  private infoSource = new Subject<string>();
  private alertSource = new Subject<string>();

  constructor(
    private snackBar: MatSnackBar
  ) {
  }

  info(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    this.infoSource.next(message);
    const conf = {
      duration: 5000,
      panelClass: action ? '' : 'no-action'
    };

    return this.snackBar.open(message, action, conf);
  }

  alert(message: string, data?: any): MatSnackBarRef<any> {
    this.alertSource.next(message);
    const conf = {
      panelClass: data ? 'alert-message' : ['alert-message', 'no-action']
    };

    if (data) {
      data['text'] = message;
      conf['data'] = data;
      return this.snackBar.openFromComponent(AlertMessageComponent, conf);
    }

    conf['duration'] = 5000;
    return this.snackBar.open(message, undefined, conf);
  }

  confirm(message: string): MatSnackBarRef<any> {
    const conf = {
      panelClass: 'alert-message',
      data: message
    };

    return this.snackBar.openFromComponent(ConfirmBannerComponent, conf);
  }

  infoMessages(): Observable<any> {
    return this.infoSource.asObservable();
  }

  alertMessages(): Observable<any> {
    return this.alertSource.asObservable();
  }
}
