import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class InsightAuditPath extends AbstractApiModel<InsightAuditPath> {
  insightAuditId: number;
  pathId: number;
  incompleteEntriesCount: number;
  completedEntriesCount: number;
  totalEntriesCount: number;
  issueTypes: string[];
  viewportCount: number;
  viewportAvailableCount: number;

  deserialize(json: any): this {
    this.insightAuditId = json.insight_audit_id;
    this.pathId = json.path_id;
    this.incompleteEntriesCount = +json.incomplete_entries_count;
    this.completedEntriesCount = json.completed_entries_count;
    this.totalEntriesCount = json.total_entries_count;
    this.issueTypes = json.issue_types;
    this.viewportCount = json.viewport_count;
    this.viewportAvailableCount = json.viewport_available_count;

    return super.deserialize(json);
  }

  percentComplete(): number {
    return 100 * this.completedEntriesCount / this.totalEntriesCount;
  }
}
