import { MapObject } from './map-object';
import { fabric } from 'fabric';
import { IdlePose, IdlePoseType } from '@ng-cloud/badger-core/models/idle-pose';
import * as _ from 'lodash';
import { Pose, RotationType } from '@ng-cloud/badger-core/models/pose';

export class IdlePoseObject extends MapObject {
  defaultOptions = {
    originX: 'center',
    originY: 'center',
    width: 24,
    height: 24,
    fill: 'transparent',
    opacity: 0.75,
    strokeWidth: 3,
    labelOptions: {
      fontSize: 8,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      fill: 'black',
      originX: 'center',
      originY: 'center'
    },
    arrowOptions: {
      height: 3,
      width: 6,
      originX: 'center',
      originY: 'center',
      angle: 90,
      opacity: 0.75
    }
  };

  constructor(public idlePose: IdlePose, public pose: Pose, options?: any) {
    super(options);
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    const square = new fabric.Rect(options);
    const text = new fabric.Text(options.text, options.labelOptions);
    const direction = new fabric.Triangle(options.arrowOptions);

    return new fabric.Group([square, text, direction], {
      selectable: false,
      evented: false,
      angle: this.pose.getRotation(RotationType.Degrees),
      originX: 'center',
      originY: 'center'
    });
  }

  protected getInstanceOptions(): any {
    let options = {
      left: this.pose.x,
      top: this.pose.y,
      labelOptions: {
        left: this.pose.x,
        top: this.pose.y,
        angle: -this.pose.getRotation(RotationType.Degrees)
      },
      arrowOptions: {
        left: this.pose.x,
        top: this.pose.y
      }
    };

    switch (this.idlePose.poseType) {
      case IdlePoseType.Dock: {
        options = _.merge(options, {
          stroke: 'green',
          labelOptions: {
            text: 'D',
            left: this.pose.x - 6,
            top: this.pose.y - 6
          },
          arrowOptions: {
            fill: 'green'
          }
        });
        break;
      }
      case  IdlePoseType.Park: {
        options = _.merge(options, {
          stroke: 'blue',
          labelOptions: {
            text: 'P',
            left: this.pose.x + 6,
            top: this.pose.y + 6
          },
          arrowOptions: {
            fill: 'blue'
          }
        });
        break;
      }
    }

    return options;
  }
}

