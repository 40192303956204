import { PoseGrouping } from './pose-grouping';
import { Heartbeat } from '@ng-cloud/badger-core/models/heartbeat';
import { PoseObject } from '@ng-cloud/badger-core/map/objects/pose-object';
import { Layers } from './map-layer';
import { MapGroupEntry } from './map-group-entry';

@Layers([
  { name: 'heartbeats', zIndex: 30 }
])
export class HeartbeatGrouping extends PoseGrouping<Heartbeat> {

  createObject(heartbeat: Heartbeat): PoseObject {
    const poseObj = super.createObject(heartbeat);

    heartbeat.isStopped && poseObj.setStopped();
    heartbeat.isCautionCone && poseObj.setCautionCone();
    heartbeat.isNavigatingToHazard && poseObj.setNavigatingToHazard();

    return poseObj;
  }

  setData(heartbeats: Heartbeat[]): MapGroupEntry<Heartbeat, PoseObject>[] {
    if (!heartbeats.length) {
      return [];
    }
    return super.setData(heartbeats);
  }
}
