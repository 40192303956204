import { MapGrouping } from './map-grouping';
import { Map } from '../../models/map';
import { Layers } from './map-layer';
import { ScanEventIssue } from '@ng-cloud/badger-core/models/scan-event-issue';
import { ScanEventIssueObject } from '@ng-cloud/badger-core/map/objects/scan-event-issue-object';

@Layers([
  { name: 'scanEventIssues', zIndex: 1 }
])

export class ScanEventIssueGrouping extends MapGrouping<ScanEventIssue, ScanEventIssueObject> {

  public static typeColors = {
    [ScanEventIssue.DEFAULT_ISSUE]: '#A3DF02',
    [ScanEventIssue.PRICE_MISMATCH]: '#A3DF02',
    [ScanEventIssue.SALE_PRICE_MISMATCH]: '#FFCB24',
    [ScanEventIssue.OUT_OF_STOCK]: '#FF5722',
    [ScanEventIssue.HOLE_OUT_OF_STOCK]: '#FF5722',
    [ScanEventIssue.ENHANCED_OUT_OF_STOCK]: '#29D6FF',
    [ScanEventIssue.NO_ISSUE]: '#D7DBDD',
    [ScanEventIssue.VIEWPORT_ISSUE]: '#DB7093',
    [ScanEventIssue.VIEWPORT_TAG_MISSING]: '#DB7093',
    [ScanEventIssue.VIEWPORT_TAG_NEW]: '#DB7093',
    [ScanEventIssue.VIEWPORT_TAG_NOT_SEEN]: '#DB7093',
    [ScanEventIssue.UNCONFIRMED_PRODUCT]: '#7B68EE'
  };

  static getColor(issue: ScanEventIssue) {
    return {
      fill: this.getColorForIssueType(issue.issueType, true),
      stroke: this.getColorForIssueType(issue.issueType)
    };
  }

  static getColorForIssueType(issueType: string, withAlpha: boolean = false): string {
    const color = ScanEventIssueGrouping.typeColors[issueType].substring(1);
    const red = parseInt(color.substring(0, 2), 16);
    const green = parseInt(color.substring(2, 4), 16);
    const blue = parseInt(color.substring(4, 6), 16);
    const alpha = withAlpha ? '0.2' : '1.0';

    return 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')';
  }

  static getPoints(issue: ScanEventIssue, storeMap: Map) {
    return issue.viewport.storeLocation.map(pt => storeMap.pointToPixels(pt));
  }

  createObject(issue: ScanEventIssue): ScanEventIssueObject {
    const points = ScanEventIssueGrouping.getPoints(issue, this.storeMap);
    return new ScanEventIssueObject(issue.viewport, points, Object.assign(ScanEventIssueGrouping.getColor(issue), this.options));
  }
}

