import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { CustomReport } from '@ng-cloud/badger-core/models/custom-report';

@Injectable()
export class CustomReportsService extends ApiService {

  /**
   *
   */
  getCustomReports(params?: any): Observable<CustomReport[]> {
    return this.list(CustomReport, 'custom_reports', params, 300);
  }
}
