import { MapGroupEntry } from './map-group-entry';
import { MapObject } from '../objects/map-object';

export class MapGroupingEvent<T, K extends MapObject> {
  eventType: GroupEventType;
  entry: MapGroupEntry<T, K>;

  constructor(eventType: GroupEventType, dataEntry?: MapGroupEntry<T, K>) {
    this.eventType = eventType;
    this.entry = dataEntry;
  }
}

export enum GroupEventType {
  added = 'added',
  removed = 'removed',
  changed = 'changed',
  render = 'render'
}
