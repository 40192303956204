import { AbstractApiModel } from './abstract-api-model';

export class ScanConfiguration extends AbstractApiModel<ScanConfiguration> {
  scanType: string;
  triggerDistance: number;
  speed: number;

  deserialize(json: any): this {
    this.scanType = json.scan_type;
    this.triggerDistance = json.trigger_distance;
    this.speed = json.speed;

    return super.deserialize(json);
  }

  serialize(): any {
    return { scan_type: this.scanType, trigger_distance: this.triggerDistance, speed: this.speed };
  }

}
