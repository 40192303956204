import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Map } from '../../models/map';

export class MapOverlayObject extends MapObject {
  defaultOptions: any = {
    selectable: false,
    evented: false,
    lockScalingFlip: true,
    fill: 'black',
    opacity: 0.6,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    width: 400,
    height: 400
  };

  private rect: fabric.Rect;

  constructor(public map: Map, config?: any) {
    super(config);
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    Object.assign(options, {
      left: 0,
      top: 0,
      width: this.map.width,
      height: this.map.height
    });

    this.rect = new fabric.Rect(options);

    return this.rect;
  }
}
