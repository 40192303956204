<div *ngIf="captureEvent">
  <mat-list>
    <mat-list-item>
      <h2>
        Capture Event
      </h2>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle>{{captureEvent.id}}</span>
      <span matListItemLine>ID</span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle>{{captureEvent.capturedAt | date: 'M/d/yy h:mm:ss a'}}</span>
      <span matListItemLine>Captured</span>
    </mat-list-item>
    <mat-list-item *ngIf="captureEvent.locationLabel">
      <span matListItemTitle>{{captureEvent.locationLabel}}</span>
      <span matListItemLine>Location Label</span>
    </mat-list-item>
    <mat-list-item *ngIf="captureEvent.zoneDescription">
      <span matListItemTitle>{{captureEvent.zoneDescription }}</span>
      <span matListItemLine>Zone Description</span>
    </mat-list-item>
    <mat-list-item *ngIf="hasAccess">
      <span matListItemTitle>{{captureEvent?.pose.x | number:'1.2-2'}} m</span>
      <span matListItemLine>X</span>
    </mat-list-item>
    <mat-list-item *ngIf="hasAccess">
      <span matListItemTitle>{{captureEvent?.pose.y | number:'1.2-2'}} m</span>
      <span matListItemLine>Y</span>
    </mat-list-item>
    <mat-list-item *ngIf="hasAccess">
      <span matListItemTitle>{{captureEvent?.pose.getDegrees() | number:'1.2-2'}}&deg;</span>
      <span matListItemLine>Angle</span>
    </mat-list-item>
    <ng-container *ngTemplateOutlet="additionalDetailTemplate; context: { $implicit: captureEvent }"></ng-container>
  </mat-list>
</div>
