import * as _ from 'lodash';

export class Price {
  quantity: number;
  price: number;
  type: string;

  getPricePerUnit(): number {
    return _.round((this.price / (this.quantity || 1)), 2);
  }

  toString(): string {
    if (this.type == 'none') {
      return 'none';
    }
    if (this.type == 'multiple') {
      return `${this.quantity}/${this.price}`;
    }

    return this.price.toString();
  }
}
