import { AbstractApiModel } from './abstract-api-model';
import { CaptureEvent } from './capture-event';
import { Store } from './store';
import { HasStore } from './interfaces/has-store';
import { Heartbeat } from './heartbeat';
import { Play } from './play';
import { Robot } from './robot';
import { ScanEvent } from './scan-event';

export class PlayExecution extends AbstractApiModel<PlayExecution> implements HasStore {
  robotPlayExecutionId: string;
  playId: number;
  playVersionId: number;
  playRevision;
  jobType: string;
  executionStatus: string;
  processStatus: string;
  startTime: Date;
  endTime: Date;
  captureEventCount: number;
  hazardCount: number;
  urgentCount: number;
  storeId: number;
  robotId: number;
  playOptions: any;
  focusSuccess: boolean;
  commandId: number;
  reasonPlayFinished: string;
  parentPlayExecutionId: number;

  store: Store;
  robot: Robot;
  play: Play;

  captureEvents: CaptureEvent[] = [];
  scanEvents: ScanEvent[] = [];
  heartbeats: Heartbeat[] = [];

  deserialize(json: any): this {
    this.robotPlayExecutionId = json.robot_play_execution_id;
    this.playId = json.play_id;
    this.playVersionId = json.play_version_id;
    this.playRevision = json.play_revision;
    this.jobType = json.job_type;
    this.executionStatus = json.execution_status;
    this.processStatus = json.process_status;
    this.startTime = json.start_time;
    this.endTime = json.end_time;
    this.storeId = json.store_id;
    this.robotId = json.robot_id;
    this.captureEventCount = json.capture_event_count;
    this.hazardCount = json.hazard_count;
    this.urgentCount = json.urgent_count;
    this.playOptions = json.play_options;
    this.focusSuccess = json.focus_success;
    this.commandId = json.command_id;
    this.reasonPlayFinished = json.reason_play_finished;
    this.parentPlayExecutionId = json.parent_play_execution_id;

    return super.deserialize(json);
  }
}
