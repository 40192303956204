import { AbstractApiModel } from './abstract-api-model';
import { User } from '@ng-cloud/badger-core';

export class Note extends AbstractApiModel<Note> {
  authorId: number;
  content: any;
  robotId: number;
  createdAt: Date;

  createdBy: User;

  deserialize(json: any): this {
    this.authorId = json.author_id;
    this.content = json.content;
    this.robotId = json.robot_id;
    this.createdAt = json.created_at;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      author_id: this.authorId,
      content: this.content,
      robot_id: this.robotId
    });
  }
}
