import { AbstractApiModel } from './abstract-api-model';
import { CdnFile } from '@ng-cloud/badger-core/models/cdn-file';

export class RobotTelemetry extends AbstractApiModel<RobotTelemetry> {
  data: any;
  type: string;
  robotId: number;

  cdnFiles: CdnFile[];

  deserialize(json: any): this {
    this.data = json.data;
    this.type = json.telemetry_type;
    this.robotId = json.robot_id;
    if (json.files) {
      this.cdnFiles = json.files.map(file => new CdnFile().deserialize(file));
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      type: this.type
    });
  }
}
