import { AbstractApiModel } from './abstract-api-model';
import { Point } from './interfaces/point';

export class Facing extends AbstractApiModel<Facing> {
  shelfLocation: Point[];

  deserialize(json: any): this {
    this.shelfLocation = json.shelf_polygon;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }
}
