import { Segment } from '@ng-cloud/badger-core/models/segment';
import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class Planogram extends AbstractApiModel<Planogram> {
  name: string;
  width: number;
  height: number;
  sequence: number;
  products: any[] = [];
  segments: Segment[] = [];

  deserialize(json: any): this {
    this.name = json.name;
    this.id = json.id;
    this.width = json.width;
    this.height = json.height;
    this.sequence = json.sequence;

    if (json.segments) {
      json.segments.forEach(segment => {
        this.segments.push(new Segment().deserialize(segment));
      });
    }

    if (json.products) {
      this.products = json.products;
    }

    return this;
  }
}
