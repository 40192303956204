import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point, RealogramViewport } from '@ng-cloud/badger-core';

export class RealogramViewportObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 1;

  defaultOptions: any = {
    cornerColor: 'white',
    cornerSize: 10,
    cornerStrokeColor: '#607d8b',
    cornerStrokeWidth: 5,
    fill: 'transparent',
    hasControls: false,
    hoverCursor: 'pointer',
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingFlip: true,
    opacity: 0.95,
    stroke: 'white',
    strokeUniform: true,
    transparentCorners: false
  };

  rect: fabric.Rect;
  strokeWidth = RealogramViewportObject.DEFAULT_STROKE_WIDTH;

  hashedColor: string;

  constructor(public viewport: RealogramViewport, private points: Point[], config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = RealogramViewportObject.DEFAULT_STROKE_WIDTH * scaleFactor;
    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
    this.hashedColor = RealogramViewportObject.getUniqueColor(this.viewport.sku.toString());
  }

  protected build(): fabric.Object {
    this.rect = new fabric.Rect(this.getOptions());
    this.rect.data = this.viewport;
    this.rect.setControlVisible('mtr', false);
    return this.rect;
  }

  protected getInstanceOptions(): any {
    return {
      width: Math.abs(this.points[0].x - this.points[1].x) - this.strokeWidth,
      height: Math.abs(this.points[0].y - this.points[2].y) - this.strokeWidth,
      left: this.points[0].x,
      top: this.points[2].y
    };
  }

  getUniqueColor() {
    return this.hashedColor;
  }

  getTypeColor() {
    return this.viewport.sku === '' ? 'red' : 'yellow';
  }

  static getUniqueColor(uniquer: string): string {
    let hash = 0;
    for (let i = 0; i < uniquer.length; i++) {
      hash = uniquer.charCodeAt(i) + ((hash << 5) - hash);
    }

    let colors = [hash % 256, (hash / 256) % 256, (hash / 256 / 256) % 256];
    const adj = 256 - Math.max(...colors);
    colors = colors.map(color => color + adj);

    return 'rgb(' + colors[0] + ',' + colors[1] + ',' + colors[2] + ')';
  }
}
