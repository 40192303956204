import { AbstractApiModel } from './abstract-api-model';

export class ZoneName extends AbstractApiModel<ZoneName> {
  identifier: string;
  description: string;

  deserialize(json: any): this {
    this.identifier = json.identifier;
    this.description = json.description;
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      identifier: this.identifier,
      description: this.description
    });
  }
}
