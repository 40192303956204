<nav id="navbar" class="bt-navbar">
  <mat-toolbar color="primary" class="mat-elevation-z3 badger-nav-bar">
    <a routerLink="/">
      <mat-icon id="badger-logo" class="badger-logo" svgIcon="badger_logo" fxLayoutAlign="start center"></mat-icon>
    </a>
    <span id="title">{{ title }}</span>
    <ng-content select="[signedInLeftAlign]"></ng-content>
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-md>
      <div fxLayoutAlign="start center" *ngIf="authService.hasCurrentUser() else signIn">
        <ng-content select="[signedInRightAlign]"></ng-content>
        <button #profileMenuButton mat-icon-button #profileMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="profileMenu" title="{{username}}">
          <mat-icon>person</mat-icon>
        </button>
      </div>
      <ng-template #signIn>
        <ng-content select="[signedRightAlign]"></ng-content>
      </ng-template>
    </div>

    <ng-content select="[smallScreenItems]"></ng-content>
  </mat-toolbar>

  <mat-menu #profileMenu [overlapTrigger]="false">
    <a mat-menu-item routerLink="users/profile">Profile</a>
    <button mat-menu-item (click)="signOut()">Sign Out</button>
    <mat-divider class="profile-menu-divider"></mat-divider>
    <mat-button-toggle-group name="switchTheme" [value]="currentTheme" class="theme-toggle-button-group"
                             [hideSingleSelectionIndicator]="true">
      <mat-button-toggle value="badger-light" (change)="toggleTheme($event.value)" class="theme-toggle-button"
                         matTooltip="Light Theme" [matTooltipShowDelay]="600">
        <mat-icon class="theme-toggle-icon">light_mode</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="badger-dark" (change)="toggleTheme($event.value)" class="theme-toggle-button"
                         matTooltip="Dark Theme" [matTooltipShowDelay]="600">
        <mat-icon class="theme-toggle-icon">dark_mode</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-menu>
</nav>
<mat-toolbar></mat-toolbar>
