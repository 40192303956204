import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: moment.Duration): string {
    if (Math.floor(value.asDays()) > 0) {
      return value.format('d[d] h[h] m[m]');
    }
    else if (Math.floor(value.asHours()) > 0) {
      return value.format('h:mm:ss');
    }
    else {
      return value.format('m:ss');
    }
  }
}
