<div class="container">
  <mat-tab-group *ngIf="configData" mat-stretch-tabs="false" animationDuration="0ms" [style.height.px]="calcDialogHeight()" #matTabGroup>
    <!-- General TAB -->
    <mat-tab label="General">
      <ng-template mat-tab-label>
        <label matTooltip="General settings" matTooltipClass="std-tooltip">General</label>
      </ng-template>
      <div class="elements">
        <div class="elements__item">
          <bt-slide-toggle
            caption="Show Interpolated Data"
            sticked="true"
            (change)="onShowInterpolatedToggle()"
            [checked]="configData.general.interpolated_data"
          >
          </bt-slide-toggle>
        </div>
        <div class="elements__item">
          <bt-slide-toggle
            caption="Automatically Apply Filters"
            sticked="true"
            (change)="onAutoApplyToggle()"
            [checked]="configData.general.auto_apply_filters"
          >
          </bt-slide-toggle>
        </div>
        <div class="elements__item">
          Top Items on Chart
          <input matInput type="number" [ngClass]="{ 'input-err': !isTopNumberValid }" maxlength="2" [(ngModel)]="numItems" min="3" max="15" (blur)="onNumItemsBlur()">
        </div>
      </div>
    </mat-tab>

    <!-- OSA, OOS, PM and Custom Tabs -->
    <mat-tab *ngFor="let item of configData.dashboard; let i = index" label="{{item.name}}">
      <ng-template mat-tab-label>
        <button class="icon-button-small" *ngIf="tabOrderEditMode && i > (maxTabs - maxCustom)" mat-icon-button (click)="onMoveLeft(item)"
                matTooltip="Move dashboard left" matTooltipClass="std-tooltip">
          <mat-icon>arrow_left</mat-icon>
        </button>

        <label class="tab-label" matTooltip="{{item.content[0].name}} dashboard configuration" matTooltipClass="std-tooltip">{{item.name}} </label>

        <button class="icon-button-small" *ngIf="tabOrderEditMode && i >= (maxTabs - maxCustom) && i < (configData.dashboard.length-1)"
                mat-icon-button (click)="onMoveRight(item)"
                matTooltip="Move dashboard right" matTooltipClass="std-tooltip">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </ng-template>

      <ng-container [ngTemplateOutlet]="typeTemplate" [ngTemplateOutletContext]="{ dboard: item }"></ng-container>

      <div class="elements__button" *ngIf="!staticDashboards.includes(item.name)" [style.height.px]="calcEditButtonHeight(item)">
        <button mat-flat-button color="primary" type="button" (click)="onEditDashboard(item)">Edit</button>
        <button mat-flat-button class="elements__button__delete" type="button" (click)="onDeleteDashboard(item)">Delete</button>
      </div>

    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>

        <button *ngIf="configData.dashboard.length > (maxTabs - maxCustom + 1)"  mat-icon-button (click)="onTabEdit()" class="new-dashboard-tab" matTooltip="Edit custom dashboards order" matTooltipClass="std-tooltip">
          <mat-icon *ngIf="!tabOrderEditMode">edit</mat-icon>
          <mat-icon *ngIf="tabOrderEditMode">done</mat-icon>
        </button>

        <button *ngIf="configData.dashboard.length < maxTabs" mat-icon-button (click)="onEditDashboard(null)" class="new-dashboard-tab" matTooltip="Create new custom dashboard"
                matTooltipClass="std-tooltip">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button class="container__close-button" mat-icon-button (click)="onClose()" matTooltip="Close configuration dialog and save changes"
          matTooltipClass="std-tooltip">
    <mat-icon>close</mat-icon>
  </button>
</div>

<ng-template #typeTemplate let-dboard="dboard">
  <div cdkDropList class="elements" (cdkDropListDropped)="drop($event, dboard)">
    <div
      *ngFor="let item of dboard.content; let i = index"
      [@moveState]="item.moveState"
      (@moveState.done)="animationMoveDone($event, dboard)"
    >
      <div class="elements__item"
           [attr.data-qa]="'qa-toggle-item-' + item.name"
           [ngClass]="i == 0 ? 'elements__main_item' : '' "
           [cdkDragData]="{dboard,item}"
           cdkDragLockAxis="y"
           cdkDragBoundary=".elements"
           cdkDrag>
        <mat-icon svgIcon="drag_vert" *ngIf="i != 0" title="Drag to change order"></mat-icon>
        <bt-slide-toggle
          caption="{{item.name}}"
          sticked="true"
          (change)="onItemToggle($event, dboard, item, i)"
          [checked]="item.state"
          [disabled]="i == 0 && anyActive && !anyActive[dboard.name]"
          [ngClass]="i == 0 ? 'main_item' : '' ">
        </bt-slide-toggle>
      </div>
    </div>
  </div>
</ng-template>
