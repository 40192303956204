import {
  BorderOrientation,
  BorderType,
  Map as ShelfMap,
  MapComponent,
  PanoramaMetadata,
  Realogram,
  RealogramBorder,
  RealogramBorderGrouping,
  RealogramViewport,
  RealogramViewportGrouping,
  RegionGrouping,
  ScanEvent,
  SectionGrouping,
  ShelfGrouping,
  Tag,
  TagGrouping
} from '@ng-cloud/badger-core';

import * as _ from 'lodash';

export class RealogramRenderer {
  static readonly SHELVES = 1002;
  static readonly REGIONS = 1003;
  static readonly SECTIONS = 1004;
  static readonly SECTION_IDS = 1005;
  static readonly PANORAMA_BORDERS = 1006;
  static readonly VIEWPORTS = 1007;
  static readonly TAGS = 1008;

  _mapComponent: MapComponent;
  _realogram: Realogram;
  _panoramaMetadata: PanoramaMetadata;
  _shelfMap: ShelfMap;
  _scanEvent: ScanEvent;

  colorizationMode = TagGrouping.TYPE;

  shelfGrouping: ShelfGrouping;
  regionGrouping: RegionGrouping;
  sectionBorderGrouping: RealogramBorderGrouping;
  sectionGrouping: SectionGrouping;
  panoramaBorderGrouping: RealogramBorderGrouping;
  tagGrouping: TagGrouping;
  viewportGrouping: RealogramViewportGrouping;

  constructor() {
  }

  public get scanEvent(): ScanEvent {
    return this._scanEvent;
  }

  public set scanEvent(scanEvent: ScanEvent) {
    this._scanEvent = scanEvent;
  }

  public get mapComponent() {
    return this._mapComponent;
  }

  public set mapComponent(mapComponent: MapComponent) {
    this._mapComponent = mapComponent;

    this.createMapGroupings();
  }

  public get realogram() {
    return this._realogram;
  }

  public set realogram(realogram: Realogram) {
    this._realogram = realogram;

    if (_.isNil(realogram)) {
      this.clear();
    }
  }

  public get shelfMap() {
    return this._shelfMap;
  }

  public set shelfMap(shelfMap: ShelfMap) {
    this._shelfMap = shelfMap;

    this.panoramaBorderGrouping.setStoreMap(this.shelfMap);
    this.sectionBorderGrouping.setStoreMap(this.shelfMap);
    this.sectionGrouping.setStoreMap(this.shelfMap);
    this.regionGrouping.setStoreMap(this.shelfMap);
    this.viewportGrouping.setStoreMap(this.shelfMap);
    this.shelfGrouping.setStoreMap(this.shelfMap);
    this.tagGrouping.setStoreMap(this.shelfMap);

    if (!_.isNil(this.scanEvent)) {
      this.panoramaMetadata = this.panoramaMetadataFromScanEvent();
    }
    else {
      this.panoramaMetadata = this.realogram.panoramaMetadata;
    }

    this.processPanoramaAnnotations();
    this.processRealogram();

    this.mapComponent.render();
  }

  public get panoramaMetadata() {
    return this._panoramaMetadata;
  }

  public set panoramaMetadata(panoramaMetadata: PanoramaMetadata) {
    this._panoramaMetadata = panoramaMetadata;

    this.panoramaBorderGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.sectionBorderGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.sectionGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.regionGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.viewportGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.shelfGrouping.setPanoramaMetadata(this.panoramaMetadata);
    this.tagGrouping.setPanoramaMetadata(this.panoramaMetadata);
  }

  protected panoramaMetadataFromScanEvent(): PanoramaMetadata {
    const panoramaMetadata = new PanoramaMetadata();
    const shelfLL = this.scanEvent.shelfCorners[0];
    const shelfUR = this.scanEvent.shelfCorners[1];
    panoramaMetadata.dpi = this.realogram.panoramaMetadata.dpi;
    panoramaMetadata.width = this.scanEvent.imageWidth;
    panoramaMetadata.height = this.scanEvent.imageHeight;
    panoramaMetadata.shelfLL = [
      this.metersToInches(shelfLL.x),
      this.metersToInches(shelfLL.y),
      0];
    panoramaMetadata.shelfUR = [
      this.metersToInches(shelfUR.x),
      this.metersToInches(shelfUR.y),
      0];
    panoramaMetadata.shelfCorners = [
      shelfLL.x,
      shelfLL.y,
      shelfUR.x,
      shelfUR.y];
    panoramaMetadata.xShelfAlign = this.metersToInches(shelfLL.x) + this.realogram.panoramaAnnotations.planogramLeft - shelfLL.x;
    panoramaMetadata.yShelfAlign = this.metersToInches(shelfLL.y) - shelfLL.y;

    return panoramaMetadata;
  }

  public metersToInches(meters: number): number {
    return meters * 39.3701;
  }

  public createMapGroupings() {
    const options = {
      selectable: false,
      evented: false,
      scaleFactor: Math.round(this.realogram.panoramaMetadata.dpi / 10)
    };

    this.shelfGrouping = new ShelfGrouping(options, 'fixtures', RealogramRenderer.SHELVES);
    this.regionGrouping = new RegionGrouping(options, 'regions', RealogramRenderer.REGIONS);
    this.sectionBorderGrouping = new RealogramBorderGrouping(options, 'modules', RealogramRenderer.SECTIONS);
    this.sectionGrouping = new SectionGrouping(options, 'module ids', RealogramRenderer.SECTION_IDS);
    this.panoramaBorderGrouping = new RealogramBorderGrouping(options, 'borders', RealogramRenderer.PANORAMA_BORDERS);
    this.tagGrouping = new TagGrouping(options, 'tags', RealogramRenderer.TAGS);
    this.viewportGrouping = new RealogramViewportGrouping(options, 'realogram viewports', RealogramRenderer.VIEWPORTS);

    this.mapComponent.addGrouping(this.panoramaBorderGrouping, true);
    this.mapComponent.addGrouping(this.shelfGrouping, true);
    this.mapComponent.addGrouping(this.sectionBorderGrouping, true);
    this.mapComponent.addGrouping(this.sectionGrouping, true);
    this.mapComponent.addGrouping(this.regionGrouping, true);
    this.mapComponent.addGrouping(this.tagGrouping, true);
    this.mapComponent.addGrouping(this.viewportGrouping, true);
  }

  protected clear() {
    if (this.mapComponent) {
      this.shelfGrouping.clearData();
      this.regionGrouping.clearData();
      this.sectionBorderGrouping.clearData();
      this.sectionGrouping.clearData();
      this.panoramaBorderGrouping.clearData();
      this.tagGrouping.clearData();
      this.viewportGrouping.clearData();
    }
  }

  protected processPanoramaAnnotations() {
    const panoramaAnnotations = this.realogram.panoramaAnnotations;

    if (panoramaAnnotations) {
      if (_.isNil(panoramaAnnotations.planogramLeft)) {
        panoramaAnnotations.planogramLeft = this.panoramaMetadata.xShelfAlign;
      }
      if (_.isNil(panoramaAnnotations.planogramRight)) {
        panoramaAnnotations.planogramRight = this.shelfMap.pxToM(this.shelfMap.width) + this.panoramaMetadata.xShelfAlign;
      }

      this.createPanoramaBorders();

      // Create Border Objects for sections
      const borders = _.map(_.slice(panoramaAnnotations.sections, 0, panoramaAnnotations.sections.length), section => {
        const border: RealogramBorder = new RealogramBorder();
        border.type = section.segmentBoundary === true ? BorderType.SEGMENT : BorderType.SECTION;
        border.position = section.right;
        border.length = panoramaAnnotations.globalHeight;
        return border;
      });

      this.sectionBorderGrouping.setData(borders);
      this.sectionGrouping.setData(panoramaAnnotations.sections);
      this.regionGrouping.setData(panoramaAnnotations.regions);
      this.tagGrouping.setPanoramaMetadata(this.panoramaMetadata);
      this.tagGrouping.setColorMode(this.colorizationMode);
      this.viewportGrouping.setColorMode(this.colorizationMode);
    }
  }

  protected processRealogram() {
    if (this.realogram) {
      const fixtures = [];
      const viewports: RealogramViewport[] = [];
      const tags: Tag[] = [];
      // Set the absolute x position for fixtures based on their segment position
      let adjustedX = this.realogram.panoramaAnnotations.planogramLeft;

      this.realogram.planograms[0].segments.forEach(segment => {
        segment.fixtures.forEach(fixture => {
          fixture.absoluteX = adjustedX;

          fixture.realogramViewports.forEach(viewport => {
            viewport.xPosition += adjustedX;

            viewports.push(viewport);

            const tag = new Tag().fromRealogramViewport(viewport);
            tag.xPosition += adjustedX;
            tags.push(tag);
          });
        });

        fixtures.push(...segment.fixtures);
        segment.absoluteX = adjustedX;
        adjustedX = adjustedX + segment.width;
      });

      this.shelfGrouping.setData(fixtures);
      this.viewportGrouping.setData(viewports);
      this.tagGrouping.setData(tags);
      this.mapComponent.render();
    }
  }

  protected createPanoramaBorders() {
    const leftBorder = new RealogramBorder();
    leftBorder.type = BorderType.PANORAMA;
    leftBorder.orientation = BorderOrientation.VERTICAL;
    leftBorder.position = this.realogram.panoramaAnnotations.planogramLeft;
    leftBorder.length = this.realogram.panoramaAnnotations.globalHeight;

    const rightBorder = new RealogramBorder();
    rightBorder.type = BorderType.PANORAMA;
    rightBorder.orientation = BorderOrientation.VERTICAL;
    rightBorder.position = this.realogram.panoramaAnnotations.planogramRight;
    rightBorder.length = this.realogram.panoramaAnnotations.globalHeight;

    const topBorder = new RealogramBorder();
    topBorder.type = BorderType.PANORAMA;
    topBorder.orientation = BorderOrientation.HORIZONTAL;
    topBorder.length = this.shelfMap.pxToM(this.panoramaMetadata.width, 1 / this.panoramaMetadata.dpi);
    topBorder.position = this.realogram.panoramaAnnotations.globalHeight;

    this.panoramaBorderGrouping.setData([leftBorder, rightBorder, topBorder]);
  }

  public setDisplayMode(displayMode: any) {
    this.colorizationMode = displayMode;

    switch (displayMode) {
      case 'type':
        this.tagGrouping.setColorMode(TagGrouping.TYPE);
        this.viewportGrouping.setColorMode(TagGrouping.TYPE);
        break;
      case 'unique':
        this.tagGrouping.setColorMode(TagGrouping.UNIQUE);
        this.viewportGrouping.setColorMode(TagGrouping.UNIQUE);
        break;
    }
    this.mapComponent.render();
  }
}
