import { Pose } from './pose';
import { AbstractApiModel } from './abstract-api-model';
import { Point } from './interfaces/point';

export class Map extends AbstractApiModel<Map> {
  mapType: string;
  image = {};
  width: number;
  height: number;
  resolution: number;
  origin: number[];

  public getUrl(size: string = 'full') {
    return this.image[size];
  }

  public mToPx(meters: number, resolution: number = this.resolution) {
    return meters / resolution;
  }

  public pxToM(pixels: number, resolution: number = this.resolution) {
    return pixels * resolution;
  }

  public pointToPixels(point: Point, resolution: number = this.resolution): Point {
    const mapOffsetX = this.origin[0] || 0;
    const mapOffsetY = this.origin[1] || 0;
    const x = this.mToPx(point.x - mapOffsetX, resolution);
    const y = -this.mToPx(point.y - mapOffsetY, resolution) + this.height;
    return { x: x, y: y };
  }

  public pointToMeters(point: Point, resolution: number = this.resolution): Point {
    const x = this.pxToM(point.x, resolution);
    const y = -this.pxToM(point.y - this.height, resolution);
    return { x: x, y: y };
  }

  /**
   * Convert the given real-world pose to local coordinates/rotation for the map image.
   * @param pose new pose scaled for the full size map image
   */
  public poseToPixels(pose: Pose): Pose {
    const point = this.pointToPixels(pose);
    return Pose.fromPoint(point, -pose.yaw);
  }

  /**
   * Convert the given local coordinates/rotation for the map image to real-world pose.
   * @param pose new pose in meters
   */
  public poseToMeters(pose: Pose): Pose {
    const point = this.pointToMeters(pose);
    return Pose.fromPoint(point, -pose.yaw);
  }

  deserialize(json: any): this {
    for (const size in json.image) {
      this.image[size] = json.image[size].url;
    }
    this.mapType = json.map_type;
    this.width = json.width;
    this.height = json.height;
    this.resolution = json.resolution;
    this.origin = json.origin;
    return super.deserialize(json);
  }
}
