import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { RealogramViewport } from '../../models/realogram-viewport';
import { RealogramViewportObject } from '../../map/objects/realogram-viewport-object';
import { PanoramaMetadata } from '@ng-cloud/badger-core/models/panorama-metadata';

export class RealogramViewportGrouping extends MapGrouping<RealogramViewport, RealogramViewportObject> {
  static readonly TYPE = 'type';
  static readonly UNIQUE = 'unique';

  colorMode = RealogramViewportGrouping.TYPE;
  panoramaMetadata: PanoramaMetadata;

  constructor(public options: any = {}, public layerName: string = 'viewports', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  setPanoramaMetadata(metadata: PanoramaMetadata) {
    this.panoramaMetadata = metadata;
  }

  setColorMode(displayMode: string) {
    this.colorMode = displayMode;

    this.getObjects().forEach(viewportObject => {
      this.setTagColor(viewportObject, displayMode === RealogramViewportGrouping.TYPE ? viewportObject.getTypeColor() : viewportObject.getUniqueColor());
    });
  }

  createObject(viewport: RealogramViewport): RealogramViewportObject {
    const xPosition = viewport.xPosition - this.panoramaMetadata.xShelfAlign;
    const yPosition = viewport.yPosition - this.panoramaMetadata.yShelfAlign;

    const points = [
      { x: xPosition, y: yPosition },
      { x: xPosition + viewport.width, y: yPosition },
      { x: xPosition + viewport.width, y: yPosition + viewport.height },
      { x: xPosition, y: yPosition + viewport.height }];

    const mappedPoints = points.map(pt => this.storeMap.pointToPixels(pt, 1 / this.panoramaMetadata.dpi));
    const viewportObject = new RealogramViewportObject(viewport, mappedPoints, this.options);

    this.setTagColor(viewportObject, this.colorMode === RealogramViewportGrouping.TYPE ? viewportObject.getTypeColor() : viewportObject.getUniqueColor());

    return viewportObject;
  }

  setTagColor(viewportObject: RealogramViewportObject, color: string) {
    viewportObject.getFabric().set({
      stroke: color
    });
  }
}
