export enum BorderType {
  PANORAMA = 'panorama',
  SECTION = 'section',
  SEGMENT = 'segment',
  SEGMENT_WARN = 'segment_warn'
}

export enum BorderOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export class RealogramBorder {
  type = BorderType.SECTION;
  orientation = BorderOrientation.VERTICAL;

  position: number;
  length: number;
}


