import { AbstractApiModel } from './abstract-api-model';
import * as _ from 'lodash';
import { User } from '@ng-cloud/badger-core';

export class Command extends AbstractApiModel<Command> {
  commandType: string;
  commandGroup: string;
  parameters: any;
  executionContext: string;
  priority: number;
  status: string;
  result: string;
  sendAttempts: number;
  sentAt: Date;
  percentComplete: number;
  timeToComplete: number;
  finishedAt: Date;
  cancelledAt: Date;
  robotId: number;
  commanderId: number;
  createdById: number;
  updatedById: number;

  createdBy: User;
  updatedBy: User;

  deserialize(json: any): this {
    this.commandType = json.command_type;
    this.commandGroup = json.command_group;
    this.parameters = !_.isEmpty(json.parameters) ? json.parameters : '';
    this.executionContext = json.execution_context;
    this.priority = json.priority;
    this.status = json.status;
    this.result = json.result;
    this.sendAttempts = json.send_attempts;
    this.sentAt = json.sent_at && new Date(json.sent_at);
    this.finishedAt = json.finished_at && new Date(json.finished_at);
    this.cancelledAt = json.cancelled_at && new Date(json.cancelled_at);
    this.robotId = json.robot_id;
    this.commanderId = json.commander_id;
    this.percentComplete = json.percent_complete;
    this.timeToComplete = json.time_to_complete;
    this.createdById = json.created_by_id;
    this.updatedById = json.updated_by_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      command_type: this.commandType,
      command_group: this.commandGroup,
      parameters: this.parameters,
      execution_context: this.executionContext,
      priority: this.priority,
      status: this.status,
      result: this.result,
      send_attempts: this.sendAttempts,
      sent_at: this.sentAt,
      finished_at: this.finishedAt,
      cancelled_at: this.cancelledAt,
      robot_id: this.robotId,
      commander_id: this.commanderId
    });
  }
}
