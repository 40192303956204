import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

export class OctoMap extends AbstractApiModel<OctoMap> {
  mapType = '3D';
  revision: number;
  storeId: number;
  url: string;

  deserialize(json: any): this {
    this.revision = json.revision;
    this.storeId = json.store_id;
    this.url = json.map && json.map.url;
    return super.deserialize(json);
  }
}
