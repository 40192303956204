import { AbstractApiModel } from './abstract-api-model';
import { Path } from '@ng-cloud/badger-core/models/path';
import { Zone } from '@ng-cloud/badger-core/models/interfaces/zone';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { PlayExecution } from '@ng-cloud/badger-core/models/play-execution';

export class ScanEvent extends AbstractApiModel<ScanEvent> implements Zone {
  public acceptedIssueCount: number;
  baseScanId: number;
  captureOnly: boolean;
  description: string;
  finishedAt: Date;
  imageHeight: number;
  imageUrl: string;
  imageWidth: number;
  issueCount: number;
  path: Path;
  pathId: number;
  playExecutionId: number;
  points: Point[];
  reviewCompleted: boolean;
  shelfCorners: Point[];
  sortOrdinal: number;
  startedAt: Date;

  playExecution: PlayExecution;

  deserialize(json: any): this {
    this.path = new Path().deserialize(json.path);
    this.pathId = json.path_id;
    this.startedAt = new Date(json.started_at);
    this.finishedAt = new Date(json.finished_at);
    this.issueCount = json.issue_count;
    this.acceptedIssueCount = json.accepted_issue_count;
    this.reviewCompleted = json.review_completed;
    this.description = this.path.name;
    this.imageUrl = json.image_url;
    this.imageWidth = json.image_width;
    this.imageHeight = json.image_height;
    this.shelfCorners = json.shelf_corners;
    this.playExecutionId = json.play_execution_id;
    this.baseScanId = json.base_scan_id;
    this.captureOnly = json.capture_only ? json.capture_only : false;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      review_completed: this.reviewCompleted
    });
  }
}
