<mat-menu #appMenu="matMenu" yPosition="above">
  <div *ngFor="let layer of enabledLayers()" mat-menu-item>
    <mat-checkbox [value]="layer.visible.toString()" (change)="toggleLayer(layer.name)" [checked]="layer.visible" (click)="$event.stopPropagation()">
      {{layer.name | startCase}}
    </mat-checkbox>
  </div>
</mat-menu>

<div class="map-control visibility-controls">
  <mat-card>
    <button mat-icon-button matTooltip="Show/Hide Layers" [matTooltipShowDelay]="600" [matMenuTriggerFor]="appMenu">
      <mat-icon>layers</mat-icon>
    </button>
  </mat-card>
</div>
