import { AbstractZone } from './abstract-zone';
import { ZoneTemplate } from './zone-template';
import * as _ from 'lodash';

export class StoreZone extends AbstractZone<StoreZone> {
  storeId: number;
  zoneTemplateId: number;
  zoneTemplate: ZoneTemplate;

  deserialize(json: any): this {
    this.storeId = json.store_id;
    this.zoneTemplateId = json.zone_template_id;
    this.zoneTemplate = new ZoneTemplate().deserialize(json.zone_template);
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      store_id: this.storeId,
      zone_template_id: this.zoneTemplateId
    });
  }

  sortArray(): string[] {
    return _.chain(this.zoneTemplate.zoneName.description.toLowerCase().trim())
      .split(/\W+/)
      .map(section => section.match(/\d+|\D+/g))
      .flatten()
      .map(section => /\d+/.test(section) ? section.padStart(5, '0') : section)
      .value();
  }

  static zoneSort = (a: string[], b: string[]) => {
    for (let i = 0; i < a.length && i < b.length; i++) {
      const compareValue = a[i].localeCompare(b[i]);
      if (compareValue !== 0) {
        return compareValue;
      }
    }
    return a.toString().localeCompare(b.toString());
  };
}
