import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Comment, Commentable, User } from '@ng-cloud/badger-core';

import * as _ from 'lodash';

export class Path extends AbstractApiModel<Path> implements Commentable {
  name: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  triggerDistance: number;
  robotDistance: number;
  speed: number;
  storeId: number;
  lightSegments: any;
  segmentSettings: any;
  active: boolean;
  activeRealogramId: number;
  realogramActivatedAt: Date;
  realogramActivatedById: number;
  lastExecutionPromotedBaseScan: boolean;

  comments: Comment[] = [];
  sortX: number;
  sortY: number;
  realogramActivatedBy: User;

  deserialize(json: any): this {
    this.name = json.name;
    this.startX = +json.start_x;
    this.startY = +json.start_y;
    this.endX = +json.end_x;
    this.endY = +json.end_y;
    this.triggerDistance = +json.trigger_distance;
    this.storeId = +json.storeId;
    this.lightSegments = json.light_segments;
    this.robotDistance = +(json.robot_distance || 0);
    this.speed = +(json.speed || 0);
    this.segmentSettings = json.segment_settings;
    this.active = json.path_active;
    this.lastExecutionPromotedBaseScan = json.promoted_base_scan ? json.promoted_base_scan : false;
    this.activeRealogramId = json.active_realogram_id;
    this.realogramActivatedAt = json.realogram_activated_at && new Date(json.realogram_activated_at);
    this.realogramActivatedById = json.realogram_activated_by;

    const sortStartX = Math.min(this.startX, this.endX);
    const sortEndX = Math.max(this.startX, this.endX);
    const sortStartY = Math.min(this.startY, this.endY);
    const sortEndY = Math.max(this.startY, this.endY);
    const slope = (sortEndY - sortStartY) / (sortEndX - sortStartX);

    if (slope > 1) {
      this.sortX = sortStartX;
      this.sortY = Math.round(sortStartY);
    }
    else {
      this.sortX = Math.round(sortStartX);
      this.sortY = sortStartY;
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name,
      store_id: this.storeId,
      start_x: this.startX,
      start_y: this.startY,
      end_x: this.endX,
      end_y: this.endY,
      trigger_distance: this.triggerDistance,
      light_segments: this.lightSegments,
      robot_distance: this.robotDistance,
      speed: this.speed,
      segment_settings: this.segmentSettings
    });
  }

  sortArray(): string[] {
    return _.chain(this.name.toLowerCase().trim())
      .split(/\W+/)
      .map(section => section.match(/\d+|\D+/g))
      .flatten()
      .map(section => /\d+/.test(section) ? section.padStart(5, '0') : section)
      .value();
  }

  getDisplayName(): string {
    return this.name;
  }

  getCommentableType(): string {
    return 'Path';
  }

  static pathSort = (a: string[], b: string[]) => {
    for (let i = 0; i < a.length && i < b.length; i++) {
      const compareValue = a[i].localeCompare(b[i]);
      if (compareValue !== 0) {
        return compareValue;
      }
    }
    return a.toString().localeCompare(b.toString());
  };
}
