import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { Barcode } from '@ng-cloud/badger-core';

export class BarcodeObject extends MapObject {
  defaultOptions: any = {
    selectable: true,
    evented: true,
    lockScalingFlip: true,
    borderColor: 'white',
    borderScaleFactor: 5.0,
    padding: 10,
    fill: 'rgba(0, 0, 255, 0.7)',
    opacity: 0.9,
    stroke: 'white',
    strokeWidth: 10,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    hoverCursor: 'pointer',
    shadow: {
      blur: 5,
      color: 'rgba(0, 0, 0, 0.7)',
      offsetX: 8,
      offsetY: 8
    }
  };

  private polygon: fabric.Polygon;

  constructor(public barcode: Barcode, private points: Point[], config?: any) {
    super(config);
  }

  protected build(): fabric.Object {
    const options = this.getOptions();

    this.polygon = new fabric.Polygon(this.points, options);
    this.polygon.data = this.barcode;

    return this.polygon;
  }
}
