import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'conciseDuration' })
export class ConciseDurationPipe implements PipeTransform {
  transform(value: moment.Duration): string {
    if (Math.floor(value.asDays()) > 0) {
      return value.format('d[d]');
    }
    else if (Math.floor(value.asHours()) > 0) {
      return value.format('h[h]');
    }
    else {
      return value.format('m:ss');
    }
  }
}
