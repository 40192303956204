import { MapObject } from '../objects/map-object';

export class MapGroupEntry<T, K extends MapObject> {
  data: T;
  object: K;

  constructor(data: T, object: K) {
    this.data = data;
    this.object = object;
  }
}
