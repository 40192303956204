import { AbstractApiModel } from './abstract-api-model';
import { PlayExecution } from './play-execution';
import { Path } from './path';

export class PathExecution extends AbstractApiModel<PathExecution> {
  message: string;
  status: string;
  processStatus: string;
  scanType: string;
  eventData: any;
  robotPathExecutionId: string;
  captureOnly: boolean;
  statusReason: string;

  scanEventId: number;
  scanStartedAt: Date;
  scanFinishedAt: Date;
  processStartedAt: Date;
  processFinishedAt: Date;
  uploadStartedAt: Date;
  uploadFinishedAt: Date;
  moderationStartedAt: Date;
  moderationFinishedAt: Date;
  shelfDistance: number

  playExecutionId: number;
  pathId: number;

  playExecution: PlayExecution;
  path: Path;

  deserialize(json: any): this {
    this.message = json.message;
    this.status = json.status;
    this.processStatus = json.process_status;
    this.eventData = json.event_data;
    this.scanType = json.scan_type;
    this.robotPathExecutionId = json.robot_path_execution_id;
    this.scanEventId = json.scan_event_id;
    this.scanStartedAt = json.scan_started_at;
    this.scanFinishedAt = json.scan_finished_at;
    this.processStartedAt = json.process_started_at;
    this.processFinishedAt = json.process_finished_at;
    this.uploadStartedAt = json.upload_started_at;
    this.uploadFinishedAt = json.upload_finished_at;
    this.moderationStartedAt = json.moderation_started_at;
    this.moderationFinishedAt = json.moderation_finished_at;
    this.playExecutionId = json.play_execution_id;
    this.pathId = json.path_id;
    this.captureOnly = json.capture_only;
    this.shelfDistance = json.shelf_distance;

    return super.deserialize(json);
  }
}
