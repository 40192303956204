import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ng-cloud/badger-core/models/store';
import { Map } from '@ng-cloud/badger-core/models/map';
import { OctoMap } from '@ng-cloud/badger-core/models/octo-map';
import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { HasStore } from '@ng-cloud/badger-core/models/interfaces/has-store';
import { StoreZone } from '@ng-cloud/badger-core/models/store-zone';
import { Path } from '@ng-cloud/badger-core/models/path';
import { Network } from '@ng-cloud/badger-core/models/network';
import { IdlePose } from '@ng-cloud/badger-core/models/idle-pose';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class StoreService extends ApiService {

  /**
   * Fetch all Stores
   */
  getStores(params?: any): Observable<Store[]> {
    return this.list(Store, 'stores', params, 300);
  }

  /**
   * Fetch Store data for the given id
   */
  getStore(id: number): Observable<Store> {
    return this.get(Store, `stores/${id}`, 600);
  }

  /**
   * Creates a Store
   */
  createStore(store: Store): Observable<Store> {
    return this.create(store, 'stores');
  }

  /**
   * Update Store
   */
  updateStore(store: Store): Observable<Store> {
    return this.update(store, `stores/${store.id}`);
  }

  /**
   * Fetch all store maps data for the given store id
   */
  getMaps(id: number, params?: any): Observable<Map[]> {
    return this.list(Map, `stores/${id}/maps`, params, 600);
  }

  /**
   * Fetch store map data for the given store id
   */
  getMap(id: number, params?: any): Observable<Map> {
    return this.get(Map, `stores/${id}/map`, 600, params);
  }

  downloadMap(storeId: number, mapType?: string): Observable<any> {
    return this.http.get(this.url(`stores/${storeId}/map/download`), {
      responseType: 'arraybuffer',
      params: mapType ? { map_type: mapType } : {}
    }).pipe(catchError((err) => this.handleError(err)));
  }

  addStores(models: HasStore[]): Observable<Store[]> {
    const modelsByStoreId = _.groupBy(models, 'storeId');
    const ids = _.uniq(models.map(model => model.storeId)).sort();
    return this.collectObservable(_.chunk(ids, 200), (idChunk) => this.getStores({ 'id[]': idChunk }).pipe(
        tap((stores: Store[]) => {
          stores.forEach(store => {
            const storeModels = modelsByStoreId[store.id];
            storeModels.forEach(model => model.store = store);
          });
        })
      ));
  }

  addStore(model: HasStore): Observable<Store> {
    return this.getStore(model.storeId).pipe(tap(store => model.store = store));
  }

  addMaps(stores: Store[]): Observable<Map> {
    return this.collectObservable(stores, store => this.addMap(store));
  }

  addMap(store: Store): Observable<Map> {
    return this.getMap(store.id).pipe(tap( storeMap => store.map = storeMap));
  }

  uploadMap(formData: FormData, id: number): Observable<Map> {
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');

    return this.http.post(this.url(`stores/${id}/maps`), formData, { headers: headers }).pipe(
      map(response => new Map().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  uploadOctoMap(formData: FormData, id: number): Observable<OctoMap> {
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');

    return this.http.post(this.url(`stores/${id}/octo_maps`), formData, { headers: headers }).pipe(
      map(response => new OctoMap().deserialize(response)),
      catchError(e => this.handleError(e)));
  }

  getOctoMaps(storeId: number, params?: any): Observable<OctoMap[]> {
    return this.list(OctoMap, `stores/${storeId}/octo_maps`, 600, params);
  }

  getOctoMap(storeId: number, octoMapId: number, params?: any): Observable<OctoMap> {
    return this.get(OctoMap, `stores/${storeId}/octo_maps/${octoMapId}`, 600, params);
  }

  getNetwork(storeId: number): Observable<Network> {
    return this.withCache(`stores/${storeId}/network`, {}, 600, () => this.http.get(this.url(`stores/${storeId}/network`))).pipe(
      map(response => {
        const network = new Network();
        const json = response;
        if (json) {
          network.deserialize(json);
        }
        return network;
      }),
      catchError(e => this.handleError(e)));
  }

  modifyNetwork(storeId: number, network: Network): Observable<Network> {
    return this.create(network, `stores/${storeId}/network`);
  }

  getZones(id: number, params?: any): Observable<StoreZone[]> {
    return this.list(StoreZone, `stores/${id}/zones`, params, 600);
  }

  modifyZones(id: number, zones: StoreZone[]): Observable<StoreZone[]> {
    const params = {
      store_zones_attributes: zones.map(z => z.serialize())
    };
    return this.http.post(this.url(`stores/${id}/zones`), params).pipe(
      tap(() => this.deleteCachedRequests(`stores/${id}/zones`)),
      map((response: any[]) => response.map(source => new StoreZone().deserialize(source))),
      catchError(e => this.handleError(e)));
  }

  getPaths(id: number): Observable<Path[]> {
    return this.list(Path, `stores/${id}/paths`, 600);
  }

  modifyPaths(id: number, paths: Path[]): Observable<Path[]> {
    const params = {
      paths_attributes: paths.map(p => p.serialize())
    };
    return this.http.post(this.url(`stores/${id}/paths`), params).pipe(
      tap(() => this.deleteCachedRequests(`stores/${id}/paths`)),
      map((response: any[]) => response.map(source => new Path().deserialize(source))),
      catchError(e => this.handleError(e)));
  }

  getIdlePoses(id: number): Observable<IdlePose[]> {
    return this.list(IdlePose, `stores/${id}/idle_poses`);
  }

  downloadZonePdf(storeId: number): Observable<any> {
    return this.http.get(this.url(`stores/${storeId}/zone_export`), {
      responseType: 'arraybuffer'
    }).pipe(catchError((err) => this.handleError(err)));
  }

  getStoreSaltConfig(storeId: number): Observable<any> {
    return this.http.get(this.url(`stores/${storeId}/salt_config`)).pipe(
      catchError((err) => this.handleError(err))
    );
  }
}
