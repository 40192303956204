import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Point } from '@ng-cloud/badger-core';
import * as _ from 'lodash';


export class Barcode extends AbstractApiModel<Barcode> {

  static UPC_A_MASK = [/[0-9,X]/, ' ', /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, ' ', /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, ' ', /[0-9,X]/];
  static UPC_E_MASK = [/[0-9,X]/, ' ', /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, ' ', /[0-9,X]/];
  static EAN_13_MASK = [/[0-9,X]/, ' ', /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, ' ', /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, /[0-9,X]/, ' ', /[0-9,X]/];

  static readonly UPC_A = 'UPC-A';
  static readonly UPC_E = 'UPC-E';
  static readonly EAN_13 = 'EAN-13';
  static readonly INVALID = 'INVALID';

  static readonly UNREVIEWED = 'UNREVIEWED';
  static readonly ACCEPTED = 'ACCEPTED';
  static readonly REJECTED = 'REJECTED';

  detectedGTIN: string;
  actualGTIN: string;
  GTIN14: string;
  barcodeRect: Point[];
  rejected: boolean;
  type: string;

  deserialize(json: any): this {
    this.detectedGTIN = json.detected_gtin;
    this.actualGTIN = json.actual_gtin;
    this.GTIN14 = json.gtin14;
    this.barcodeRect = json.barcode_rect;
    this.rejected = json.rejected;
    this.type = Barcode.getType(this.getGtin());

    if (this.type === Barcode.UPC_E) {
      Barcode.validateCode(this.detectedGTIN);
    }
    return super.deserialize(json);
  }

  serialize(): any {
    if (this.rejected === false) {
      this.generateGTIN14();
    }

    return Object.assign(super.serialize(), {
      id: this.id,
      actual_gtin: this.actualGTIN,
      gtin14: this.GTIN14,
      rejected: this.rejected,
      barcode_rect: this.barcodeRect
    });
  }

  getState(): string {
    return _.isNil(this.rejected) ? Barcode.UNREVIEWED : (this.rejected ? Barcode.REJECTED : Barcode.ACCEPTED);
  }

  getMask() {
    let mask;

    switch (Barcode.getType(this.getGtin())) {
      case Barcode.UPC_A:
        mask = Barcode.UPC_A_MASK;
        break;
      case Barcode.UPC_E:
        mask = Barcode.UPC_E_MASK;
        break;
      case Barcode.EAN_13:
        mask = Barcode.EAN_13_MASK;
        break;
      case Barcode.INVALID:
        mask = [''];
        break;
    }

    return mask;
  }

  getGtin() {
    return this.actualGTIN || this.detectedGTIN;
  }

  generateGTIN14() {
    switch (Barcode.getType(this.getGtin())) {
      case Barcode.UPC_A:
      case Barcode.EAN_13:
        this.GTIN14 = this.getGtin().padStart(14, '0');
        break;
      case Barcode.UPC_E:
        this.GTIN14 = Barcode.convertUPCEtoUPCA(this.getGtin()).padStart(14, '0');
        break;
    }
  }

  static convertUPCEtoUPCA(code: string) {
    let convertedCode = code.slice(1, 7);

    const firstDigit = parseInt(code.charAt(0), 10);
    const lastDigit = parseInt(code.charAt(6), 10);

    if (lastDigit > 4) {
      convertedCode = convertedCode.slice(0, 5) + '0000' + lastDigit;
    }
    else if (lastDigit > 2) {
      convertedCode = convertedCode.slice(0, lastDigit) + '00000' + convertedCode.slice(lastDigit, 5);
    }
    else {
      convertedCode = convertedCode.slice(0, 2) + lastDigit + '0000' + convertedCode.slice(2, 5);
    }

    convertedCode = firstDigit + convertedCode;
    convertedCode = convertedCode + Barcode.calculateCheckDigit(convertedCode);

    return convertedCode;
  }

  static getType(code: string): string {
    let type = Barcode.INVALID;

    if (code) {
      switch (code.length) {
        case 8:
          type = this.UPC_E;
          break;
        case 12:
          type = this.UPC_A;
          break;
        case 13:
          type = this.EAN_13;
          break;
      }
    }
    return type;
  }

  static calculateCheckDigit(code: string): number {

    // Split the digits and reverse them.
    const digits = code.split('').reverse();

    // Sum the odd digits + even digits * 3
    const total = _.reduce(digits, (sum, value, index) => sum + (+value * [3, 1][index % 2]), 0);

    // Compute the expected Check Digit
    return (10 - (total % 10)) % 10;
  }

  static validateCode(code: string, type?: string) {
    const checkType = _.isNil(type) ? Barcode.getType(code) : type;

    switch (checkType) {
      case Barcode.UPC_E:
        if (code.length != 8) {
          return false;
        }
        break;
      case Barcode.UPC_A:
        if (code.length != 12) {
          return false;
        }
        break;
      case Barcode.EAN_13:
        if (code.length != 13) {
          return false;
        }
        break;
      default:
        return false;
    }

    const checkCode = checkType === Barcode.UPC_E ? Barcode.convertUPCEtoUPCA(code) : code;

    // Get the Check Digit
    const checkDigit = +checkCode.charAt(checkCode.length - 1);

    return Barcode.calculateCheckDigit(checkCode.slice(0, checkCode.length - 1)) === checkDigit;
  }
}
