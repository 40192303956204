import { ApiService } from '@ng-cloud/badger-core/services/api.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiModel } from '../models/api-model';
import { Location } from '@angular/common';

/* Use this service if you need a second API server */
@Injectable()
export class Api2Service extends ApiService {
  static apiBase = '';
  static apiPath = '';

  url(route: string) {
    const basePath = Location.joinWithSlash(Api2Service.apiBase, Api2Service.apiPath);
    return Location.joinWithSlash(basePath, route);
  }

  destroy<T extends ApiModel>(model: T, route: string, body: T = null): Observable<T> {
    return this.http.delete(this.url(route), body ? { body: body.serialize() } : null).pipe(
      tap(() => this.cachedRequests.clear()),
      map(response => response ? model.deserialize(response) : model),
      catchError(e => this.handleError(e)));
  }
}
