import { Pose } from '@ng-cloud/badger-core/models/pose';

export class LaserScan {
  angleMin: number;
  angleMax: number;
  angleIncrement: number;
  ranges: number[];
  pose: Pose;

  deserialize(json: any): this {
    this.angleMin = json.angle_min;
    this.angleMax = json.angle_max;
    this.angleIncrement = json.angle_increment;
    this.ranges = json.ranges;
    this.pose = json.pose && new Pose().deserialize(json.pose);
    return this;
  }
}
