import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { CaptureEvent } from '../../models/capture-event';
import { AuthorizationService } from '@ng-cloud/badger-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bt-capture-event-detail',
  templateUrl: './capture-event-detail.component.html',
  styleUrls: ['./capture-event-detail.component.scss']
})
export class CaptureEventDetailComponent implements OnInit {
  @ContentChild(TemplateRef) additionalDetailTemplate;
  @Input() captureEvent: CaptureEvent;

  hasAccess = false;

  constructor(
    protected authorizationService: AuthorizationService) {
  }

  ngOnInit() {
    this.authorizationService.accessToAny(['admin']).pipe(take(1)).subscribe(hasAccess => {
      this.hasAccess = hasAccess;
    });
  }
}
