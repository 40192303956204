import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayThumbComponent } from './play-thumb/play-thumb.component';
import { BtCommonModule } from '../common/bt-common.module';
import { BtMapModule } from '../map/bt-map.module';
import { PlayFormComponent } from './play-form/play-form.component';
import { PlayMapComponent } from './play-map/play-map.component';
import { PlayOutputsFormComponent } from './play-outputs-form/play-outputs-form.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule,
    BtMapModule
  ],
  declarations: [
    PlayThumbComponent,
    PlayFormComponent,
    PlayMapComponent,
    PlayOutputsFormComponent
  ],
  exports: [
    PlayThumbComponent,
    PlayFormComponent,
    PlayMapComponent
  ]
})
export class BtPlayModule {
}
