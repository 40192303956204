export class RealogramViewport {
  id: string;
  sku: string;
  guid: string;
  xPosition: number;
  yPosition: number;
  width: number;
  height: number;
  depth: number;
  tagX: number;
  tagY: number;
  tagZ: number;
  tagWidth: number;
  tagHeight: number;
  verticalFacings: any[];
  horizontalFacings: any[];
  laydownFacings: any[];

  deserialize(json: any): this {
    this.id = json.guid;
    this.sku = json.sku;
    this.guid = json.guid;
    this.xPosition = json.x;
    this.yPosition = json.y;
    this.width = json.width;
    this.height = json.height;
    this.depth = json.depth;
    this.tagX = json.tag_x;
    this.tagY = json.tag_y;
    this.tagZ = json.tag_z;
    this.tagWidth = json.tag_width;
    this.tagHeight = json.tag_height;
    this.verticalFacings = json.vertical_facings;
    this.horizontalFacings = json.horizontal_facings;
    this.laydownFacings = json.laydown_facings;

    return this;
  }

  serialize(): any {
    return {
      guid: this.guid,
      x: this.xPosition,
      y: this.yPosition,
      width: this.width,
      height: this.height
    };
  }
}
