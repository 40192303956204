import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MultiUserRoomService } from '@ng-cloud/badger-core/services/multi-user-room.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bt-multi-user-room',
  templateUrl: './multi-user-room.component.html',
  styleUrls: ['./multi-user-room.component.scss']
})
export class MultiUserRoomComponent implements OnChanges, OnDestroy {
  @Input() roomName: string;
  @Input() capacity = 5;

  subscription: Subscription = null;
  roster: string[] = [];
  showList = false;
  private subscribedRoomName: string = null;

  constructor(
    protected multiUserRoomService: MultiUserRoomService
  ) {
  }

  ngOnChanges() {
    if (this.roomName == this.subscribedRoomName) {
      return;
    }
    if (this.roomName) {
      this.enter();
    }
    else {
      this.exit();
    }
  }

  ngOnDestroy() {
    this.exit();
  }

  enter() {
    if (this.subscription) {
      this.exit();
    }
    this.subscribedRoomName = this.roomName;
    this.subscription = this.multiUserRoomService.enterRoom(this.roomName)
      .subscribe(room => {
          this.roster = room.roster;
        }
      );
  }

  exit() {
    this.subscribedRoomName = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
