import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { BarcodeObject } from '@ng-cloud/badger-core/map/objects/barcode-object';
import { Barcode } from '@ng-cloud/badger-core/models/barcode';

export class BarcodeGrouping extends MapGrouping<Barcode, BarcodeObject> {

  public static colors = {
    [Barcode.UNREVIEWED]: '#FFFF00',
    [Barcode.REJECTED]: '#FF0000',
    [Barcode.ACCEPTED]: '#00FF00'
  };

  constructor(public options: any = {}, public layerName: string = 'barcodes', public zIndex: number = 1) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  static getColors(barcode: Barcode) {
    const state = barcode.getState();
    return { stroke: BarcodeGrouping.getColorForState(state), fill: BarcodeGrouping.getColorForState(state, true) };
  }

  static getColorForState(state: string, withAlpha: boolean = false): string {
    const color = BarcodeGrouping.colors[state].substring(1);
    const red = parseInt(color.substring(0, 2), 16);
    const green = parseInt(color.substring(2, 4), 16);
    const blue = parseInt(color.substring(4, 6), 16);
    const alpha = withAlpha ? '0.1' : '1.0';

    return 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')';
  }

  createObject(data: Barcode): BarcodeObject {
    const points = data.barcodeRect.map(pt => pt);
    return new BarcodeObject(data, points, Object.assign(BarcodeGrouping.getColors(data), this.options));
  }
}
