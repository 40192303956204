import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Organization } from '../models/organization';
import { HasOrganization } from '../models/interfaces/has-organization';
import { HasRetailOrganization } from '../models/interfaces/has-retail-organization';
import { VpnConfig } from '../models/vpn-config';
import { HttpHeaders } from '@angular/common/http';
import { InsightConfiguration } from '../models/insight-configuration';

@Injectable()
export class OrganizationService extends ApiService {

  /**
   * Fetch all Organizations
   */
  getOrganizations(params?: any): Observable<Organization[]> {
    return this.list(Organization, 'organizations', params);
  }

  /**
   * Fetch Organization data for the given id
   */
  getOrganization(id: number): Observable<Organization> {
    return this.get(Organization, `organizations/${id}`, 600);
  }

  /**
   * Create Organization
   */
  createOrganization(org: Organization): Observable<Organization> {
    return this.create(org, 'organizations');
  }

  /**
   * Update Organization
   */
  updateOrganization(org: Organization): Observable<Organization> {
    return this.update(org, `organizations/${org.id}`);
  }

  /**
   * Delete Organization
   */
  deleteOrganization(org: Organization): Observable<any> {
    return this.destroy(org, `organizations/${org.id}`);
  }

  /**
   * Sets value for organization on a model which has an organization field
   */
  addOrganization(model: HasOrganization): Observable<Organization> {
    return this.getOrganization(model.organizationId).pipe(tap(org => model.organization = org));
  }

  addRetailOrganization(model: HasRetailOrganization): Observable<Organization> {
    return this.getOrganization(model.retailOrganizationId).pipe(tap(org => model.retailerOrg = org));
  }

  uploadVpnConfig(formData: FormData, id: number): Observable<VpnConfig[]> {
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');

    return this.http.post(this.url(`organizations/${id}/vpn_config/create_configs`), formData, { headers: headers }).pipe(
      map((response: any[]) => {
        const configs = [];
        response.forEach(configJson => configs.push(new VpnConfig().deserialize(configJson)));
        return configs;
      }),
      catchError(e => this.handleError(e)));
  }

  getInsightConfigurations(organizationId): Observable<InsightConfiguration> {
    return this.get(InsightConfiguration, `organizations/${organizationId}/insight_configurations`, 600);
  }

  updateInsightConfiguration(insightConfig: InsightConfiguration): Observable<InsightConfiguration> {
    return this.update(insightConfig, `organizations/${insightConfig.organizationId}/insight_configurations`);
  }

  getVpnConfigs(organizationId): Observable<VpnConfig[]> {
    return this.list(VpnConfig, `organizations/${organizationId}/vpn_configs`);
  }

  getOrgTypes() {
    return ['retail', 'fmcg'];
  }
}
