<h2 mat-dialog-title>{{ title }}</h2>

<form [formGroup]="formGroup" (ngSubmit)="create()">
  <mat-dialog-content>
    <bt-form-errors></bt-form-errors>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="10px">

        <bt-form-field style="width: 100%;">
          <mat-form-field color="accent" appearance="outline">
            <input #abbrInput matInput name="title" formControlName="title" maxlength="5" (keyup)="inputUpperCase($event)">
            <mat-label>Abbreviation</mat-label>
          </mat-form-field>
          <bt-field-errors></bt-field-errors>
        </bt-form-field>


        <bt-form-field style="width: 100%;">
          <mat-form-field color="accent" appearance="outline">
            <input #nameInput matInput name="name" formControlName="name" minlength="2" maxlength="30">
            <mat-label>Dashboard Title</mat-label>
          </mat-form-field>
          <bt-field-errors></bt-field-errors>
        </bt-form-field>
      </div>
    </div>

    <div class="lists">
      <div class="lists__section">
        <mat-list role="list">
          <mat-list-item *ngFor="let item of allItems" role="listitem">
            <div class="lists__item">{{ item }}
              <button mat-icon-button (click)="onSelectItem(item)" class="icon-button-small">
                <mat-icon color="accent">add</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="lists__section">
        <mat-list role="list">
          <mat-list-item *ngFor="let item of selectedItems" role="listitem">
            <div class="lists__item">{{ item }}
              <button mat-icon-button (click)="onDeleteItem(item)" class="icon-button-small">
                <mat-icon color="accent">remove</mat-icon>
              </button>
            </div>
          </mat-list-item>
          <div class="add-hint" *ngIf="selectedItems.length==0"> Press plus (+) button to add charts</div>
        </mat-list>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button [matDialogClose]="">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="formGroup.invalid || selectedItems.length==0">Save</button>
  </mat-dialog-actions>
</form>
