import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActionCableService } from 'angular2-actioncable';

import { ErrorService } from '@ng-cloud/badger-core/services/error.service';
import { AuthorizationService } from '@ng-cloud/badger-core/services/authorization.service';
import { Store } from '@ng-cloud/badger-core/models/store';

import * as momentTz from 'moment-timezone';
import * as _ from 'lodash';

@Injectable()
export class TimezoneService extends ApiService {

  localTimezone: string;

  constructor(http: HttpClient, cableService: ActionCableService, errorService: ErrorService, authService: AuthorizationService) {
    super(http, cableService, errorService, authService);

    this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Fetch Timezone Map of countryCode names to timezone names
   */
  getTimezoneMap(): Observable<any[]> {
    return this.http.get(this.url('timezones')).pipe(
      filter(response => response != null)
    ) as Observable<any[]>;
  }

  /**
   * Returns the local (browser) timezone
   */
  getLocalTimezone() {
    return this.localTimezone;
  }

  getTimezoneAbbreviation(timezone: string): string {
    return momentTz().tz(timezone).format('z');
  }

  /**
   * Returns the timezone offset for the given date in minutes.
   */
  getTimezoneOffset(date: Date, timezone: string): number {
    const moment = momentTz.tz(date, timezone);
    const offsetString = moment.format('Z');
    const operator = offsetString.slice(0, 1);
    const numArray: number[] = offsetString.slice(1).split(':').map(Number);
    const minutes = _.reduce(numArray, (sum, value, index) => sum + (index === 0 ? value * 60 : value), 0);

    return Number(operator + minutes);
  }

  /**
   * Given a date and two timezones this method will return formatted date and time strings for each of the
   * specified timezones as well as a string indicating the offset in days (if applicable).
   */
  getTimeStringsWithOffset(date: any, primaryTz: string, secondaryTz: string, includeSeconds: boolean = false, dateFormat: string = 'MMM Do, YYYY'):
    { primaryTime, primaryDate, secondaryTime, secondaryDate, dayOffset } {
    const primaryMoment = momentTz.tz(date, primaryTz);
    const secondaryMoment = momentTz.tz(date, secondaryTz);

    const timeFormat = includeSeconds === false ? 'h:mm a ' : 'h:mm:ss a ';
    const primaryTime = primaryMoment.format(timeFormat) + this.getTimezoneAbbreviation(primaryTz);
    const primaryDate = primaryMoment.format(dateFormat);

    let secondaryTime;
    let secondaryDate;
    let dayOffset;

    if (primaryTz !== secondaryTz) {
      let dayOffsetString = '';

      if (primaryMoment.format(dateFormat) !== secondaryMoment.format(dateFormat)) {
        const localOffset = this.getTimezoneOffset(date, secondaryTz);
        const storeOffset = this.getTimezoneOffset(date, primaryTz);
        dayOffsetString = (localOffset > storeOffset) ? ' +1 Day' : ' -1 Day';
      }

      secondaryTime = secondaryMoment.format(timeFormat) + this.getTimezoneAbbreviation(secondaryTz);
      dayOffset = dayOffsetString;
      secondaryDate = secondaryMoment.format(dateFormat);
    }

    return { primaryTime, primaryDate, secondaryTime, secondaryDate, dayOffset };
  }

  getMainDateTimeString(timestamp: Date, store: Store): string {
    let timeString = '';
    if (store && timestamp) {
      const timeStrings = this.getTimeStringsWithOffset(timestamp, store.timezone, this.getLocalTimezone(), false, 'MM/D/YY');

      timeString = timeStrings.primaryDate + ' ' + timeStrings.primaryTime;
      if (timeStrings.secondaryTime) {
        timeString += ' (' + timeStrings.secondaryTime + timeStrings.dayOffset + ')';
      }
    }

    return timeString;
  }
}
