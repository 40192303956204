import { fabric } from 'fabric';
import { MapContextMenuOption } from '../map.component';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

export abstract class MapObject {
  defaultOptions: any = {};
  clientOptions: any;

  protected renderSource: Subject<MapObject> = new Subject<MapObject>();

  protected fabricObject: fabric.Object;
  private associatedEventHandlers = [];

  protected constructor(options: any = {}) {
    this.clientOptions = Object.assign({}, options);
  }

  /**
   * This method is required to specify how the fabric object should be built.
   */
  protected abstract build(): fabric.Object;

  /**
   * This method is called after the fabric object is built to allow any further configuration.
   * Most often this is where any event handlers will be attached.
   */
  protected setup(): void {
  }

  /**
   * Get final options object with custom options merged over defaults.
   */
  getOptions(): any {
    return _.merge({}, this.defaultOptions, this.clientOptions, this.getInstanceOptions());
  }

  /**
   * Get the underlying FabricJS object.
   */
  getFabric(): fabric.Object {
    if (!this.fabricObject) {
      this.fabricObject = this.build();
      this.setup();
    }
    return this.fabricObject;
  }

  /**
   * Notifies listeners (usually a map) that this object needs to be re-rendered.
   */
  render(): void {
    this.renderSource.next(this);
  }

  /**
   * Listen for render requests.
   */
  renderRequested(): Observable<MapObject> {
    return this.renderSource.asObservable();
  }

  /**
   * Set opacity based on position in a sequence.
   *
   * @param position index of the object in the sequence
   * @param count total count of objects in sequence
   * @param maximum limit of objects that may be in sequence
   */
  setFade(position: number, count: number, maximum: number = 30): void {
    if (this.getOptions().hasFade) {
      const opacity = 1 - (0.9 * (count - (position + 1)) / maximum);
      this.getFabric().set('opacity', opacity);
    }
  }

  /**
   * Helper to allow a FabricJS listener to be applied with TypeScript arrow syntax
   */
  on(eventType: string, callback: (event: any, context: any, object: MapObject) => void): (event) => void {
    const fabricObject = this.getFabric();
    const handler = function(event) {
      callback(event, this, fabricObject);
    };
    fabricObject.on(eventType, handler);
    return handler;
  }

  /**
   * Helper to listen for events on an associated object.
   * Handlers attached to an associated object will automatically be removed when this object is destroyed.
   */
  when(object: MapObject, eventType: string, callback: (event: any, context: any, object: MapObject) => void): (event) => void {
    const handler = object.on(eventType, callback);
    this.associatedEventHandlers.push({ object: object, eventType: eventType, handler: handler });
    return handler;
  }

  /**
   * Add a right click context menu to this object
   */
  contextMenu(menu: MapContextMenuOption[]) {
    this.getFabric().contextMenu = menu;
  }

  /**
   * Handles any necessary clean up when object is deleted, such as removing event handlers.
   * This should be overridden by subclasses where needed.
   */
  public destroy(): void {
    this.getFabric().off();
    this.associatedEventHandlers.forEach(obj => obj.object.getFabric().off(obj.eventType, obj.handler));
    this.renderSource.complete();
  }

  /**
   * Override this method to specify options that need to be calculated dynamically when an object is built.
   */
  protected getInstanceOptions(): any {
    return {};
  }
}
