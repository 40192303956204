import { Injectable } from '@angular/core';
import { ApiService, Comment } from '@ng-cloud/badger-core';
import { Commentable } from '@ng-cloud/badger-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends ApiService {
  createComment(comment: Comment, commentable: Commentable): Observable<Comment> {
    comment.commentableType = commentable.getCommentableType();
    comment.commentableId = commentable.id;
    return this.create(comment, `comments`);
  }

  updateComment(comment: Comment): Observable<Comment> {
    return this.update(comment, `comments/${comment.id}`);
  }

  deleteComment(comment: Comment): Observable<Comment> {
    return this.destroy(comment, `comments/${comment.id}`);
  }

  getComments(commentable: Commentable): Observable<Comment[]> {
    return this.list(Comment, `comments`, { commentable_id: commentable.id, commentable_type: commentable.getCommentableType()});
  }
}
