import { AbstractInsightReview } from './abstract-insight-review';
import { ViewportReviewResult } from './viewport-review-result';

export class ScanEventIssueInsightReview extends AbstractInsightReview {

  scanEventIssueId: number;
  rejected: boolean;
  rejectionReason: string;
  correctedPrice: string;
  autoModerationMethod: string;
  viewportReviewResult: ViewportReviewResult;

  userResponse = UserResponse.NONE;

  deserialize(json: any): this {
    this.scanEventIssueId = json.scan_event_issue_id;
    this.rejected = json.rejected;
    this.rejectionReason = json.rejection_reason;
    this.correctedPrice = json.corrected_price;
    this.autoModerationMethod = json.auto_moderation_method;

    if (json.viewport_review_result) {
      this.viewportReviewResult = new ViewportReviewResult().deserialize(json.viewport_review_result);
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      rejected: this.rejected,
      rejection_reason: this.rejectionReason,
      scan_event_issue_id: this.scanEventIssueId,
      corrected_price: this.correctedPrice,
      auto_moderation_method: this.autoModerationMethod,
      viewport_review_result_attributes: this.viewportReviewResult && this.viewportReviewResult.serialize()
    });
  }

  getType(): string {
    return 'insight';
  }
}

export enum UserResponse {
  NONE = 'NONE',
  YES = 'YES',
  NO = 'NO',
  INDETERMINATE = 'INDETERMINATE'
}

export enum RejectionReason {
  skuMisread = 'sku_misread',
  priceMisread = 'price_misread',
  holeMisidentification = 'hole_misidentification',
  productNotIdentified = 'product_not_identified',
  badArtwork = 'bad_artwork',
  badViewport = 'bad_viewport',
  badProductPose = 'bad_product_pose',
  missedHole = 'missed_hole',
  missingArtwork = 'missing_artwork',
  coarseGrainDiscrimination = 'coarse_grain_discrimination',
  fineGrainDiscrimination = 'fine_grain_discrimination',
  indeterminate = 'indeterminate'
}
